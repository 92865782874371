import React, { useEffect, useState } from 'react'
import './AdminDashboard.css'
import db, { auth } from '../firebase';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dialog, DialogContent, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';



function AdminDashboard(props) {
  const navigate = useNavigate()

  const [allAccounts, setAllAccounts] = useState(props.allAccounts)
  const [currentAccount, setCurrentAccount] = useState()
  const [categories, setCategories] = useState()
  const [allTakes, setAllTakes] = useState(props.allTakes)
  const [listAllData, setListAllData] = useState() //dom format
  const [searchByUserList, setSearchByUserList] = useState({})
  
  // filter data
  const [hideUnknown, setHideUnknown] = useState(false)

  useEffect(() => {
    // user db
    db.collection('users')
    .get().then(querySnapshot => {
      const accounts = {};
      querySnapshot.forEach(async userDoc => {
        accounts[userDoc.id] = userDoc.data()
      });
      setAllAccounts(Object.values(accounts))
      setCurrentAccount(Object.values(accounts).filter(account => account.email === auth.currentUser.email)[0])
      // Find a current account user
      // const currentAccount = Object.values(accounts).filter(accounts.userEmail === auth.currentUser.email)
      // console.log(Object.values(accounts))
    })
    // category db
    db.collection('categories').get().then(querySnapshot => {
      const categories = {};
      querySnapshot.forEach(async categoryDoc => {
        categories[categoryDoc.id] = categoryDoc.data();
      });
      setCategories(Object.values(categories)[0].title);
    })
    


    setListAllData(
      props.allTakes.map((take, index) => (
        <div className="allTakesData" key={Object.keys(take)[0]}>
          <p className="allTakesData__index">{index}</p>
          <p className="allTakesData__userId">{Object.values(take)[0].userId === 'anonymusUserId' ? "Unknown" : Object.values(take)[0].userId}</p>
          {/* <p className="allTakesData__name">{allAccountsTemp[0].find(account => account.email === Object.values(take)[0].userEmail)[0].firstName}</p> */}
          <p className="allTakesData__userEmail">{Object.values(take)[0].userEmail === 'anonymusUserEmail' ? "Unknown" : Object.values(take)[0].userEmail}</p>
          <p className="allTakesData__category">
            {Object.values(take)[0].aortaBlockId ? "Aorta" : ""}
            {Object.values(take)[0].bowelBlockId ? "Bowel" : ""}
            {Object.values(take)[0].cardioBlockId ? "Cardiothoracic" : ""}
            {Object.values(take)[0].ocularBlockId ? "Ocular" : ""}
            {Object.values(take)[0].pelvicBlockId ? "Pelvic" : ""}
            {Object.values(take)[0].renalBlockId ? "Renal" : ""}
            {Object.values(take)[0].biliaryBlockId ? "Biliary" : ""}
            {Object.values(take)[0].thoracicBlockId ? "Thoracic" : ""}
            {Object.values(take)[0].traumaBlockId ? "Trauma" : ""}
          </p>
          <p className="allTakesData__block">
            {Object.values(take)[0].aortaBlockId ? Object.values(take)[0].aortaBlockId : ""}
            {Object.values(take)[0].bowelBlockId ? Object.values(take)[0].bowelBlockId : ""}
            {Object.values(take)[0].cardioBlockId ? Object.values(take)[0].cardioBlockId : ""}
            {Object.values(take)[0].ocularBlockId ? Object.values(take)[0].ocularBlockId : ""}
            {Object.values(take)[0].pelvicBlockId ? Object.values(take)[0].pelvicBlockId : ""}
            {Object.values(take)[0].renalBlockId ? Object.values(take)[0].renalBlockId : ""}
            {Object.values(take)[0].biliaryBlockId ? Object.values(take)[0].biliaryBlockId : ""}
            {Object.values(take)[0].thoracicBlockId ? Object.values(take)[0].thoracicBlockId : ""}
            {Object.values(take)[0].traumaBlockId ? Object.values(take)[0].traumaBlockId : ""}
          </p>
          <p className="allTakesData__score">{Object.values(take)[0].takeScore}%</p>
          <p className="allTakesData__time">{Object.values(take)[0].takeTime}</p>
          <p className="allTakesData__submitTime">{Object.values(take)[0].submitTime.split(', ')[0]}</p>
          <p className="allTakesData__delete" onClick={() => deleteData(Object.keys(take)[0])}><DeleteOutlineIcon /></p>
        </div>
      ))
    )
  }, [])

  const deleteData = (takeId) => {
    db.collection("takes").doc(takeId).delete().then(() => {
      let updatedAllTakes = allTakes.filter(take => Object.keys(take)[0] !== takeId)
      setAllTakes(updatedAllTakes)
      setListAllData(
        updatedAllTakes.map((take, index) => (
          <div className="allTakesData" key={Object.keys(take)[0]}>
            <p className="allTakesData__index">{index}</p>
            <p className="allTakesData__userId">{Object.values(take)[0].userId === 'anonymusUserId' ? "Unknown" : Object.values(take)[0].userId}</p>
            {/* <p className="allTakesData__name">{allAccountsTemp[0].find(account => account.email === Object.values(take)[0].userEmail)[0].firstName}</p> */}
            <p className="allTakesData__userEmail">{Object.values(take)[0].userEmail === 'anonymusUserEmail' ? "Unknown" : Object.values(take)[0].userEmail}</p>
            <p className="allTakesData__category">
              {Object.values(take)[0].aortaBlockId ? "Aorta" : ""}
              {Object.values(take)[0].bowelBlockId ? "Bowel" : ""}
              {Object.values(take)[0].cardioBlockId ? "Cardiothoracic" : ""}
              {Object.values(take)[0].ocularBlockId ? "Ocular" : ""}
              {Object.values(take)[0].pelvicBlockId ? "Pelvic" : ""}
              {Object.values(take)[0].renalBlockId ? "Renal" : ""}
              {Object.values(take)[0].biliaryBlockId ? "Biliary" : ""}
              {Object.values(take)[0].thoracicBlockId ? "Thoracic" : ""}
              {Object.values(take)[0].traumaBlockId ? "Trauma" : ""}
            </p>
            <p className="allTakesData__block">
              {Object.values(take)[0].aortaBlockId ? Object.values(take)[0].aortaBlockId : ""}
              {Object.values(take)[0].bowelBlockId ? Object.values(take)[0].bowelBlockId : ""}
              {Object.values(take)[0].cardioBlockId ? Object.values(take)[0].cardioBlockId : ""}
              {Object.values(take)[0].ocularBlockId ? Object.values(take)[0].ocularBlockId : ""}
              {Object.values(take)[0].pelvicBlockId ? Object.values(take)[0].pelvicBlockId : ""}
              {Object.values(take)[0].renalBlockId ? Object.values(take)[0].renalBlockId : ""}
              {Object.values(take)[0].biliaryBlockId ? Object.values(take)[0].biliaryBlockId : ""}
              {Object.values(take)[0].thoracicBlockId ? Object.values(take)[0].thoracicBlockId : ""}
              {Object.values(take)[0].traumaBlockId ? Object.values(take)[0].traumaBlockId : ""}
            </p>
            <p className="allTakesData__score">{Object.values(take)[0].takeScore}%</p>
            <p className="allTakesData__time">{Object.values(take)[0].takeTime}</p>
            <p className="allTakesData__submitTime">{Object.values(take)[0].submitTime.split(', ')[0]}</p>
            <p className="allTakesData__delete" onClick={() => deleteData(Object.keys(take)[0])}><DeleteOutlineIcon /></p>
          </div>
        ))
      )
      console.log("Document successfully deleted!");
    }).catch((error) => {
      console.error("Error removing document: ", error);
    });
  }
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const toggleToShowDeleteSuccess = () => {
    setShowDeleteSuccess(!showDeleteSuccess)
  }

  
  const toggleUnknownUserData = () => {
    if (hideUnknown){
      setHideUnknown(false)
      db.collection('takes').get().then(querySnapshot => {
        const allTakesObj = [];
        querySnapshot.forEach(async takeDoc => {
          allTakesObj.push({[takeDoc.id]: takeDoc.data()})
        });
        setAllTakes(allTakesObj)
        setListAllData(
          allTakesObj.map((take, index) => (
            <div className="allTakesData" key={Object.keys(take)[0]}>
              <p className="allTakesData__index">{index}</p>
              <p className="allTakesData__userId">{Object.values(take)[0].userId === 'anonymusUserId' ? "Unknown" : Object.values(take)[0].userId}</p>
              {/* <p className="allTakesData__name">{allAccountsTemp[0].find(account => account.email === Object.values(take)[0].userEmail)[0].firstName}</p> */}
              <p className="allTakesData__userEmail">{Object.values(take)[0].userEmail === 'anonymusUserEmail' ? "Unknown" : Object.values(take)[0].userEmail}</p>
              <p className="allTakesData__category">
                {Object.values(take)[0].aortaBlockId ? "Aorta" : ""}
                {Object.values(take)[0].bowelBlockId ? "Bowel" : ""}
                {Object.values(take)[0].cardioBlockId ? "Cardiothoracic" : ""}
                {Object.values(take)[0].ocularBlockId ? "Ocular" : ""}
                {Object.values(take)[0].pelvicBlockId ? "Pelvic" : ""}
                {Object.values(take)[0].renalBlockId ? "Renal" : ""}
                {Object.values(take)[0].biliaryBlockId ? "Biliary" : ""}
                {Object.values(take)[0].thoracicBlockId ? "Thoracic" : ""}
                {Object.values(take)[0].traumaBlockId ? "Trauma" : ""}
              </p>
              <p className="allTakesData__block">
                {Object.values(take)[0].aortaBlockId ? Object.values(take)[0].aortaBlockId : ""}
                {Object.values(take)[0].bowelBlockId ? Object.values(take)[0].bowelBlockId : ""}
                {Object.values(take)[0].cardioBlockId ? Object.values(take)[0].cardioBlockId : ""}
                {Object.values(take)[0].ocularBlockId ? Object.values(take)[0].ocularBlockId : ""}
                {Object.values(take)[0].pelvicBlockId ? Object.values(take)[0].pelvicBlockId : ""}
                {Object.values(take)[0].renalBlockId ? Object.values(take)[0].renalBlockId : ""}
                {Object.values(take)[0].biliaryBlockId ? Object.values(take)[0].biliaryBlockId : ""}
                {Object.values(take)[0].thoracicBlockId ? Object.values(take)[0].thoracicBlockId : ""}
                {Object.values(take)[0].traumaBlockId ? Object.values(take)[0].traumaBlockId : ""}
              </p>
              <p  className="allTakesData__score">{Object.values(take)[0].takeScore}%</p>
              <p className="allTakesData__time">{Object.values(take)[0].takeTime}</p>
              <p className="allTakesData__submitTime">{Object.values(take)[0].submitTime.split(', ')[0]}</p>
              <p className="allTakesData__delete" onClick={() => deleteData(Object.keys(take)[0])}><DeleteOutlineIcon /></p> 
            </div>
          ))
        )
      })
    } else {
      setHideUnknown(true)
      const filteredUnknown = allTakes.filter(take => Object.values(take)[0].userEmail !== "anonymusUserEmail")
      setListAllData(
        filteredUnknown.map((take, index) => (
          <div className="allTakesData" key={Object.keys(take)[0]}>
            <p className="allTakesData__index">{index}</p>
            <p className="allTakesData__userId">{Object.values(take)[0].userId === 'anonymusUserId' ? "Unknown" : Object.values(take)[0].userId}</p>
            <p className="allTakesData__userEmail">{Object.values(take)[0].userEmail === 'anonymusUserEmail' ? "Unknown" : Object.values(take)[0].userEmail}</p>
            <p className="allTakesData__category">
              {Object.values(take)[0].aortaBlockId ? "Aorta" : ""}
              {Object.values(take)[0].bowelBlockId ? "Bowel" : ""}
              {Object.values(take)[0].cardioBlockId ? "Cardiothoracic" : ""}
              {Object.values(take)[0].ocularBlockId ? "Ocular" : ""}
              {Object.values(take)[0].pelvicBlockId ? "Pelvic" : ""}
              {Object.values(take)[0].renalBlockId ? "Renal" : ""}
              {Object.values(take)[0].biliaryBlockId ? "Biliary" : ""}
              {Object.values(take)[0].thoracicBlockId ? "Thoracic" : ""}
              {Object.values(take)[0].traumaBlockId ? "Trauma" : ""}
            </p>
            <p className="allTakesData__block">
              {Object.values(take)[0].aortaBlockId ? Object.values(take)[0].aortaBlockId : ""}
              {Object.values(take)[0].bowelBlockId ? Object.values(take)[0].bowelBlockId : ""}
              {Object.values(take)[0].cardioBlockId ? Object.values(take)[0].cardioBlockId : ""}
              {Object.values(take)[0].ocularBlockId ? Object.values(take)[0].ocularBlockId : ""}
              {Object.values(take)[0].pelvicBlockId ? Object.values(take)[0].pelvicBlockId : ""}
              {Object.values(take)[0].renalBlockId ? Object.values(take)[0].renalBlockId : ""}
              {Object.values(take)[0].biliaryBlockId ? Object.values(take)[0].biliaryBlockId : ""}
              {Object.values(take)[0].thoracicBlockId ? Object.values(take)[0].thoracicBlockId : ""}
              {Object.values(take)[0].traumaBlockId ? Object.values(take)[0].traumaBlockId : ""}
            </p>
            <p  className="allTakesData__score">{Object.values(take)[0].takeScore}%</p>
            <p className="allTakesData__time">{Object.values(take)[0].takeTime}</p>
            <p className="allTakesData__submitTime">{Object.values(take)[0].submitTime.split(', ')[0]}</p>
            <p className="allTakesData__delete" onClick={() => deleteData(Object.keys(take)[0])}><DeleteOutlineIcon /></p>
          </div>
        ))
      )
    }
  }

  
  const [searchByUserSelect, setSearchByUserSelect] = useState({})
  
  const selectUserNameToSearch = () => {
    // for(let i = 0; i < allAccounts.length; i++ ){
    //   if(allAccounts[i].email === )
    // }
  }


  return (
    <div className="adminDashboard__adminCheck">
      {currentAccount && currentAccount.isAdmin ? 
        <div className="adminDashboard">
          {/* all data */}
          <h2>All users test data</h2>
          <div className="toggleHideUnknown">
            <Switch onClick={() => toggleUnknownUserData()} /><span className="smallText">Hide unknown user data</span>
          </div>
          <div className="allTakesDataWrapper smallText">
            <div className="allTakesData allTakesDataLegend">
              <p className="allTakesData__index">#</p>
              <p className="allTakesData__userId">Id</p>
              <p className="allTakesData__userEmail">Email</p>
              <p className="allTakesData__category">Category</p>
              <p className="allTakesData__block">Block #</p>
              <p  className="allTakesData__score">Score</p>
              <p className="allTakesData__time">Time</p>
              <p className="allTakesData__submitTime">Submitted</p>
              <p className="allTakesData__delete"></p>
            </div>
            {listAllData}
          </div>

          <Dialog
            open={showDeleteSuccess}
            onClose={toggleToShowDeleteSuccess}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              The data is successfully deleted!
            </DialogContent>
          </Dialog>

        </div>
       :
        <div className="adminDashboard">
          <div className="centerMessage">
            <h2>We are sorry</h2>
            <p>You do not have access to this page.</p>
            <br />
            <button onClick={() => navigate('/contact')} className="ub_solidPurple">Request access</button>
          </div>
        </div>
      } 
    </div>
    
  )
}

export default AdminDashboard