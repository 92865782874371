import React from 'react'
import tempIntroVid from './sfaSeed/EF View - placeholder.mp4'

import './SfaCheatsheet.css'


function SfaCheatsheet() {
  return (
    <div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <video controls playsInline autoPlay muted loop controlsList='nodownload'>
            <source src={"https://ultrasoundbox.s3.amazonaws.com/sfa/EF+Video+Final.mp4"} />
          </video>
          {/* <iframe width="100%" height="auto" src="https://www.youtube.com/embed/ukALBfWv-Ws" title="POCUS for LV systolic function" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
        <div>
          <p>Overview Video of left ventricular systolic function assessment.</p>
        </div>
      </div>

      <div className="Cheatsheet__sectionHeadline">
        <h2>Parasternal Long</h2>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/0-30%25+PL.gif" alt="Parasternal Long axis view" />
        </div>
        <div>
          <p>Example of a Parasternal Long axis view with a reduced systolic function</p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/PL+Normal.gif" alt="Parasternal Long axis view" />
        </div>
        <div>
          <p>Example of a Parasternal long axis view with a normal systolic function</p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/71-100%25+PL..gif" alt="Parasternal Long axis view" />
        </div>
        <div>
          <p>Example of the Parasternal Long axis view with a hyperdynamic systolic function</p>
        </div>
      </div>


      <div className="Cheatsheet__sectionHeadline">
        <h2>Parasternal Short</h2>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/0-30%25+PS.gif" alt="Parasternal Short axis view" />
        </div>
        <div>
          <p>Example of a Parasternal Short axis view with a reduced systolic function</p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/PS+Normal.gif" alt="Parasternal Short axis view" />
        </div>
        <div>
          <p>Example of a Parasternal Short axis view with a normal systolic function </p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/71-100%25+PS.gif" alt="Parasternal Short axis view" />
        </div>
        <div>
          <p>Example of the Parasternal Short axis view with a hyperdynamic systolic function</p>
        </div>
      </div>



      <div className="Cheatsheet__sectionHeadline">
        <h2>Apical Four</h2>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/0-30%25+a4.gif" alt="Apical Four view" />
        </div>
        <div>
          <p>Example of an Apical 4 view with a reduced systolic function</p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/A4+Normal.gif" alt="Apical Four view" />
        </div>
        <div>
          <p>Example of a Apical Four view with a normal systolic function</p>
        </div>
      </div>
      <div className='Cheatsheet__sectionWrapper'>
        <div>
          <img src="https://ultrasoundbox.s3.amazonaws.com/sfa/reference_page/71-100%25+A4.gif" alt="Apical Four view" />
        </div>
        <div>
          <p>Example of an Apical 4 view with a hyperdynamic systolic function</p>
        </div>
      </div>



      
      
      
      
      
      




    </div>
  )
}

export default SfaCheatsheet