import React from "react";
import "./SfaAgreement.css";

function SfaAgreement() {
  return (
    <div className="sfaAgreementComponent">
      <h1>Consent Form</h1>
      <div className="sfaAgreementWrapper">
        <div className="sfaAgreement__box">
          <h2>Who are we? </h2>
          <p>
            We are a group of researchers who are part of the Lifespan health
            system in Providence, RI. We are conducting a research study that
            has been approved by the Lifespan Institutional Review Board (IRB).
          </p>

          <h2>What are the goals of this project?</h2>
          <p>
            We are asking you to be in a research study to help us to find out
            our web-based ultrasound education tool we developed is useful in
            teaching this difficult skill of ultrasound interpretation.{" "}
          </p>

          <h2>What will I be expected to do in this study?</h2>
          <p>
            To complete all the cases and two surveys within the module. Like
            many existing online question banks, you will work through and
            interpret 83 clips of ultrasound images.
          </p>
          <p>
            We are asking you to be in this study because you are able to find
            this skill useful in your clinical practice. We are offering all
            learners interested in the ability to interpret ultrasonographic
            clips the opportunity to participate in this trial.{" "}
          </p>
          <p>
            Being in this study is voluntary. You can choose to use this tool or
            to not use this tool. You can start using this tool, then change
            your mind and stop being in the study.{" "}
          </p>
          <p>
            If you consent to using this tool, your accuracy, and the time you
            spent interacting with the site will be recorded. Your survey
            responses will also be recorded as a group. Your individual answers
            and metrics will not be connected to you in any way, and we will not
            ask any personal information. The members of the study team will not
            be able to track your individual metrics or results.{" "}
          </p>

          <h2>Will I benefit from being in the study?</h2>
          <p>
            Although this study will not directly benefit you, it may be
            beneficial to medical education. This study may help guide the
            future of ultrasound education for future clinicians.
          </p>

          <h2>Will my study information be kept safe?</h2>
          <p>
            Every effort will be made to ensure your privacy and the
            confidentiality and security of your information. Only the
            investigators will have access to the grouped, anonymized data we
            collect. Any results that may be published will not identify
            individual participants. Your name will not be reported in any
            publication or presentation of the results of this research study;
            only the data obtained because of your participation in this study
            will be made public.{" "}
          </p>

          <h2>Will I get any money or gifts for being in this study?</h2>
          <p>
            You will not be compensated or reimbursed for your participation in
            this study.
          </p>

          <h2>Other information</h2>
          <p>
            You can contact Dr. Leland Perice at leland_perice@brown.edu if you
            have questions about this study.
          </p>
          <p>
            If you have any questions about your rights as a research
            participant you may contact the Lifespan Research Protection Office
            Institutional Review Board (IRB) at 401-444-6246. An IRB is a group
            of people that reviews research studies to make sure that
            participant rights and safety are protected.
          </p>

          <h2>Giving consent</h2>
          <p>
            By continuing, you are agreeing to participation in this study. Make
            sure you understand what the study is about before you sign.
          </p>

          <h2>Giving permission</h2>
          <p>
            By clicking the button below and continuing, you are agreeing to
            participation in this study. You are also agreeing that the
            information we collect will be used as we described earlier in this
            form.
          </p>
          <p>
            If you have any questions about the study after you sign this
            document, you can contact the study team using the information
            provided above.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SfaAgreement;
