import React, { useState } from 'react'

function SfaInterpretationDB(prop) {
  console.log('prop, prop.interpretation', prop, prop.interpretation)

  const [ interpretationContent, setInterpretationContent] = useState(prop.interpretation)


  return (
    <div>
      {interpretationContent === 1 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
1. Assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, myocardial walls show minimal thickening and the endocardial walls are coming together minimally, indicating severely reduced systolic function.
          </p>
          <br /><br />
          2. Another way to evaluate left ventricular systolic function is to look at how the anterior mitral valve moves relative to the interventricular septum. This can be used in conjunction with left ventricular wall visual assessments. 
          <ol type='a'>
            <li>
 If the mitral valve comes very close to the septum during diastole, this represents normal systolic function.
            </li>
            <li>
If it makes it nowhere near the septum during diastole, this represents reduced systolic function. 
            </li>
            <li>
 This method is less reliable if the heart rhythm is irregular, if there is aortic regurgitation, or mitral valve pathology. In these cases, EPSS no longer accurately represents the systolic function. Lastly, EPSS can only be used in the parasternal long axis view. 
            </li>
          </ol>
          <br /><br />
          <p>
In this clip, the mitral valve excursion is minimal. It is very far from touching the septum during diastole, indicating severely reduced systolic function.
          </p>
        </div>
      : null}
      {interpretationContent === 2 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
1. Assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, myocardial walls show reduced thickening and the endocardial walls are coming together poorly, indicating reduced systolic function. 
          </p>
          <br /><br />
          2. Another way to evaluate left ventricular systolic function is to look at how the anterior mitral valve moves relative to the interventricular septum. This can be used in conjunction with left ventricular wall visual assessments. 
          <ol type='a'>
            <li>
 If the mitral valve comes very close to the septum during diastole, this represents normal systolic function.
            </li>
            <li>
If it makes it nowhere near the septum during diastole, this represents reduced systolic function. 
            </li>
            <li>
 This method is less reliable if the heart rhythm is irregular, if there is aortic regurgitation, or mitral valve pathology. In these cases, EPSS no longer accurately represents the systolic function. Lastly, EPSS can only be used in the parasternal long axis view. 
            </li>
          </ol>
          <br /><br />
          <p>
          In this clip, the mitral valve is far from the septum during diastole, indicating moderately reduced systolic function.
          </p>
        </div>
      : null}
      {interpretationContent === 3 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
1. Assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, the myocardial walls are contracting well and the endocardial walls are coming together well, indicating normal systolic function. 
          </p>
          <br /><br />
          2. Another way to evaluate left ventricular systolic function is to look at how the anterior mitral valve moves relative to the interventricular septum. This can be used in conjunction with left ventricular wall visual assessments. 
          <ol type='a'>
            <li>
 If the mitral valve comes very close to the septum during diastole, this represents normal systolic function.
            </li>
            <li>
If it makes it nowhere near the septum during diastole, this represents reduced systolic function. 
            </li>
            <li>
 This method is less reliable if the heart rhythm is irregular, if there is aortic regurgitation, or mitral valve pathology. In these cases, EPSS no longer accurately represents the systolic function. Lastly, EPSS can only be used in the parasternal long axis view. 
            </li>
          </ol>
          <br /><br />
          <p>
          In this clip, the mitral valve is touching or nearly touching the septum during diastole, indicating normal or hyperdynamic systolic function.
          </p>
        </div>
      : null}
      {interpretationContent === 4 ?
        <div>
          <p>
              To qualitatively assess the function here, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
First, assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, the myocardial walls are contracting well and the  endocardial walls nearly make contact during end systole. Almost all of the anechoic space (representing the blood in the ventricle) disappears. 

<br /><br />
This indicates that nearly all the blood in the left ventricle is being pushed out with each cardiac cycle. This happens in states of decreased preload to the heart, or other words the amount of blood making it to the left ventricle is decreased. The most common clinical scenarios this occurs in is sepsis, acute blood loss, or dehydration. Dehydration and acute blood loss results in hypovolemic/hemorrhagic shock in which there is a reduction of overall fluid in the circulatory system. Sepsis results in distributive shock, where fluid is leaking out of the circulatory system into other spaces. EPSS, the other qualitative method described in the other questions with parasternal long axis views, is not able to distinguish between normal and hyperdynamic function.
          </p>
        </div>
      : null}
      {interpretationContent === 5 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
First, assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, myocardial walls show minimal thickening and the endocardial walls are coming together minimally, indicating severely reduced systolic function. 
          </p>
        </div>
      : null}
      {interpretationContent === 6 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
First, assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, myocardial walls show reduced thickening and the endocardial walls are coming together poorly, indicating reduced systolic function. 
          </p>
        </div>
      : null}
      {interpretationContent === 7 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
First, assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, the myocardial walls are contracting well and the endocardial walls are coming together well, indicating normal systolic function. 
          </p>
        </div>
      : null}
      {interpretationContent === 8 ?
        <div>
          <p>
              To qualitatively assess the function, evaluate the left ventricular myocardium throughout the cardiac cycle. This can be performed in any cardiac view (Parasternal Long, Parasternal Short, A4C).
              </p>
             <br /><br />
          <p>
First, assess changes to left ventricular wall thickness, which thickens during systole and thins during diastole. Next, visually assess whether the endocardial borders are moving symmetrically towards the center of the heart.   Note any changes to the width and area of anechoic space or blood in the left ventricle, by evaluating how well the endocardial walls come together.
          </p>
             <br /><br />
          <ol type='a'>

            <li>
If the myocardium is contracting well and the endocardial walls are moving inwardly well, the ejection fraction is normal. 
            </li>
            <li>
If the myocardial contraction is reduced and the endocardial walls do not move inwardly well, the ejection fraction is reduced. Differentiating  between moderately and severely reduced ejection can be challenging for new learners, but improves with deliberate practice.  
            </li>
            <li>
              If the myocardium is contracting well and the endocardial walls are almost touching, the ejection fraction is hyperdynamic. 
            </li>
            <li>
            This method may be less reliable if there are regional wall motion abnormalities. 
</li>
          </ol>
          <br /><br />
          <p>
Here, the myocardial walls are contracting well and the  endocardial walls nearly make contact during end systole. Almost all of the anechoic space (representing the blood in the ventricle) disappears. 
<br /><br />
This indicates that nearly all the blood in the left ventricle is being pushed out with each cardiac cycle. This happens in states of decreased preload to the heart, or other words the amount of blood making it to the left ventricle is decreased. The most common clinical scenarios this occurs in is sepsis, acute blood loss, or dehydration. Dehydration and acute blood loss results in hypovolemic/hemorrhagic shock in which there is a reduction of overall fluid in the circulatory system. Sepsis results in distributive shock, where fluid is leaking out of the circulatory system into other spaces. EPSS, the other qualitative method described in the other questions with parasternal long axis views, is not able to distinguish between normal and hyperdynamic function.
          </p>
        </div>
      : null}
    </div>
  )
}

export default SfaInterpretationDB