import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './TraumaView.css'
import './TorsoView.css'

import traumaBlockList from '../seed/traumaBlockSeed'

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Dialog, DialogContent } from '@mui/material';

function TraumaView() {
  
  const traumaBlockListRef = useRef(traumaBlockList)
  const { state } = useLocation(); //this doesn't work?
  const location = useLocation();
  const locPath = location.pathname;
  const locNumCut = locPath.split('-')
  const locNum = locNumCut[locNumCut.length - 1] //current block number

  const currentBlockViews = traumaBlockListRef.current[locNum - 1 * 1].usviews


  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);

  const [zoomView, setZoomView] = useState(false);
  const openZoomView = (e) => {
    setZoomView(!zoomView)
  }



  const handleOne = (e) => {
    setShowOne(!showOne);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
  }
  const handleTwo = (e) => {
    setShowOne(false);
    setShowTwo(!showTwo);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
  }
  const handleThree = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(!showThree);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
  }
  const handleFour = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(!showFour);
    setShowFive(false);
    setShowSix(false);
  }
  const handleFive = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(!showFive);
    setShowSix(false);
  }
  const handleSix = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(!showSix);
  }
  
  return (
    <div className="traumaView torsoViewComponent">
      { zoomView ?
        <Dialog
          open={zoomView}
          onClose={openZoomView}
          fullWidth="true"
          maxWidth="xl"
          className='zoomView__popupWrapper'
        >
          <DialogContent className="zoomView__popupContainer">
            <div className="zoomView__close smallText" onClick={openZoomView}>Close</div>
            {showOne ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[0]}`} />
                </video>
              </div>
            ) : ""}

            {showTwo ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[1]}`} />
                </video>
              </div>
            ) : ""}

            {showThree ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[2]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[2]}`} />
                </video>
              </div>
            ) : ""}

            {showFour ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[3]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[3]}`} />
                </video>
              </div>
            ) : ""}

            {showFive ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[4]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[4]}`} />
                </video>
              </div>
            ) : ""}
            
            {showSix ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[5]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[5]}`} />
                </video>
              </div>
            ) : ""}
          </DialogContent>
        </Dialog>

        : ""}


      <div className="torsoView__torsoWrapper">
        <div className="torsoView__torsoImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/torsoFront.jpg" alt="" />
        </div>
        <div className="torsoView__marks">
          { currentBlockViews[0] ? (
              <div onClick={() => handleOne()} className={showOne ? "torsoView__mark torsoView__markOneTrauma select" : "torsoView__mark torsoView__markOneTrauma"}>
              </div>
            ) : ("")
          }
          { currentBlockViews[1] ? (
            <div onClick={() => handleTwo()} className={showTwo ? "torsoView__mark torsoView__markTwoTrauma select" : "torsoView__mark torsoView__markTwoTrauma"}>
            </div>
          ) : ("")}
          {
            currentBlockViews[2] ? (
              <div onClick={() => handleThree()} className={showThree ? "torsoView__mark torsoView__markThreeTrauma select" : "torsoView__mark torsoView__markThreeTrauma"}>
              </div>
            ) : ("")
          }
          {
            currentBlockViews[3] ? (
              <div onClick={() => handleFour()} className={showFour ? "torsoView__mark torsoView__markFourTrauma select" : "torsoView__mark torsoView__markFourTrauma"}>
              </div>
            ) : ("")
          }
          {
            currentBlockViews[4] ? (
              <div onClick={() => handleFive()} className={showFive ? "torsoView__mark torsoView__markFiveTrauma select" : "torsoView__mark torsoView__markFiveTrauma"}>
              </div>
            ): ("")
          }
          {
            currentBlockViews[5] ? (
              <div onClick={() => handleSix()} className={showSix ? "torsoView__mark torsoView__markSixTrauma select" : "torsoView__mark torsoView__markSixTrauma"}>
              </div>
            ) : ("")
          }
          
          
        </div>
      </div>

      <div className="torsoView__usviewWrapper">
        {
          showOne || showTwo ||showThree || showFour || showFive || showSix ?
          <div className="torsoView__usviewZoom"><ZoomInIcon onClick={openZoomView} /></div>
          :
          ""
        }
        
        {showOne ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[0]}`} />
            </video>
          </div>
        ) : ""}

        {showTwo ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[1]}`} />
            </video>
          </div>
        ) : ""}

        {showThree ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[2]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[2]}`} />
            </video>
          </div>
        ) : ""}

        {showFour ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[3]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[3]}`} />
            </video>
          </div>
        ) : ""}

        {showFive ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[4]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[4]}`} />
            </video>
          </div>
        ) : ""}
        
        {showSix ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[5]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[5]}`} />
            </video>
          </div>
        ) : ""}

      </div>
    </div>
  )
}

export default TraumaView
