import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  setDoc,
  QuerySnapshot,
} from "firebase/firestore";
import db from "../firebase";

import femRuqDB from "./seeds/femRuqDB";
import femLuqDB from "./seeds/femLuqDB";
import femPelvicDB from "./seeds/femPelvicDB";
import FemExplanationComponent from "./FemExplanationComponent";

import FemView from "./FemView";
import SfaCheatsheet from "../sfa/SfaCheatsheet";

import "./FemQuestionScreen.css";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import FemCheatsheet from "./FemCheatsheet";

function FemQuestionScreen(prop) {
  // get date and time
  let currentTimestamp = Date.now();
  let date = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);

  const [questionSet, setQuestionSet] = useState(prop.accountInfo.qSet);
  const [currentQnum, setCurrentQnum] = useState(prop.accountInfo.currentQ);
  const [totalScore, setTotalScore] = useState(prop.accountInfo.totalScore);

  const [isCheatsheetOpen, setIsCheatsheetOpen] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const [showFemAnswer, setShowFemAnswer] = useState(false);
  const [doneAllQuestions, setDoneAllQuestions] = useState(false);
  const [selectedFemAnswers, setSelectedFemAnswers] = useState({
    ruq: null,
    luq: null,
    pelvic: null,
    none: null,
  });
  const [checkAnswerButtonActive, setCheckAnswerButtonActive] = useState(false);
  const [pastQuestionSet, setPastQuestionSet] = useState(
    prop.accountInfo.pastQuestionSet
  );

  // Question Cheatsheet toggle
  const toggleCheatsheet = () => {
    setIsCheatsheetOpen(!isCheatsheetOpen);
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;

    // Convert value to Boolean (true for "yes", false for "no")
    const booleanValue = value === "true";

    // Create a temporary copy of the current state, and update the appropriate value.
    const updatedAnswers = {
      ...selectedFemAnswers,
      [name]: booleanValue,
    };

    // Update the state with the new value
    setSelectedFemAnswers(updatedAnswers);

    // Check if all regions have either "true" or "false" selected
    const allRegionsSelected = ["ruq", "luq", "pelvic"].every(
      (region) =>
        updatedAnswers[region] === true || updatedAnswers[region] === false
    );

    // Enable the check answer button only if all questions have been answered
    setCheckAnswerButtonActive(allRegionsSelected);
  };

  const handleCheckboxChange = (event) => {
    const input = event.target;

    if (input.value === "none") {
      document.querySelector('input[id="ruq"]').checked = false;
      document.querySelector('input[id="luq"]').checked = false;
      document.querySelector('input[id="pelvic"]').checked = false;
      setSelectedFemAnswers({
        ruq: false,
        luq: false,
        pelvic: false,
        none: !selectedFemAnswers.none,
      });
    } else {
      document.querySelector('input[id="none"]').checked = false;
      setSelectedFemAnswers({
        ...selectedFemAnswers,
        [input.value]: !selectedFemAnswers[input.value],
        none: false,
      });
    }

    // Block or unblock the check the answer button
    if (
      document.querySelector('input[id="ruq"]').checked ||
      document.querySelector('input[id="luq"]').checked ||
      document.querySelector('input[id="pelvic"]').checked ||
      document.querySelector('input[id="none"]').checked
    ) {
      setCheckAnswerButtonActive(true);
    } else {
      setCheckAnswerButtonActive(false);
    }
  };

  // Check answer + score + show explanation
  const checkFemAnswers = () => {
    let correctNum = 0;
    const updatedPastQset = [
      ...pastQuestionSet,
      {
        timeTaken: "",
        questionIndex: currentQnum - 1,
        score: correctNum,
        resultSet: {
          ruq: {
            imageUrl: questionSet[currentQnum - 1].RUQquestion.imageUrl,
            answer: questionSet[currentQnum - 1].RUQquestion.fluid,
            userChoice: selectedFemAnswers.ruq,
            isCorrect:
              questionSet[currentQnum - 1].RUQquestion.fluid ===
              selectedFemAnswers.ruq,
          },
          luq: {
            imageUrl: questionSet[currentQnum - 1].LUQquestion.imageUrl,
            answer: questionSet[currentQnum - 1].LUQquestion.fluid,
            userChoice: selectedFemAnswers.luq,
            isCorrect:
              questionSet[currentQnum - 1].LUQquestion.fluid ===
              selectedFemAnswers.luq,
          },
          pelvic: {
            imageUrl: questionSet[currentQnum - 1].PELquestion.imageUrl,
            answer: questionSet[currentQnum - 1].PELquestion.fluid,
            userChoice: selectedFemAnswers.pelvic,
            isCorrect:
              questionSet[currentQnum - 1].PELquestion.fluid ===
              selectedFemAnswers.pelvic,
          },
        },
      },
    ];

    setTotalScore(correctNum + totalScore);
    setPastQuestionSet(updatedPastQset);

    // Add classes for right or wrong answers
    document.querySelectorAll(".femQradioLabel").forEach((label) => {
      const region = label.querySelector("input").name; // e.g., "ruq", "luq", "pelvic"
      const isCorrect =
        updatedPastQset[updatedPastQset.length - 1].resultSet[region].isCorrect;

      if (label.querySelector("input").checked) {
        if (isCorrect) {
          label.classList.add("correct");
          label.classList.remove("incorrect");
        } else {
          label.classList.add("incorrect");
          label.classList.remove("correct");
        }
      }
    });

    let femScore = 0;
    const resultSet = updatedPastQset[updatedPastQset.length - 1].resultSet;
    if (resultSet.ruq.isCorrect) femScore++;
    if (resultSet.luq.isCorrect) femScore++;
    if (resultSet.pelvic.isCorrect) femScore++;
    setTotalScore(femScore + totalScore);

    setPastQuestionSet(updatedPastQset);

    prop.updateCurrentAccountInfo({
      ...prop.accountInfo,
      currentQ: currentQnum + 1,
      pastQuestionSet: updatedPastQset,
      totalScore: totalScore,
    });

    // update the firebase database
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === prop.accountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              timeStamp: date,
              currentQ: currentQnum + 1,
              pastQuestionSet: updatedPastQset,
              totalScore: femScore + totalScore,
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });

    setCheckboxDisabled(true);
    setShowFemAnswer(true);
  };

  const removeAllCheck = () => {
    let inputs = document.querySelectorAll("input");
    for (let j = 0; j < inputs.length; j++) {
      if (inputs[j].checked) {
        inputs[j].checked = false;
      } else {
        inputs[j].checked = false;
      }
    }
  };

  const nextQ = () => {
    // Remove correct and incorrect classes for all radio labels
    document.querySelectorAll(".femQradioLabel").forEach((label) => {
      label.classList.remove("correct", "incorrect");
    });

    setShowFemAnswer(false);
    setCheckboxDisabled(false);
    removeAllCheck();
    setSelectedFemAnswers({
      ruq: null,
      luq: null,
      pelvic: null,
      none: null,
    });

    // If we reached 34 questions, trigger post-survey and stop increment
    if (pastQuestionSet.length === 34) {
      setDoneAllQuestions(true);
      prop.updateQuestionProgress(true); // Notify FemLanding that all questions are done

      // Update the parent component state with current progress
      prop.updateCurrentAccountInfo({
        ...prop.accountInfo,
        currentQ: 35, // Prepare to continue after post-survey
        pastQuestionSet: pastQuestionSet,
        totalScore: totalScore,
      });
    } else {
      // Otherwise, continue to the next question
      const nextQuestionNum = currentQnum + 1;
      setCurrentQnum(nextQuestionNum);

      // Update the parent component state with the next question
      prop.updateCurrentAccountInfo({
        ...prop.accountInfo,
        pastQuestionSet: pastQuestionSet,
        totalScore: totalScore,
        currentQ: nextQuestionNum,
      });
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      {doneAllQuestions ? null : (
        <div>
          {/* Cheatsheet modal */}
          {isCheatsheetOpen ? (
            <div
              className="sfaCheatsheetBackdrop"
              onClick={() => toggleCheatsheet()}
            ></div>
          ) : null}
          {isCheatsheetOpen ? (
            <div className="sfaCheatsheetModalWrapper">
              <div className="sfaCheatsheetModal">
                {/* <h1>Reference Sheet</h1> */}
                <div
                  className="sfaCheatsheetModal-close"
                  onClick={() => toggleCheatsheet()}
                >
                  <p>CLOSE</p>
                </div>
                <FemCheatsheet />
              </div>
            </div>
          ) : null}

          {/* score */}
          <div className="femQuestionProcessWrapper">
            <div className="femQuestionProcessText">
              <p>
                Progress: {currentQnum}/{questionSet.length}
              </p>
            </div>
            <div className="femQuestionProcessText">
              <p>
                {/* Calculate Correct Counts */}
                {(() => {
                  // Calculate total percentage score
                  const totalPossibleScore = pastQuestionSet.length * 3; // Each question has 3 parts
                  const currentScorePercentage =
                    totalPossibleScore > 0
                      ? ((totalScore / totalPossibleScore) * 100).toFixed(2)
                      : "0.00";

                  // Display Score
                  return `${currentScorePercentage}% correct`;
                })()}
              </p>
              {/* <span>
                {(() => {
                  let ruqCorrect = 0;
                  let luqCorrect = 0;
                  let pelvicCorrect = 0;

                  pastQuestionSet.forEach((question) => {
                    if (question.resultSet.ruq.isCorrect) ruqCorrect++;
                    if (question.resultSet.luq.isCorrect) luqCorrect++;
                    if (question.resultSet.pelvic.isCorrect) pelvicCorrect++;
                  });
                  return `(RUQ ${ruqCorrect} | LUQ ${luqCorrect} | Pelvic ${pelvicCorrect})`;
                })()}
              </span> */}
            </div>
            <div className="sfaQuestionProcessText femQuestionProcessTextLink">
              <div
                className="sfaQuestion__cheatsheetToggleBtn sfaQuestion__cheatsheetToggleBtnWithIcon"
                onClick={() => toggleCheatsheet()}
              >
                <ScienceOutlinedIcon />
                <p>Reference Sheet</p>
              </div>
            </div>
          </div>
          {/* images */}
          {questionSet.map((set, idx) => {
            if (idx === currentQnum - 1)
              return (
                <div>
                  {/* Views */}
                  <FemView
                    imgUrls={{
                      ruq: set.RUQquestion.imageUrl,
                      luq: set.LUQquestion.imageUrl,
                      pelvic: set.PELquestion.imageUrl,
                    }}
                  />
                </div>
              );
          })}

          {/* Question list start */}
          <div className="femQuestionChoiceWrapper">
            <div className="femQchoicesWrapper">
              <p className="femQuestion">
                Please indicate for each view if there is free fluid
              </p>

              <div className="femQchoiceWrapper" key="ruq">
                <label className="femQchoiceLabel">RUQ has free fluid</label>
                <div className="femQradioOptions">
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="ruq"
                      value="true"
                      checked={selectedFemAnswers.ruq === true}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    Yes
                    <span className="radioCheckmark"></span>
                  </label>
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="ruq"
                      value="false"
                      checked={selectedFemAnswers.ruq === false}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    No
                    <span className="radioCheckmark"></span>
                  </label>
                </div>
              </div>

              <div className="femQchoiceWrapper" key="luq">
                <label className="femQchoiceLabel">LUQ has free fluid</label>
                <div className="femQradioOptions">
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="luq"
                      value="true"
                      checked={selectedFemAnswers.luq === true}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    Yes
                    <span className="radioCheckmark"></span>
                  </label>
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="luq"
                      value="false"
                      checked={selectedFemAnswers.luq === false}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    No
                    <span className="radioCheckmark"></span>
                  </label>
                </div>
              </div>

              <div className="femQchoiceWrapper" key="pelvic">
                <label className="femQchoiceLabel">Pelvic has free fluid</label>
                <div className="femQradioOptions">
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="pelvic"
                      value="true"
                      checked={selectedFemAnswers.pelvic === true}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    Yes
                    <span className="radioCheckmark"></span>
                  </label>
                  <label className="femQradioLabel">
                    <input
                      type="radio"
                      name="pelvic"
                      value="false"
                      checked={selectedFemAnswers.pelvic === false}
                      onChange={(event) => handleRadioChange(event)}
                      disabled={checkboxDisabled}
                    />
                    No
                    <span className="radioCheckmark"></span>
                  </label>
                </div>
              </div>
            </div>
            {showFemAnswer ? (
              <div className="femAnswer">
                <div>
                  <p className="smallText" style={{ fontWeight: "bold" }}>
                    Answer:
                  </p>

                  <p className="femAnswerBold">
                    {questionSet[currentQnum - 1].RUQquestion.fluid
                      ? "RUQ "
                      : null}
                    {questionSet[currentQnum - 1].LUQquestion.fluid
                      ? "LUQ "
                      : null}
                    {questionSet[currentQnum - 1].PELquestion.fluid
                      ? "Pelvic "
                      : null}
                    {!questionSet[currentQnum - 1].RUQquestion.fluid &&
                    !questionSet[currentQnum - 1].LUQquestion.fluid &&
                    !questionSet[currentQnum - 1].PELquestion.fluid
                      ? "None above"
                      : null}
                  </p>
                </div>
                {/* <div>
                  <button
                    onClick={() => setShowFemExplanation(!showFemExplanation)}
                  >
                    {showFemExplanation
                      ? "Hide explanation"
                      : "Show explanation"}
                  </button>
                </div> */}

                <FemExplanationComponent
                  answerImg={{
                    ruq: questionSet[currentQnum - 1].RUQquestion.answerRef,
                    luq: questionSet[currentQnum - 1].LUQquestion.answerRef,
                    pelvic: questionSet[currentQnum - 1].PELquestion.answerRef,
                  }}
                />
              </div>
            ) : null}
          </div>

          {/* Button */}
          <div className="femButtonWrapper">
            {showFemAnswer ? (
              <button className="ub_solidPurple" onClick={nextQ}>
                Next
              </button>
            ) : (
              <button
                className="ub_solidPurple"
                onClick={checkFemAnswers}
                disabled={checkAnswerButtonActive ? false : true}
              >
                Check the answer
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FemQuestionScreen;
