import React from 'react'
import { useNavigate } from "react-router-dom";

import './Banner.css'

import { Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';

function Banner() {
  let navigate = useNavigate();

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + '...' : string;
  }

  return (
    <header className="banner">
      
      <div className="banner__contents">
        <div className="">
          <h2 className="banner__text">
            An interactive Ultrasound Education Platform
          </h2>
          <p className="banner__subtitle">
            Click any category below to get started on mastering point of care ultrasound
          </p>
        </div>
      </div>

      <div className="banner__bg">
        <img src="https://ultrasoundbox.s3.amazonaws.com/cardiothoracic/block6/2.gif" alt="" />
      </div>

    </header>
  )
}

export default Banner
