import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import './AuthorPopup.css'

import cardioBlockList from './seed/cardioBlockSeed';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import db from './firebase';


function LineEditorPopup(props) {

  const [showAboutLineEditor, setShowAboutLineEditor] = useState(false);
  // const ref = useRef()
  const { state } = useLocation();
  // console.log(props.lineEditorInfo)

  // All users
  const [lineEditorPhoto, setlineEditorPhoto] = useState()
  useEffect(() => {
    db.collection('users')
    .where("email", "==", `${props.lineEditorInfo.email}`)
    .get()
    .then(querySnapshot => {
      const accounts = [];
      querySnapshot.forEach(async userDoc => {
        accounts.push(userDoc.data())
      });
      setlineEditorPhoto(accounts[0].photo)
    })
  }, [])

  return (
    <div className="authorPopup" id="authorPopup">
      {/* profile photo */}
      <div className="authorPhotoWrapper">
        <div className="authorPhoto" style={{backgroundImage: lineEditorPhoto ? `url("${lineEditorPhoto}")` : "url('https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png')"}}></div>
      </div>

      {/* profile content */}
          <div className="authorContent">
            {props.lineEditorInfo.name ? (
              <p>{props.lineEditorInfo.name}
              </p>
            ) : (
              <p>Leland Perice</p>
            )}
            <span className="smallText">{props.lineEditorInfo.institution ? props.lineEditorInfo.institution : ""}</span><br /><br />
            {/* Author info Linkedin*/}
            {props.lineEditorInfo.linkedinUrl && props.lineEditorInfo.linkedinUrl !== null ? (
              <a href={`${props.lineEditorInfo.linkedinUrl}`} target="_blank" style={{color:"#0e76a8"}}><LinkedInIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Facebook*/}
            {props.lineEditorInfo.facebookUrl ? (
              <a href={`${props.lineEditorInfo.facebookUrl}`} target="_blank" style={{color: "#3b5998"}}><FacebookIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Instagram*/}
            {props.lineEditorInfo.instagramUrl ? (
              <a href={`${props.lineEditorInfo.instagramUrl}`} target="_blank" style={{color: "#833AB4"}}><InstagramIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Twitter*/}
            {props.lineEditorInfo.twitterUrl ? (
              <a href={`${props.lineEditorInfo.twitterUrl}`} target="_blank" style={{color: "#1DA1F2"}}><TwitterIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Email – on hold*/}
            {/* {props.lineEditorInfo.email ? (
              <a href={`${props.lineEditorInfo.email}`} target="_blank"><MailOutlineIcon />
              </a>
            ) : (
              ""
            )} */}
            {/* Author info Phone Number*/}
            {props.lineEditorInfo.phoneNumber ? (
              <a href={`${props.lineEditorInfo.phoneNumber}`} target="_blank"><PhoneIphoneIcon />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
  )
}

export default LineEditorPopup
