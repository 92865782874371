import React, { useEffect, useRef, useState } from 'react'
import db, { auth } from './firebase'
import { useSelector } from 'react-redux'
import { selectUser } from './features/userSlice'

import './Row.css'
import BannerLoggedIn from './BannerLoggedIn'
import AortaBlockList from './AortaBlockList'
import BowelBlockList from './BowelBlockList'
import CardioBlockList from './CardioBlockList'
import BiliaryBlockList from './BiliaryBlockList'
import TraumaBlockList from './TraumaBlockList'
import PelvicBlockList from './PelvicBlockList'
import RenalBlockList from './RenalBlockList'
import OcularBlockList from './OcularBlockList'
import ThoracicBlockList from './ThoracicBlockList'

import aortaBlockList from './seed/aortaBlockSeed'
import bowelBlockList from './seed/bowelBlockSeed'
import cardioBlockList from './seed/cardioBlockSeed'
import biliaryBlockList from './seed/biliaryBlockSeed'
import renalBlockList from './seed/renalBlockSeed'
import traumaBlockList from './seed/traumaBlockSeed'
import pelvicBlockList from './seed/pelvicBlockSeed'
import ocularBlockList from './seed/ocularBlockSeed'
import thoracicBlockList from './seed/thoracicBlockSeed'



import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


function Row() {
  
  const [categories, setCategories] = useState([])
  const [difficulty, setDifficulty] = useState()
  const [allTakes, setAllTakes] = useState([])


  const [isAortaActive, setAortaActive] = useState(false);
  const [isBowelActive, setBowelActive] = useState(false);
  const [isCardioActive, setCardioActive] = useState(false);
  const [isTraumaActive, setTraumaActive] = useState(false);
  const [isRenalActive, setRenalActive] = useState(false);
  const [isEfastActive, setEfastActive] = useState(false);
  const [isBiliaryActive, setBiliaryActive] = useState(false);
  const [isPelvicActive, setPelvicActive] = useState(false);
  const [isOcularActive, setOcularActive] = useState(false);
  const [isThoracicActive, setThoracicActive] = useState(false);


  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);

  const user = useSelector(selectUser)

  const blockListRef = useRef(null)
  const aortaBlockListRef = useRef(aortaBlockList)
  const bowelBlockListRef = useRef(bowelBlockList)
  const cardioBlockListRef = useRef(cardioBlockList)
  const renalBlockListRef = useRef(renalBlockList)
  const biliaryBlockListRef = useRef(biliaryBlockList)
  const traumaBlockListRef = useRef(traumaBlockList)
  const pelvicBlockListRef = useRef(pelvicBlockList)
  const ocularBlockListRef = useRef(ocularBlockList)
  const thoracicBlockListRef = useRef(thoracicBlockList)



  // window.location.reload()
  
  // When a category is selected, auto scroll to the block list
  const afterSelectCategory = () => blockListRef.current.scrollIntoView({behavior: "smooth"});

  // Toggle appropreate blockList
  const toggleCardio = () => {
    if(isCardioActive) {
      setCardioActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(true)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const toggleRenal = () => {
    if(isRenalActive) {
      setRenalActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(true)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }

  const toggleEfast = () => {
    if(isEfastActive) {
      setEfastActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(true)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }

  const toggleBiliary = () => {
    if(isBiliaryActive) {
      setBiliaryActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(true)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const toggleTrauma = () => {
    if(isTraumaActive) {
      setTraumaActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(true)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const togglePelvic = () => {
    if(isPelvicActive) {
      setPelvicActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(true)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const toggleOcular = () => {
    if(isOcularActive) {
      setOcularActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(true)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const toggleThoracic = () => {
    if(isThoracicActive) {
      setThoracicActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(true)
      setAortaActive(false)
      setBowelActive(false)
    }
  }
  const toggleAorta = () => {
    if(isAortaActive) {
      setAortaActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(true)
      setBowelActive(false)
    }
  }
  const toggleBowel = () => {
    if(isBowelActive) {
      setBowelActive(false)
    } else {
      afterSelectCategory()
      setCardioActive(false)
      setRenalActive(false)
      setEfastActive(false)
      setBiliaryActive(false)
      setTraumaActive(false)
      setPelvicActive(false)
      setOcularActive(false)
      setThoracicActive(false)
      setAortaActive(false)
      setBowelActive(true)
    }
  }


  useEffect(() => {
    console.log('useEffect started')

    db.collection('takes').get().then(querySnapshot => {
      const allTakes = {};
      querySnapshot.forEach(async takeDoc => {
        allTakes[takeDoc.id] = takeDoc.data();
      });
      setAllTakes(allTakes);
    })
    db.collection('categories').get().then(querySnapshot => {
      const categories = {};
      querySnapshot.forEach(async categoryDoc => {
        categories[categoryDoc.id] = categoryDoc.data();
      });
      setCategories(categories);
      setIsCategoriesLoading(false);
    })
    db.collection('difficulty').get().then(querySnapshot => {
      const difficulty = {};
      querySnapshot.forEach(async difficultyDoc => {
        difficulty[difficultyDoc.id] = difficultyDoc.data();
      });
      setDifficulty(difficulty);
    })

    console.log('useEffect end')
    
  }, [])

  // Categorize all takes
  const allTakesArray = Object.values(allTakes)

  const allAortaTakes = [];
  const allBowelTakes = [];
  const allCardioTakes = [];
  const allRenalTakes = [];
  const allBiliaryTakes = [];
  const allTraumaTakes = [];
  const allPelvicTakes =[];
  const allOcularTakes =[];
  const allThoracicTakes =[];



  // Progress bar per category
  const progressBar = (prog, total) => {

  }

  for(let i = 0; i < allTakesArray.length; i++) {
    if(auth.currentUser !== null){
      if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].bowelBlockId){
        allBowelTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].cardioBlockId){
        allCardioTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].renalBlockId){
        allRenalTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].biliaryBlockId){
        allBiliaryTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].traumaBlockId){
        allTraumaTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].pelvicBlockId){
        allPelvicTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].ocularBlockId){
        allOcularTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].thoracicBlockId){
        allThoracicTakes.push(allTakesArray[i])
      } else if(auth.currentUser.uid && allTakesArray[i].userId === auth.currentUser.uid && allTakesArray[i].aortaBlockId){
        allAortaTakes.push(allTakesArray[i])
      }
      // Duplicate above to create other categories takes
    }
  }

  const aortaTakesIdSorted = allAortaTakes.map((take) => take.aortaBlockId).sort((a, b) => a - b);
  const bowelTakesIdSorted = allBowelTakes.map((take) => take.bowelBlockId).sort((a, b) => a - b);
  const cardioTakesIdSorted = allCardioTakes.map((take) => take.cardioBlockId).sort((a, b) => a - b);
  const renalTakesIdSorted = allRenalTakes.map((take) => take.renalBlockId).sort((a, b) => a - b);
  const biliaryTakesIdSorted = allBiliaryTakes.map((take) => take.biliaryBlockId).sort((a, b) => a - b);
  const traumaTakesIdSorted = allTraumaTakes.map((take) => take.traumaBlockId).sort((a, b) => a - b);
  const pelvicTakesIdSorted = allPelvicTakes.map((take) => take.pelvicBlockId).sort((a, b) => a - b);
  const ocularTakesIdSorted = allOcularTakes.map((take) => take.ocularBlockId).sort((a, b) => a - b);
  const thoracicTakesIdSorted = allThoracicTakes.map((take) => take.thoracicBlockId).sort((a, b) => a - b);


  const aortaCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allAortaTakes.length; j++){
      if(!result.includes(aortaTakesIdSorted[j])){
        result.push(aortaTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const bowelCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allBowelTakes.length; j++){
      if(!result.includes(bowelTakesIdSorted[j])){
        result.push(bowelTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const cardiacCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allCardioTakes.length; j++){
      if(!result.includes(cardioTakesIdSorted[j])){
        result.push(cardioTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const renalCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allRenalTakes.length; j++){
      if(!result.includes(renalTakesIdSorted[j])){
        result.push(renalTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const biliaryCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allBiliaryTakes.length; j++){
      if(!result.includes(biliaryTakesIdSorted[j])){
        result.push(biliaryTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const traumaCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allTraumaTakes.length; j++){
      if(!result.includes(traumaTakesIdSorted[j])){
        result.push(traumaTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const pelvicCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allPelvicTakes.length; j++){
      if(!result.includes(pelvicTakesIdSorted[j])){
        result.push(pelvicTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const ocularCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allOcularTakes.length; j++){
      if(!result.includes(ocularTakesIdSorted[j])){
        result.push(ocularTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };
  const thoracicCalcHowManyBlocksTook = () => {
    let result = [];
    for(let j = 0; j < allThoracicTakes.length; j++){
      if(!result.includes(thoracicTakesIdSorted[j])){
        result.push(thoracicTakesIdSorted[j]);
      } else continue;
    }
    return result.length;
  };




  return (
    <div className="row">
      { auth.currentUser ? (
        <BannerLoggedIn />
      ): ""}
      
      {/* Category starts */}
      <p className="fontPurple">Select a category</p>
      {isCategoriesLoading ?
      <div className="row__categories">
        <p>Loading...</p>
      </div>
      :
      <>
      {Object.entries(categories).map(([categoryId, categoryData]) => {
        return (
          <div key={categoryId} className="row__categories">
            {categoryData.title.sort().map((categoryItem, index) => {
              return (
                <div key={categoryItem}
                  className={`row__categoryRow 
                  ${categoryItem === 'cardiothoracic' && isCardioActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'renal' && isRenalActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'biliary' && isBiliaryActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'trauma' && isTraumaActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'pelvic' && isPelvicActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'ocular' && isOcularActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'thoracic' && isThoracicActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'aorta' && isAortaActive ?  "row__categoryRow categoryChosen" : ""}
                  ${categoryItem === 'bowel' && isBowelActive ?  "row__categoryRow categoryChosen" : ""}
                  `}
                  // hide pelvic for now
                  // style={{display: `${categoryItem === 'pelvic' ?  "none" : ""}`}}
                  // 
                  onClick={() => {
                    if(categoryItem === 'cardiothoracic') {
                      return toggleCardio()
                    } else if (categoryItem === 'renal'){
                      return toggleRenal()
                    } else if (categoryItem === 'efast') {
                      return toggleEfast()
                    } else if (categoryItem === 'biliary') {
                      return toggleBiliary()
                    } else if (categoryItem === 'trauma') {
                      return toggleTrauma()
                    } else if (categoryItem === 'pelvic') {
                      return togglePelvic()
                    } else if (categoryItem === 'ocular') {
                      return toggleOcular()
                    } else if (categoryItem === 'thoracic') {
                      return toggleThoracic()
                    } else if (categoryItem === 'aorta') {
                      return toggleAorta()
                    } else if (categoryItem === 'bowel') {
                      return toggleBowel()
                    }
                  }}
                >
                
                <div className="row__categoryTitle"><p>{categoryItem}</p>
                </div>
                

                <div className="row__categoryInfo">
                  <div className="row__categoryProgress smallText">
                    {auth.currentUser !== null && categoryItem === 'cardiothoracic' ? (
                      <span>{cardiacCalcHowManyBlocksTook()} / {cardioBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'renal' ? (
                      <span>{renalCalcHowManyBlocksTook()} / {renalBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'biliary' ? (
                      <span>{biliaryCalcHowManyBlocksTook()} / {biliaryBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'trauma' ? (
                      <span>{traumaCalcHowManyBlocksTook()} / {traumaBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'pelvic' ? (
                      <span>{pelvicCalcHowManyBlocksTook()} / {pelvicBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'ocular' ? (
                      <span>{ocularCalcHowManyBlocksTook()} / {ocularBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'thoracic' ? (
                      <span>{thoracicCalcHowManyBlocksTook()} / {thoracicBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'aorta' ? (
                      <span>{aortaCalcHowManyBlocksTook()} / {aortaBlockListRef.current.length}</span>
                    ) : ("")}
                    {auth.currentUser !== null && categoryItem === 'bowel' ? (
                      <span>{bowelCalcHowManyBlocksTook()} / {bowelBlockListRef.current.length}</span>
                    ) : ("")}
                    
                  </div>
                </div>

                
                {auth.currentUser !== null && categoryItem === 'aorta' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ aortaCalcHowManyBlocksTook() / aortaBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'bowel' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ bowelCalcHowManyBlocksTook() / bowelBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'cardiothoracic' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ cardiacCalcHowManyBlocksTook() / cardioBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'renal' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ renalCalcHowManyBlocksTook() / renalBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'biliary' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ biliaryCalcHowManyBlocksTook() / biliaryBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'trauma' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ traumaCalcHowManyBlocksTook() / traumaBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'pelvic' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ pelvicCalcHowManyBlocksTook() / pelvicBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'ocular' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ ocularCalcHowManyBlocksTook() / ocularBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                {auth.currentUser !== null && categoryItem === 'thoracic' ? (
                  <div className="row__progressBarWrap">
                    <div className="row__progressBarFrame">
                      <div className="row__progressBar" style={{width: `${ thoracicCalcHowManyBlocksTook() / thoracicBlockListRef.current.length * 100}%`}}></div>
                    </div>
                  </div>
                ) : ("")}
                
                
              </div>
              )
            })} 
          </div>
        )
      })}
      </>
      }
      


      {/* Block List starts */}
      <p ref={blockListRef} className="fontPurple">Select a question block</p>
      <div className="row__blockWrapper">
        { isCardioActive === false
        && isRenalActive === false
        && isEfastActive === false
        && isBiliaryActive === false 
        && isTraumaActive === false 
        && isPelvicActive === false 
        && isOcularActive === false 
        && isThoracicActive === false 
        && isAortaActive === false 
        && isBowelActive === false 
        ? (
          <p className="row__blockNotSelected smallText"><HelpOutlineIcon /> Please select a category first</p>
        ) : ''}

        { isCardioActive ? (
          <CardioBlockList data={{allCardioTakes, difficulty}} />
        ) : ''}

        { isRenalActive ? (
          <div>
            <RenalBlockList data={{allRenalTakes, difficulty}} />
          </div>
        ) : ''}

        { isThoracicActive ? (
          <div>
           <ThoracicBlockList data={{allThoracicTakes, difficulty}} />
          </div>
          
        ) : ''}

        { isBiliaryActive ? (
          <div>
            <BiliaryBlockList data={{allBiliaryTakes, difficulty}} />
          </div>
        ) : ''}

        { isTraumaActive ? (
          <div>
           <TraumaBlockList data={{allTraumaTakes, difficulty}} />
          </div>
        ) : ''}

        { isPelvicActive ? (
          <div>
           <PelvicBlockList data={{allPelvicTakes, difficulty}} />
          </div>
        ) : ''}

        { isOcularActive ? (
          <div>
           <OcularBlockList data={{allOcularTakes, difficulty}} />
          </div>
        ) : ''}

        { isAortaActive ? (
          <div>
           <AortaBlockList data={{allAortaTakes, difficulty}} />
          </div>
        ) : ''}
        { isBowelActive ? (
          <div>
           <BowelBlockList data={{allBowelTakes, difficulty}} />
          </div>
        ) : ''}

      </div>
    </div>
  )
}

export default Row
