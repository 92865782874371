import React, { useState } from 'react';
import emailjs from 'emailjs-com'
import { WithContext as ReactTags } from 'react-tag-input';
import Axios from 'axios'

import '../screens/SubmitYoursScreen.css'
import './ContributionCategory.css'
import { MenuItem, Paper, Step, StepContent, StepLabel, Stepper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

// MUI popup setup
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
// MUI popup setup finished

// MUI stepper setup
const steps = [
  {
    label: 'Difficulty and related tag'
  },
  {
    label: 'Author and vignette prompt',
  },
  {
    label: 'Ultrasound view image/video upload',
  },
  {
    label: 'Ultrasound view interpretation'
  },
  {
    label: 'Questions and answers'
  },
  {
    label: 'Additional questions'
  },
  {
    label: 'Contact information'
  }
]
// MUI stepper setup finished


function ContributionThoracic(props) {
  const navigate = useNavigate()
  const [isSubmitActive, setIsSubmitActive] = useState(true)
  const [isTorsoToggled, setIsTorsoToggled] = useState(false)
  
  const [senderName, setSenderName] = useState('')
  const [senderEmail, setSenderEmail] = useState('')

  const [difficultyId, setDifficultyId] = useState('')
  const [difficultyDisplayName, setDifficultyDisplayName] = useState('')
  const [blockTags, setBlockTags] = useState([])

  const [answerOne, setAnswerOne] = useState('')
  const [answerTwo, setAnswerTwo] = useState('')
  const [answerThree, setAnswerThree] = useState('')
  const [additional, setAdditional] = useState('')

  
  const [displayAnswerOne, setDisplayAnswerOne] = useState('')
  const [displayAnswerTwo, setDisplayAnswerTwo] = useState('')
  const [displayAnswerThree, setDisplayAnswerThree] = useState('')

  const [authorInfo, setAuthorInfo] = useState({
                                        name: '',
                                        degree: '',
                                        institution: '',
                                        linkedinUrl: '',
                                        facebookUrl: '',
                                        instagramUrl: '',
                                        twitterUrl: '',
                                        email: '',
                                        extraOneName: '',
                                        extraOneDegree: '',
                                        extraOneInstitution: '',
                                        extraOneLinkedinUrl: '',
                                        extraOneFacebookUrl: '',
                                        extraOneInstagramUrl: '',
                                        extraOneTwitterUrl: '',
                                        extraOneEmail: '',
                                        extraTwoName: '',
                                        extraTwoDegree: '',
                                        extraTwoInstitution: '',
                                        extraTwoLinkedinUrl: '',
                                        extraTwoFacebookUrl: '',
                                        extraTwoInstagramUrl: '',
                                        extraTwoTwitterUrl: '',
                                        extraTwoEmail: '',
                                      })
                                      
  // Use uploaded image id to the contribution form content
  const [imgOne, setImgOne] = useState("")
  const [imgTwo, setImgTwo] = useState("")
  const [imgThree, setImgThree] = useState("")
  const [imgFour, setImgFour] = useState("")
  const [readingAnswer, setReadingAnswer] = useState("")
  const [readingInterpretation, setReadingInterpretation] = useState("")
  const [readingExplanation, setReadingExplanation] = useState("")

  const [contributionData, setContributionData] = useState(
    {
      categoryId: 1,
      difficultyLevel: '',
      questionTags: ['', ''],
      authorInfo: authorInfo,
      props: '',
      usviews:  [ "", "", "", "" ],
      questionInfo: [
        {
          qtype: 'reading',
          question: 'Are there b-lines in the thorax? If so, which side?',
          options: 'Right, Left, None',
          answer: '',
        },
        {
          qtype: 'reading',
          question: 'Is there evidence of a pneumothorax?',
          options: 'Yes, No',
          answer: '',
        },
        {
          qtype: 'reading',
          question: 'Is there free fluid in the thorax?',
          options: 'Yes, No',
          answer: '',
        },
      ],
      interpretation: [
        '',
        '',
        ''
      ]
    },
  )

  // Each section help popup
  const [ isDifficultyHelpOpen, setIsDifficultyHelpOpen] = useState(false)
  const [ isTagsHelpOpen, setIsTagsHelpOpen] = useState(false)
  const [ isCreditHelpOpen, setIsCreditHelpOpen] = useState(false)
  const [ isPropsHelpOpen, setIsPropsHelpOpen] = useState(false)
  const [ isImageHelpOpen, setIsImageHelpOpen] = useState(false)
  const [ isInterpretationHelpOpen, setIsInterpretationHelpOpen] = useState(false)
  const [ isQnAHelpOpen, setIsQnAHelpOpen] = useState(false)
  const [ isAdditionalHelpOpen, setIsAdditionalHelpOpen] = useState(false)
  const [ isSenderHelpOpen, setIsSenderHelpOpen] = useState(false)
  const toggleSectionHelp =(params) => {
    if ( params === 'Difficulty'){
      setIsDifficultyHelpOpen(!isDifficultyHelpOpen)
    } else if (params === 'Tags') {
      setIsTagsHelpOpen(!isTagsHelpOpen)
    } else if(params === 'Credit'){
      setIsCreditHelpOpen(!isCreditHelpOpen)
    } else if (params === 'Props'){
      setIsPropsHelpOpen(!isPropsHelpOpen)
    } else if (params === 'ImageUpload') {
      setIsImageHelpOpen(!isImageHelpOpen)
    } else if(params === 'Interpretation'){
      setIsInterpretationHelpOpen(!isInterpretationHelpOpen)
    } else if(params === 'QnA'){
      setIsQnAHelpOpen(!isQnAHelpOpen)
    } else if(params === 'Additional'){
      setIsAdditionalHelpOpen(!isAdditionalHelpOpen)
    } else if(params === 'Sender'){
      setIsSenderHelpOpen(!isSenderHelpOpen)
    }
  }

  // Set steppers for each section
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  

  const torsoToggleMobile = (e) => {
    setIsTorsoToggled(!isTorsoToggled)
  }
  const getSenderName = (e) => {
    setSenderName(e.target.value)
  }
  const getSenderEmail = (e) => {
    setSenderEmail(e.target.value)
  }
  const selectDifficulty = (e) => {
    let difficultyName = e.target.value;
    let findId = ''
    if( e.target.value === 'Easy'){
      findId = 'difficulty1'
    } else if (e.target.value === 'Intermediate'){
      findId = 'difficulty2'
    } else if (e.target.value === 'Advanced'){
      findId = 'difficulty3'
    } else {
      findId = 'Unknown'
    }
    setDifficultyId(`${findId}`)
    setDifficultyDisplayName(difficultyName)
  }


  // For Tags
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleAddition = tag => {
    setBlockTags([...blockTags, tag]);
  };
  const onTagUpdate = (i, newTag) => {
    const updatedTags = blockTags.slice();
    updatedTags.splice(i, 1, newTag);
    setBlockTags(updatedTags);
  };
  const handleDelete = i => {
    setBlockTags(blockTags.filter((tag, index) => index !== i));
  };
  const onClearAll = () => {
    setBlockTags([]);
  };


  const getAuthorInfo = (params, e) => {
    setAuthorInfo({...authorInfo, [params] :e.target.value })
  }
  
  const [viewExtraAuthors, setViewExtraAuthors] = useState(false)
  const toggleExtraAuthors = () => {
    setViewExtraAuthors(!viewExtraAuthors)
  }
  const getContributionData = (params, e) => {
    setContributionData({...contributionData, [params] :e.target.value })
  }
  const getReadingAnswer = (e) => {
    setReadingAnswer(e.target.value)
  }
  const getReadingInterpretation = (e) => {
    setReadingInterpretation(e.target.value)
  }
  const getReadingExplanation = (e) => {
    setReadingExplanation(e.target.value)
  }
  const getAnswerOne = (e) => {
    setAnswerOne(e.target.value.charAt(e.target.value.length - 1))
    setDisplayAnswerOne(e.target.value)
  }
  const getAnswerTwo = (e) => {
    setAnswerTwo(e.target.value.charAt(e.target.value.length - 1))
    setDisplayAnswerTwo(e.target.value)
  }
  const getAnswerThree = (e) => {
    setAnswerThree(e.target.value.charAt(e.target.value.length - 1))
    setDisplayAnswerThree(e.target.value)
  }
  const getAdditional = (e) => {
    setAdditional(e.target.value)
  }


  // Upload ultrasound views on Cloudinary
  const uploadViewOne = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0])
    formData.append("upload_preset", "s8qubgyt")

    Axios.post("https://api.cloudinary.com/v1_1/ultrasoundbox/upload", formData)
    .then((response) => setImgOne(response.data.url));
  }

  const uploadViewTwo = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0])
    formData.append("upload_preset", "s8qubgyt")

    Axios.post("https://api.cloudinary.com/v1_1/ultrasoundbox/upload", formData)
    .then((response) => setImgTwo(response.data.url));
  }

  const uploadViewThree = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0])
    formData.append("upload_preset", "s8qubgyt")

    Axios.post("https://api.cloudinary.com/v1_1/ultrasoundbox/upload", formData)
    .then((response) => setImgThree(response.data.url));
  }

  const uploadViewFour = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0])
    formData.append("upload_preset", "s8qubgyt")

    Axios.post("https://api.cloudinary.com/v1_1/ultrasoundbox/upload", formData)
    .then((response) => setImgFour(response.data.url));
  }


  // Email the contribution data
  const contributionDataToSend = {
    userSubmitCategoryId: 3,
    userSubmitCategory: 'thoracic',
    userSubmitDifficultyId: difficultyId,
    userSubmitDifficulty: difficultyDisplayName,
    userSubmitTagList: blockTags.map((tag) => tag.text).join(', '),

    userSubmitAuthorName: authorInfo.name,
    userSubmitAuthorDegree: authorInfo.degree,
    userSubmitAuthorInstitution: authorInfo.institution,
    userSubmitAuthorEmail: authorInfo.email,
    userSubmitAuthorLinkedin: authorInfo.linkedinUrl,
    userSubmitAuthorFacebook: authorInfo.facebookUrl,
    userSubmitAuthorInstagram: authorInfo.instagramUrl,
    userSubmitAuthorTwitter: authorInfo.twitterUrl,

    userSubmitAuthorNameExtraOne: authorInfo.extraOneName,
    userSubmitAuthorDegreeExtraOne: authorInfo.extraOneDegree,
    userSubmitAuthorInstitutionExtraOne: authorInfo.extraOneInstitution,
    userSubmitAuthorEmailExtraOne: authorInfo.extraOneEmail,
    userSubmitAuthorLinkedinExtraOne: authorInfo.extraOneLinkedinUrl,
    userSubmitAuthorFacebookExtraOne: authorInfo.extraOneFacebookUrl,
    userSubmitAuthorInstagramExtraOne: authorInfo.extraOneInstagramUrl,
    userSubmitAuthorTwitterExtraOne: authorInfo.extraOneTwitterUrl,

    userSubmitAuthorNameExtraTwo: authorInfo.extraTwoName,
    userSubmitAuthorDegreeExtraTwo: authorInfo.extraTwoDegree,
    userSubmitAuthorInstitutionExtraTwo: authorInfo.extraTwoInstitution,
    userSubmitAuthorEmailExtraTwo: authorInfo.extraTwoEmail,
    userSubmitAuthorLinkedinExtraTwo: authorInfo.extraTwoLinkedinUrl,
    userSubmitAuthorFacebookExtraTwo: authorInfo.extraTwoFacebookUrl,
    userSubmitAuthorInstagramExtraTwo: authorInfo.extraTwoInstagramUrl,
    userSubmitAuthorTwitterExtraTwo: authorInfo.extraTwoTwitterUrl,

    
    userSubmitProps: contributionData.props,
    userSubmitUsviews: `${imgOne}, ${imgTwo}, ${imgThree}, ${imgFour}`,
    userSubmitReadingAnswer: readingAnswer,
    userSubmitReadingInterpretation: readingInterpretation,
    userSubmitReadingExplanation: readingExplanation,
    userSubmit_Q1Answer: answerOne,
    userSubmit_Q2Answer: answerTwo,
    userSubmit_Q3Answer: answerThree,
    userSubmit_additional: additional,
    userSubmitName: senderName,
    userSubmitEmail: senderEmail,
  }

  
  function sendEmail(e) {
    e.preventDefault();
    setIsSubmitActive(false);
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_2yxuog9', contributionDataToSend, process.env.REACT_APP_EMAILJS_USER_ID)
    .then(res => {
      navigate('/thankyou')
    }).catch( err => alert(err))
    
  }

  



  return (
    <div className="contributionCategory">
      { isTorsoToggled ? (
        <div className="contributionCategory__toggledTorso">
          
          <img src="https://ultrasoundbox.s3.amazonaws.com/thoracic/ThoracicNum.jpg" alt="" />
          <p className="ub_textWhite" onClick={() => torsoToggleMobile()}>Close</p>

        </div>
      ) : ("")}

      <Box component="form" noValidate autoComplete="off">

        {/* Stepper starts */}
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>
                  { index === 0 ? (
                    <div className="contributionCategory__2colSection">
                      <div className="contributionCategory__section contributionCategory__difficultyWrapper">
                        <h3>Difficulty level</h3>
                        <span onClick={() => toggleSectionHelp("Difficulty")} className="contributionCategory__helpLink ub_textPurple">What is the difficulty level?</span>
                        
                        <BootstrapDialog
                          onClose={() => toggleSectionHelp("Difficulty")}
                          aria-labelledby="sectionHelp_difficulty"
                          open={isDifficultyHelpOpen}
                        >
                          <BootstrapDialogTitle id="sectionHelp_difficulty" onClose={() => toggleSectionHelp("Difficulty")}>
                            What is the difficulty level?
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Typography gutterBottom>
                              Select which level of learner this question is best suited for. You can select between the following levels: medical student learners, residents learners and ultrasound trained fellows and above.
                            </Typography>
                          </DialogContent>
                        </BootstrapDialog>

                        <div className="contributionCategory__difficulty">
                          <TextField id="difficulty" select label="Difficulty level" value={difficultyDisplayName} onChange={(e) => selectDifficulty(e)}>
                            {props.difficultyList.map((item) => {
                              return (
                                <MenuItem key={item.difficultyId} name={item.level} value={item.level}>{item.level}
                                  {item.level === 'Easy' ? ' (Medical students)' : ''}
                                  {item.level === 'Intermediate' ? ' (Residents)' : ''}
                                  {item.level === 'Advanced' ? ' (Ultrasound fellow)' : ''}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </div>
                      </div>

                      <div className="contributionCategory__section contributionCategory__tags">
                        <h3>Category tags</h3>
                        <span onClick={() => toggleSectionHelp("Tags")} className="contributionCategory__helpLink ub_textPurple">What are category tags?</span>
                        <BootstrapDialog
                          onClose={() => toggleSectionHelp("Tags")}
                          aria-labelledby="sectionHelp_tags"
                          open={isTagsHelpOpen}
                        >
                          <BootstrapDialogTitle id="sectionHelp_tags" onClose={() => toggleSectionHelp("Tags")}>
                            What are category tags?
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Typography gutterBottom>
                              Include 1-4 tags. Tags provide important search terms to help with discoverability and organization. 
                            </Typography>
                          </DialogContent>
                        </BootstrapDialog>
                        { blockTags.length > 0 ? ""
                        : (
                          <div><p className='smallText'>No related tags yet</p></div>
                        )}
                        
                        <ReactTags
                          tags={blockTags}
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          onTagUpdate={onTagUpdate}
                          handleAddition={handleAddition}
                          inputFieldPosition="bottom"
                          readOnly={false}
                          allowDeleteFromEmptyInput={true}
                          allowAdditionFromPaste={true}
                          allowUnique={true}
                          inline={true}
                          onClearAll={onClearAll}
                          autocomplete
                        />
                      </div>
                      
                    </div>
                  ) : null}

                  { index === 1 ? (
                    <div className="contributionCategory__2colSection">
                      <div className="contributionCategory__section contributionCategory__creditWrapper">
                        <h3>Author credit</h3>
                        <span  onClick={() => toggleSectionHelp("Credit")} className="contributionCategory__helpLink ub_textPurple">What is author credit?</span>
                        <BootstrapDialog
                          onClose={() => toggleSectionHelp("Credit")}
                          aria-labelledby="sectionHelp_credit"
                          open={isCreditHelpOpen}
                        >
                          <BootstrapDialogTitle id="sectionHelp_credit" onClose={() => toggleSectionHelp("Credit")}>
                            What is author credit?
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Typography gutterBottom>
                              Include up to 3 authors. Any other included information will be displayed in the case as well. The only mandatory field is the author name(s).
                            </Typography>
                          </DialogContent>
                        </BootstrapDialog>
                        
                        <div className="contributionCategory__credit">
                          <TextField id="author_name" label="Your full name" name="author_name" variant="outlined" onChange={(e) => getAuthorInfo('name', e)} />
                          <TextField id="author_degree" label="Degree" name="author_degree" variant="outlined" onChange={(e) => getAuthorInfo('degree', e)} />
                          <TextField id="author_institution" label="Institution" name="author_institution" variant="outlined" onChange={(e) => getAuthorInfo('institution', e)} />
                          <TextField id="author_email" label="Email address" name="author_email" variant="outlined" onChange={(e) => getAuthorInfo('email', e)} />
                          <TextField id="author_linkedin" label="Linkedin URL" name="author_linkedin" variant="outlined" onChange={(e) => getAuthorInfo('linkedinUrl', e)} />
                          <TextField id="author_facebook" label="Facebook URL" name="author_facebook" variant="outlined" onChange={(e) => getAuthorInfo('facebookUrl', e)} />
                          <TextField id="author_instagram" label="Instagram URL" name="author_instagram" variant="outlined" onChange={(e) => getAuthorInfo('instagramUrl', e)} />
                          <TextField id="author_twitter" label="Twitter URL" name="author_twitter" variant="outlined" onChange={(e) => getAuthorInfo('twitterUrl', e)} />
                        </div>
                        <div>
                          { viewExtraAuthors ? 
                            <p id="contributionCategory__addAuthors" className="contributionCategory__addAuthors ub_textPurple" onClick={() => toggleExtraAuthors()}>- Hide other authors</p>
                          :
                            <p id="contributionCategory__addAuthors" className="contributionCategory__addAuthors ub_textPurple" onClick={() => toggleExtraAuthors()}>+ More than one author?</p>
                          }
                          
                        </div>
                        { viewExtraAuthors ? 
                          <div className="contributionCategory__credit contributionCategory__creditExtraOne">
                            <p>Author information</p>
                            <TextField id="author_name" label="Your full name" name="author_name" variant="outlined" onChange={(e) => getAuthorInfo('extraOneName', e)} />
                            <TextField id="author_degree" label="Degree" name="author_degree" variant="outlined" onChange={(e) => getAuthorInfo('extraOneDegree', e)} />
                            <TextField id="author_institution" label="Institution" name="author_institution" variant="outlined" onChange={(e) => getAuthorInfo('extraOneInstitution', e)} />
                            <TextField id="author_email" label="Email address" name="author_email" variant="outlined" onChange={(e) => getAuthorInfo('extraOneImail', e)} />
                            <TextField id="author_linkedin" label="Linkedin URL" name="author_linkedin" variant="outlined" onChange={(e) => getAuthorInfo('extraOneLinkedinUrl', e)} />
                            <TextField id="author_facebook" label="Facebook URL" name="author_facebook" variant="outlined" onChange={(e) => getAuthorInfo('extraOneFacebookUrl', e)} />
                            <TextField id="author_instagram" label="Instagram URL" name="author_instagram" variant="outlined" onChange={(e) => getAuthorInfo('extraOneInstagramUrl', e)} />
                            <TextField id="author_twitter" label="Twitter URL" name="author_twitter" variant="outlined" onChange={(e) => getAuthorInfo('extraOneTwitterUrl', e)} />
                          </div>
                        : null}
                        
                        { viewExtraAuthors ? 
                          <div className="contributionCategory__credit contributionCategory__creditExtraTwo">
                            <p>Author information</p>
                            <TextField id="author_name" required label="Your full name" name="author_name" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoName', e)} />
                            <TextField id="author_degree" label="Degree" name="author_degree" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoDegree', e)} />
                            <TextField id="author_institution" label="Institution" name="author_institution" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoInstitution', e)} />
                            <TextField id="author_email" label="Email address" name="author_email" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoEmail', e)} />
                            <TextField id="author_linkedin" label="Linkedin URL" name="author_linkedin" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoLinkedinUrl', e)} />
                            <TextField id="author_facebook" label="Facebook URL" name="author_facebook" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoFacebookUrl', e)} />
                            <TextField id="author_instagram" label="Instagram URL" name="author_instagram" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoInstagramUrl', e)} />
                            <TextField id="author_twitter" label="Twitter URL" name="author_twitter" variant="outlined" onChange={(e) => getAuthorInfo('extraTwoTwitterUrl', e)} />
                          </div>
                        : null }
                        
                      </div>
                      
                      <div className="contributionCategory__section contributionCategory__props">
                        <h3>Question prompt</h3>
                        <span onClick={() => toggleSectionHelp("Props")}  className="contributionCategory__helpLink ub_textPurple">What is this section?</span>
                        <BootstrapDialog
                          onClose={() => toggleSectionHelp("Props")}
                          aria-labelledby="sectionHelp_props]"
                          open={isPropsHelpOpen}
                        >
                          <BootstrapDialogTitle id="sectionHelp_props]" onClose={() => toggleSectionHelp("Props")}>
                            How to fill each section
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Typography gutterBottom>
                              This should present a brief description of a clinical scenario associated with the clips and images. The majority of cases only include 2-3 sentences here at most. We want to emphasize the interpretation of the clips, rather then thinking through a traditional clinical question. 
                            </Typography>
                          </DialogContent>
                        </BootstrapDialog>
                        
                        <TextField id="props" label="Vignette prompt" name="props" multiline rows={4}  onChange={(e) => getContributionData('props', e)} />
                      </div>
                      
                    </div>
                  ) : null}

                  { index === 2 ? (
                    <div className="contributionCategory__section">
                      {/* <h3>Upload images</h3> */}
                      <p className='smallText'>Upload as many as possible from your clinical case. We support JPG, JPEG, GIF, MP4, MOV. Please de-identify your clips and remove any patient information. The following open source tool can be used to assist in doing this: <a href="https://www.coreultrasound.com/clipdeidentifier/" target="_blank">Click here</a></p>
                      <div className="contributionCategory__imgUploadSection">
                        <div className="contributionCategory__torso">
                          <div className="contributionCategory__torsoToggleMobile" onClick={() => torsoToggleMobile()}>
                            View<br />locations
                          </div>
                          <img src="https://ultrasoundbox.s3.amazonaws.com/thoracic/ThoracicNum.jpg" alt="" />
                        </div>
                        <div className="contributionCategory__imgUploadWrapper">
                          <div>
                            <div className="contributionCategory__imageUpload">
                              <span className="smallText">Ultrasound view 1</span>
                              <input type="file" resource_type="all" name="attachment" onChange={(e) => uploadViewOne(e)} />
                            </div>
                            
                            <div className="contributionCategory__imageUpload">
                              <span className="smallText">Ultrasound view 2</span>
                              <input type="file" resource_type="all" name="attachment" onChange={(e) => uploadViewTwo(e)} />
                            </div>
                          </div>
                          <div>
                            <div className="contributionCategory__imageUpload">
                              <span className="smallText">Ultrasound view 3</span>
                              <input type="file" resource_type="all" name="attachment" onChange={(e) => uploadViewThree(e)} />
                            </div>

                            <div className="contributionCategory__imageUpload">
                              <span className="smallText">Ultrasound view 4</span>
                              <input type="file" resource_type="all" name="attachment" onChange={(e) => uploadViewFour(e)} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {index === 3 ? (
                    <div className="contributionCategory__section">
                      {/* <h3>Interpretation</h3> */}
                      <span onClick={() => toggleSectionHelp("Interpretation")}  className="contributionCategory__helpLink ub_textPurple">How to fill each section</span>
                      <BootstrapDialog
                          onClose={() => toggleSectionHelp("Interpretation")}
                          aria-labelledby="sectionHelp_interpretation"
                          open={isInterpretationHelpOpen}
                      >
                        <BootstrapDialogTitle id="sectionHelp_interpretation" onClose={() => toggleSectionHelp("Interpretation")}>
                          How to fill each section
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            <span className="contributionHelpPopupTitle">Answer</span>
                            This section should be used to explain to the user how to get to the correct interpretation, and the clinical signifiance of that interpretation. Teaching of the key takewaways should also be put here.
                          </Typography>
                          <Typography gutterBottom>
                            <span className="contributionHelpPopupTitle">Interpretation</span>
                            This is the official interpretation that would go into the chart, to give the user an idea of what a formal description of the findings would look like.
                          </Typography>
                          <Typography gutterBottom>
                            <span className="contributionHelpPopupTitle">Impression</span>
                            This is where the official impression that would go into the chart, gteaching the user what the key findings were.
                          </Typography>
                        </DialogContent>
                      </BootstrapDialog>

                      <div className="contributionCategory__sectionInterpretationSample smallText">
                        <p> &lt; Sample &gt;</p>
                        <span>Answer</span>
                        <p>No evidence of pathology is appreciable on this POCUS. This is a normal ultrasound exam.</p>
                        <span>Interpretation</span>
                        <p>Focused transthoracic cardiac ultrasound and pulmonary ultrasound examinations were performed. No pericardial effusion was present. No pulmonary edema was appreciated. A-lines are present in all visualized lung fields. No signs of pneumothorax. No global wall motion abnormality was identified.</p>
                        <span>Impression</span>
                        <p>Normal Cardiac ECHO. Normal Pulmonary exam.</p>
                      </div>
                      <div className="contributionCategory__sectionInterpretation">
                        
                        <p className="contributionCategory__sectionInterpretationGuide smallText">What you want to convey to the learner</p>
                        <TextField id="answer" label="Interpretation answer" name="answer" multiline onChange={(e) => getReadingAnswer(e)} />
                        
                        <p className="contributionCategory__sectionInterpretationGuide smallText">The official interpretation that goes into the chart</p>
                        <TextField id="interpretation" label="Interpretation" name="interpretation" multiline  onChange={(e) => getReadingInterpretation(e)} />
                        
                        <p className="contributionCategory__sectionInterpretationGuide smallText">The official impression that goes into the chart</p>
                        <TextField id="review" label="Explanation" name="review" multiline onChangeCapture={(e) => getReadingExplanation(e)} />
                      </div>
                      
                    </div>
                  ) : null}

                  { index === 4 ? (
                    <div className="contributionCategory__section">
                      <p className="contributionCategory__QAGuide smallText">We have pre-populated this field with highly recommended questions based on the category of question you selected. Answer as many as possible. If any of these are not applicable to your case, answer with “N/A” or just leave it blank. The next section has a free text box where you can input your own questions and answers outside of these recommended ones. <span  onClick={() => toggleSectionHelp("QnA")}  className="contributionCategory__helpLink ub_textPurple">How to</span>
                      </p>
                      <BootstrapDialog
                        onClose={() => toggleSectionHelp("QnA")}
                        aria-labelledby="sectionHelp_interpretation"
                        open={isQnAHelpOpen}
                      >
                        <BootstrapDialogTitle id="sectionHelp_interpretation" onClose={() => toggleSectionHelp("QnA")}>
                          Section Guide
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            This is where the key binary questions that the user needs to answer to create an interpretation. We provide highly suggested questions automatically, however if you feel they do not fit your case or are unable to be answered based on the information presented in your case, then you may select (N/A) in the answers to indicate they are not applicable. Any questions that you want to add can inputted into the free text box in the next section.
                          </Typography>
                        </DialogContent>
                      </BootstrapDialog>

                      <div className="contributionCategory__QAWrapper">
                        <div className="contributionCategory__Q">
                          <p><span>Question: </span>Do you see any Pericardial effusion? If so, is there evidence of tamponode physiology?</p>
                        </div>
                        <div className="contributionCategory__A">
                          <TextField id="qOne_qType" select label="Answer" value={displayAnswerOne} onChange={getAnswerOne} helperText="Please select the answer of this question">
                            <MenuItem key="thoracic__q1_0" value="thoracic__q1_0">
                              N/A
                            </MenuItem>
                            <MenuItem key="thoracic__q1_1" value="thoracic__q1_1">
                              Option 1. No pericardial effusion
                            </MenuItem>
                            <MenuItem key="thoracic__q1_2" value="thoracic__q1_2">
                              Option 2. Pericardial effusion present with no evidence of tamponade physiology
                            </MenuItem>
                            <MenuItem key="thoracic__q1_3" value="thoracic__q1_3">
                              Option 3. Pericardial effusion with evidence of tamponade physiology
                            </MenuItem>
                          </TextField>
                        </div>
                      </div>

                      <div className="contributionCategory__QAWrapper">
                        <div className="contributionCategory__Q">
                          <p><span>Question: </span>How is the systolic function?</p>
                        </div>
                        <div className="contributionCategory__A">
                          <TextField id="qOne_qType" select label="Answer" value={displayAnswerTwo} onChange={getAnswerTwo} helperText="Please select the answer of this question">
                            <MenuItem key="thoracic__q2_0" value="thoracic__q2_0">
                              N/A
                            </MenuItem>
                            <MenuItem key="thoracic__q2_1" value="thoracic__q2_1">
                              Option 1. Normal
                            </MenuItem>
                            <MenuItem key="thoracic__q2_2" value="thoracic__q2_2">
                              Option 2. Mildly depressed
                            </MenuItem>
                            <MenuItem key="thoracic__q2_3" value="thoracic__q2_3">
                              Option 3. Significantly depressed
                            </MenuItem>
                          </TextField>
                        </div>
                      </div>

                      <div className="contributionCategory__QAWrapper">
                        <div className="contributionCategory__Q">
                          <p><span>Question: </span>Do you see any focal wall motion abnormalities in the left ventricle?</p>
                        </div>
                        <div className="contributionCategory__A">
                          <TextField id="qOne_qType" select label="Answer" value={displayAnswerThree} onChange={getAnswerThree} helperText="Please select the answer of this question">
                            <MenuItem key="thoracic__q3_0" value="thoracic__q3_0">
                              N/A
                            </MenuItem>
                            <MenuItem key="thoracic__q3_1" value="thoracic__q3_1">
                              Option 1. Yes
                            </MenuItem>
                            <MenuItem key="thoracic__q3_2" value="thoracic__q3_2">
                              Option 2. No
                            </MenuItem>
                          </TextField>
                        </div>
                      </div>

                    </div>
                  ) : null}

                  { index === 5 ? (
                    <div className="contributionCategory__section contributionCategory__additional">

                      <p className='smallText'>Please enter any additional questions and answers you want to add to the case in the free text box below.</p>
                      <TextField id="" label="Additional questions" multiline rows={4} onChange={(e) => getAdditional(e)} />
                    </div>
                  ) : null}

                  {index === 6 ? (
                    <div className="contributionCategory__section contributionCategory__senderInfoWrapper">
                      <p className="smallText">The contact information for the author who will deal with correspondence should be put here.
                      <span onClick={() => toggleSectionHelp("Sender")}  className="contributionCategory__helpLink ub_textPurple">Who should I put?</span>
                      </p>
                      <BootstrapDialog
                        onClose={() => toggleSectionHelp("Sender")}
                        aria-labelledby="sectionHelp_sender"
                        open={isSenderHelpOpen}
                      >
                        <BootstrapDialogTitle id="sectionHelp_sender" onClose={() => toggleSectionHelp("Sender")}>
                          Who should I put?
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            The contact information for the author who will deal with correspondence should be put here. We will then review the case and then get back to you with any suggested edits, reject the case or approve the case as is. 
                          </Typography>
                        </DialogContent>
                      </BootstrapDialog>

                      <div className="contributionCategory__senderInfo">
                        <TextField id="" label="Contact name" onChange={(e) => getSenderName(e)} />
                        <TextField id="senderEmail" label="Contact email"  onChange={(e) => getSenderEmail(e)} />
                      </div>
                    </div>
                  ) : null}

                </Typography>
                <Box>
                  <div>
                    { index === steps.length - 1 ? (
                      <div className="contributionCategory__section contributionCategory__submitButton">
                        <button className="ub_solidPurple" type="submit" value="submit" onClick={(e) => sendEmail(e)} disabled={isSubmitActive ? false : true}>Submit</button>
                      </div>
                    ) : (
                      <button className="ub_solidPurple" onClick={handleNext}>
                        Next
                      </button>
                    ) }
                    
                    <button className="ub_textPurple" disabled={index === 0} onClick={handleBack}>Back</button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}



        
        
        

        

      </Box>
    </div>
    
  )
}

export default ContributionThoracic