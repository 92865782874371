import React from 'react';
import emailjs from 'emailjs-com'

import './ContactUs.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, TextField } from '@mui/material';
import { Box } from '@mui/system';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function ContactUs() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // sendEmail()
    window.location.reload();
  };
  

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, 'template_qm8cy3i', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
    .then(res => {
      // console.log(res);
      // window.location.reload();
    }).catch( err => console.log(err))
  }

  return (
    <div className="contactUs">
      <h1>Contact Us</h1>
      <Box onSubmit={sendEmail} component="form" noValidate autoComplete="off">
        <TextField id="user_name" label="Your full name" name="user_name" variant="outlined" />

        <TextField id="user_email" label="Email address" name="user_email" variant="outlined" />

        <TextField id="message" label="Message" name="message" placeholder="Message" multiline rows={4} />

        <button onClick={handleClickOpen} type="submit" value="send" className='ub_solidPurple contactUS__submit'>Send</button>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Thank you for contacting us!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We will be back to you as soon as possible. Our average response time is in 24hours.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
      </Box>
      
    </div>
    
  )
}

export default ContactUs
