import React, { useEffect, useRef, useState } from 'react';
import './LoadingScreen.css'

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

function LoadingScreen() {
  const count = document.querySelector('#loadingCount');
  const [counter, setCounter] = useState(0);
  
  useInterval(() => {
    setCounter(counter + 1);
  }, 1000);
  
  return <div className="loadingScreen">
    <div className="loadingScreen__textColorAnimation">
      {/* <h1 id="loadingCount" className="loadingScreen__textColorAnimation">Ultra<br />sound<br />Box</h1> */}
      <img src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-vertWhite.png" alt="" />
    </div>
  </div>;
}

export default LoadingScreen;
