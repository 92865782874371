const susDB = [
  {
    questionSetId: 1,
    vignette:
      "An unidentified 42-year-old man is found in asystolic arrest and ROSC is obtained in the field by EMS. He is intubated, sedated, and started on targeted temperature modulation upon arrival at the hospital. EEG is connected and findings over the next 72 hours are shown below (four selected epochs are shown, they are not directly contiguous but represent the evolution of this patient’s EEG).",
    figures: [
      {
        url: "https://ultrasoundbox.s3.amazonaws.com/susmitResource/test1/Figure1.png",
        caption:
          "Suppressed background with periodic epileptiform discharges time-locked with low amplitude axial myoclonic jerks.",
      },
      {
        url: "https://ultrasoundbox.s3.amazonaws.com/susmitResource/test1/Figure2.png",
        caption:
          "Burst suppression with appearance of polyspike and wave discharges.",
      },
      {
        url: "https://ultrasoundbox.s3.amazonaws.com/susmitResource/test1/Figure3x.png",
        caption:
          "Discontinuous record with bursts of polyspike-and-wave discharges.",
      },
      {
        url: "https://ultrasoundbox.s3.amazonaws.com/susmitResource/test1/Figure4.png",
        caption: "",
      },
    ],
    video: {},
    questionList: [
      {
        questionId: 1,
        question: "Is this a normal or abnormal EEG?",
        choices: { 1: "Normal", 2: "Abnormal" },
        answers: [2],
      },
      {
        questionId: 2,
        question: "What is the montage?",
        choices: {
          1: "Longitudinal Bipolar",
          2: "Transverse Bipolar",
          3: "Circumferential Bipolar",
          4: "Average Reference",
          5: "Cz Reference",
          6: "Ispilateral Ear Reference",
          7: "Laplacian Reference",
        },
        answers: [1],
      },
      {
        questionId: 3,
        question: "What is the background voltage?",
        choices: {
          1: "Suppressed (<10 microvolts)",
          2: "Low (>10 and <20 microvolts)",
          3: "Normal (20-150 microvolts)",
          4: "High (>150 microvolts)",
        },
        answers: [1],
      },
      {
        questionId: 4,
        question: "Are the frequency and voltage symmetric?",
        choices: {
          1: "Symmetric",
          2: "Asymmetric ",
        },
        answers: [1],
      },
      {
        questionId: 5,
        question: "What is the background continuity?",
        choices: {
          1: "Continuous",
          2: "Nearly Continuous (<10% suppressed)",
          3: "Discontinuous (10-49% suppressed)",
          4: "Burst-suppression (>50% suppressed)",
          5: "Diffuse Suppression",
        },
        answers: [4],
      },
      {
        questionId: 6,
        question: "Black oval – are these discharges epileptiform?",
        choices: {
          1: "Yes",
          2: "No",
        },
        answers: [1],
      },
      {
        questionId: 7,
        question:
          "Refer to the feature highlighted by the black oval – Select the features present here",
        choices: {
          1: "di- or tri-phasic waves with sharp or spiky morphology",
          2: "different wave duration than the ongoing background activity: either shorter or longer",
          3: "asymmetry of the waveform",
          4: "the transient is followed by an associated slow after-wave",
          5: "Disruption of the background by the labeled feature",
        },
        answers: [1, 2, 3, 5],
      },
      {
        questionId: 8,
        question:
          "The patient exhibits hyperkinetic jerks of the mouth and shoulders, time-locked with the discharges marked in the black oval. What is the probable diagnosis?",
        choices: {
          1: "Myoclonic status epilepticus",
          2: "Focal myoclonus",
          3: "Lance Adams syndrome",
        },
        answers: [1],
      },
      {
        questionId: 9,
        question:
          "Another patient with a similar presentation (post cardiac arrest with initially suppressed EEG) is now having myoclonic jerks time-locked with narrow, vertex-maximal spike-and-wave discharges. In this case, however, the EEG continues to show preserved cortical background. What is the likely diagnosis in this case?",
        choices: {
          1: "Myoclonic status epilepticus",
          2: "Focal myoclonus",
          3: "Lance Adams syndrome",
        },
        answers: [3],
      },
    ],
    conceptImgs: [
      {
        url: "https://ultrasoundbox.s3.amazonaws.com/susmitResource/test1/Figure5.png",
        caption:
          "Caption from Kural et al: Infographic summarizing the 6 IFCN criteria for identifying IEDs (1) Di- or tri-phasic wave with pointed peak; (2) different wave duration than the ongoing background activity; (3) asymmetry of the waveform; (4) followed by a slow after-wave; (5) the background activity is disrupted by the presence of the IEDs; and (6) voltage map with distribution of the negative and positive potentials suggesting a source in the brain corresponding to a radial, oblique, or tangential orientation of the source.23 For further details, see the Methods section. The voltage maps in window (6) show a tangential orientation (source in the left middle frontal gyrus) and a radial orientation (source in the left superior frontal gyrus); the irregular distribution of the potentials in the last voltage map does not imply a source in the brain (it was an artifact with sharp morphology) and does not fulfill this criterion; negative potentials are in blue, and positive potentials are in red. IED = interictal epileptiform discharge; IFCN = International Federation of Clinical Neurophysiology.",
      },
    ],
    concepts: [
      {
        title: "Myoclonic status epilepticus",
        content: [
          "The vignette describes axial myoclonic jerks time-locked with high‐amplitude poly-spike bursts on EEG, all occurring within 24h of anoxic brain injury.",
          "This is myoclonic status epilepticus (MSE), which indicates diffuse and catastrophic brain injury and a poor prognosis. [1],[2]",
          "In contrast, patients with Lance‐Adams syndrome (final question, Question are characterized by action myoclonus upon awakening from coma, with preserved cortical background activity on EEG, with or without narrow, vertex-maximal epileptiform discharges.[1],[3]",
          "Based on one large cohort, approximately half of these patients with pattern 2 ultimately regained consciousness from post-anoxic coma, though recovery was delayed by up to four weeks.[1],[4]",
        ],
      },
      {
        title: "Burst Suppression",
        content: [
          "Defined as suppression of 50-99% suppression of the EEG background.",
          "Causes: anesthetic or sedative overdose and anoxic brain injury.",
          "The length of the anoxic period and the length of subsequent EEG suppression are correlated.",
          "The occurrence of epileptiform discharges (intra-burst content) and degree of suppression both correlate with worse prognosis, which is why it is important to take into account both the inter-burst interval and the intra-burst content.[5]",
        ],
      },
      {
        title: "Defining epileptiform discharges",
        content: [
          "A useful framework is presented here in this infographic adapted from Kural et al. summarizing the International Federation of Clinical Neurophysiology (IFCN) criteria for identifying interictal epileptiform discharge (IEDs).[6]",
          [
            "Di- or tri-phasic wave with pointed peak.",
            "Different wave duration than the ongoing background activity.",
            "Asymmetry of the waveform.",
            "Followed by a slow after-wave.",
            "The background activity is disrupted by the presence of the IEDs.",
            "Voltage map with distribution of the negative and positive potentials suggesting a source in the brain corresponding to a radial, oblique, or tangential orientation of the source.",
          ],
        ],
      },
    ],
    references: [
      "Elmer J, Rittenberger JC, Faro J, et al. Clinically distinct electroencephalographic phenotypes of early myoclonus after cardiac arrest. Ann Neurol. 2016;80(2):175–184.",
      "Edlow BL, Claassen J, Schiff ND, Greer DM. Recovery from disorders of consciousness: mechanisms, prognosis and emerging therapies. 2020 December 14 Epub.",
      "Coppler PJ, Callaway CW, Guyette FX, Baldwin M, Elmer J. Early risk stratification after resuscitation from cardiac arrest. J Am Coll Emerg Physicians Open. 2020;1(5):922–931.",
      "Wijdicks EFM, Parisi JE, Sharbrough FW. Prognostic value of myoclonus status in comatose survivors of cardiac arrest. Ann Neurol. 1994;35(2):239–243.",
      "Niedermeyer E, Sherman DL, Geocadin RJ, Hansen HC, Hanley DF. The Burst-Suppression Electroencephalogram. Clin EEG Neurosci. 1999;30(3):99–105.",
      "Kural MA, Duez L, Sejer Hansen V, et al. Criteria for defining interictal epileptiform discharges in EEG: A clinical validation study. Neurology. 2020;94(20):E2139–E2147.",
    ],
  },
];

module.exports = susDB;
