import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import db from './firebase'
import './Footer.css'

function Footer() {

  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([])

  useEffect(() => {
    db.collection('categories').get().then(querySnapshot => {
      const categories = {};
      querySnapshot.forEach(async categoryDoc => {
        categories[categoryDoc.id] = categoryDoc.data();
      });
      setCategoryList(Object.values(categories)[0].title.sort());
    })
  }, [])


  return (
    <div className="footer">
      <div className="footer__nav">
        <div>
          <p className="smallText" onClick={() => navigate('/about')}>
            About us
          </p>
          <p className="smallText" onClick={() => navigate('/team')}>
            Our team
          </p>
          <p className="smallText" onClick={() => navigate('/study-materials')}>
            Study materials
          </p>
          <p className="smallText" onClick={() => navigate('/case-submit')}>
            Submit case
          </p>
          <p className="smallText" onClick={() => navigate('/faqs')}>
            FAQs
          </p>
          <p className="smallText" onClick={() => navigate('/contact')}>
            Contact us
          </p>
          
        </div>
        

      </div>

      <div className="footer__company">
        <img src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-horz-white.png" alt="" onClick={() => navigate('/')} /><span className="smallText">© UltrasoundBox 2022. All Rights Reserved.</span>
        <span className="smallText ub_textPurple" onClick={() => navigate('/privacy-policy')}>Privacy policy</span>
      </div>
    </div>
  )
}

export default Footer
