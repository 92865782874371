import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import "./FemDone.css";
import success3d from "../3d-png/Succes.png";

function FemDone(prop) {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", paddingBottom: 100 }}>
      <div className="icon3dStyle">
        <img src={success3d} alt="You are the best" />
      </div>
      <div>
        <h3>You are the best!</h3>
        <p>Your total score is</p>
        <h1 className="scoreResult">
          {(
            (prop.accountInfo.totalScore /
              (prop.accountInfo.pastQuestionSet.length * 3)) *
            100
          ).toFixed(2)}
          %
        </h1>
        <p>
          Total correct answer count: {prop.accountInfo.totalScore} /
          {prop.accountInfo.pastQuestionSet.length * 3}
        </p>
        <br />
        <br />
        <p>
          Thank you so much for your participation. If you have any further
          questions, please <Link to="/contact">contact us</Link>.
        </p>
        <br />
        <button className="ub_solidPurple" onClick={() => navigate("/")}>
          Go to UltrasoundBox Home
        </button>
      </div>
    </div>
  );
}

export default FemDone;
