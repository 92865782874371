import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './AortaView.css'
import './TorsoView.css'

import aortaBlockList from '../seed/aortaBlockSeed'

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Dialog, DialogContent } from '@mui/material';

function AortaView() {
  
  const aortaBlockListRef = useRef(aortaBlockList)
  // const { state } = useLocation();
  const location = useLocation();
  const locPath = location.pathname;
  const locNumCut = locPath.split('-')
  const locNum = locNumCut[locNumCut.length - 1] //current block number

  const currentBlockViews = aortaBlockListRef.current[locNum * 1 - 1].usviews


  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [showSeven, setShowSeven] = useState(false);
  const [showEight, setShowEight] = useState(false);

  const [zoomView, setZoomView] = useState(false);
  const openZoomView = (e) => {
    setZoomView(!zoomView)
  }



  const handleOne = (e) => {
    setShowOne(!showOne);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleTwo = (e) => {
    setShowOne(false);
    setShowTwo(!showTwo);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleThree = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(!showThree);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleFour = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(!showFour);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleFive = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(!showFive);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleSix = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(!showSix);
    setShowSeven(false);
    setShowEight(false);
  }
  const handleSeven = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(!showSeven);
    setShowEight(false);
  }
  const handleEight = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowFive(false);
    setShowSix(false);
    setShowSeven(false);
    setShowEight(!showEight);
  }

  return (
    <div className="aortaView torsoViewComponent">
      { zoomView ?
        <Dialog
          open={zoomView}
          onClose={openZoomView}
          fullWidth="true"
          maxWidth="xl"
          className='zoomView__popupWrapper'
        >
          <DialogContent className="zoomView__popupContainer">
            <div className="zoomView__close smallText" onClick={openZoomView}>Close</div>

            {showOne ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[0][0])[0]}`} />
                </video>
              </div>
            ) : ""}

            {showTwo ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[0][1])[0]}`} />
                </video>
              </div>
            ) : ""}

            {showThree ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[1]}`} />
                </video>
              </div>
            ) : ""}

            {showFour ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[2][0])[0]}`} />
                </video>
              </div>
            ) : ""}

            {showFive ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[2][1])[0]}`} />
                </video>
              </div>
            ) : ""}
            
            {showSix ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[3]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[3]}`} />
                </video>
              </div>
            ) : ""}

            {showSeven ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[4]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[4]}`} />
                </video>
              </div>
            ) : ""}

            {showEight ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[5]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[5]}`} />
                </video>
              </div>
            ) : ""}
          </DialogContent>
        </Dialog>

        : ""}


      <div className="torsoView__torsoWrapper">
        <div className="torsoView__torsoImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/torsoFront.jpg" alt="" />
        </div>
        <div className="torsoView__marks">
          { Object.values(currentBlockViews[0][0])[0] || Object.values(currentBlockViews[0][1])[0] ? (
              <div onClick={() => handleOne()} className={showOne || showTwo ? "torsoView__mark torsoView__markOneAorta select" : "torsoView__mark torsoView__markOneAorta"}>
              </div>
            ) : ("")
          }
          { currentBlockViews[1] ? (
            <div onClick={() => handleThree()} className={showThree ? "torsoView__mark torsoView__markThreeAorta select" : "torsoView__mark torsoView__markThreeAorta"}>
            </div>
          ) : ("")}
          {
            Object.values(currentBlockViews[2][1])[0] || Object.values(currentBlockViews[2][1])[0] ? (
              <div onClick={() => handleFour()} className={showFour || showFive ? "torsoView__mark torsoView__markFourAorta select" : "torsoView__mark torsoView__markFourAorta"}>
              </div>
            ) : ("")
          }
          {
            currentBlockViews[3] ? (
              <div onClick={() => handleSix()} className={showSix ? "torsoView__mark torsoView__markSixAorta select" : "torsoView__mark torsoView__markSixAorta"}>
              </div>
            ) : ("")
          }
          {
            currentBlockViews[4] ? (
              <div onClick={() => handleSeven()} className={showSeven ? "torsoView__mark torsoView__markSevenAorta select" : "torsoView__mark torsoView__markSevenAorta"}>
              </div>
            ): ("")
          }
          {
            currentBlockViews[5] ? (
              <div onClick={() => handleEight()} className={showEight ? "torsoView__mark torsoView__markEightAorta select" : "torsoView__mark torsoView__markEightAorta"}>
              </div>
            ) : ("")
          }
          
          
        </div>
      </div>

      <div className="torsoView__usviewWrapper">
        {
          showOne || showTwo ||showThree || showFour || showFive || showSix || showSeven || showEight ?
          <div className="torsoView__usviewZoom"><ZoomInIcon onClick={openZoomView} /></div>
          :
          ""
        }



        {/* Alternative view tabs */}
        {
          (showOne || showTwo) && (Object.values(currentBlockViews[0][0])[0]) && (Object.values(currentBlockViews[0][1])[0]) ? (
            <div className="torsoView__markAlt torsoView__MarkAltTwo">
              <p onClick={() => handleOne()} className={showOne ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[0][0])[0]}
              </p>
              <p onClick={() => handleTwo()}  className={showTwo ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[0][1])[0]}</p>
            </div>
          ) : ("")
        }
        {
          (showFour || showFive) && (Object.values(currentBlockViews[2][0])[0]) && (Object.values(currentBlockViews[2][1])[0]) ? (
            <div className="torsoView__markAlt torsoView__MarkAltFive">
              <p onClick={() => handleFour()} className={showFour ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[2][0])[0]}
              </p>
              <p onClick={() => handleFive()}  className={showFive ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[2][1])[0]}</p>
            </div>
          ) : ("")
        }
        {/* Alternative view tabs end */}


        
        {showOne ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]}`} />
            </video>
          </div>
        ) : ""}
        {showTwo ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[0][1])[0]}`} />
            </video>
          </div>
        ) : ""}

        {showThree ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[1]}`} />
            </video>
          </div>
        ) : ""}
        {showFour ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][0])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[2][0])[0]}`} />
            </video>
          </div>
        ) : ""}
        {showFive ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[2][1])[0]}`} />
            </video>
          </div>
        ) : ""}
        
        {showSix ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[3]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[3]}`} />
            </video>
          </div>
        ) : ""}

        {showSeven ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[4]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[4]}`} />
            </video>
          </div>
        ) : ""}

        {showEight ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[5]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[5]}`} />
            </video>
          </div>
        ) : ""}
      </div>
    </div>
  )
}

export default AortaView
