import React, { useState, useRef, useEffect } from 'react'
import { doc, addDoc, collection, updateDoc, setDoc } from "firebase/firestore"; 
import db from '../firebase';


import { GoogleFormProvider, useGoogleForm, useShortAnswerInput } from 'react-google-forms-hooks'
import { useStopwatch } from 'react-timer-hook';
import preSurveyForm from './sfaSeed/preSurveyForm.json'
import postSurveyForm from './sfaSeed/postSurveyForm.json'
import sfaSeed from './sfaSeed/sfaSeed'


import './SfaLogin.css'
import './SfaStyle.css'
import SfaAgreement from './SfaAgreement';

import CheckboxInput from './sfaGoogleDocComponents/CheckboxInput'
import RadioInput from './sfaGoogleDocComponents/RadioInput'
import ShortAnswerInput from './sfaGoogleDocComponents/ShortAnswerInput'
import LongAnswerInput from './sfaGoogleDocComponents/LongAnswerInput'
import RadioGridInput from './sfaGoogleDocComponents/RadioGridInput'
import CheckboxGridInput from './sfaGoogleDocComponents/CheckboxGridInput'
import DropdownInput from './sfaGoogleDocComponents/DropdownInput'
import LinearGrid from './sfaGoogleDocComponents/LinearGrid'
import SfaCheatsheet from './SfaCheatsheet';
import { Link } from 'react-router-dom';

import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import SfaInterpretationDB from './SfaInterpretationDB';
import SfaImpressionDB from './SfaImpressionDB';

import tempIntroVid from './sfaSeed/EF View - placeholder.mp4'
import emptybox3d from '../3d-png/Empty-BOX.png'



const PreSurveyQuestions = () => {
  const preSurveyFormFields = preSurveyForm.fields
  return (
    <div className="SfaSurveyQuestions">
      {preSurveyFormFields.map((field, index) => {
        // const { id } = field
        
        let questionInput = null
        if( field.type === 'CHECKBOX'){
          questionInput = <CheckboxInput id={field.id} />
        } else if (field.type === 'RADIO') {
          questionInput = <RadioInput id={field.id} />
        } else if (field.type === 'SHORT_ANSWER') {
          questionInput = <ShortAnswerInput id={field.id} />
        } else if (field.type === 'LONG_ANSWER') {
          questionInput = <LongAnswerInput id={field.id} />
        } else if (field.type === 'RADIO_GRID') {
          questionInput = <RadioGridInput id={field.id} />
        } else if (field.type === 'CHECKBOX_GRID') {
          questionInput = <CheckboxGridInput id={field.id} />
        } else if (field.type === 'DROPDOWN') {
          questionInput = <DropdownInput id={field.id} />
        } else if (field.type === 'LINEAR') {
          questionInput = <LinearGrid id={field.id} />
        }
        return (
          // <div key={id}>
          <div className="surveyQuestion" key={field.id}>
            <p>{index + 1}. {field.label}</p>
            <div className="surveyQuestionInputWrapper">{questionInput}</div>
            {/* <p>{field.description}</p> */}
          </div>
        )
      })}
    </div>
  )
}
const PostSurveyQuestions = () => {
  const postSurveyFormFields = postSurveyForm.fields
  return (
    <div className='SfaSurveyQuestions'>
      {postSurveyFormFields.map((field, index) => {
        const { id } = field
        
        let questionInput = null
        if( field.type === 'CHECKBOX'){
          questionInput = <CheckboxInput id={id} />
        } else if (field.type === 'RADIO') {
          questionInput = <RadioInput id={id} />
        } else if (field.type === 'SHORT_ANSWER') {
          questionInput = <ShortAnswerInput id={id} />
        } else if (field.type === 'LONG_ANSWER') {
          questionInput = <LongAnswerInput id={id} />
        } else if (field.type === 'RADIO_GRID') {
          questionInput = <RadioGridInput id={id} />
        } else if (field.type === 'CHECKBOX_GRID') {
          questionInput = <CheckboxGridInput id={id} />
        } else if (field.type === 'DROPDOWN') {
          questionInput = <DropdownInput id={id} />
        } else if (field.type === 'LINEAR') {
          questionInput = <LinearGrid id={id} />
        }
        return (
          // <div key={id}>
          <div className="surveyQuestion" key={id}>
            <p>{index + 1}. {field.label}</p>
            <div className="surveyQuestionInputWrapper">{questionInput}</div>
            {/* <p>{field.description}</p> */}
          </div>
        )
      })}
    </div>
  )
}

// Timer form
// function QuestionStopwatch() {
//   const {
//     seconds,
//     minutes,
//     hours,
//     days,
//     isRunning,
//     start,
//     pause,
//     reset,
//   } = useStopwatch({ autoStart: true });

//   console.log('Stopwatch', {seconds})
//   return (
//     <div>
//       <div>
//         <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
//       </div>
//       <p>{isRunning ? 'Running' : 'Not running'}</p>
//       <button onClick={start}>Start</button>
//       <button onClick={pause}>Pause</button>
//       <button onClick={reset}>Reset</button>
//     </div>
//   );
// }



function SfaLogin() {

  const signupUserIdRef = useRef(null);
  const loginUserIdRef = useRef(null)

  const [haveAccount, setHaveAccount] = useState(true)
  const loginSignupToggle = () => {
    setHaveAccount(!haveAccount)
  }
  const [isLoggedin, setIsLoggedin] = useState(false)
  const [accountInfo, setAccountInfo] = useState({})
  const [ isCheatsheetOpen, setIsCheatsheetOpen ] = useState(false)
  const [showExplanation, setShowExplanation] = useState(false)

  // To display sfa content using account info
  const [isDoneAgreement, setIsDoneAgreement] = useState(false)
  const [isDonePreSurvey, setIsDonePreSurvey] = useState(false)
  const [isDoneQuestions, setIsDoneQuestions] = useState(false)
  const [isDonePostSurvey, setIsDonePostSurvey] = useState(false)
  const [form, setForm] = useState(isDonePreSurvey ? postSurveyForm : preSurveyForm)
  const [pastQs, setPastQs] = useState([])
  const [currentQ, setCurrentQ] = useState({})
  const [isCurrentQCorrect, setIsCurrentQCorrect] = useState(false)
  const [remainedQs, setRemainedQs] = useState([])

  const [correctQCount, setcorrectQCount] = useState(null)
  const [avgScore, setAvgScore] = useState(null)

  const [ isUserSelectedAnswer, setIsUserSelectedAnswer ] = useState(false)
  const [ foldInterpretation, setFoldInterpretation ] = useState(true)
  const [ videoUpdate, setVideoUpdate ] = useState(false)


  // React-hook-form setup for Pre-survey
  const methods = useGoogleForm({ form })
  // const onSubmitPre = async (data) => {
  //   console.log('>>> Here is the data', data)
  //   await methods.submitToGoogleForms(data)
  //   alert('Form submitted with success!')
  //   preSurveySubmit()
  // }
  // React-hook-form setup for Pre-survey end
  
  // React-hook-form setup for Post-survey
  const postMethods = useGoogleForm({ form })
  const onSubmitPost = async (postData) => {
    // console.log('>>> Here is the postData', postData)
    await postMethods.submitToGoogleForms(postData)
    // alert('Form submitted with success!')
    postSurveySubmit()
  }
  // React-hook-form setup for Post-survey end


  // Question choices
  const sfaQuestionChoices = [
    'Significantly decreased systolic function',
    'Decreased systolic function',
    'Normal systolic function',
    'Hyperdynamic systolic function'
  ]
  // Question choices end




  // load all users for login
  // temporarily commented -------------------------
  // const collectAllUsers = []
  // useEffect(() => {
  //   db.collection('sfausers')
  //   .get().then(querySnapshot => {
  //     querySnapshot.forEach(async sfauserDoc => {
  //       collectAllUsers.push(sfauserDoc.data())
  //     })
  //   })
  // })
  // randomize question seed order
  const randomizedQs = []
  const shuffleQuestion = (remainedQs) => {
    const copyArray = remainedQs.map(a => a)
    for (let k = 0; k < remainedQs.length; k++){
      const randomNumGen = Math.floor(Math.random() * (copyArray.length))
      randomizedQs.push(copyArray[randomNumGen])
      copyArray.splice(randomNumGen, 1)
    }
    return randomizedQs
  }
  

  const sfaSignup = async(e) => {
    e.preventDefault();

    // get date and time
    let currentTimestamp = Date.now()
    let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(currentTimestamp)

    
    // Collect all user info
    const signupAllSfaUserIDs = [];
    await db.collection('sfausers')
    .get().then(querySnapshot => {
      querySnapshot.forEach(async sfauserDoc => {
        signupAllSfaUserIDs.push(sfauserDoc.data().userId)
      })
      if(signupUserIdRef.current.value.length < 4){
        window.alert('User ID should be longer than 4 letters.')
      } else if (signupUserIdRef.current.value.toLowerCase() === 'asdf' || signupUserIdRef.current.value.toLowerCase() === 'qwer' || signupUserIdRef.current.value.toLowerCase() === 'abcd' || signupUserIdRef.current.value.toLowerCase() === 'zxcv' || signupUserIdRef.current.value === '1234'){
        window.alert('Unable User ID. Try different User ID.')
      } else if (signupAllSfaUserIDs.includes(signupUserIdRef.current.value)){
        window.alert('This User ID is taken.')
      }else {
        let shuffledQs = shuffleQuestion(sfaSeed)
        setDoc(doc(db, "sfausers", signupUserIdRef.current.value), {
          userId: signupUserIdRef.current.value,
          joined: date,
          lastLogin: date,
          loginHistory: [date],
          remainedQs: shuffledQs,
          currentQ: shuffledQs[0],
          pastQs: [],
          donePreSurvey: false,
          donePostSurvey: false,
          doneAgreement: false,
          correctQCount: 0,
          avgScore: 0
        })
        // local state update
        setAccountInfo({
          userId: signupUserIdRef.current.value,
          joined: date,
          lastLogin: date,
          loginHistory: [date],
          remainedQs: shuffledQs,
          currentQ: shuffledQs[0],
          pastQs: [],
          donePreSurvey: false,
          donePostSurvey: false,
          doneAgreement: false,
          correctQCount: 0,
          avgScore: 0
        })
        setIsDoneAgreement(false);
        setForm(preSurveyForm)
        setIsDonePreSurvey(false);
        setIsDonePostSurvey(false);
        setCurrentQ(shuffledQs[0])
        setRemainedQs(shuffledQs)
        setPastQs([])
        setcorrectQCount(0)
        setcorrectQCount(0)
        setAvgScore(0)

        setIsLoggedin(true)
      }
    })

    // scroll to top
    window.scrollTo(0, 0);
  }
  // Signup end
  
  

  // Login start
  const sfaLogin = (e) => {
  // Get current time
  let currentTimestamp = Date.now()
  let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(currentTimestamp)

  const collectAllUsers = []
  db.collection('sfausers').get().then(querySnapshot => {
    querySnapshot.forEach(async sfauserDoc => {
      collectAllUsers.push(sfauserDoc.data())
    })
    const matchedUser = collectAllUsers.find(user => user.userId === loginUserIdRef.current.value)
    if (matchedUser) {
      // keep account info
      setAccountInfo({
        joined: matchedUser.joined,
        userId: matchedUser.userId,
        lastLogin: date,
        loginHistory: [...matchedUser.loginHistory, date],
        remainedQs: matchedUser.remainedQs,
        currentQ: matchedUser.currentQ, 
        pastQs: matchedUser.pastQs,
        donePreSurvey: matchedUser.donePreSurvey,
        donePostSurvey: matchedUser.donePostSurvey,
        doneAgreement: matchedUser.doneAgreement,
        correctQCount: matchedUser.correctQCount,
        avgScore: matchedUser.avgScore
      })
      setIsDoneAgreement(matchedUser.doneAgreement);
      setForm(matchedUser.donePreSurvey ? postSurveyForm : preSurveyForm)
      setIsDonePreSurvey(matchedUser.donePreSurvey);
      setIsDoneQuestions(matchedUser.remainedQs.length === 0 ? true : false)
      setIsDonePostSurvey(matchedUser.donePostSurvey);
      setCurrentQ(matchedUser.currentQ)
      setRemainedQs(matchedUser.remainedQs)
      setPastQs(matchedUser.pastQs)
      setcorrectQCount(matchedUser.correctQCount)
      setAvgScore(matchedUser.avgScore)
      setIsLoggedin(true)
      // Moving on the page
      setIsLoggedin(true)
      // updated firebase db
      const updateLastLoginFB = async() => {
        await updateDoc(doc(db, "sfausers", matchedUser.userId), {
          lastLogin: date,
          loginHistory: [...matchedUser.loginHistory, date]
        })
      }
      updateLastLoginFB()
    } else {
      window.alert('User ID not found.')
    }
  })
  // scroll to top
  window.scrollTo(0, 0);
}



  // SFA Agreement start
  const [ agreementSigned, setAgreementSigned] = useState(false)
  const toggleConsent = (e) => {
    if(document.querySelector('input#consent[type="checkbox"]').checked){
      setAgreementSigned(true)
    } else( setAgreementSigned(false))
  }
  const clickAgreement = () => {
    // updated firebase db
    const updateLastLoginFBAgreement = async() => {
      const firebaseUserIdMatch = doc(db, "sfausers", accountInfo.userId)
      await updateDoc(firebaseUserIdMatch, {
        doneAgreement: true,
      })
      .catch((err) => {console.error("Cannot login", err)})
    }
    updateLastLoginFBAgreement()
    setIsDoneAgreement(true)

    // scroll to top
    window.scrollTo(0, 0);
  }
  // SFA Agreement end




  // SFA PreSurvey start
  const [ isSurveySubmitButtonActive, setIsSurveySubmitButtonActive ] = useState(false)
  // // CHECK INPUTS
  const checkSurvey = () => {
    const totalNumberSurveyQuestions = document.querySelectorAll('.surveyQuestion').length - 1
    const checkedNumberSurveyQuestions = document.querySelectorAll('input:checked').length
    if(checkedNumberSurveyQuestions === totalNumberSurveyQuestions) {
      setIsSurveySubmitButtonActive(true)
    } else {
      setIsSurveySubmitButtonActive(false)
    }
  }
  // // submit
  const onSubmitPre = async (data) => {
    // console.log('>>> Here is the data', data)
    await methods.submitToGoogleForms(data)
    // alert('Form submitted with success!')

    // Update firebase db
    const updateLastLoginFBPreSurvey = async() => {
      const firebaseUserIdMatch = doc(db, "sfausers", accountInfo.userId)
      await updateDoc(firebaseUserIdMatch, {
        donePreSurvey: true,
      })
      .catch((err) => {console.error("Something's wrong", err)})
    }
    updateLastLoginFBPreSurvey()
    setIsDonePreSurvey(true)

    // Timer reset
    reset()
    // scroll to top
    window.scrollTo(0, 0);
    // Back the state to false to disable button
    setIsSurveySubmitButtonActive(false)
  }
  // SFA PreSurvey end





  // Stopwatch
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });
  console.log(seconds)
  // Stopwatch end





  // Zoom US view in question
  const [showZoomSfaView, setShowZoomSfaView] = useState(false)
  const sfaZoomViewToggle =() => {
    setShowZoomSfaView(!showZoomSfaView)
  }


  // Question Cheatsheet toggle
  const toggleCheatsheet = () => {
    setIsCheatsheetOpen(!isCheatsheetOpen)
  }
  // Question Cheatsheet toggle end
  // Question answer chosen -> make sure only one should be checked
  const removeAllCheck = () => {
    for ( let r = 0; r < 4; r++){
      if(document.getElementById(r + 1).checked == true){
        document.getElementById(r + 1).checked = false;
      }
    }
  }
  const sfaAnswerChosen = async (idNum) => {
    setIsUserSelectedAnswer(true)
    console.log('idNum, currentQ.answer, idNum === currentQ.answer', idNum, currentQ.answer, idNum === currentQ.answer)
    if(idNum === currentQ.answer){
      setIsCurrentQCorrect(true)
    } else {
      setIsCurrentQCorrect(false)
    }
    await removeAllCheck()
    document.getElementById(idNum).checked = true;
  }
  // Question answer chosen end
  // Question answer submit -> show answer start
  const sfaQuesetionAnswerShow = async() => {
    // disable selecting choices anymore
    let allCheckboxes = document.querySelectorAll('input[type="checkbox"')
    for(let node of allCheckboxes) {node.disabled = true;}

    setShowExplanation(true)
    let markedAnswer = document.querySelectorAll('input[type="checkbox"]:checked')
    let newRemainedQs = []
    const GenNewRemainedQs = () => {
      const copyOfRemainedQs = remainedQs.map(a => a)
      copyOfRemainedQs.splice(0, 1)
      newRemainedQs = copyOfRemainedQs
    }

    GenNewRemainedQs()
    // Update firebase db
    const updateQuestionUserAnswer = doc(db, "sfausers", accountInfo.userId)
    await updateDoc(updateQuestionUserAnswer, {
      pastQs: [...pastQs, {
        questionId: remainedQs[0].questionId,
        questionText: remainedQs[0].questionText,
        usViewUrl: remainedQs[0].usViewUrl,
        torsoUrl: remainedQs[0].torsoUrl,
        usViewLocation: remainedQs[0].usViewLocation,
        isCorrect: remainedQs[0].answer === Number(markedAnswer[0].id) ? true : false,
        correctAnswer: remainedQs[0].answer,
        userAnswer: Number(markedAnswer[0].id),
        timeSpent: `${days}:${hours}:${minutes}:${seconds}`
      }],
      remainedQs: newRemainedQs,
      currentQ: newRemainedQs[0] ? newRemainedQs[0] : null,
      correctQCount: remainedQs[0].answer === Number(markedAnswer[0].id) ? correctQCount + 1 : correctQCount,
      avgScore: remainedQs[0].answer === Number(markedAnswer[0].id) ? ((correctQCount + 1) / (pastQs.length + 1)) * 100 : ((correctQCount) / (pastQs.length + 1)) * 100
    })
    // .then(window.alert('Answer is submitted. Click Next for next question.'))
    .catch((err) => {console.error("Error on submitting answer", err)})
  }
  // Question answer submit -> show answer end

  // Question anwwer submit -> move to next step start
  const sfaNextQuestion = async() => {
    // un-disable selecting choices anymore
    let allCheckboxes = document.querySelectorAll('input[type="checkbox"')
    for(let node of allCheckboxes) {node.disabled = false;}

    // Video update
    setVideoUpdate(!videoUpdate)

    // disable submit button again
    setIsUserSelectedAnswer(false)


    let markedAnswer = document.querySelectorAll('input[type="checkbox"]:checked')
    
    let newRemainedQs = []
    const GenerateNewRemainedQs = () => {
      if(remainedQs.length > 1){
        const copyOfRemainedQs = remainedQs.map(a => a)
        copyOfRemainedQs.splice(0, 1)
        newRemainedQs = copyOfRemainedQs
      } else if(remainedQs.length === 1) {
        setIsDoneQuestions(true)
        setForm(postSurveyForm)
      }
      
    }
    GenerateNewRemainedQs()
    setCurrentQ(newRemainedQs[0] ? newRemainedQs[0] : null);
    setRemainedQs(newRemainedQs);
    setPastQs([...pastQs, {
      questionId: remainedQs[0].questionId,
      questionText: remainedQs[0].questionText,
      usViewUrl: remainedQs[0].usViewUrl,
      torsoUrl: remainedQs[0].torsoUrl,
      usViewLocation: remainedQs[0].usViewLocation,
      isCorrect: isCurrentQCorrect,
      correctAnswer: remainedQs[0].answer,
      userAnswer: Number(markedAnswer[0].id) + 1,
      timeSpent: `${days}:${hours}:${minutes}:${seconds}`
    }])
    setAccountInfo({
      ...accountInfo,
      remainedQs: newRemainedQs,
      currentQ: newRemainedQs[0] ? newRemainedQs[0] : null,
      pastQs: [...pastQs, {
        questionId: remainedQs[0].questionId,
        questionText: remainedQs[0].questionText,
        usViewUrl: remainedQs[0].usViewUrl,
        torsoUrl: remainedQs[0].torsoUrl,
        usViewLocation: remainedQs[0].usViewLocation,
        isCorrect: remainedQs[0].answer === Number(markedAnswer[0].id) + 1 ? true : false,
        correctAnswer: remainedQs[0].answer,
        userAnswer: Number(markedAnswer[0].id) + 1,
        timeSpent: `${days}:${hours}:${minutes}:${seconds}`
      }],
      correctQCount: isCurrentQCorrect ? correctQCount + 1 : correctQCount,
      avgScore: isCurrentQCorrect ? ((correctQCount + 1) / (pastQs.length + 1)) * 100 : (correctQCount / (pastQs.length + 1)) * 100,
    })
    setcorrectQCount(isCurrentQCorrect ? correctQCount + 1 : correctQCount)
    setAvgScore(isCurrentQCorrect ? ((correctQCount + 1) / (pastQs.length + 1)) * 100 : ((correctQCount) / (pastQs.length + 1)) * 100)
    removeAllCheck()
    setShowExplanation(false)


    // scroll to top
    window.scrollTo(0, 0);
  }
  // Question anwwer submit -> move to next step end


  // Interpretation detail content toggle
  const foldInterpretationToggle = () => {
    setFoldInterpretation(!foldInterpretation)
  }


  // Post survey submit start
  const postSurveySubmit = () => {
    // Update firebase db
    const updateLastLoginFBPostSurvey = async() => {
      const firebaseUserIdMatchPostSurvey = doc(db, "sfausers", accountInfo.userId)
      await updateDoc(firebaseUserIdMatchPostSurvey, {
        donePostSurvey: true,
      })
      // .then(window.alert('Everything is done!'))
      .catch((err) => {console.error("Something went wrong", err)})
    }
    updateLastLoginFBPostSurvey()
    setIsDonePostSurvey(true)

    // scroll to top
    window.scrollTo(0, 0);
  }
  // Post survey submit end



  return (
    <>
    {isLoggedin ?
    //////////////////////////////////////////////////////////////////////
    // If logged in
      <div className="SfaContainer">

        {/* Consent/agreement step */}
        { !isDoneAgreement && !isDonePreSurvey && !isDoneQuestions ?
        <div className="SfaAgreement">
          <SfaAgreement />
          <div className="SfaAgreement__consentWrapper">
            <input type="checkbox" id="consent" name="consent" onClick={toggleConsent} />
            <label>I have read this consent describing the project and the research study being performed and how the information collected will be used. I agree to this.</label>
            <button className="ub_solidPurple" disabled = {agreementSigned ? false : true} onClick={clickAgreement}>Agree</button>
          </div>
          
        </div>
        : null }
        

        {/* Pre-survey step */}
        { isDoneAgreement && !isDonePreSurvey && !isDoneQuestions && !isDonePostSurvey ?
        <div className="SfaSurvey SfaPreSurvey">
          <GoogleFormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmitPre)} onChange={checkSurvey}>
              {preSurveyForm.title && (
                <>
                  <h1>Pre Trial Survey</h1>
                  {preSurveyForm.description && (
                    <p style={{ fontSize: '.8rem' }}>{preSurveyForm.description}</p>
                  )}
                </>
              )}
              <PreSurveyQuestions />
              <button type='submit' className="ub_solidPurple" disabled={isSurveySubmitButtonActive ? false : true}>Submit</button>
            </form>
          </GoogleFormProvider>
        </div>
        : null }

        

        {/* Cheatsheet modal */}
        {isCheatsheetOpen ? 
        <div className="sfaCheatsheetBackdrop" onClick={() => toggleCheatsheet()}>
        </div>
        : null}
        {isCheatsheetOpen ? 
        <div className="sfaCheatsheetModalWrapper">
          <div className="sfaCheatsheetModal">
            {/* <h1>Reference Sheet</h1> */}
            <div className="sfaCheatsheetModal-close" onClick={() => toggleCheatsheet()}>
              <p>CLOSE</p>
            </div>
            <SfaCheatsheet />
          </div>
        </div>
        : null}


        {/* Question US view zoom popup modal */}
        {showZoomSfaView ? 
        <div className="sfaUsZoomViewBackdrop" onClick={()=> sfaZoomViewToggle()}></div>
        : null}
        {showZoomSfaView ?
        <div className="sfaUsZoomViewWrapper">
          <div className="sfaUsZoomView">
            <video playsInline autoPlay muted loop>
              <source src={`${currentQ.usViewUrl}`} />
            </video>
          </div>
          <div className="sfaUsZoomView-close" onClick={() => sfaZoomViewToggle()}>
            <p>CLOSE</p>
          </div>
        </div>
        : null}

        {/* Questions process */}
        { isDoneAgreement && isDonePreSurvey && !isDoneQuestions && !isDonePostSurvey ?
        <div className='sfaQuestionProcessWrapper'>
          <div className="sfaQuestionProcessText">
            <p>Process: {pastQs.length}/{sfaSeed.length}</p>
          </div>
          <div className="sfaQuestionProcessText">
            <p>Score: {avgScore.toFixed(0)}%</p>
          </div>
          <div className="sfaQuestionProcessText">
            <div className='sfaQuestion__cheatsheetToggleBtn sfaQuestion__cheatsheetToggleBtnWithIcon' onClick={() => toggleCheatsheet()}>
              <ScienceOutlinedIcon /><p>Reference Sheet</p>
            </div>
          </div>
          {/* <div className='sfaQuestionProcessFrame'>
            <div className="sfaQuestionProcess" style={{ width: `${pastQs.length / sfaSeed.length * 100 + 1}%` }} ></div>
          </div> */}
        </div>
        : null}
        {/* Questions */}
        { isDoneAgreement && isDonePreSurvey && !isDoneQuestions && !isDonePostSurvey ?
        <div className="sfaQuestionWrapper">
          <div className="sfaQuestion__viewWrapper">
            <div className='sfaQuestion__viewSticky'>
              <div className="sfaQuestion__viewUS">
                {/* Test zoom toggle button location */}
                <div className="sfaQuestion__viewZoomToggle" onClick={() => sfaZoomViewToggle()}>
                  <ZoomInOutlinedIcon />
                </div>
                {videoUpdate ?
                <div className="sfaQuestion__viewTorso" style={{backgroundImage: `url(${currentQ.torsoUrl})`}}>
                </div>
                : null}
                {!videoUpdate ?
                <div className="sfaQuestion__viewTorso" style={{backgroundImage: `url(${currentQ.torsoUrl})`}}>
                </div>
                : null}
                

                {/* {currentQ.usViewUrl} */}
                { videoUpdate ? 
                <video playsInline autoPlay muted loop>
                  <source src={`${currentQ.usViewUrl}`} />
                </video>
                : null}
                { !videoUpdate ? 
                <video playsInline autoPlay muted loop>
                  <source src={`${currentQ.usViewUrl}`} />
                </video>
                : null}
              </div>
            </div>
            
            
          </div>

          <div className="sfaQuestion__contentWrapper">
            <div className="sfaQuestion__contentHeader">
              <p className="sfaQuestion__contentHeader-question">{sfaSeed.length - remainedQs.length + 1}. What is the left ventricular systolic function of the clip shown?</p>
            </div>
            <div className="sfaQuestion__contentChoices">
              {sfaQuestionChoices.map((choice, index) => {
                return (
                  <div className={`sfaQuestion__contentChoices-choice 
                    ${showExplanation && isCurrentQCorrect ? "sfaCorrectChoice" : null}
                    ${showExplanation && !isCurrentQCorrect ? "sfaIncorrectChoice" : null}
                    `} key={choice}>
                    <input type="checkbox" id={index + 1} name="" value={choice} onClick={() => sfaAnswerChosen(index + 1)} />
                    <label htmlFor={index + 1}>{index + 1}. {choice}</label>
                  </div>
                )
              })}
            </div>
            {showExplanation ? 
            <div className="sfaAnswerExpWrapper">
              <div className="sfaAnswerExp-answer">
                {isCurrentQCorrect ? 
                <p className='sfaQuestion__correctBadge sfaQuestion__correctBadge-correct'>Correct</p>
                :
                <p className='sfaQuestion__correctBadge sfaQuestion__correctBadge-incorrect'>Wrong</p>
                }
                <p>Answer: {currentQ.answer}</p>
              </div>
              <div className="sfaAnswerExp-interp">
                { foldInterpretation ?
                <p className="sfaAnswerExp-title ub_textPurple" onClick={() => foldInterpretationToggle()}>Read detailed explanation</p>
                : null}
                
                {/* <p>{currentQ.interpretation}</p> */}
                { foldInterpretation ? 
                null
                : 
                <SfaInterpretationDB interpretation={currentQ.interpretation} />
                }

                { !foldInterpretation ?
                <p className="sfaAnswerExp-title ub_textPurple" onClick={() => foldInterpretationToggle()}>Hide detailed explanation</p>
                : null}
              </div>
              <div className="sfaAnswerExp-impres">
                <p className="sfaAnswerExp-title">Impression</p>
                <SfaImpressionDB impression={currentQ.impression} />
              </div>
            </div>
            : null}

            {/* Button to show explanation */}
            {!showExplanation && (remainedQs.length > 1) ?
            <div className="sfaQuestion__next">
              {/* saving original below */}
              {/* <button type="next" className="ub_solidPurple" onClick={() => sfaQuesetionAnswerShow()} disabled = {isUserSelectedAnswer ? false : true}>Submit</button> */}
              <button type="next" className="ub_solidPurple" onClick={function(e) {sfaQuesetionAnswerShow(); pause()}} disabled = {isUserSelectedAnswer ? false : true}>Submit</button>
            </div>
            :null }
            {/* Button to go next question */}
            {showExplanation && (remainedQs.length > 1) ? 
            <div className="sfaQuestion__next">
              {/* Saving original */}
              {/* <button type="next" className="ub_solidPurple" onClick={() => sfaNextQuestion()}>Next</button> */}
              <button type="next" className="ub_solidPurple" onClick={function(e){sfaNextQuestion(); reset()}}>Next</button>
            </div>
            : null}

            {/* Button for the last question show explanation */}
            {!showExplanation && (remainedQs.length === 1) ?
            <div className="sfaQuestion__next">
              <button type="next" className="ub_solidPurple" onClick={() => sfaQuesetionAnswerShow()}>Almost done</button>
            </div>
            : null
            }
            {/* Button for the last question and end */}
            {showExplanation && (remainedQs.length === 1) ?
            <div className="sfaQuestion__next">
              <button type="next" className="ub_solidPurple" onClick={() => sfaNextQuestion()}>Done!</button>
            </div>
            : null
            }
            
          </div>
        </div>
        : null }


        {/* Post Survey */}
        { isDoneAgreement && isDonePreSurvey && isDoneQuestions && !isDonePostSurvey ?
        <div className="SfaSurvey SfaPostSurvey">
          <GoogleFormProvider {...postMethods}>
            <form onSubmit={postMethods.handleSubmit(onSubmitPost)} onChange={checkSurvey}>
              {postSurveyForm.title && (
                <>
                  <h1>Post Trial Survey</h1>
                  {postSurveyForm.description && (
                    <p style={{ fontSize: '.8rem' }}>{postSurveyForm.description}</p>
                  )}
                </>
              )}
              <PostSurveyQuestions />
              <button type='submit' className="ub_solidPurple" disabled={isSurveySubmitButtonActive ? false : true}>Submit</button>
            </form>
          </GoogleFormProvider>
        </div>
        : null }


        {/* Done! */}
        { isDoneAgreement && isDonePreSurvey && isDoneQuestions && isDonePostSurvey ? 
        <div className="sfaDone">
          <div>
            <img src={emptybox3d} alt="" />
          </div>

          <div className='sfaDone__score'>
            <p>Thank you!<br />Your cumulated Score is</p>
            <h1>{avgScore.toFixed(2)}%</h1>
          </div>
          <Link to="/">
            <button className="ub_solidPurple">Back to UltrasoundBox home</button>
          </Link>
          
          
        </div>
        : null}
      </div>
    :

    //////////////////////////////////////////////////////////////////////
    // Not logged in
      <div className="SfaContainer">
        {/* <h1>Left Ventricular Systolic Function Interpretation Learning Module</h1> */}

        <div className="SfaContainer__conentWrapper">
          
          
          { !haveAccount ?
          // Not have account
          <div className='SfaLogin'>
            <h1>Left Ventricular Systolic Function Interpretation Learning Module</h1>
            <div className="SfaLogin__contentWrapper">
              
              <div className="SfaLogin__content SfaLogin__content-login">
                {/* <h2>Register</h2> */}
                <div className="SfaLogin__toggleTabWrapper">
                  <p className="SfaLogin_toggle SfaLogin_toggleUnselected" onClick={loginSignupToggle}>Login</p>
                  <p className="SfaLogin_toggle SfaLogin_toggleSelected">Register</p>
                </div>

                <input ref={signupUserIdRef} placeholder='User ID' type="text" />
                <button type="submit" className="ub_solidPurple" onClick={sfaSignup}>Sign up</button>
                {/* <p className="smallText ub_textPurple SfaLogin_toggle" onClick={loginSignupToggle}>Already have an account?</p> */}
                <p className="smallText SfaLogin__userNameInfo">
                  * Has to be more than 4 characters.<br />
                  * Cannot be continuous characters or numbers.
                </p>
              </div>
              
              
            </div>
          </div>
          :
          // Have account
          <div className='SfaLogin'>
            <h1>Left Ventricular Systolic Function Interpretation Learning Module</h1>
            <div className="SfaLogin__contentWrapper">
              
              <div className="SfaLogin__content SfaSignup__content-signup">
                {/* <h2>Login</h2> */}
                <div className="SfaLogin__toggleTabWrapper">
                  <p className="SfaLogin_toggle SfaLogin_toggleSelected">Login</p>
                  <p className="SfaLogin_toggle SfaLogin_toggleUnselected" onClick={loginSignupToggle}>Register</p>
                </div>
                
                <input ref={loginUserIdRef} placeholder='User ID' type="text" />
                <button className="ub_solidPurple" onClick={function(e){sfaLogin(); reset()}}>Login</button>
                {/* <p className="smallText ub_textPurple SfaLogin_toggle" onClick={loginSignupToggle}>Don't have an account yet?</p> */}
              </div>
            </div>
          </div>
          }

          <div className="SfaLogin__content-intro">
            <video controls playsInline autoPlay muted loop controlsList='nodownload'>
              <source src={"https://ultrasoundbox.s3.amazonaws.com/sfa/EF+Video+Final.mp4"} />
            </video>
            
          </div>
          
          
        </div>

        <div className="SfaLogin__description">
          <p> This module is intended to teach users how to interpret left ventricular systolic function using point-of-care ultrasound (POCUS). It is still under development, and as such all user data will be collected and analyzed to improve the module and for research purposes.
            <br /><br /> Left ventricular systolic function is a significant determinant of the overall cardiac output, and can be visually estimated without formally calculating an ejection fraction. This is an essential skill for bedside clinicians that requires intentional practice. Here, we provide images from real cases for the learner to use at their own pace and time.  
          <br /><br /> We hope our module, consisting of clips with normal, reduced, and hyperdynamic, left ventricular systolic function will improve your ability to perform this assessment. </p>
        </div>


        <div className="SfaLogin__members">
          <div className="SfaLogin__memberGroup">
            <p className="SfaLogin__memberGroupTitle">Creators</p>
            <div className="SfaLogin__memberList">
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://ultrasoundbox.s3.amazonaws.com/members/leland-2.jpg" alt="" />
                </div>
                <p>Leland Perice, MD</p>
              </div>
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://ultrasoundbox.s3.amazonaws.com/members/Matthew+taylor%2C+MD.JPG" alt="" />
                </div>
                <p>Matthew Taylor, MD</p>
              </div>
              
            </div>
          </div>

          <div className="SfaLogin__memberGroup">
            <p className="SfaLogin__memberGroupTitle">Editors</p>
            <div className="SfaLogin__memberList">
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://ultrasoundbox.s3.amazonaws.com/members/Nhu-NguyenLe.jpeg" alt="" />
                </div>
                <p>Nhu-Nguyen Le, MD</p>
              </div>
            </div>
          </div>

          <div className="SfaLogin__memberGroup">
            <p className="SfaLogin__memberGroupTitle">Faculty Reviewers</p>
            <div className="SfaLogin__memberList">
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://ultrasoundbox.s3.amazonaws.com/members/Joseph+Pare%2C+MD%2C+MHS.jpg" alt="" />
                </div>
                <p>Joseph Pare, MD, MHS</p>
              </div>
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://ultrasoundbox.s3.amazonaws.com/members/Jennifer+Rogers%2CMD.jpeg" alt="" />
                </div>
                <p>Jennifer Rogers, MD</p>
              </div>
              {/* <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://via.placeholder.com/300" alt="" />
                </div>
                <p>Leland Perice</p>
              </div>
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://via.placeholder.com/300" alt="" />
                </div>
                <p>Leland Perice</p>
              </div>
              <div className="SfaLogin__member">
                <div className="SfaLogin__memberPhoto">
                  <img src="https://via.placeholder.com/300" alt="" />
                </div>
                <p>Leland Perice</p>
              </div> */}
            </div>
          </div>
        </div>
        
      </div>
    }

    </>
    
  )
}

export default SfaLogin