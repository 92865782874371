// Original
import firebase, { getApp } from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// import env from "react-dotenv";

import { getFirestore } from "firebase/firestore"

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
const db = firebaseApp.firestore();
const storedb = getFirestore();
const auth = firebase.auth();




export { auth }
export default db;
export {storedb};
