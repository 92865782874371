import React from "react";

import "./FemCheatsheet.css";

function FemCheatsheet() {
  return (
    <div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/Intro+and+Reference+video+FEM.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>
            Overview Video of left ventricular systolic function assessment.
          </p>
        </div>
      </div>

      <div className="Cheatsheet__sectionHeadline">
        <h2>Left upper quadrant</h2>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/LUQ+abnormal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of abnormal left upper quadrant </p>
        </div>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/LUQ+normal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of normal left upper quadrant </p>
        </div>
      </div>

      <div className="Cheatsheet__sectionHeadline">
        <h2>Right upper quadrant</h2>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/RUQ+abnormal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of abnormal right upper quadrant </p>
        </div>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/RUQ+normal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of normal right upper quadrant </p>
        </div>
      </div>

      <div className="Cheatsheet__sectionHeadline">
        <h2>Pelvic</h2>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/Pelvic+abnormal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of abnormal pelvic </p>
        </div>
      </div>
      <div className="Cheatsheet__sectionWrapper">
        <div>
          <video
            controls
            playsInline
            autoPlay
            muted
            loop
            controlsList="nodownload"
          >
            <source
              src={
                "https://ultrasoundbox.s3.amazonaws.com/fem/reference_videos/Pelvis+normal.mp4"
              }
            />
          </video>
        </div>
        <div>
          <p>Example of normal pelvic </p>
        </div>
      </div>
    </div>
  );
}

export default FemCheatsheet;
