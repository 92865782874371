import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import './MaterialScreen.css'


function MaterialScreen() {

  const navigate = useNavigate();
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  return (
    <div className="materialScreen">
      <div className='materialScreen__header'>
        <h1>Study Materials</h1>
        <p>Listed below are some useful reference resources </p>
      </div>

      <div className="materialScreen__cardWrapper">

        <div className="materialScreen__card">
          <div className="materialScreen__cardContent">
            <div className="materialScreen__cardImage">
              <img src="https://ultrasoundbox.s3.amazonaws.com/material/pocus.jpg" alt="" />
              <div className="gradBottom"></div>
            </div>
            
            <div className='materialScreen__cardText'>
              <p>The Pocus Atlas</p>
              <span className="smallText">Open source atlas of ultrasound clips</span>
            </div>
          </div>
          <div className="materialScreen__cardAction">
            <a href="https://www.thepocusatlas.com/" target="_blank" className="ub_textPurple">Learn More</a>
          </div>
        </div>

        <div className="materialScreen__card">
          <div className="materialScreen__cardContent">
            <div className="materialScreen__cardImage">
              <img src="https://ultrasoundbox.s3.amazonaws.com/material/temp.jpg" alt="" />
              <div className="gradBottom"></div>
            </div>
            <div className='materialScreen__cardText'>
              <p>Emergency Ultrasound Teaching</p>
              <span className="smallText">Video series for each ultrasound topic</span>
            </div>
          </div>
          <div className="materialScreen__cardAction">
            <a href="http://www.emergencyultrasoundteaching.com/narrated_lectures.html" target="_blank" className="ub_textPurple">Learn More</a>
          </div>
        </div>

        <div className="materialScreen__card">
          <div className="materialScreen__cardContent">
            <div className="materialScreen__cardImage">
              <img src="https://ultrasoundbox.s3.amazonaws.com/material/sonoguide.jpg" alt="" />
              <div className="gradBottom"></div>
            </div>
            <div className='materialScreen__cardText'>
              <p>SONOGUIDE</p>
              <span className="smallText">Introductory review of the basics of ultrasound (has introduction sections for each of the basic views and pictures of those views if you need to review those)</span>
            </div>
          </div>
          <div className="materialScreen__cardAction">
            <a href="https://www.acep.org/sonoguide/" target="_blank" className="ub_textPurple">Learn More</a>
          </div>
        </div>

        <div className="materialScreen__card">
          <div className="materialScreen__cardContent">
            <div className="materialScreen__cardImage">
              <img src="https://ultrasoundbox.s3.amazonaws.com/material/core.jpg" alt="" />
              <div className="gradBottom"></div>
            </div>
            <div className='materialScreen__cardText'>
              <p>Core Ultrasound</p>
              <span className="smallText">Bank of example clips of each pathology</span>
            </div>
          </div>
          <div className="materialScreen__cardAction">
            <a href="https://www.coreultrasound.com/clipbank/" target="_blank" className="ub_textPurple">Learn More</a>
          </div>
        </div>

        <div className="materialScreen__card">
          <div className="materialScreen__cardContent">
            <div className="materialScreen__cardImage">
              <img src="https://ultrasoundbox.s3.amazonaws.com/material/gel.jpg" alt=" " />
              <div className="gradBottom"></div>
            </div>
            <div className='materialScreen__cardText'>
              <p>Ultrasound G.E.L Podcast</p>
              <span className="smallText">Podcast which reviews the landmark papers within the ultrasound would</span>
            </div>
          </div>
          <div className="materialScreen__cardAction">
            <a href="https://www.ultrasoundgel.org/articles" target="_blank" className="ub_textPurple">Learn More</a>
          </div>
        </div>

      </div>

      <div className='materialScreen__more'>
        
        <div className="materialScreen__moreContent">
          <h2>Want to share your go-to ultrasound educational resource?</h2>
          <p>Let us know and we can post it here!</p>
          <button className='ub_solidPurple' onClick={() => navigate('/contact')}>Share together</button>
        </div>
        {/* <img src="https://ultrasoundbox.s3.amazonaws.com/img/mobile%2BdesktopUser.png" alt="" /> */}
      </div>
    </div>
  )
}

export default MaterialScreen
