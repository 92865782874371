import { Button, Icon } from '@mui/material'
import React, { useEffect } from 'react'
import './LoginScreen.css'
import SignupScreen from './SignupScreen'

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

function LoginScreen() {

  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  return (
    <div className="loginScreen">

      {/* Content */}
      <div className="loginScreen__body">
        <div className="loginScreen__intro">
          <h2>More with an account</h2>
          <p>Learn from real cases, real patients and real exprience</p>
        </div>
        <SignupScreen />
        
      </div>
    </div>
  )
}

export default LoginScreen
