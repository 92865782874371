import { ExpandMoreOutlined } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './FaqScreen.css'

function FaqScreen() {
  const navigate = useNavigate();
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <div className='faqScreen'>
      <div className="faqScreen__faq">
        <div className="faqScreen__faqQs">
          <h2>FAQs</h2>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} area-aria-controls='panel1bh-content' id="panel1bh-header">
              <p>Why is UltrasoundBox in beta?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="faqScreen__faqA">We currently have a limited number of cases. Being an open-source platform, we need help in creating a collection of cases from the world to learn from.</p>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} area-aria-controls='panel2bh-content' id="panel2bh-header">
              <p>How do questions get created?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="faqScreen__faqA">Cases are contributed to us through the website. We then review them and upload those that we think offer valuable educational content for the world to benefit from.</p>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} area-aria-controls='panel3bh-content' id="panel3bh-header">
              <p>Can I create a question?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="faqScreen__faqA">Yes! We welcome any ultrasound-passionate people to join us and create more cases to share with the community.<br /><span onClick={() => navigate('/case-submit')} className="ub_textPurple">Submit your question here.</span></p>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} area-aria-controls='panel4bh-content' id="panel4bh-header">
              <p>Do I have to create an account?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="faqScreen__faqA">No, you don't have to. You can enjoy all of the content we have without an account. But we offer additional features with accounts such as score analytics of your past attempts. Going forward, we are going to continuously add more features to create the best educational experience possible.</p>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="faqScreen__faqImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/studentsitting2.png" alt="" />
         </div>
      </div>
    </div>
  )
}

export default FaqScreen