const TraumaBlockList = [
  // Block 1
  {
    categoryId: 3,
    difficultyLevel: 'difficulty1',
    questionTags: [ 'Anatomy, eFAST'],
    authorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center, Brooklyn, New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: 'Match each letter with the correct anatomical structures',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/trauma/block1/1.gif', 'https://ultrasoundbox.s3.amazonaws.com/trauma/block1/2.gif', 'https://ultrasoundbox.s3.amazonaws.com/trauma/block1/3.gif', 'https://ultrasoundbox.s3.amazonaws.com/trauma/block1/4.gif','','https://ultrasoundbox.s3.amazonaws.com/trauma/block1/5.gif'],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'What structure is A in the RUQ?',
        options: 'Kidney, Liver, Spleen, Gallbladder',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'What structure is B in the RUQ?',
        options: 'Kidney, Liver, Spleen, Gallbladder',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'What structure is A in the LUQ?',
        options: 'Kidney, Liver, Lung , Spleen, Stomach, Gallbladder',
        answer: 3,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'What structure is B in the LUQ?',
        options: 'Kidney, Liver, Lung , Spleen, Stomach, Gallbladder',
        answer: 4,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'What structure is C in the LUQ?',
        options: 'Kidney, Liver, Lung , Spleen, Stomach, Gallbladder',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'What structure is A in the Pelvic view?',
        options: 'Kidney, Liver, Bladder , Bowel, Seminal Vesicle, Gallbladder',
        answer: 3,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is A in the supxiphoid view?',
        options: 'Right Atrium, Right Ventricle, Left Atrium, Left Ventricle, Liver, Gallbladder',
        answer: 5,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is B in the supxiphoid view?',
        options: 'Right Atrium, Right Ventricle, Left Atrium , Left Ventricle, Liver, Gallbladder',
        answer: 2,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is C in the supxiphoid view?',
        options: 'Right Atrium, Right Ventricle, Left Atrium , Left Ventricle, Liver, Gallbladder',
        answer: 1,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is D in the supxiphoid view?',
        options: 'Right Atrium, Right Ventricle, Left Atrium , Left Ventricle, Liver, Gallbladder',
        answer: 4,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is E in the supxiphoid view?',
        options: 'Right Atrium, Right Ventricle, Left Atrium, Left Ventricle, Liver, Gallbladder',
        answer: 3,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is A in the left thorax view?',
        options: 'Lung, Intercostal Muscles, Rib , Diaphragm',
        answer: 3,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is B in the left thorax view?',
        options: 'Lung, Pleural line / Intercostal Muscles, Rib , Diaphragm',
        answer: 2,
        explain: ''
      },
	  {
        qtype: 'reading',
        question: 'What structure is C in the left thorax view?',
        options: 'Lung, Pleural line / Intercostal Muscles, Rib , Diaphragm',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'general',
        question: ' Regarding the anatomy of the LUQ view of the fast exam:',
        options: 'The LUQ view is anterior and inferior compared to the RUQ view , The phrenicocolic ligament restricts flow of free fluid directly from the left para-colic gutter to the splenorenal recess, It is the most sensitive view of the FAST for free fluid, None of the above',
        answer: 2,
        explain: '2: This ligament blocks fluid from freely coming to the LUQ from the left paracolic gutter. As free fluid does not easily flow into the splenorenal recess, it is important to visualize the left diaphragm and subphrenic space in the LUQ view of the fast as fluid can initially accumulate above the spleen.'
      },
      {
        qtype: 'general',
        question: 'The most dependent part of the peritoneal cavity in the supine position is the:',
        options: ' Right upper quadrant, Left upper quadrant , Pelvis, None of the above',
        answer: 1,
        explain: '1: The most sensitive view on FAST is the RUQ at the caudal tip of the liver. Morrisons pouch, which is the hepatorenal recess (the space between the kidney and liver) in the RUQ, is the most dependent part of the abdomen in the supine patient.'
      },
    ]
  },
	
	
// Block 2
	  {
    categoryId: 3,
    difficultyLevel: 'difficulty1',
    questionTags: [ 'eFAST'],
    authorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center, Brooklyn, New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '26 year old male comes to the ED after being hit in the abdomen with a golf club. You perform a FAST exam:',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/trauma/block2/1.gif','','','','',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Is there free fluid in the RUQ?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'general',
        question: ' What is the minimum volume of free fluid in the peritoneal cavity that will show up on a fast exam?',
        options: '10cc, 100cc, 500cc, 750cc',
        answer: 2,
        explain: '2: 100cc is the smallest volume that can be detected with atrained operator using amodern ultrasound on the right upper quadrant view.Non-trained operators may need 250-500cc to be able to consistently detect free fluid in this view.'
      },
      {
        qtype: 'general',
        question: 'The patient states he feels light-headed and his blood pressure drops to 90/50. Of the following options, which is the most appropriate next step in the management of this patient?',
        options: ' Repeat US in 30 minutes, Repeat Hematocrit at 4 hours, Obtain a CT scan of the abdomen with IV contrast, Call surgery for the patient to go to the operating room',
        answer: 4,
        explain: '4: With a positive FAST and hemodynamic instability, the patient should go directly to the operating room, with stabilization measures such as blood transfusion being done simultaneously as to not delay definitive management'
      },
	],
    interpretation: [
      ['This is a positive FAST exam, with free fluid found within the abdomen. This is seen in the right upper quadrant, which has a small amount of free fluid at the tip of liver. This is the first place fluid will accumulate acutely. This likely represents hemorrhage in this otherwise healthy young patient coming to the emergency room after trauma.'],
      ['Right upper quadrant found to have abdominal free fluid.'],
      ['There is free fluid identified in the RUQ suggesting intraabdominal hemorrhage.']
    ]  
  },
		
		// Block 3
	  {
    categoryId: 3,
    difficultyLevel: 'difficulty1',
    questionTags: [ 'eFAST'],
    authorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center,Brooklyn ,New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '31 year old male comes in after a motor vehicle accident. You perform a FAST: ',
    patientGender: 'male',
    usviews:  ['','https://ultrasoundbox.s3.amazonaws.com/trauma/block3/2.gif','','','',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Is there free fluid in the LUQ?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'general',
        question: 'How does your imaging impact the probability this patient has significant intra-abdominal traumatic sequelae?',
        options: 'Increases it, Does not change the probability, Decreases it, Rules out any significant traumatic sequelae',
        answer: 1,
        explain: '1: The FAST exam is very specific for assessment of free fluid and is able to detect as little as 100ml of free fluid within the peritoneal cavity in the right upper quadrant (most sensitive window of the FAST. It is also the most dependent in the supine position so fluid will accumulate here first). In a young male with no other medical problems who comes in after trauma, presence of fluid is likely blood, representing most commonly a splenic or liver laceration.'
      },
      {
        qtype: 'general',
        question: ' Other then some abdominal tenderness, there are no other significant findings on exam. The patient is hemodynamically stable. What is the appropriate next step in the management of this patient?',
        options: 'Discharge the patient, Call surgery to arrange for the patient to go directly to the operating room, Obtain a CT scan of the abdomen',
        answer: 3,
        explain: '3: A positive FAST in this otherwise healthy patient likely represents hemorrhage from intra-abdominal traumatic sequelae. As he is hemodynamically stable, a CT scan is indicated to assess for the source of the free fluid'
      },
	],
    interpretation: [
      ['This is a positive FAST exam, with free fluid found in the abdomen. This likely represents hemorrhage in an otherwise healthy young patient coming to the emergency room after trauma.','BREAK','The phrenicocolic ligament, which connects the spleen and colon, prevents blood from directly entering between the spleen and kidney. Rather, blood in the LUQ flows superior to the spleen and then eventually makes its way between the spleen and kidney. This makes the area between the diaphragm and spleen the most sensitive area for free fluid in the LUQ.'],
     ['Left upper quadrant was found to have abdominal free fluid..'],
      ['There is free fluid identified in the LUQ suggesting intraabdominal hemorrhage.']
    ]  
  },
		// Block 4
	  {
    categoryId: 3,
    difficultyLevel: 'difficulty2',
    questionTags: [ 'eFAST'],
    authorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center, Brooklyn, New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '33 year old female comes in after a motor motorcycle accident. You perform a FAST:',
    patientGender: 'female',
    usviews:  ['','','https://ultrasoundbox.s3.amazonaws.com/trauma/block4/3.gif','','',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Is there free fluid in the Pelvis?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'general',
        question: 'How does your imaging impact the probability this patient has significant intra-abdominal traumatic sequelae?',
        options: 'Increases it, Does not change the probability, Decreases it, Rules out any significant traumatic sequelae',
        answer: 1,
        explain: '1: The FAST exam is very specific when assessing free fluid. Here we see fluid outside the bladder which likely represents blood'
      },
      {
        qtype: 'general',
        question: ' The following can create false positive eFAST exams in the suprapubic view:',
        options: 'Seminal vesicles, Free fluid in the pouch of Douglas in a woman, Ovarian cyst, Fluid within a collapsed bladder, All of the above',
        answer: 5,
        explain: '5. All of these things can be mistaken for free fluid in this view. Notably, premenopausal women occasionally have a small amount of physiologic free fluid in the pouch of Douglas (related to the menstrual cycle).'
      },
	],
    interpretation: [
      ['This is a positive FAST exam, with free fluid found within the abdomen. This likely represents hemorrhage in this otherwise healthy young patient coming to the emergency room after trauma'],
      ['Pelvic view found to have abdominal free fluid.'],
      ['There is free fluid identified in the pelvic area suggesting intraabdominal hemorrhage.']
    ]  
  },
		
// Block 5
	  {
    categoryId: 3,
    difficultyLevel: 'difficulty1',
    questionTags: [ 'eFAST'],
    authorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center, Brooklyn, New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    additionalAuthorInfo: {
      name: '',
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '33 year old male comes in after a motor motorcycle accident. You perform a FAST: ',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/trauma/block5/1.mp4','https://ultrasoundbox.s3.amazonaws.com/trauma/block5/2.mp4','','','',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Is there free fluid in the RUQ?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there free fluid in the LUQ?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },

	],
    interpretation: [
      ['The free fluid in these cases were really subtle. It can be seen in Morrison’s pouch in the right upper quadrant and in the splenorenal space in the left upper quadrant. Make sure you pay close attention during the FAST exam to not miss this.','BREAK','Also, it is important to note that hypoechoic spaces can represent any fluid, not just blood. In this case the free fluid seen on these clips turned out to be bowel contents secondary to perforated small bowel'],
      ['RUQ view showing free fluid between the right kidney and liver. LUQ view showing free fluid between the spleen and the left kidney at the beginning of the clip.'],
      ['There is free fluid identified in the RUQ and LUQ suggesting intraabdominal hemorrhage.']
    ]  
  },


//	Block 6
   {
    categoryId: 3,
    difficultyLevel: 'difficulty2',
    questionTags: [ 'pelvic', 'ectopic', 'hemoperitoneum'],
    authorInfo: {
      name: 'Shirley Wu, MD',
      institution: 'Brown Emergency Medicine, Providence, Rhode Island', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    additionalAuthorInfo: {
      name: '',
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    editorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: 'lelandperice@gmail.com', 
      phoneNumber: '', 
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: 'Young female presents with syncope and abdominal pain. She has not had a period for the past few months and is found to have significant abdominal pain. You perform a POCUS:',
    patientGender: 'female',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/trauma/block6/1.mp4','','https://ultrasoundbox.s3.amazonaws.com/trauma/block6/3.mp4','','',''],
    questionInfo: 
    [
      {
        qtype: 'reading', 
        question: 'Is there free fluid?', 
        options: 'Yes, No',
        answer: 1, 
        explain: ''
      }, 
     {
        qtype: 'reading', 
        question: 'Is there an intrauterine pregnancy?', 
        options: 'Yes, No',
        answer: 2, 
        explain: ''
      }, 
      {
        qtype: 'reading', 
        question: 'What is the most likely diagnosis?', 
        options: 'Perforated viscous, Ruptured ovarian cyst, Ruptured ectopic pregnancy, Physiologic findings in menses',
        answer: 3, 
        explain: ''
      }, 
      {
        qtype: 'reading', 
        question: 'Of the following choices, which is the best next step in the management of this patient?', 
        options: 'Surgery consult , Monitor the patient , OB/GYN consult',
        answer: 3, 
        explain: ''
      }
  	], 
    interpretation: 
    [
      ['Upon these POCUS findings, and in the context of the patient not having had a period for the past few months, there was high concern for a ruptured ectopic pregnancy. The patient was taken to the operating room where she was found to have a ruptured ectopic pregnancy in the left fallopian tube with over 2 liters of hemoperitoneum. After successful operative intervention, she was discharged the next day without further complication. Upon close inspection, it is possible to see a tubal ring sign in the pelvic clip, which is a direct visualization of the tubal ectopic pregnancy outside the uterus. This has a positive predictive value of over 90% for ectopic pregnancy.'],
      ['No intrauterine pregnancy seen. Tubal ring sign seen in the adnexa outside the uterus consistent with an ectopic pregnancy. Significant free fluid seen in the right upper quadrant.'], 
      ['No intrauterine pregnancy along with tubal ring sign and significant free fluid raises concern for a ruptured ectopic pregnancy.']
    ]  
  },  



]
module.exports = TraumaBlockList