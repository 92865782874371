const wmaQuestionSets = [
  {
    id: 1,
    name: 'Parasternal Short',
    choices: [
      'Anterior', 'Anterolateral', 'Inferolateral', 'Inferior', 'Inferoseptal', 'Anteroseptal'
    ]
  },
  {
    id: 2,
    name: 'Parasternal Long',
    choices: [
      'Apex', 'Apical Anterior', 'Mid Anteroseptal', 'Basal Anteroseptal', 'Apical Lateral', 'Mid Inferolateral', 'Basal Inferolateral'
    ]
  },
  {
    id: 3,
    name: 'Apical 4',
    choices: [
      'Apex', 'Apical Lateral', 'Mid Anterolateral', 'Basal Anterolateral', 'Apical Septal', 'Mid Inferoseptal', 'Basal Inferoseptal' 
    ]
  }
]
module.exports = wmaQuestionSets