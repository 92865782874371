import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import './BannerLoggedIn.css'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import db, { auth } from './firebase';

function BannerLoggedIn() {
  const [accounts, setAccounts] = useState([])
  let navigate = useNavigate();

  function truncate(string, n) {
    return string?.length > n ? string.substr(0, n - 1) + '...' : string;
  }
  useEffect(() => {
    if(auth.currentUser){
      db.collection('users').where("email", "==", `${auth.currentUser.email}`)
      .get().then(querySnapshot => {
        const accounts = [];
        querySnapshot.forEach(async userDoc => {
          accounts.push(userDoc.data())
          // accounts[userDoc.id] = userDoc.data();
        });
        setAccounts(accounts[0])
      })
    }
    
  }, [])


  return (
    <header className="bannerLoggedIn">
      { auth.currentUser ? 
      <div className="bannerLoggedIn__contents">
        <div className="">
          <h1 className="bannerLoggedIn__text">
            {accounts.firstName ? `Hi, ${accounts.firstName}` : "Welcome"}
          </h1>
          <p onClick={() => navigate('/profile')} className="bannerLoggedIn__editProfile smallText"><EditOutlinedIcon fontSize='small' /> Edit profile</p>
        </div>
      </div>
      : ""}
      

    </header>
  )
}

export default BannerLoggedIn
