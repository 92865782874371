import React, { useEffect, useState } from 'react';
import db, { auth } from '../firebase';

import cardioBlockList from '../seed/cardioBlockSeed'
import biliaryBlockList from '../seed/biliaryBlockSeed'
import renalBlockList from '../seed/renalBlockSeed'
import traumaBlockList from '../seed/traumaBlockSeed'
import pelvicBlockList from '../seed/pelvicBlockSeed'
import ocularBlockList from '../seed/ocularBlockSeed'
import thoracicBlockList from '../seed/thoracicBlockSeed'
import aortaBlockList from '../seed/aortaBlockSeed'
import bowelBlockList from '../seed/bowelBlockSeed'

import DataSummary from '../DataSummary'
import './DataScreen.css'




function DataScreen() {

  const [categories, setCategories] = useState([])

  const [myTakes, setMyTakes] = useState([]);
  const [myCardiothoracic, setMyCardiothoracic] = useState([]);
  const [myCardiothoracicBlocks, setMyCardiothoracicBlocks] = useState([]);
  const [myRenal, setMyRenal] = useState([]);
  const [myBiliary, setMyBiliary] = useState([]);
  const [myTrauma, setMyTrauma] = useState([]);
  const [myPelvic, setMyPelvic] = useState([]);
  const [myOcular, setMyOcular] = useState([]);
  const [myThoracic, setMyThoracic] = useState([]);
  const [myAorta, setMyAorta] = useState([]);
  const [myBowel, setMyBowel] = useState([]);


  useEffect(() => {
    db.collection('categories').get().then(querySnapshot => {
      const categories = {};
      querySnapshot.forEach(async categoryDoc => {
        categories[categoryDoc.id] = categoryDoc.data();
      });
      setCategories(Object.values(categories)[0].title.sort());
    })
    db.collection('takes').where("userEmail", "==", auth.currentUser.email).get().then(querySnapshot => {
      const allTakesObj = {};
      querySnapshot.forEach(async takeDoc => {
        allTakesObj[takeDoc.id] = takeDoc.data();
      });
      const myTakes = Object.values(allTakesObj)
      setMyTakes(myTakes)
      
      // Collect Cardiothoracic takes
      const allMyCardioTakes = myTakes.filter(take => take.cardioBlockId)
      setMyCardiothoracic(allMyCardioTakes)
      let cardioBlockCount = [];
      allMyCardioTakes.map(take => {
        if(!cardioBlockCount.includes(take.cardioBlockId)) cardioBlockCount.push(take.cardioBlockId)
      })
      setMyCardiothoracicBlocks(cardioBlockCount)
      
      setMyRenal(myTakes.filter(take => take.renalBlockId))
      setMyBiliary(myTakes.filter(take => take.biliaryBlockId))
      setMyTrauma(myTakes.filter(take => take.traumaBlockId))
      setMyPelvic(myTakes.filter(take => take.pelvicBlockId))
      setMyOcular(myTakes.filter(take => take.ocularBlockId))
      setMyThoracic(myTakes.filter(take => take.thoracicBlockId))
      setMyAorta(myTakes.filter(take => take.aortaBlockId))
      setMyBowel(myTakes.filter(take => take.bowelBlockId))
    })
  }, [])

  // Unique blocks taken with its best score 
  // This was needed to ignore blocks taken more than once
  const countUniqueBestScoreBlocks = (takes, blockIdObj) => {
    let blockIdKey = Object.values(blockIdObj)
    let sortedBest = takes.sort((a, b) => (a.takeScore < b.takeScore) ? 1 : (a.takeScore === b.takeScore) ? ((a.takeTime < b.takeTime) ? 1 : -1 ) : -1)
    let uniqueBlocks = [];
    let uniqueBlocksIds = uniqueBlocks.map( block => block[`${blockIdKey}`])

    for ( let i = 0; i < sortedBest.length; i++) {
      if(!uniqueBlocksIds.includes(sortedBest[i][`${blockIdKey}`])) {
        uniqueBlocks.push(sortedBest[i])
        uniqueBlocksIds = uniqueBlocks.map( block => block[`${blockIdKey}`])
      }
    }
    return uniqueBlocks
  }
  // unique blocks taken count finish


  // Calculate Overview data
  // // Total progress
  const countAllBlocks = cardioBlockList.length + biliaryBlockList.length + renalBlockList.length + traumaBlockList.length + pelvicBlockList.length + ocularBlockList.length + thoracicBlockList.length + aortaBlockList.length + bowelBlockList.length
  const countBlocksTaken = 
    + countUniqueBestScoreBlocks(myCardiothoracic, {findId:"cardioBlockId"}).length + 
    + countUniqueBestScoreBlocks(myRenal, {findId:"renalBlockId"}).length
    + countUniqueBestScoreBlocks(myBiliary, {findId:"biliaryBlockId"}).length
    + countUniqueBestScoreBlocks(myTrauma, {findId:"traumaBlockId"}).length
    + countUniqueBestScoreBlocks(myPelvic, {findId:"pelvicBlockId"}).length 
    + countUniqueBestScoreBlocks(myOcular, {findId:"ocularBlockId"}).length 
    + countUniqueBestScoreBlocks(myThoracic, {findId:"thoracicBlockId"}).length
    + countUniqueBestScoreBlocks(myAorta, {findId:"aortaBlockId"}).length
    + countUniqueBestScoreBlocks(myBowel, {findId:"bowelBlockId"}).length
  const totalProgressPercentage = (countBlocksTaken / countAllBlocks * 100).toFixed(2)
  // // Average score
  const allScoreCombined = () => {
    let combinedScore = 0;
    myAorta.map(take => combinedScore += take.takeScore)
    myBiliary.map(take => combinedScore += take.takeScore)
    myBowel.map(take => combinedScore += take.takeScore)
    myCardiothoracic.map(take => combinedScore += take.takeScore)
    myOcular.map(take => combinedScore += take.takeScore)
    myPelvic.map(take => combinedScore += take.takeScore)
    myRenal.map(take => combinedScore += take.takeScore)
    myThoracic.map(take => combinedScore += take.takeScore)
    myTrauma.map(take => combinedScore += take.takeScore)
    return combinedScore / (myAorta.length + myBiliary.length + myBowel.length + myCardiothoracic.length + myOcular.length + myPelvic.length + myRenal.length + myThoracic.length + myTrauma.length)
  }

  // Overview data finish





  // Each category data
  const eachCategoryAvg = (category) => {
    let eachCategoryTotalScore = 0
    let eachCategoryAvgScore = 0
    if(category === 'aorta'){
      myAorta.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myAorta.length).toFixed(2)
    } else if(category === 'biliary'){
      myBiliary.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myBiliary.length).toFixed(2)
    } else if(category === 'bowel'){
      myBowel.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myBowel.length).toFixed(2)
    } else if(category === 'cardiothoracic'){
      myCardiothoracic.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myCardiothoracic.length).toFixed(2)
    } else if(category === 'ocular'){
      myOcular.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myOcular.length).toFixed(2)
    } else if(category === 'pelvic'){
      myPelvic.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myPelvic.length).toFixed(2)
    } else if(category === 'renal'){
      myRenal.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myRenal.length).toFixed(2)
    } else if(category === 'thoracic'){
      myThoracic.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myThoracic.length).toFixed(2)
    } else if(category === 'trauma'){
      myTrauma.map(take => eachCategoryTotalScore += take.takeScore)
      eachCategoryAvgScore = (eachCategoryTotalScore / myTrauma.length).toFixed(2)
    }
    return eachCategoryAvgScore
  }
  // Each category data finish
  
  





  return (
    <div className="dataScreen">
      
      <div className="dataScreen__overallData">

        {/* Overview section */}
        <div>
          Overview
          <div className="dataScreen__overviewUnitWrapper">
            Total progress<br />
            {totalProgressPercentage}%<br/>
            {countBlocksTaken} / {countAllBlocks}
          </div>
          <div className="dataScreen__overviewUnitWrapper">
            Average score<br />
            {allScoreCombined()}%<br/>
          </div>
          {/* <div className="dataScreen__overviewUnitWrapper">
            Best category<br />
            {allScoreCombined()}%<br/>
          </div> */}
        </div>

        {/* Each category section */}
        <div>
          Each category
          <div className="dataScreen__eachCategoryUnit">
            Aorta<br />
            Average score: {eachCategoryAvg('aorta')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            biliary<br />
            Average score: {eachCategoryAvg('biliary')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            bowel<br />
            Average score: {eachCategoryAvg('bowel')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            cardiothoracic<br />
            Average score: {eachCategoryAvg('cardiothoracic')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            ocular<br />
            Average score: {eachCategoryAvg('ocular')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            pelvic<br />
            Average score: {eachCategoryAvg('pelvic')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            renal<br />
            Average score: {eachCategoryAvg('renal')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            thoracic<br />
            Average score: {eachCategoryAvg('thoracic')}%
          </div>
          <div className="dataScreen__eachCategoryUnit">
            trauma<br />
            Average score: {eachCategoryAvg('trauma')}%
          </div>
        </div>
        
        <div className="dataScreen__overallDataContent">
          {categories.map(category => {
            return(
              <div className="dataScreen__eachCategoryData" key={category}>
                <p>{category}
                  <span className="smallText">  
                    
                    { category === 'pelvic' ? `${countUniqueBestScoreBlocks(myPelvic, {findId:"pelvicBlockId"}).length} / ${pelvicBlockList.length}` : ""}
                    { category === 'renal' ? `${countUniqueBestScoreBlocks(myRenal, {findId:"renalBlockId"}).length} / ${renalBlockList.length}` : ""}
                    { category === 'thoracic' ? `${countUniqueBestScoreBlocks(myThoracic, {findId:"thoracicBlockId"}).length} / ${thoracicBlockList.length}` : ""}
                    { category === 'trauma' ? `${countUniqueBestScoreBlocks(myTrauma, {findId:"traumaBlockId"}).length} / ${traumaBlockList.length}` : ""}
                    { category === 'aorta' ? `${countUniqueBestScoreBlocks(myAorta, {findId:"aortaBlockId"}).length} / ${aortaBlockList.length}` : ""}
                    { category === 'biliary' ? `${countUniqueBestScoreBlocks(myBiliary, {findId:"biliaryBlockId"}).length} / ${biliaryBlockList.length}` : ""}
                    { category === 'bowel' ? `${countUniqueBestScoreBlocks(myBowel, {findId:"bowelBlockId"}).length} / ${bowelBlockList.length}` : ""}
                    { category === 'cardiothoracic' ? `${countUniqueBestScoreBlocks(myCardiothoracic, {findId:"cardioBlockId"}).length} / ${cardioBlockList.length}` : ""}
                    { category === 'ocular' ? `${countUniqueBestScoreBlocks(myOcular, {findId:"ocularBlockId"}).length} / ${ocularBlockList.length}` : ""}
                  </span>
                </p>
                <div className="dataScreen__progressBarWrapper">
                  { category === 'aorta' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myAorta, {findId:"aortaBlockId"}).length / aortaBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'cardiothoracic' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myCardiothoracic, {findId:"cardioBlockId"}).length / cardioBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'ocular' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myOcular, {findId:"ocularBlockId"}).length / ocularBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'pelvic' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myPelvic, {findId:"pelvicBlockId"}).length / pelvicBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'renal' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myRenal, {findId:"renalBlockId"}).length / renalBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'biliary' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myBiliary, {findId:"biliaryBlockId"}).length / biliaryBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'thoracic' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myThoracic, {findId:"thoracicBlockId"}).length / thoracicBlockList.length) * 100}%`}}></div> : ""}
                  { category === 'trauma' ? 
                    <div className="dataScreen__progressBar" style={{width: `${(countUniqueBestScoreBlocks(myTrauma, {findId:"traumaBlockId"}).length / traumaBlockList.length) * 100}%`}}></div> : ""}

                  
                </div>
              </div>
            )
          })}
        </div>
      </div>
      
      {/* <DataSummary /> */}
    </div>
  )
}

export default DataScreen