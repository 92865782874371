import React from 'react'
import { useNavigate } from 'react-router-dom'

import './NotFoundScreen.css'

function NotFoundScreen() {
  const navigate = useNavigate();

  return (
    <div className="notFoundScreen">
      <h1>Oops!</h1>
      <p>Something went wrong.
        <br />Please try again or report an issue to support.</p>
      <br />
      <button className='ub_solidPurple' onClick={() => navigate('/')}>Home</button>
      <br /><button className='ub_textPurple' onClick={() => navigate('/contact')}>Contact us</button>
      
    </div>
  )
}

export default NotFoundScreen