import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThankYouContributionScreen.css'

import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box } from '@mui/system';
import { Step, StepLabel, Stepper } from '@mui/material';

function ThankYouContributionScreen() {
  const navigate = useNavigate()
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  const steps = [
    'Submitted!',
    'Review & audit',
    'Publish case',
    'Receive credit'
  ];

  return <div className="thankYouContribution">
    <div className="thankYouContribution__bodyWrapper">
      
      <div className="thankYouContribution__title">
        <FavoriteIcon />
        <h2>Thank you</h2>
        <p>for your contribution</p>
      </div>
      
      <div className="thankYouContribution__process">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel className="smallText">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <p className="thankYouContribution__processDetail">Your contributed case is successfully delivered to UltrasoundBox team for review and audit. We make sure the case is valid, correct and unique in our database.<br /><br />Once it is approved by our team, the case will be shared on the site publically and the author will receive the credit! This submission status will be updated via contact information from the form.</p>
      </div>
      <button className="ub_solidPurple" onClick={() => navigate('/') }>Go to UltrasoundBox</button>
      <button className="ub_textPurple" onClick={() => navigate('/case-submit')}>More cases to share?</button>
    </div>
  </div>;
}

export default ThankYouContributionScreen;
