import React, { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  setDoc,
  QuerySnapshot,
} from "firebase/firestore";
import db from "../firebase";

import "./surveyStyle.css";

const FemPreSurvey = (prop) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState(prop.accountInfo.username);
  const [trainingLevel, setTrainingLevel] = useState("");
  const [onlineEdExp, setOnlineEdExp] = useState("");
  const [fastExp, setFastExp] = useState("");
  const [expLevel, setExpLevel] = useState("");
  const [comfortLevel, setComfortLevel] = useState("");
  const [performFrequency, setPerformFrequency] = useState("");

  const [isAllAnswered, setIsAllAnswered] = useState(false);

  let currentTimestamp = Date.now();
  let date = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);

  const handleSubmit = (event) => {
    event.preventDefault();
    // update master local status
    prop.updatePreSurveyProgress(date);
    // update DB
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === prop.accountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              // lastLogin: date,
              preSurveyDone: {
                email,
                username,
                trainingLevel,
                onlineEdExp,
                fastExp,
                expLevel,
                comfortLevel,
                performFrequency,
                date,
              },
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });
  };
  // Implement form submission here, for example send data to a server
  console.log({
    email,
    username,
    trainingLevel,
    onlineEdExp,
    fastExp,
    expLevel,
    comfortLevel,
    performFrequency,
    date,
  });

  return (
    <div>
      <h1>Account Creation and Pre-Test Questionnaire</h1>
      <form>
        <div className="surveySection">
          <legend>User Information</legend>

          <label className="smallText" htmlFor="email">
            Email<span className="markRequired">Required</span>
          </label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <br />
          <label className="smallText" htmlFor="username">
            Username:
          </label>
          <br />
          <input
            type="text"
            id="username"
            name="username"
            disabled
            // onChange={(e) => setUsername(prop.accountInfo.username)}
            required
            value={`${prop.accountInfo.username}`}
          />
        </div>

        <div className="surveySection">
          <legend>Current Level of Training</legend>

          <label className="surveySectionLabel" htmlFor="preMed">
            Pre-Medical School
            <input
              type="radio"
              id="preMed"
              name="training_level"
              value="preMed"
              onChange={(e) => setTrainingLevel(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          {[1, 2, 3, 4].map((el, index) => (
            <label className="surveySectionLabel" htmlFor={`m${el}`}>
              M{el}
              <input
                type="radio"
                id={`m${el}`}
                name="training_level"
                value={`m${el}`}
                onChange={(e) => setTrainingLevel(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
          ))}
          {[1, 2, 3, 4, 5, 6].map((el, index) => (
            <label className="surveySectionLabel" htmlFor={`pgy${el}`}>
              PGY{el}
              <input
                type="radio"
                id={`pgy${el}`}
                name="training_level"
                value={`pgy${el}`}
                onChange={(e) => setTrainingLevel(e.target.value)}
              />
              <span className="checkmark"></span>
            </label>
          ))}
          <label className="surveySectionLabel" htmlFor="pgy6+">
            PGY6+
            <input
              type="radio"
              id="pgy6+"
              name="training_level"
              value="pgy6+"
              onChange={(e) => setTrainingLevel(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="attending">
            Attending
            <input
              type="radio"
              id="attending"
              name="training_level"
              value="attending"
              onChange={(e) => setTrainingLevel(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="other">
            Other
            <input
              type="radio"
              id="other"
              name="training_level"
              value="other"
              onChange={(e) => setTrainingLevel(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="surveySection">
          <legend>
            Have you ever used an online question bank to aid in your education?
          </legend>

          <label className="surveySectionLabel" htmlFor="yes">
            Yes
            <input
              type="radio"
              id="yes"
              name="onlineEdExp"
              value="yes"
              onChange={(e) => setOnlineEdExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="no">
            No
            <input
              type="radio"
              id="no"
              name="onlineEdExp"
              value="no"
              onChange={(e) => setOnlineEdExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="surveySection">
          <legend>
            Do you have any prior experience in interpreting a FAST exam?
          </legend>

          <label className="surveySectionLabel" htmlFor="noPreviousFastExp">
            No previous FAST experience previous FAST experience
            <input
              type="radio"
              id="noPreviousFastExp"
              name="fastExp"
              value="noPreviousFastExp"
              onChange={(e) => setFastExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="knowButNeverAttempted">
            I know what a FAST exam is, but have never attempted to interpret a
            FAST exam on my own
            <input
              type="radio"
              id="knowButNeverAttempted"
              name="fastExp"
              value="knowButNeverAttempted"
              onChange={(e) => setFastExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="haveSomeExp">
            I have some experience in interpreting FAST exams
            <input
              type="radio"
              id="haveSomeExp"
              name="fastExp"
              value="haveSomeExp"
              onChange={(e) => setFastExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="significantExp">
            I have significant experience in interpreting FAST exams
            <input
              type="radio"
              id="significantExp"
              name="fastExp"
              value="significantExp"
              onChange={(e) => setFastExp(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="surveySection">
          <legend>
            On a scale from 1 = no experience to 10 = extensive experience, what
            is your skill level with point of care ultrasound (POCUS)
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="expLevel"
              name="expLevel"
              value={expLevel}
              className="dropdownSelect"
              onChange={(e) => setExpLevel(e.target.value)}
            >
              <option value="">Select your skill level</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            On a scale from 1 = not at all comfortable to 10 = extremely
            comfortable, how comfortable are you performing a FAST determine
            whether or not there is intraperitoneal free fluid:
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="comfortLevel"
              name="comfortLevel"
              value={comfortLevel}
              className="dropdownSelect"
              onChange={(e) => setComfortLevel(e.target.value)}
            >
              <option value="">Select your comfort level</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            When caring for patients with an indication for a point of care FAST
            exam, how often do you perform this procedure yourself?{" "}
          </legend>
          <label className="surveySectionLabel" htmlFor="never">
            Never (1)
            <input
              type="radio"
              id="never"
              name="performFrequency"
              value="never"
              onChange={(e) => setPerformFrequency(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="rarely">
            Rarely (2)
            <input
              type="radio"
              id="rarely"
              name="performFrequency"
              value="rarely"
              onChange={(e) => setPerformFrequency(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="sometimes">
            Sometimes (3)
            <input
              type="radio"
              id="sometimes"
              name="performFrequency"
              value="sometimes"
              onChange={(e) => setPerformFrequency(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="often">
            Often (4)
            <input
              type="radio"
              id="often"
              name="performFrequency"
              value="often"
              onChange={(e) => setPerformFrequency(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <label className="surveySectionLabel" htmlFor="always">
            Always (5)
            <input
              type="radio"
              id="always"
              name="performFrequency"
              value="always"
              onChange={(e) => setPerformFrequency(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="buttonWrapper">
          {email !== "" &&
          trainingLevel !== "" &&
          onlineEdExp !== "" &&
          fastExp !== "" &&
          expLevel !== "" &&
          comfortLevel !== "" &&
          performFrequency !== "" ? (
            <button className="ub_solidPurple" onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            <button className="ub_solidPurple" disabled onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FemPreSurvey;
