import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './SubmitYoursAd.css'

import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';

function SubmitYoursAd() {
  const navigate = useNavigate()


  return (
    <div className="submitYoursAd">
      
      <div className='submitYoursAd__contentWrapper'>
        {/* <BeenhereOutlinedIcon style={{color:"#1976d2"}}/> */}
        <h2>Submit your case and get credit</h2>
        <p>Have an interesting case you want to share so that others can learn from it? Submit it by clicking below!</p>
        <button className="ub_solidPurple" onClick={() => navigate('/case-submit')}>Submit</button>
      </div>
    </div>
  )
}

export default SubmitYoursAd
