import { Avatar, Button, Divider, List, ListItem, ListItemText, Menu, MenuItem, SwipeableDrawer } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import db, { auth } from './firebase';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import './Nav.css'
import { Box } from '@mui/system';




function Nav() {

  let navigate = useNavigate();
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const mediaMatch = window.matchMedia('(max-width: 1120px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  
  const [signIn, setSignIn] = useState(false);
  const [show, handleShow] = useState(false);
  const [authEmail, setAuthEmail] = useState('')
  const [userInfo, setUserInfo] = useState({})
  const [avatarInitial, setAvatarInitial] = useState('')

  const [showDropdown, setShowDropdown] = useState(false)
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    if(openDropdown) setOpenDropdown(false)
    setShowDropdown(open);
  };


  const userLogOut = () => {
    // navigate('/')
    auth.signOut()
    window.location.reload()
  }

  
  const currentUrl = document.location.href.split('/')[document.location.href.split('/').length - 1]
  

  useEffect(() => {
    if(auth.currentUser){
      db.collection('users').where("email", "==", `${auth.currentUser.email}`)
      .get().then(querySnapshot => {
        const accounts = [];
        querySnapshot.forEach(async userDoc => {
          accounts.push(userDoc.data())
        });
        setUserInfo(accounts[0])
      })
    }
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.addListener(handler);
    
    // window.addEventListener("scroll", transitionNavBar);
    // return () => window.removeEventListener('scroll', transitionNavBar)
  }, [])

  const [ openDropdown, setOpenDropdown] = useState(false)
  const toggleDropdown = () => {
    toggleDrawer(false)
    setOpenDropdown(!openDropdown)
  }
  const linkTo = (str) =>{
    setOpenDropdown(false)
    toggleDrawer(false)
    navigate(`/${str}`)
  }
  
  return (
    <div className="nav">
      { openDropdown ?
        <div className="nav__bg" onClick={() => setOpenDropdown(false)}></div>
      : "" }
      <div className="nav__contents">
        <div className="nav__logoWrapper">
          <img onClick={() => navigate("/")} className="nav__logo" src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-horz.png" alt="logo" />
          <p>&beta;eta</p>
        </div>
        
        
        <div className="nav__contentsNav">
          <div className='nav__contentsDir smallText'>
            <p className={currentUrl === '' ? "nav__currentDesktop" : ""} onClick={() => linkTo('')}>Cases</p>
            <p className={currentUrl === 'sfa' ? "nav__currentDesktop" : ""} onClick={() => linkTo('sfa')}>SFA</p>
            <p className={currentUrl === 'about' || currentUrl === 'team' || currentUrl === 'faqs' || currentUrl === 'contact' ? "nav__currentDesktop" : "about"} onClick={() => toggleDropdown()}>About</p>
            <p className={currentUrl === 'study-materials' ? "nav__currentDesktop" : ""} onClick={() => linkTo('study-materials')}>Study materials</p>
            <p className={currentUrl === 'case-submit' ? "nav__currentDesktop" : ""} onClick={() => linkTo('case-submit')} >Submit case</p>
          </div>

          {/* { auth.currentUser ? (
            <div className="nav__toggleDropdown" onClick={toggleDrawer(!showDropdown)}>
              <p className="smallText">{userInfo.firstName ? `Hi, ${userInfo.firstName}` : 'Account'}</p>
              <p className={showDropdown ? "nav__toggleArrow arrowReverse" : "nav__toggleArrow"}><KeyboardArrowDownIcon /></p>
            </div>
          ) : (
            <div className="nav__toggleDropdown">
              { matches ? "" : <a onClick={() => navigate('/login')} className="loginScreen__getStarted ub_textPurple">Login / Register</a>}
              
              { matches ? (
                <p onClick={toggleDrawer(!showDropdown)} className="smallText nav__burger">
                  Menu {showDropdown ? <CloseOutlinedIcon /> : <MenuOutlinedIcon />}
                </p>
              ) : ""}
            </div>
          )} */}
          <div className="nav__toggleDropdown">
              { matches ? (
                <p onClick={toggleDrawer(!showDropdown)} className="smallText nav__burger">
                  Menu {showDropdown ? <CloseOutlinedIcon /> : <MenuOutlinedIcon />}
                </p>
              ) : ""}
            </div>
        </div>


        { openDropdown ? 
          <div className={`${auth.currentUser ? "nav__contentsDropdownLoggedIn" : "nav__contentsDropdown"} smallText`}>
            <p onClick={() => linkTo('about')}>About us</p>
            <p onClick={() => linkTo('team')}>Our team</p>
            <p onClick={() => linkTo('faqs')}>FAQs</p>
            <p onClick={() => linkTo('contact')}>Contact us</p>
          </div>
        : ""}
        

        {/* Avatar dropdown */}
        <SwipeableDrawer anchor='right' open={showDropdown} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} disableBackdropTransition={!iOS} disableDiscovery={iOS}>
          <Box className='nav__dropdown smallText' role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {matches ? (
              <ul>
                <li className={currentUrl === '' ? "nav__currentMobile" : ""} onClick={() => navigate('/')}>Cases</li>
                <li className={currentUrl === 'sfa' ? "nav__currentMobile" : ""} onClick={() => navigate('/sfa')}>SFA</li>
                <li className={currentUrl === 'about' ? "nav__currentMobile" : ""} onClick={() => navigate('/about')}>About us</li>
                <li className={currentUrl === 'team' ? "nav__currentMobile" : ""} onClick={() => navigate('/team')}>Our team</li>
                <li className={currentUrl === 'study-materials' ? "nav__currentMobile" : ""} onClick={() => navigate('/study-materials')}>Study materials</li>
                <li className={currentUrl === 'case-submit' ? "nav__currentMobile" : ""} onClick={() => navigate('/case-submit')} >Submit case</li>
                <li className={currentUrl === 'faqs' ? "nav__currentMobile" : ""} onClick={() => navigate('/faqs')}>FAQs</li>
                <li className={currentUrl === 'contact' ? "nav__currentMobile" : ""} onClick={() => navigate('/contact')}>Contact us</li>
                <hr />
              </ul>
            ): ""}
            {/* { auth.currentUser ? (
              <ul>
                <li onClick={() => navigate('/profile')}>Account</li>
                <li onClick={() => navigate('/data')}>Data Report</li>
                <hr />
                { userInfo.isAdmin ? <li onClick={() => navigate('/admin')}>Admin access</li> : ""}
                { userInfo.isAdmin ? <hr /> : ""}
                <li onClick={userLogOut}>Log out</li>
              </ul>
            ) : (
              <ul>
                <li className="ub_textPurple" onClick={() => navigate('/login')}>Login / Register</li>
              </ul>
            )} */}
            <ul>
              
              
            </ul>
          </Box>
        </SwipeableDrawer>
        
      </div>
    </div>
  )
}

export default Nav
