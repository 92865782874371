const thoracicBlockList = [
	

	// Block 1
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'chest, bronchogram', 'pleural effusion'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '75 year old male presents with a chief complaint of a cough and shortness of breath. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block1/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block1/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block1/3.mp4','https://ultrasoundbox.s3.amazonaws.com/thoracic/block1/4.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Are there b-lines present?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Is an area with no pleural sliding?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Is there free fluid in the thorax?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Are there air bronchograms?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }

	], 
    interpretation: [
      ['B-lines: B-lines form when the lung tissue thickens, the most common etiology of which is to fill with fluid. Up to three B-lines can be considered physiologic. Here, less than 3 in the anterior lung fields, making this less likely pathologic. It is possible that due to the type of probe used or brightness setting during the recording of this clip that the b-lines are underrepresented, especially considering the significant right sided pleural effusion and the small left one.','BREAK',
	  'Spine sign: The spine is visible over the right base as the pleural effusion acts as a great acoustic window for the sound waves from the probe. This is called the “spine sign”, which is a sign seen when there is a large amount of pleural fluid.','BREAK',
	  'Reverberation artifact: A-lines are a form of reverberation artifact. Reverberation artifact occurs when an ultrasound beam bounces between two parallel, closely located reflective surface. The beam reflects back and forth between the surfaces before finally being registered by the probe. In this case the two surfaces are the pleura line and ultrasound probe itself.','BREAK',
	  'Bronchograms, or lung consolidations, as seen at the left lung base in this clip, most commonly occur in pneumonia and/or atelectasis.','BREAK',{text: 'Here', hyperlink: 'https://www.pocus101.com/lung-ultrasound-made-easy-step-by-step-guide/'},'is a resource to review these concepts more in depth'], 
      ['Large right pleural effusion. Small left pleural effusion with static air bronchograms. Less than 3 B-lines in the bilateral anterior apices. Good pleural movement bilaterally.'], 
      ['Large right pleural effusion, small left pleural effusion. Static air bronchograms at the left base which could be consistent with pneumonia.']
    ]  
  },
	
	
	// Block 2
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'chest', 'pneumothorax'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '83 year old male presents with a chief complaint of a cough and shortness of breath. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block2/2.png', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block2/1.png', '',''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Is there evidence of a pneumothorax?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['The anterior lung fields show a “sandy beach sign” bilaterally, or the lung moving with the use of m-mode. This rules out pneumothorax.','BREAK',{text: 'Here', hyperlink:' https://www.pocus101.com/ultrasound-machine-basics-knobology-probes-and-modes/#M-Mode_Motion_Mode'}, 'is a resource to review M-mode.','BREAK',{text: 'Here', hyperlink: 'https://rebelem.com/ultrasound-detection-pneumothorax/'}, 'is a resource to review the seashore sign in ruling out pneumothorax.'], 
      ['Good lung sliding in the anterior aspects bilaterally.'], 
     [ 'Normal Lungs ']
    ]  
  },
	
	
	// Block 3
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'chest', 'pneumothorax'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '15 year old male presents with sudden onset of chest pain and shortness of breath. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block3/1.png', '', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block3/3.mp4',''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Is there an area with no lung sliding in the views provided?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      } 
	], 
    interpretation: [
      ['The lung field seen in the clip is of non-moving lung. The m-mode clip shows a barcode sign, which is consistent with the lung not moving. In this case this was found to be a pneumothorax. This can happen spontaneously in skinny young teenagers, the underlying etiology of which is not entirely elucidated.'], 
      ['Absence of lung sliding over the right lung. No b-lines. No pleural effusions.'], 
     [ 'No lung sliding over the right lung.']
    ]  
  },
	
	
	// Block 4
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'Bronchograms', 'chest', 'Hepatization of lung'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '75 year old male presents with shortness of breath and cough. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block4/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block4/2.mp4', '','https://ultrasoundbox.s3.amazonaws.com/thoracic/block4/4.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Are there any areas with no pleural sliding seen?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      },
		{
        qtype: 'reading', 
        question: 'Are there A-lines?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Are there B-lines?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Are there air bronchograms?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['There are numerous b-lines anteriorly which can most commonly be seen in pulmonary edema or severe viral infection such as covid-19 pneumonia. The air bronchograms and hepatization of the lung (where the lung looks similar to the liver in appearance) which is consistent with severe infection, possibly pneumonia.'], 
      ['B-lines bilaterally. Hepatization of the left lung base with air bronchograms consistent with pneumonia. Good pleural sliding bilaterally'], 
      ['B-lines bilaterally. Hepatization of the left lung base with air bronchograms consistent with pneumonia.']
    ]  
  },
	

	// Block 5
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'pneumothorax', 'chest'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '25 year old male presents after being struck in the chest with a baseball bat. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block5/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block5/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block5/3.mp4', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Are there any areas with no pleural sliding seen?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }
	], 
    interpretation: [
     [ 'No lung sliding of the right anterior wall chest which is consistent with a pneumothorax. The presence of lung sliding rules out a pneumothorax. The absence of lung sliding, however, is not specific. Other causes such as a right main stem intubation, or extremely poor air movement in the context of severe asthma/copd can also cause little to no lung movement. This is why it is important to interpret the ultrasound findings in the clinical context.'], 
      ['No pleural movement of the right anterior chest wall. Good pleural movement of the left anterior lung. No b-lines.'], 
      ['No pleural movement of the right anterior chest wall.']
    ]  
  },
	
	// Block 6
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'chest', 'bronchograms'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '35 year old male presents with shortness of breath, cough and fever. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block6/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block6/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block6/3.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block6/4.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Are there any areas with no pleural sliding seen?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      },
		{
        qtype: 'reading', 
        question: 'Is there free fluid in the thorax?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Are there air bronchograms?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }
	], 
    interpretation: [
      ['There are air bronchograms  and a sub-pleural effusion focally over the left anterior chest. In the given clinical context of shortness of breath, cough and a fever, this is consistent with a bacterial or viral pneumonia. Focal findings in one area of the chest are more specific for a bacterial pneumonia, however viral pneumonia is still a possibility. There are 1-2 b-lines visualized over the right base. Up to three can be physiologic.'], 
      ['Subpleural effusion and bronchograms over the left anterior lung. No pleural effusions bilaterally. No pathologic number of b-lines bilaterally.'], 
      ['Subpleural effusion and bronchograms over the left anterior lung.']
    ]  
  },
	
	// Block 7
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'chest', 'pneumothorax'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '81 year old male presents with shortness of breath requiring intubation. You intubate the patient and notice afterward that the oxygen saturation is dropping. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block7/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block7/2.mp4', '', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Is there good lung sliding bilaterally?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      },
		{
        qtype: 'general',
        question: 'What would be the next appropriate action in the context of these findings?',
        options: ' Place a left sided chest tube, Reposition the patient, Check the insertion depth of the endotracheal tube at the lip',
        answer: 3,
        explain: '3: With the absence of lung sliding and likely lung sounds over the left chest after intubation, it is best practice to immediately check the endotracheal tube (ETT) insertion depth at the lip to see if it was inserted too far. If it has been, as in this case, then the ETT is likely in the right mainstem bronchus. The ETT should generally be placed around 21 cm for women and 23cm for men. A chest xray should also be obtained and the ETT should be at least 4cm from the carina. The other possibility for lack of lung sliding is a pneumothorax, however it is not the only possibility, and a chest tube should not be placed empirically in this case until an xray has been obtained and a definitive diagnosis of pneumothorax is made.'
      },
	], 
    interpretation: [
     ['This patient has no lung sliding over the left chest. The possibilities include pneumothorax or more likely the endotracheal tube (ETT) being placed too far in, resulting in being placed in the right mainstem bronchus. This will result in no lung sliding over the left lung. The first thing that should be checked is how far the ETT tube has been placed at the lip level, and to check its position relative to the carina on chest xray.'], 
      ['No pleural movement in the left anterior lung field. Good pleural movement in the right anterior lung. No b-lines bilaterally.'], 
      ['No lung sliding over the left anterior lung.']
    ]  
  },

	// Block 8
	  {
    categoryId: 7, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'chest', 'bronchograms', 'b-lines'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '95 year old male comes in with fever and shortness of breath. You perform a POCUS', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/thoracic/block8/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block8/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block8/3.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block8/4.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Is there free fluid in the thorax?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      },
		{
        qtype: 'reading', 
        question: 'Are there b-lines?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Are there air bronchograms?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }
	], 
    interpretation: [
      ['The anterior lungs have an irregular pleura and so many B-lines they become confluent and produce a so-called “Waterfall” sign. B-lines exclude pneumothorax, as you would not see b-lines if there was only air, despite the poor movement of the pleura. Confluent B-lines can be seen in severe covid-19 infection or pulmonary edema.','BREAK','There are bronchograms at the bilateral bases and a small pleural effusion over the right base. The bronchograms could represent lung atelectasis or infection.','BREAK','The irregular pleura, also called the shred sign, is the margin between consolidated and aerated lung, and often represents infection. It is visualized here over the right anterior lung. This sign makes the visualized b-lines more likely secondary to infection, although this is not definitive.'], 
      ['Numerous confluent b-lines bilaterally. Bronchograms at the bilateral lung bases. Small right pleural effusion. Pleural movement seen bilaterally. Irregular pleura over the right anterior lung.'], 
      ['Numerous confluent b-lines bilaterally. Bronchograms at the bilateral lung bases. Irregular pleura over the right anterior lung.']
    ]  
  },	
	

	// Block 9
  {
    categoryId: 7, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'chest', 'bronchograms', 'pneumonia'], 
    authorInfo: {
      name: 'Shirley Wu, MD', 
      institution: 'Brown Emergency Medicine, Providence, Rhode Island', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Leland Perice, MD', 
      institution: 'Brown Emergency Medicine, Providence, Rhode Island', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: 'lelandperice@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: 'A 78 year old man presents with weakness and vomiting. He is found to be hypoxic. You perform a POCUS:', 
    usviews:  ['', '', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block9/3.mp4', 'https://ultrasoundbox.s3.amazonaws.com/thoracic/block9/4.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Is there a pleural effusion?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      },
		{
        qtype: 'reading', 
        question: 'Are there air bronchograms? If so, are they static or dynamic?', 
        options: 'No air bronchograms, Static air bronchograms, Dynamic air bronchograms', 
        answer: 3, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'What is the diagnosis?', 
        options: 'Healthy lung, Atelectasis, Pneumonia', 
        answer: 3, 
        explain: ''
      }
	], 
    interpretation: [
      ['Here we see hepatization of the lungs and dynamic air bronchograms raising concern for pneumonia. In the context of this patients vomiting, this is concerning for an aspiration pneumonia.','BREAK','If there is fluid mixed with air inside the bronchioles, it moves centrifugally with inspiration, which is termed a dynamic air bronchogram. This is nearly 100% specific for pneumonia (1). Static air bronchograms on the other hand, are bronchi seen within a consolidation which show no motion during respiration. Static air bronchograms are seen classically in atelectasis. It is worth noting that in patients with a suspicion for pneumonia, finding unilateral b-lines is specific enough to diagnose pneumonia (2).','BREAK','This patient was also found to have a small bowel obstruction and confirmation of extensive consolidative airspace disease and endobronchial debris on CT scan of the chest and abdomen. The bowel obstruction was likely the etiology of the vomiting, which then resulted in aspiration and pneumonia.','BREAK','References:','BREAK','1: Lichtenstein D, Mezière G, Seitz J. The dynamic air bronchogram. A lung ultrasound sign of alveolar consolidation ruling out atelectasis. Chest. 2009;135(6):1421-1425.','BREAK','2: Cortellaro F, Colombo S, Coen D, Duca PG. Lung ultrasound is an accurate diagnostic tool for the diagnosis of pneumonia in the emergency department. Emerg Med J. 2012;29(1):19-23.'],
      ['Hepatization of left lung base along with pleural effusion and a positive spine sign. Hepatization of right lung base along with both static and dynamic air bronchograms.'], 
      ['Hepatization of both lung bases and dynamic air bronchograms in the right lung base consistent with pneumonia.']
    ]  
  },	
	]
module.exports = thoracicBlockList