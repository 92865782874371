const wmaDB = [
  {
    questionId: 1,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/1.mp4',
    answerUsView: 'https://ultrasoundbox.s3.amazonaws.com/wma/1_answer.mp4',
    questionModel: 'apical 4',
    answer: [5]
  },
  {
    questionId: 2,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/2.mp4',
    answerUsView: 'https://assets.mixkit.co/videos/preview/mixkit-rain-falling-on-the-water-of-a-lake-seen-up-18312-large.mp4',
    questionModel: 'parasternal long',
    answer: [1, 2]
  },
  {
    questionId: 3,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/3.mp4',
    answerUsView: 'https://assets.mixkit.co/videos/preview/mixkit-rain-falling-on-the-water-of-a-lake-seen-up-18312-large.mp4',
    questionModel: 'parasternal short',
    answer: [1, 2]
  },
  {
    questionId: 4,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/4.mp4',
    answerUsView: 'https://assets.mixkit.co/videos/preview/mixkit-rain-falling-on-the-water-of-a-lake-seen-up-18312-large.mp4',
    questionModel: 'parasternal short',
    answer: [1, 2]
  },
  {
    questionId: 5,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/5.mp4',
    answerUsView: 'https://assets.mixkit.co/videos/preview/mixkit-rain-falling-on-the-water-of-a-lake-seen-up-18312-large.mp4',
    questionModel: 'parasternal short',
    answer: [1, 2]
  },
  {
    questionId: 6,
    usView: 'https://ultrasoundbox.s3.amazonaws.com/wma/6.mp4',
    answerUsView: 'https://assets.mixkit.co/videos/preview/mixkit-rain-falling-on-the-water-of-a-lake-seen-up-18312-large.mp4',
    questionModel: 'parasternal short',
    answer: [1, 2]
  },
]
module.exports = wmaDB