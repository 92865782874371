import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  // The 'reducers' field lets us define reducers and generate associated actions
  reducers: {
    // use the PayloadAction type to declare the contents of 'action.payload'
    login: (state, action) => {
      state.user = action.payload;
      state.userEmail = action.payload.email;
    },
    logout: (state) => {
      state.user = null;
    }
  }
});

export const { login, logout } = userSlice.actions;

// Selectors: allow us to import info
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;