import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./MembersScreen.css";

function MembersScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="membersScreen">
      <div className="membersScreen__hero">
        <h1>Meet the Team</h1>
      </div>

      <div className="membersScreen__members">
        {/* Business and operation team */}

        <div className="membersScreen__membersWrapper">
          <p className="fontPurple">Business and Operations Team</p>
          <div className="membersScreen__memberList smallText">
            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/LelandPerice.jpg"
                alt=""
              />
              <span>Founder / Editor in Chief</span>
              <p>Leland Perice, MD</p>
              {/* <span>Maimonides</span> */}
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/EveeKim.jpg"
                alt=""
              />
              <span>Fullstack Developer</span>
              <p>Evee Kim</p>
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/AndrewChu.png"
                alt=""
              />
              <span>Strategic Advisor</span>
              <p>Andrew Chu, MD, MPH, MBA</p>
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/user-profile-images/image-asset.png"
                alt=""
              />
              <span>Key Partner</span>
              <p>Matthew Riscinti, MD</p>
            </div>
          </div>
        </div>

        {/* Editorial Team */}
        <div className="membersScreen__membersWrapper">
          <p className="fontPurple">Editorial Team</p>
          <div className="membersScreen__memberList smallText">
            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/carrie-2.jpeg"
                alt=""
              />
              <span>
                Content Expert &<br />
                Social Media Manager
              </span>
              <p>Carrie Walsh, MD</p>
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/Ridhima2.jpeg"
                alt=""
              />
              <span>Content Expert</span>
              <p>Ridhima Ghei, MD</p>
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/Nhu-NguyenLe.jpeg"
                alt=""
              />
              <span>Lead Editor</span>
              <p>Nhu-Nguyen Le, MD</p>
            </div>
          </div>
        </div>

        {/* Contributors */}
        <div className="membersScreen__membersWrapper">
          <p className="fontPurple">Contributors</p>
          <div className="membersScreen__memberList smallText">
            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/MichelleHaimowitz.jpg"
                alt=""
              />
              <span>Contributor</span>
              <p>Michelle Haimowitz, MD</p>
            </div>

            <div className="membersScreen__member">
              <img
                src="https://ultrasoundbox.s3.amazonaws.com/members/RebeccaYang.jpg"
                alt=""
              />
              <span>Contributor</span>
              <p>Rebecca Yang</p>
            </div>

            <div className="membersScreen__member membersScreen__memberJoinWrapper">
              <div className="membersScreen__memberJoin">
                <img
                  src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-vert.png"
                  alt="Join us as a contributor"
                />
              </div>
              <span>Contributor</span>
              <p>Interested in joining the team?</p>
              <a className="ub_textPurple" onClick={() => navigate("/contact")}>
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Partnership */}
      <div className="membersScreen__partner">
        <div className="membersScreen__partnerLogos">
          <img
            src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-horz.png"
            alt=""
          />
          <a href="https://www.thepocusatlas.com/" target="_blank">
            <img
              src="https://ultrasoundbox.s3.amazonaws.com/img/pocusatlas.png"
              alt=""
            />
          </a>
        </div>
        <p>
          UltrasoundBox is a proud to collaborate with The Pocus Atlas.
          <br />
          Together we work to improve POCUS education.
        </p>
      </div>
    </div>
  );
}

export default MembersScreen;
