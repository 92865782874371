import React, { useEffect, useState } from "react";
import db from "../firebase";

import "./FemSurveyData.css";

function FemSurveyData() {
  const [isLoading, setIsLoading] = useState(true);
  const [allFemUserSurveyData, setAllFemUserSurveyData] = useState([]);

  useEffect(() => {
    const fetchSurveyData = async (e) => {
      // Collect all user info
      const allFemUsers = [];
      await db
        .collection("femusers")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (femuserDoc) => {
            if (femuserDoc.data().preSurveyDone !== null) {
              allFemUsers.push({
                username: femuserDoc.data().username,
                preSurvey: femuserDoc.data().preSurveyDone,
              });
            }
          });

          // local state update
          setAllFemUserSurveyData(allFemUsers);
          setIsLoading(false);
        });
    };
    fetchSurveyData();
  });

  // console.log(allFemUserSurveyData);

  return (
    <div className="femSurveyDataWrapper">
      <h1>FEM survey data</h1>
      <div className="femSurveyDataTableWrapper">
        <div className="smallText breakWord">
          <div>
            <h3>Name</h3>
            <p>Email</p>
          </div>
          <div>
            <p>Current level of training</p>
          </div>
          <div>
            <p>
              Have you ever used an online question bank to aid in your
              education?
            </p>
          </div>
          <div>
            <p>Do you have any prior experience in interpreting a FAST exam?</p>
          </div>
          <div>
            <p>
              On a scale from 1 = no experience to 10 = extensive experience,
              what is your skill level with point of care ultrasound (POCUS)
            </p>
          </div>
          <div>
            <p>
              On a scale from 1 = not at all comfortable to 10 = extremely
              comfortable, how comfortable are you performing a FAST determine
              whether or not there is intraperitoneal free fluid:
            </p>
          </div>
          <div>
            <p>
              When caring for patients with an indication for a point of care
              FAST exam, how often do you perform this procedure yourself?
            </p>
          </div>
        </div>
        {isLoading ? (
          <div className="loadingWrapper">
            <p>Loading...</p>
          </div>
        ) : (
          allFemUserSurveyData.map((data, index) => {
            return (
              <div className="breakAll">
                <div>
                  <h3>{data.username}</h3>
                  <p className="smallText">{data.preSurvey["email"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["trainingLevel"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["onlineEdExp"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["fastExp"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["expLevel"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["comfortLevel"]}</p>
                </div>
                <div>
                  <p>{data.preSurvey["performFrequency"]}</p>
                </div>
              </div>
            );
          })
        )}
        {/* {allFemUserSurveyData.map((data, index) => {
          return (
            <div className="breakAll">
              <div>
                <h3>{data.username}</h3>
                <p className="smallText">{data.preSurvey["email"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["trainingLevel"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["onlineEdExp"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["fastExp"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["expLevel"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["comfortLevel"]}</p>
              </div>
              <div>
                <p>{data.preSurvey["performFrequency"]}</p>
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
}

export default FemSurveyData;
