import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import React, { useRef, useState } from 'react'
import db, { auth } from '../firebase';
import './SignupScreen.css'
import { addDoc, collection } from "firebase/firestore"; 
import {useNavigate} from 'react-router-dom'




function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`signupScreen__tab${index}`}
      className="signupScreen__tabContent"
      aria-labelledby={`signupScreen__tab${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `signupScreen__tab${index}`,
    'aria-controls': `signupScreen__tab${index}`,
    'className': 'signupScreen__tab'
  };
}

export default function SignupScreen() {

  const navigate = useNavigate();


  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const institutionRef = useRef(null);

  const sendPWemailRef = useRef(null);
  const [sentPWemail, setSentPWemail] = useState(false)
  const [showAuthPopup, setShowAuthPopup] = useState([false, {headline: '', content: ''}])
  
  // Register
  const [loading, setLoading] = useState(false);
  const [isTogglePwReset, setIsTogglePwReset] = useState(false)

  let currentTimestamp = Date.now()
  let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(currentTimestamp)


  const register = (e) => {
    e.preventDefault();

    const docRef = addDoc(collection(db, "users"), {
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value,
      email: emailRef.current.value,
      title: title,
      institution: institutionRef.current.value,
      isAdmin: false,
      joined: date
    });
    
    auth.createUserWithEmailAndPassword(
      emailRef.current.value,
      passwordRef.current.value
    ).then((authUser) => {
      // console.log(authUser)
      navigate('/')
    })
    .catch((error) => {
      // alert(error.message)
      if(error.code === 'auth/weak-password') {
        setShowAuthPopup([true, {headline: "Weak password", content: 'Your password is considered to be insecure. Please choose another password.'}])
      } else if (error.code === 'auth/internal-error'){
        setShowAuthPopup([true, {headline: "Something went wrong", content: 'Sorry, something went wrong there. Try again.'}])
      } else {
        alert(error.message)
      }
      console.log(error.code)
    });
  }

  // Login
  const signIn = (e) => {
    e.preventDefault();
    auth.signInWithEmailAndPassword(
      emailRef.current.value,
      passwordRef.current.value
    ).then((authUser) => {
      // console.log(authUser);
      navigate('/')
    }).catch((error) => {
      if(error.code === "auth/wrong-password"){
        setShowAuthPopup([true, {headline: "Incorrect password", content: 'The password you entered is incorrect. Please try again.'}])
      } else if (error.code === "auth/too-many-requests"){
        setShowAuthPopup([true, {headline: "Too many failed attempts", content: 'Your account is locked because of too many failed attempts. Try again in a few hours.'}])
      } else if (error.code === "auth/user-not-found"){
        setShowAuthPopup([true, {headline: "Couldn't find your account", content: 'Enter a different account or register a new account.'}])
      } else if (error.code === "auth/email-already-in-use") {
        setShowAuthPopup([true, {headline: "The email address is already in use.", content: 'Please login or contact us.'}])
      }
      else { alert(error.message)}
    }
    // console.log(error.code === "auth/wrong-password" ? "Wrong password" : "")
      // alert(error.message)
    )
  }

  

  // Forgot password
  const forgotPW = () => {
    setIsTogglePwReset(!isTogglePwReset)
  }
  const sendResetEmail = () => {
    auth.sendPasswordResetEmail(sendPWemailRef.current.value)
    .then(() => {
      setSentPWemail(true)
      // alert('Password reset email is sent!')
    }).catch((err) => {
      alert('error.code', 'error.message')
    })
  }

  // MUI tab
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Select title
  const [title, setTitle] = useState('')
  const getTitleSelect = (e) => {
    setTitle(e.target.value)
  }

  // Close MUI popup
  const closeMUIpopup = (dialogName) => {
    if( dialogName === 'setSentPWemail') {
      setSentPWemail(false)
      setIsTogglePwReset(false)
    }
  }
  const closeMUIauthPopup = () => {
    setShowAuthPopup([false, {headline: '', content: ''}]);
  }


  return (
    <div className="signupScreen">
      <Dialog
        open={sentPWemail}
        onClose={() => closeMUIpopup("setSentPWemail")}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Password reset email is sent!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Check your email and reset your password.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeMUIpopup("setSentPWemail")}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showAuthPopup[0]}
        onClose={closeMUIauthPopup}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{showAuthPopup[1].headline ? showAuthPopup[1].headline : null}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {showAuthPopup[1].content ? showAuthPopup[1].content : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMUIauthPopup}>Close</Button>
        </DialogActions>
      </Dialog>



      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Login" {...a11yProps(0)} />
            <Tab label="Register" {...a11yProps(1)} />
          </Tabs>
        </Box>
        
        { isTogglePwReset ? (
          <TabPanel value={value} index={0}>
            <p>Your email to reset password</p>
            <input ref={sendPWemailRef} type="email" placeholder='Your email address' />
            <button type="submit" className="ub_solidPurple" onClick={sendResetEmail}>Reset password</button>
            <p className='ub_textPurple siginupScreen__lostPWbackLogin' onClick={forgotPW}>Back to Login</p>
            <br />
          </TabPanel>
          
        ) : (
          <TabPanel value={value} index={0}>
            <p>Login</p>
            <input ref={emailRef} placeholder="Email" type="email" />
            <input ref={passwordRef} placeholder="password" type="password" />
            <p className='ub_textPurple  signupScreen_forgotPW' onClick={forgotPW}>Forgot password?</p>
            <button type="submit" className="ub_solidPurple" id="loginSubmit" onClick={signIn}>Sign In</button>
            <br />
          </TabPanel>
        )}
          
        
        <TabPanel value={value} index={1}>
          <p>Register</p>
            <input ref={firstNameRef} placeholder="First name" type="text" />
            <input ref={lastNameRef} placeholder="Last name" type="text" />
            <TextField id="title" select label="Title" value={title} className="signupScreen__titleSelect smallText" onChange={(e) => getTitleSelect(e)}>
              <MenuItem key="positionTitle1" value="Medical student" name="Medical student">Medical student</MenuItem>
              <MenuItem key="positionTitle2" value="Resident" name="Resident">Resident</MenuItem>
              <MenuItem key="positionTitle3" value="Fellow" name="Fellow">Fellow</MenuItem>
              <MenuItem key="positionTitle4" value="Faculty" name="Faculty">Faculty</MenuItem>
              <MenuItem key="positionTitle7" value="Other" name="Other">Other</MenuItem>
            </TextField>
            <input ref={institutionRef} placeholder="Institution (Optional)" type="text" />
            <input ref={emailRef} placeholder="Email" type="email" />
            <input ref={passwordRef} placeholder="password" type="password" />
            <button type="submit" className="ub_solidPurple" onClick={register}>Sign Up</button><br /><br />
        </TabPanel>
      </Box>
      <a className="ub_textPurple" onClick={() => navigate('/')}>Enter as a guest</a>
      {/* <a disabled={loading} className="ub_textPurple" onClick={() => navigate(-1)}>Back</a> */}
      
      
    </div>
  )
}

