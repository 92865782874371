import React, { useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in';
import ContributionCardiothoracic from '../contributionCategory/ContributionCardiothoracic';
import './SubmitYoursScreen.css'
import { MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import db from '../firebase';

import AnnouncementIcon from '@mui/icons-material/Announcement';

import ContributionTrauma from '../contributionCategory/ContributionTrauma';
import ContributionBiliary from '../contributionCategory/ContributionBiliary';
import ContributionRenal from '../contributionCategory/ContributionRenal';
import ContributionThoracic from '../contributionCategory/ContributionThoracic';
import ContributionPelvic from '../contributionCategory/ContributionPelvic';
import ContributionOcular from '../contributionCategory/ContributionOcular';
import ContributionAorta from '../contributionCategory/ContributionAorta';
import ContributionBowel from '../contributionCategory/ContributionBowel';



function SubmitYoursScreen() {

  // Open the page from the top
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  
  const [categoryList, setCategoryList] = useState([])
  const [difficultyList, setDifficultyList] = useState('')

  const [selectedCategoryId, setSelectedCategoryId] = useState('')
  const [selectedCategoryName, setSelectedCategoryName] = useState('')

  const selectCategory = (e) => {
    let displayNameInput = (e.target.value);
    let categoryIdInput = categoryList.indexOf(e.target.value) + 1
    // setSelectedCategoryId(categoryIdInput)
    setSelectedCategoryName(displayNameInput)
  }
  
  useEffect(() => {
    db.collection('categories').get().then(querySnapshot => {
      const categories = {};
      querySnapshot.forEach(async categoryDoc => {
        categories[categoryDoc.id] = categoryDoc.data();
      });
      setCategoryList(Object.values(categories)[0].title.sort());
    })
    db.collection('difficulty').get().then(querySnapshot => {
      const difficulties = [];
      querySnapshot.forEach(async categoryDoc => {
        difficulties[categoryDoc.id] = categoryDoc.data();
      });
      
      setDifficultyList(Object.values(difficulties));
    })
  }, [])


  return (
    <div className="submitYoursScreen">
      <div className="submitYoursScreen__banner">
        {/* <h1>Share your case</h1> */}
        <div className="submitYoursScreen__intro">
          {/* <img class="submitYourScreenSeal" src="https://ultrasoundbox.s3.amazonaws.com/img/usb-submityourcase-seal.png" alt="Submit your case seal" /> */}
          <h2>UltrasoundBox publishes image-based cases for all the world to use</h2>
          <p>We are an open-source platform, and our content is free for all to use. We provide the educational publishing opportunity for any and all users of ultrasound. All submission are peer-reviewed.</p><p>If you wish to contribute a case, start by selecting the category of question you wish to contribute below.
          </p>
      </div>
      </div>
      
      <div className="submitYoursScreen__categorySelect">
        <h2 className="fontPurple">Select Category</h2>
        <span className='smallText'>Select the category for which you case falls under. At this time we are taking questions in the core categories of Cardiothoracic, Renal, Thoracic, Biliary, Trauma, Pelvic, Ocular, Vascular and Soft Tissue.</span>
        
        <Box component="form" noValidate autoComplete="off">
          <div className="submitYoursScreen__section submitYoursScreen__sectionCategory">
            <div>
              <TextField id="category" select label="Category" value={selectedCategoryName} onChange={(e) => selectCategory(e)}>
                {categoryList.map((item, index) => {
                  return (<MenuItem key={item} name={item} value={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</MenuItem>)
                })}
              </TextField>
            </div>
          </div>
        </Box>
      </div>
      <FadeIn>
        { selectedCategoryName === 'cardiothoracic' ? <ContributionCardiothoracic difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'renal' ? <ContributionRenal difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'thoracic' ? <ContributionThoracic difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'biliary' ? <ContributionBiliary difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'trauma' ? <ContributionTrauma difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'pelvic' ? <ContributionPelvic difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'ocular' ? <ContributionOcular difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'bowel' ? <ContributionBowel difficultyList={difficultyList} /> : "" }
        { selectedCategoryName === 'aorta' ? <ContributionAorta difficultyList={difficultyList} /> : "" }
      </FadeIn>
      
    </div>
    
  )
}

export default SubmitYoursScreen