import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
} from "react-router-dom";

import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import ProfileScreen from "./screens/ProfileScreen";

import HomeScreen from "./screens/HomeScreen";
import db, { auth, analytics } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser } from "./features/userSlice";
import CardioBlockScreen from "./screens/CardioBlockScreen";
import RenalBlockScreen from "./screens/RenalBlockScreen";
import BiliaryBlockScreen from "./screens/BiliaryBlockScreen";
import TraumaBlockScreen from "./screens/TraumaBlockScreen";
import PelvicBlockScreen from "./screens/PelvicBlockScreen";
import OcularBlockScreen from "./screens/OcularBlockScreen";
import ThoracicBlockScreen from "./screens/ThoracicBlockScreen";

import Nav from "./Nav";
import Footer from "./Footer";
import LandingScreen from "./screens/LandingScreen";
import AboutScreen from "./screens/AboutScreen";
import MaterialScreen from "./screens/MaterialScreen";
import ContactScreen from "./screens/ContactScreen";
import SubmitYoursScreen from "./screens/SubmitYoursScreen";
import ThankYouContributionScreen from "./screens/ThankYouContributionScreen";
import LoadingScreen from "./loadingScreen/LoadingScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import MembersScreen from "./screens/MembersScreen";
import BowelBlockScreen from "./screens/BowelBlockScreen";
import QRlanding from "./screens/QRlanding";
import AdminDashboard from "./screens/AdminDashboard";
import FaqScreen from "./screens/FaqScreen";
import DataScreen from "./screens/DataScreen";
import NoAccessScreen from "./screens/NoAccessScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import BlockScreen from "./screens/BlockScreen";
import AortaBlockScreen from "./screens/AortaBlockScreen";

import WmaLandingScreen from "./wma/WmaLandingScreen";
import SfaLogin from "./sfa/SfaLogin";
import SfaAdmin from "./sfa/SfaAdmin";

import Susmit from "./susmitTest/Susmit";
import FemLanding from "./fem/FemLanding";
import FemSurveyData from "./fem/FemSurveyData";
import FemTestData from "./fem/FemTestData";

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [allAccounts, setAllAccounts] = useState();
  const [allTakes, setAllTakes] = useState();

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    };
    fetchData();

    // Google Analytics setup
    ReactGA.initialize("G-KH3MNL5PFT");
    ReactGA.pageview(window.location.pathname + window.location.search);

    // user db
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        const accounts = {};
        querySnapshot.forEach(async (userDoc) => {
          accounts[userDoc.id] = userDoc.data();
        });
        setAllAccounts(Object.values(accounts));
      });
    db.collection("takes")
      .get()
      .then((querySnapshot) => {
        const allTakesObj = [];
        querySnapshot.forEach(async (takeDoc) => {
          // allTakesObj[takeDoc.id] = takeDoc.data();
          allTakesObj.push({ [takeDoc.id]: takeDoc.data() });
        });
        setAllTakes(allTakesObj);
      });

    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        // logged In
        dispatch(
          login({
            uid: userAuth.uid,
            email: userAuth.email,
          })
        );
      } else {
        // logged out
        dispatch(logout());
      }
    });
    return unsubscribe;
  }, [dispatch]);

  return (
    <div className="app">
      {isLoading ? (
        <Router>
          <Routes>
            <Route path="/*" element={<LoadingScreen />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Nav />
          <Routes>
            {/* {
              auth.currentUser ? <Route path="/" element={<Navigate to="/home" />} /> : <Route exact path="/" element={<LandingScreen />} />
            } */}
            {auth.currentUser ? (
              <Route path="/profile" element={<ProfileScreen />} />
            ) : (
              <Route exact path="/profile" element={<NoAccessScreen />} />
            )}
            {auth.currentUser ? (
              <Route path="/data" element={<DataScreen />} />
            ) : (
              <Route exact path="/data" element={<NoAccessScreen />} />
            )}
            <Route exact path="/landing" element={<LandingScreen />} />
            <Route exact path="/" element={<HomeScreen />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route
              exact
              path="/admin"
              element={
                <AdminDashboard allTakes={allTakes} allAccounts={allAccounts} />
              }
            />
            <Route exact path="/about" element={<AboutScreen />} />
            {/* <Route exact path="/profile" element={<ProfileScreen />} /> */}
            <Route exact path="/faqs" element={<FaqScreen />} />
            <Route exact path="/contact" element={<ContactScreen />} />
            <Route exact path="/case-submit" element={<SubmitYoursScreen />} />
            <Route
              exact
              path="/thankyou"
              element={<ThankYouContributionScreen />}
            />
            <Route exact path="/study-materials" element={<MaterialScreen />} />

            <Route exact path="/aorta/:id" element={<AortaBlockScreen />} />
            <Route exact path="/biliary/:id" element={<BiliaryBlockScreen />} />
            <Route exact path="/bowel/:id" element={<BowelBlockScreen />} />
            <Route
              exact
              path="/cardiothoracic/:id"
              element={<CardioBlockScreen />}
            />
            <Route exact path="/ocular/:id" element={<OcularBlockScreen />} />
            <Route exact path="/pelvic/:id" element={<PelvicBlockScreen />} />
            <Route exact path="/renal/:id" element={<RenalBlockScreen />} />
            <Route
              exact
              path="/thoracic/:id"
              element={<ThoracicBlockScreen />}
            />
            <Route exact path="/trauma/:id" element={<TraumaBlockScreen />} />
            <Route exact path="/team" element={<MembersScreen />} />

            {/* WMA PAGES */}
            <Route exact path="/wma" element={<WmaLandingScreen />} />

            {/* SFA PAGES */}
            <Route exact path="/sfa" element={<SfaLogin />} />
            <Route exact path="/sfa-study-admin" element={<SfaAdmin />} />

            {/* FEM PAGES */}
            <Route exact path="/fem" element={<FemLanding />} />
            <Route exact path="/fem/survey-data" element={<FemSurveyData />} />
            <Route exact path="/fem/test-data" element={<FemTestData />} />

            {/* Susmit test pages */}
            <Route exact path="/susmit" element={<Susmit />} />

            <Route exact path="/nice-to-meet-you" element={<QRlanding />} />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicyScreen />}
            />

            <Route path="/404" element={<NotFoundScreen />} />
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>

          <Footer />
        </Router>
      )}
    </div>
  );
}

export default App;
