import React, { useEffect, useState, Component } from 'react'
import { doc } from "firebase/firestore"; 
import db from '../firebase'

import ScrollContainer from 'react-indiana-drag-scroll'
import { CSVLink } from "react-csv";
import FilterableTable from "react-filterable-table";
import './SfaAdmin.css'






function SfaAdmin() {

  const [ isDoneLoading, setIsDoneLoading ] = useState(false)
  const [ allUserList, setAllUserList ] = useState([])
  const [ allUserAvgScore, setAllUserAvgScore ] = useState()
  
  const [ dataToExportCSV, setDataToExportCSV] = useState([])


  // Question choices
  const sfaQuestionChoices = [
    'Significantly decreased systolic function (EF 0-30%)',
    'Decreased systolic function (EF 31-50%)',
    'Normal systolic function (EF 51-70%)',
    'Hyperdynamic systolic function (EF 71-100%)'
  ]
  // Question choices end


  // calculate all average score of all users
  const findTotalAvgScore = () => {
    let sumAvgScore = 0;
    for(let i = 0; i < allUserList.length; i++){
      sumAvgScore = sumAvgScore + allUserList[i].avgScore
    }
    setAllUserAvgScore((sumAvgScore / (allUserList.length)).toFixed(2))
  }


  useEffect(() => {
    const functionWrapper = async() => {
      console.log('functionWrapper#')
      const fetchAllUserInfo = async() => {
        console.log('fetchAllUserInfo????')
        await db.collection('sfausers')
        .get().then(querySnapshot => {
          querySnapshot.forEach(sfauserDoc => {
            allUserList.push(sfauserDoc.data())
          })
        })
        await findTotalAvgScore()
        // Function to unnest data to excel export
        const unNestData = ()=> {
          for(let u = 0; u < allUserList.length; u++){
            const qDetailList = {}
            let totalTimeSpentInSeconds = 0;
            for(let qDetail = 0; qDetail < allUserList[u].pastQs.length; qDetail++){
              // Correct&wrong details per question
              qDetailList[`Question ${qDetail}`] = `${allUserList[u].pastQs[qDetail].isCorrect ? `Q ${allUserList[u].pastQs[qDetail].questionId} - Correct` : `Q ${allUserList[u].pastQs[qDetail].questionId} - Wrong`} : For , correct answer is ${sfaQuestionChoices[allUserList[u].pastQs[qDetail].correctAnswer - 1]}. User chose ${sfaQuestionChoices[allUserList[u].pastQs[qDetail].userAnswer - 1]}`
              // add up all spentTime per quetion
              if(allUserList[u].pastQs[qDetail].timeSpent){
                allUserList[u].pastQs[qDetail].timeSpent.split(':').map((stamp, index) => {
                  if(index === 0){
                    totalTimeSpentInSeconds = totalTimeSpentInSeconds + (Number(stamp) * 86400)
                    // days
                  } else if (index === 1){
                    // hours
                    totalTimeSpentInSeconds = totalTimeSpentInSeconds + (Number(stamp) * 3600)
                  } else if (index === 2) {
                    // minutes
                    totalTimeSpentInSeconds =  totalTimeSpentInSeconds + (Number(stamp) * 60)
                  } else if (index === 3) {
                    // seconds
                    totalTimeSpentInSeconds += Number(stamp)
                  }
                  // console.log('TIMESTAMP', index, stamp, allUserList[u].userId)
                })
              }
            } 
            let hoursLeft = Math.floor(totalTimeSpentInSeconds / 3600);
            let min = Math.floor((totalTimeSpentInSeconds - hoursLeft * 3600) / 60);
            let secondsLeft = totalTimeSpentInSeconds - hoursLeft * 3600 - min * 60;
            secondsLeft = Math.round(secondsLeft * 100) / 100;
            let TotalTimeSpentCalculated = hoursLeft< 10 ? "0" + hoursLeft + "hr" : hoursLeft + "hr";
            TotalTimeSpentCalculated += (min < 10 ? "0" + min + "min" : min + "min");
            TotalTimeSpentCalculated += (secondsLeft< 10 ? "0" + secondsLeft + "sec" : secondsLeft + "sec");
            
            const qGeneralList = {
              userCount: u + 1,
              userId: allUserList[u].userId,
              avgScore: allUserList[u].avgScore.toFixed(2),
              doneAgreement: allUserList[u].doneAgreement ? "done" : "n/a",
              donePreSurvey: allUserList[u].donePreSurvey ? "done" : "n/a",
              donePostSurvey: allUserList[u].donePostSurvey ? "done" : "n/a",
              process: allUserList[u].pastQs.length,
              correct: allUserList[u].correctQCount,
              joined: allUserList[u].joined,
              lastLogin: allUserList[u].lastLogin,
              PastLogins: allUserList[u].loginHistory ? (allUserList[u].loginHistory).length : 'none',
              totalTime: TotalTimeSpentCalculated
            }
            dataToExportCSV.push(
              {
                ...qDetailList,
                ...qGeneralList
              }
            )
          }
        }
        await unNestData()
      }
      if (isDoneLoading === false ){
        await fetchAllUserInfo()
      }
      setIsDoneLoading(true)
    }
    functionWrapper()
  }, [])
  console.log('allUserList,', allUserList)
  console.log('isDoneLoading', isDoneLoading)
  console.log('dataToExportCSV', dataToExportCSV)


  // Data for the table to display; can be anything
  const data = [
    { name: "Steve", age: 27, job: "Sandwich Eater" },
    { name: "Gary", age: 35, job: "Falafeler" },
    { name: "Greg", age: 24, job: "Jelly Bean Juggler" },
    { name: "Jeb", age: 39, job: "Burrito Racer" },
    { name: "Jeff", age: 48, job: "Hot Dog Wrangler" }
  ];

  // Fields to show in the table, and what object properties in the data they bind to
  const fields = [
    { name: 'userCount', displayName: "#", inputFilterable: true, sortable: true },
    { name: 'userId', displayName: "ID", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'avgScore', displayName: "Score", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'doneAgreement', displayName: "Consent", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'donePreSurvey', displayName: "Pre-survey", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'process', displayName: "Question #", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'donePostSurvey', displayName: "Post-survey", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'correct', displayName: "Correct", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'joined', displayName: "Joined at", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'lastLogin', displayName: "Last login", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'PastLogins', displayName: "Past Logins", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'totalTime', displayName: "Total time spent", inputFilterable: true, exactFilterable: false, sortable: true},
    { name: 'Question 0', displayName: "1", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 1', displayName: "2", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 2', displayName: "3", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 3', displayName: "4", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 4', displayName: "5", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 5', displayName: "6", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 6', displayName: "7", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 7', displayName: "8", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 8', displayName: "9", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 9', displayName: "10", inputFilterable: true, exactFilterable: false, sortable: true },
    { name: 'Question 10', displayName: "11", inputFilterable: true, exactFilterable: false, sortable: true },
  ];
  
  


  return (
    <div className="sfaAdmin">
      <div>
        <h1>SFA ADMIN PAGE</h1>

        {
          isDoneLoading ? 
          <CSVLink
            data={dataToExportCSV}
            filename={"sfaAdmin-test.csv"}
            target="_blank"
          >
            Download Excel
          </CSVLink>
          : null
        }
        { isDoneLoading ?
        <div className="sfaAdmin__summaryWrapper">
          <div className="sfaAdmin__summaryBox">
            Total users: {allUserList.length}
          </div>
          <div className="sfaAdmin__summaryBox">
            Average score: {allUserAvgScore}%
          </div>
          <div className="sfaAdmin__summaryBox">
          </div>
          <div className="sfaAdmin__summaryBox">
          </div>
        </div>
        : null}

        <br /><br />

        { isDoneLoading ? 
        <ScrollContainer className="sfaAdmin__dragScrollableTable">
          <FilterableTable
            namespace="SFA data"
            initialSort="userCount"
            data={dataToExportCSV}
            fields={fields}
            pagersVisible={false}
            trClassName="sfaDataTableCell"
            noRecordsMessage="There are no data to display"
            noFilteredRecordsMessage="No data match your filters!"
          />
        </ScrollContainer>
        
        : 
        <div className="sfaAdmin__loading">
          Loading...
        </div>
        }

        {/* { isDoneLoading ? 
        <div className="sfaAdmin__userListWrapper">
          { allUserList.map((user, index) => 
            <div className="sfaAdmin__userWrapper" key={user.userId}>

              <div className="sfaAdmin__userCol sfaAdmin__userCol0">
                <p>{index + 1}</p>
              </div>

              <div className="sfaAdmin__userCol sfaAdmin__userCol1">
                <div className="sfaAdmin__userCol1Row">
                  <p>{user.userId}</p>
                  <p>Process: 
                    {user.doneAgreement ? null : "Consent"}
                    {user.doneAgreement && !user.donePreSurvey ? "Pre-survey" : null}
                    {user.doneAgreement && user.donePreSurvey && !user.donePostSurvey ? `Question ${(user.pastQs.length * 1) + 1}` : null}
                    {user.doneAgreement && user.donePreSurvey && user.remainedQs === 1 && !user.donePostSurvey ? "Post-survey" : null }
                  </p>
                </div>
                <div className="sfaAdmin__userCol1Row">
                  <p>{user.avgScore.toFixed(2)}% average score</p>
                  <p>Correct: {user.correctQCount}, Incorrect: {user.pastQs.length - user.correctQCount }, Remained: {user.remainedQs.length + 1}</p>
                  <p>Total ** minutes / average ** minutes spent</p>
                </div>
                <div className="sfaAdmin__userCol1Row">
                  <p>{user.loginHistory ? user.loginHistory.length : 'n/a'} times logged in</p>
                  <p>Last login: {user.lastLogin}</p>
                  <p>Joined: {user.joined}</p>
                </div>
              </div>

              <div className="sfaAdmin__userCol sfaAdmin__userCol2">
                <div className="sfaAdmin__userCol2Row">
                  <button>View details/manage</button>
                </div>
                <div className="sfaAdmin__userCol2Row">
                  <button>Delete account</button>
                </div>
              </div>
              
            </div>
          )}
          
        </div>
        :
        <div className="sfaAdmin__loading">
          Loading...
        </div>
        } */}
        
      </div>
    </div>
  )
}

export default SfaAdmin