import React, { useEffect } from 'react'
import ReactGA from 'react-ga';

import './AboutScreen.css'
import { useNavigate } from 'react-router-dom';

import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import Marquee from '../Marquee';


function AboutScreen() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)

      window.scrollTo(0, 0)
  }, []);

  const navigate = useNavigate()

  return (
    <div className="aboutScreen">

      <div className="aboutScreen__aboutWrapper">
        <div className="aboutScreen__aboutItem">
          <h1>Our Mission</h1>
          <p><br />UltrasoundBox is a platform that empowers users of all levels to master the skill of interpreting ultrasound images that are taken from real clinical cases. Our innovation allows users to move around a model patient and see ultrasound images. A series of questions follow that allow users to actively create an interpretation of the images. Our goal is to provide a high-quality ultrasound education to all learners. All our content is free, open access, and peer reviewed.</p>
        </div>
      </div>

      <div className="aboutScreen__feature">
        <div className="aboutScreen__featurePoint">
          <div className="aboutScreen__featureCircle"><VolunteerActivismOutlinedIcon /></div>
          <div className="aboutScreen__featureText">
            <h2>For everyone</h2>
            <p>Our content is free to access and use as you see fit</p>
          </div>
          
        </div>
        <div className="aboutScreen__featurePoint">
          <div className="aboutScreen__featureCircle"><MobileFriendlyOutlinedIcon /></div>
          <div className="aboutScreen__featureText">
            <h2>Mobile friendly</h2>
            <p>Use the application seamlessly on the go</p>
          </div>
          
        </div>
        <div className="aboutScreen__featurePoint">
          <div className="aboutScreen__featureCircle"><ThreePOutlinedIcon /></div>
          <div className="aboutScreen__featureText">
            <h2>Take part</h2>
            <p>Contribute your own case and get publishing credit</p>
          </div>
          
        </div>
        <div className="aboutScreen__featurePoint">
          <div className="aboutScreen__featureCircle"><PsychologyOutlinedIcon /></div>
          <div className="aboutScreen__featureText">
            <h2>Critical thinking</h2>
            <p>Gives learners the opportunity actively interpret ultrasound images</p>
          </div>
        </div>

      </div>

      <div className="aboutScreen__story">
        {/* <img src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundGeneral-circle.png" alt="" /> */}
        <div className="aboutScreen__storyContent">
          <div className="aboutScreen__storyText">
            <h1>Our Story</h1>
            
            <p><br />Point of Care Ultrasound (POCUS) is rapidly becoming a necessary skill in all branches of medicine, yet many medical students do not have access to ultrasound instruction because of a lack of financial resources and dedicated faculty time. Why not create a tool that gives everyone the chance to learn how to interpret ultrasound images?
            <br /><br/>
            To that end, we created this open-source platform to upload, share, and disseminate real clinical cases and their associated ultrasound images. This allows all learners the opportunity to think through these cases as if they were the first person at the bedside. The best part is that learners can use it whenever they want and wherever they are.</p>

          </div>
        </div>
      </div>


      <div className="aboutScreen__experience">
        <video playsInline loop autoPlay style={{pointerEvents: "none"}}>
          <source src="https://ultrasoundbox.s3.amazonaws.com/video/usExperience.mp4" type="video/mp4" />
        </video>
        <div className="aboutScreen__testimonial">
            <img src="https://ultrasoundbox.s3.amazonaws.com/members/SzymonKutyla.jpg" alt="Szymon Kutyla, MS4 Rutgers" />
            <div className='aboutScreen__testimonialText'>
              <FormatQuoteOutlinedIcon color="primary" />
              <h2>It's one of the best resources I've used for learning ultrasound and I'm so grateful that I learned about it.</h2>
              <span className='smallText'>Szymon Kutyla, MS4 Rutgers</span>
            </div>
          </div>
      </div>


      {/* <Marquee /> */}

      

    </div>
  )
}

export default AboutScreen
