const sfaSeed = [
  {
    questionId: 1,
    questionText: 'Question One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/1.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  // {
  //   questionId: 2,
  //   questionText: 'Question Two',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/wma/2.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/wma/ps.png',
  //   usViewLocation: 'ps',
  //   answer: 4,
  //   interpretation: '4',
  //   impression: '4'
  // },
  {
    questionId: 3,
    questionText: 'Question Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/3.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 4,
    questionText: 'Question Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/4.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 5,
    questionText: 'Question Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/5.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 4,
    interpretation: 4,
    impression: 4,
  },
  {
    questionId: 6,
    questionText: 'Question Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/6.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 7,
    questionText: 'Question Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/7.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 8,
    questionText: 'Question Eight',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/8.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 9,
    questionText: 'Question Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/9.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 4,
    interpretation: 4,
    impression: 4,
  },
  {
    questionId: 10,
    questionText: 'Question Ten',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/10.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 2,
    interpretation: 6,
    impression: 2,
  },
  {
    questionId: 11,
    questionText: 'Question Eleven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/11.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 12,
    questionText: 'Question Twelve',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/12.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  // {
  //   questionId: 13,
  //   questionText: 'Question Thirteen',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/13.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
  //   usViewLocation: 'ps',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  // {
  //   questionId: 14,
  //   questionText: 'Question Fourteen',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/14.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: 1,
  //   interpretation: 1,
  //   impression: 1,
  // },
  {
    questionId: 15,
    questionText: 'Question Fifteen',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/15.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 16,
    questionText: 'Question Sixteen',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/16.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  {
    questionId: 17,
    questionText: 'Question Seventeen',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/17.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 18,
    questionText: 'Question Eighteen',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/18.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 19,
    questionText: 'Question Nineteen',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/19.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 20,
    questionText: 'Question Twenty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/20.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 2,
    interpretation: 6,
    impression: 2,
  },
  {
    questionId: 21,
    questionText: 'Question Twenty One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/21.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 22,
    questionText: 'Question Twenty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/22.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 23,
    questionText: 'Question Twenty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/23.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 24,
    questionText: 'Question Twenty Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/24.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 25,
    questionText: 'Question Twenty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/25.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  {
    questionId: 26,
    questionText: 'Question Twenty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/26.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  // {
  //   questionId: 27,
  //   questionText: 'Question Twenty Seven',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/27.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  // {
  //   questionId: 28,
  //   questionText: 'Question Twenty Eight',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/28.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  // {
  //   questionId: 29,
  //   questionText: 'Question Twenty Nine',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/29.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 30,
    questionText: 'Question Thirty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/26.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  // {
  //   questionId: 31,
  //   questionText: 'Question Thirty One',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/31.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: 1,
  //   interpretation: 1,
  //   impression: 1,
  // },
  {
    questionId: 32,
    questionText: 'Question Thirty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/32.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  {
    questionId: 33,
    questionText: 'Question Thirty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/33.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  // {
  //   questionId: 34,
  //   questionText: 'Question Thirty Four',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/34.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: 1,
  //   interpretation: 1,
  //   impression: 1,
  // },
  {
    questionId: 35,
    questionText: 'Question Thirty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/35.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 36,
    questionText: 'Question Thirty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/36.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 37,
    questionText: 'Question Thirty Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/37.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 38,
    questionText: 'Question Thirty Eight',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/38.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 39,
    questionText: 'Question Thirty Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/39.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 40,
    questionText: 'Question Fourty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/40.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  // {
  //   questionId: 41,
  //   questionText: 'Question Fourty One',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/41.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
  //   usViewLocation: 'ps',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 42,
    questionText: 'Question Fourty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/42.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 43,
    questionText: 'Question Fourty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/43.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 44,
    questionText: 'Question Fourty Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/44.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 4,
    interpretation: 8,
    impression: 4,
  },
  {
    questionId: 45,
    questionText: 'Question Fourty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/45.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 4,
    interpretation: 8,
    impression: 4,
  },
  {
    questionId: 46,
    questionText: 'Question Fourty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/46.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 47,
    questionText: 'Question Fourty Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/47.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  // {
  //   questionId: 48,
  //   questionText: 'Question Fourty Eight',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/48.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
  //   usViewLocation: 'a4',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 49,
    questionText: 'Question Fourty Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/49.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 2,
    interpretation: 6,
    impression: 2,
  },
  {
    questionId: 50,
    questionText: 'Question Fifty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/50.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 51,
    questionText: 'Question Fifty One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/51.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 52,
    questionText: 'Question Fifty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/52.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 53,
    questionText: 'Question Fifty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/53.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 54,
    questionText: 'Question Fifty Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/54.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 55,
    questionText: 'Question Fifty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/55.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 56,
    questionText: 'Question Fifty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/56.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 57,
    questionText: 'Question Fifty Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/57.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 58,
    questionText: 'Question Fifty Eight',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/58.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 59,
    questionText: 'Question Fifty Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/59.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 60,
    questionText: 'Question Sixty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/60.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 4,
    interpretation: 4,
    impression: 4,
  },
  {
    questionId: 61,
    questionText: 'Question Sixty One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/61.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 62,
    questionText: 'Question Sixty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/62.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 63,
    questionText: 'Question Sixty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/63.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 64,
    questionText: 'Question Sixty Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/64.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 65,
    questionText: 'Question Sixty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/65.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 66,
    questionText: 'Question Sixty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/66.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 67,
    questionText: 'Question Sixty Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/67.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  // {
  //   questionId: 68,
  //   questionText: 'Question Sixty Eight',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/68.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 69,
    questionText: 'Question Sixty Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/69.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  {
    questionId: 70,
    questionText: 'Question Seventy',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/70.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  // {
  //   questionId: 71,
  //   questionText: 'Question Seventy One',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/71.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 72,
    questionText: 'Question Seventy Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/72.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  // {
  //   questionId: 73,
  //   questionText: 'Question Seventy Three',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/73.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
  //   usViewLocation: 'a4',
  //   answer: 3,
  //   interpretation: 3,
  //   impression: 3,
  // },
  {
    questionId: 74,
    questionText: 'Question Seventy Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/74.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 75,
    questionText: 'Question Seventy Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/75.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 76,
    questionText: 'Question Seventy Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/76.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 77,
    questionText: 'Question Seventy Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/77.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  // {
  //   questionId: 78,
  //   questionText: 'Question Seventy Eight',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/78.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
  //   usViewLocation: 'pl',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 79,
    questionText: 'Question Seventy Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/79.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 80,
    questionText: 'Question Eighty',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/80.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 81,
    questionText: 'Question Eighty One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/81.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 82,
    questionText: 'Question Eighty Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/82.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 83,
    questionText: 'Question Eighty Three',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/83.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 84,
    questionText: 'Question Eighty Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/84.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 85,
    questionText: 'Question Eighty Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/85.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 2,
    interpretation: 2,
    impression: 2,
  },
  {
    questionId: 86,
    questionText: 'Question Eighty Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/86.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 87,
    questionText: 'Question Eighty Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/87.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 88,
    questionText: 'Question Eighty Eight',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/88.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 3,
    interpretation: 7,
    impression: 3,
  },
  {
    questionId: 89,
    questionText: 'Question Eighty Nine',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/89.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 90,
    questionText: 'Question Ninety',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/90.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 91,
    questionText: 'Question Ninety One',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/91.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 3,
    interpretation: 3,
    impression: 3,
  },
  {
    questionId: 92,
    questionText: 'Question Ninety Two',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/92.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Short.jpg',
    usViewLocation: 'ps',
    answer: 2,
    interpretation: 6,
    impression: 2,
  },
  // {
  //   questionId: 93,
  //   questionText: 'Question Ninety Three',
  //   usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/93.mp4',
  //   torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
  //   usViewLocation: 'a4',
  //   answer: ,
  //   interpretation: ,
  //   impression: ,
  // },
  {
    questionId: 94,
    questionText: 'Question Ninety Four',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/94.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 95,
    questionText: 'Question Ninety Five',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/95.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 96,
    questionText: 'Question Ninety Six',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/96.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
  {
    questionId: 97,
    questionText: 'Question Ninety Seven',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/97.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Apical+4.jpg',
    usViewLocation: 'a4',
    answer: 1,
    interpretation: 5,
    impression: 1,
  },
  {
    questionId: 98,
    questionText: 'Question Ninety Eight',
    usViewUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/98.mp4',
    torsoUrl: 'https://ultrasoundbox.s3.amazonaws.com/sfa/Parasternal+Long.jpg',
    usViewLocation: 'pl',
    answer: 1,
    interpretation: 1,
    impression: 1,
  },
]

module.exports = sfaSeed