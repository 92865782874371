import React, { useState } from "react";

import "./FemExplanationComponent.css";

function FemExplanationComponent(prop) {
  const [showRuq, setShowRuq] = useState(false);
  const [showLuq, setShowLuq] = useState(false);
  const [showPelvic, setShowPelvic] = useState(false);

  const [ruqAnswerImg, setRuqAnswerImg] = useState(prop.answerImg.ruq);
  const [luqAnswerImg, setLuqAnswerImg] = useState(prop.answerImg.luq);
  const [pelvicAnswerImg, setPelvicAnswerImg] = useState(prop.answerImg.pelvic);

  console.log("ruqAnswerImg", ruqAnswerImg);

  const handleToShowExplanation = (category) => {
    if (category === "ruq") {
      setShowRuq(!showRuq);
      setShowLuq(false);
      setShowPelvic(false);
    } else if (category === "luq") {
      setShowRuq(false);
      setShowLuq(!showLuq);
      setShowPelvic(false);
    } else if (category === "pelvic") {
      setShowRuq(false);
      setShowLuq(false);
      setShowPelvic(!showPelvic);
    }
  };

  const hideAll = () => {
    setShowRuq(false);
    setShowLuq(false);
    setShowPelvic(false);
  };

  return (
    <div className="femExplanationContainer">
      <p className="smallText" style={{ fontWeight: "bold" }}>
        Explanation:
      </p>
      <div className="">
        <p>
          To determine whether a FAST is positive for free fluid or not,
          evaluate for the presence of anechoic (black) areas in each of the
          three views (RUQ, LUQ, pelvic). Notably, intraperitoneal free fluid
          typically forms sharp edges as it is not contained within an internal
          organ.
        </p>
        <div className="femExplanationToggle">
          <button
            className={showRuq ? "femExpToggleOnStyle" : "femExpToggleOffStyle"}
            onClick={() => handleToShowExplanation("ruq")}
          >
            RUQ
          </button>
        </div>
        <div className="femExplanationToggle">
          <button
            className={showLuq ? "femExpToggleOnStyle" : "femExpToggleOffStyle"}
            onClick={() => handleToShowExplanation("luq")}
          >
            LUQ
          </button>
        </div>
        <div className="femExplanationToggle">
          <button
            className={
              showPelvic ? "femExpToggleOnStyle" : "femExpToggleOffStyle"
            }
            onClick={() => handleToShowExplanation("pelvic")}
          >
            Pelvic
          </button>
        </div>
        <div className="femExplanationContent">
          {showRuq ? (
            <div>
              {ruqAnswerImg ? (
                <img src={ruqAnswerImg} alt="Answer fluid image for RUQ" />
              ) : null}

              <p>
                In the right upper quadrant view make sure to visualize both
                Morison’s pouch and the tip of the liver. Because of attachments
                between the liver and diaphragm, fluid will less often go here.
                Visualizing the entire liver tip is important because this is
                the most sensitive spot of the FAST exam for free fluid.
                Overall, the right upper quadrant is the most dependent area for
                free fluid in the abdomen in the supine patient. Fluid appears
                as an anechoic stripe within these spaces.
              </p>
              <button className="ub_textPurple smallText" onClick={hideAll}>
                Hide explanation
              </button>
            </div>
          ) : null}

          {showLuq ? (
            <div>
              {luqAnswerImg ? (
                <img src={luqAnswerImg} alt="Answer fluid image for LUQ" />
              ) : null}

              <p>
                In the left upper quadrant view, make sure to visualize from the
                diaphragm to the inferior pole of the kidney, including the
                splenorenal recess. Look for fluid above the diaphragm, between
                the spleen and kidney, and make sure to visualize both the
                superior and inferior poles of the kidney. Because of different
                organ attachment, fluid in the left upper quadrant can be found
                between the diaphragm and the spleen, as well as between the
                spleen and the kidney.
              </p>
              <button className="ub_textPurple smallText" onClick={hideAll}>
                Hide explanation
              </button>
            </div>
          ) : null}

          {showPelvic ? (
            <div>
              {pelvicAnswerImg ? (
                <img
                  src={pelvicAnswerImg}
                  alt="Answer fluid image for Pelvic"
                />
              ) : null}

              <p>
                In the pelvic view, use the pubic symphysis as a landmark to
                visualize the bladder in both the transverse and sagittal
                orientations. The bladder appears as an anechoic structure as it
                is fluid filled. As you fan through the entirety of the bladder
                in both views, look for fluid superior to the bladder, between
                the bladder and rectum (retrovesicular pouch) in males, between
                the bladder and uterus (vesicouterine pouch) and the rectum and
                uterus (pouch of Douglas) in females.
              </p>
              <button className="ub_textPurple smallText" onClick={hideAll}>
                Hide explanation
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default FemExplanationComponent;
