import React, { useEffect, useState } from "react";

import "../ultrasoundviews/TorsoView.css";
import "./FemView.css";

function FemView(prop) {
  const [luqImg, setLuqImg] = useState(prop.imgUrls.luq);
  const [ruqImg, setRuqImg] = useState(prop.imgUrls.ruq);
  const [pelvicImg, setPelvicImg] = useState(prop.imgUrls.pelvic);

  const [femToggle, setFemToggle] = useState({
    luq: false,
    ruq: false,
    pelv: false,
  });

  const femToggleUpdate = (toggle) => {
    if (toggle === "luq") {
      setFemToggle({
        luq: true,
        ruq: false,
        pelv: false,
      });
    } else if (toggle === "ruq") {
      setFemToggle({
        luq: false,
        ruq: true,
        pelv: false,
      });
    } else if (toggle === "pelvic") {
      setFemToggle({
        luq: false,
        ruq: false,
        pelv: true,
      });
    }
    console.log(femToggle);
  };

  useEffect(() => {});

  return (
    <div className="torsoViewComponent">
      {/* torso & toggle */}
      <div className="torsoView__torsoWrapper">
        <div className="torsoToggleWrapper">
          {/* torso img */}
          <div className="torsoView__torsoImg">
            <img
              src="https://ultrasoundbox.s3.amazonaws.com/img/torsoFront.jpg"
              alt="torso"
            />
          </div>
          {/* toggles */}
          <div className="torsoView__marks">
            <div
              className={`torsoView__mark luqHandle ${
                femToggle.luq ? "select" : ""
              }`}
              onClick={(e) => femToggleUpdate("luq")}
            ></div>
            <div
              className={`torsoView__mark ruqHandle ${
                femToggle.ruq ? "select" : ""
              }`}
              onClick={(e) => femToggleUpdate("ruq")}
            ></div>
            <div
              className={`torsoView__mark pelvicHandle ${
                femToggle.pelv ? "select" : ""
              }`}
              onClick={(e) => femToggleUpdate("pelvic")}
            ></div>
          </div>
        </div>
      </div>

      {/* us views */}
      <div className="femUsViewWrapper">
        {femToggle.luq ? (
          <div className="torsoView__usview">
            <video
              controls="false"
              playsInline
              autoPlay
              muted
              loop
              controlsList="nodownload"
            >
              <source src={luqImg} />
            </video>
          </div>
        ) : null}
        {femToggle.ruq ? (
          <div>
            <video
              controls
              playsInline
              autoPlay
              muted
              loop
              controlsList="nodownload"
            >
              <source src={ruqImg} />
            </video>
          </div>
        ) : null}
        {femToggle.pelv ? (
          <div>
            <video
              controls
              playsInline
              autoPlay
              muted
              loop
              controlsList="nodownload"
            >
              <source src={pelvicImg} />
            </video>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FemView;
