import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import './AuthorPopup.css'

import cardioBlockList from './seed/cardioBlockSeed';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import db from './firebase';

function AuthorPopup(props) {

  const [showAboutAuthor, setShowAboutAuthor] = useState(false);
  const cardioBlockListRef = useRef(cardioBlockList)
  // const ref = useRef()
  const { state } = useLocation();


  // All users
  const [authorPhoto, setAuthorPhoto] = useState()
  useEffect(() => {
    db.collection('users')
    .where("email", "==", `${props.authorInfo.email}`)
    .get()
    .then(querySnapshot => {
      const accounts = [];
      querySnapshot.forEach(async userDoc => {
        accounts.push(userDoc.data())
      });
      setAuthorPhoto(accounts[0].photo)
    })
  }, [])
  
  
  return (
    <div className="authorPopup" id="authorPopup">
      {/* profile photo */}
      <div className="authorPhotoWrapper">
        <div className="authorPhoto" style={{backgroundImage: authorPhoto ? `url("${authorPhoto}")` : "url('https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png')"}}></div>
      </div>

      {/* profile content */}
          <div className="authorContent">
            {props.authorInfo.name ? (
              <p>{props.authorInfo.name}</p>
            ) : (
              <p>Leland Perice</p>
            )}
            <span className="smallText">{props.authorInfo.institution ? props.authorInfo.institution : ""}</span><br /><br />
            {/* Author info Linkedin*/}
            {props.authorInfo.linkedinUrl && props.authorInfo.linkedinUrl !== null ? (
              <a className="authorSocial" href={`${props.authorInfo.linkedinUrl}`} target="_blank" style={{color:"#0e76a8"}}><LinkedInIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Facebook*/}
            {props.authorInfo.facebookUrl ? (
              <a className="authorSocial" href={`${props.authorInfo.facebookUrl}`} target="_blank" style={{color: "#3b5998"}}><FacebookIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Instagram*/}
            {props.authorInfo.instagramUrl ? (
              <a className="authorSocial" href={`${props.authorInfo.instagramUrl}`} target="_blank" style={{color:"#833AB4"}}><InstagramIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Twitter*/}
            {props.authorInfo.twitterUrl ? (
              <a className="authorSocial" href={`${props.authorInfo.twitterUrl}`} target="_blank" style={{color:"#1DA1F2"}}><TwitterIcon />
              </a>
            ) : (
              ""
            )}
            {/* Author info Email – on hold*/}
            {/* {props.authorInfo.email ? (
              <a className="authorSocial" href={`${props.authorInfo.email}`} target="_blank"><MailOutlineIcon />
              </a>
            ) : (
              ""
            )} */}
            {/* Author info Phone Number*/}
            {props.authorInfo.phoneNumber ? (
              <a className="authorSocial" href={`${props.authorInfo.phoneNumber}`} target="_blank"><PhoneIphoneIcon />
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
  )
}

export default AuthorPopup
