import React, { useState } from 'react'
import './WmaLandingScreen.css'

function WmaImageComponent(prop) {

  console.log('prop', prop)
  console.log('prop.currentImg', prop.currentImg)

  const [ currentImgProp, setCurrentImgProp] = useState(prop.currentImg);
  // setCurrentImgProp(prop.currentImg)

  return (
    <div className="wmaImageComponent wmaQuestions__view">
      <div className="wmaQuestions__usview" style={{backgroundImage : `url("${currentImgProp}")`}}>
        <video playsInline autoPlay muted loop>
          <source src={`${currentImgProp}`} />
        </video>
      </div>
      {/* torso */}
      <img className="wmaQuestions__torso" src="https://ultrasoundbox.s3.amazonaws.com/wma/WMA_torso.png" alt="" />
    </div>
  )
}

export default WmaImageComponent