const renalBlockList = [
	
	



	// Block 1
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal'], 
    authorInfo: {
      name: ' Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '23 year old male with no past medical history presents with dysuria, increased urinary frequency and flank pain. You perform a POCUS:', 
    usviews:  ['', 'https://ultrasoundbox.s3.amazonaws.com/renal/block2/1.gif', ''], 
    questionInfo: [
      {
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
      {
        qtype: 'general',
        question: ' What are the ultrasonographic findings of pyelonephritis?',
        options: 'Hydronephrosis , Hypoechoic renal parenchyma, None of the above',
        answer: 3,
        explain: '3: There are no ultrasonographic findings of pyelonephritis. It is a clinical diagnosis. Complications such as a renal abscess could have findings, however this is a different diagnosis.'
      },
	], 
    interpretation: [
      ['This is a normal left kidney.'], 
      ['There is no hydronephrosis seen in the left kidney.'], 
      ['Normal Kidney.']
    ]  
  }, 
	
	
	// Block 2
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal'], 
    authorInfo: {
      name: ' Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '68 year old male with a past medical history of nephrolithiasis presents with sudden onset right sided inguinal and flank pain associated with hematuria. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block3/1.gif', '', ''], 
    questionInfo: [
      {
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['In this clip, the renal pelvis calyces are dilated but there is no thinning of the renal parenchyma, making this mild to moderate hydronephrosis, grade 2.','BREAK','Grade 1 (mild) means there is mild dilation of the renal pelvis without any dilation of the calyces.','BREAK','Grade 2 (mild) means there is moderate dilation of the renal pelvis that extends to a few calyces.','BREAK','Grade 3 (moderate) means the renal pelvis dilation extends to all the calyces.','BREAK','Grade 4 (severe) means there is extension of dilation to all the calyces with the addition of thinning of the renal parenchyma.', {text: 'Here', hyperlink: 'https://www.pocus101.com/renal-ultrasound-made-easy-step-by-step-guide/'}, 'is a nice pocket card for this.'], 
      ['Moderate hydronephrosis is seen within the right kidney.'], 
      ['Moderate hydronephrosis in the right kidney.']
    ]  
  }, 

	// Block 3
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'ureteral jet', 'hydronephrosis'], 
    authorInfo: {
      name: ' Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '57 year old male with a past medical history of hypertension, nephrolithiasis and diabetes mellitus presents with right sided flank pain for a few weeks, fever and dysuria. You perform a POCUS: ', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block4/1.mp4', '', 'https://ultrasoundbox.s3.amazonaws.com/renal/block4/3.mov'], 
    questionInfo: [
      {
        qtype: 'reading', 
        question: 'What is the degree of hydronephrosis seen in the right kidney?', 
        options: 'Mild, Moderate, Severe', 
        answer: 3, 
        explain: ''
      },
	], 
    interpretation: [
      ['There is severe right-sided hydronephrosis. There are also no right sided ureteric jets seen on the bladder view. This suggests an obstructing right renal stone.','BREAK','The severity of the Hydronephrosis is based on the amount of dilatation in the kidney. The most severe cases result in renal cortex thinning. This takes time to occur and indicates that the severe hydronephrosis has been present for some time, as was the case in this patient.','BREAK','The patient also presents with fever and dysuria, suggesting infection of the obstructed urine behind the kidney stone and therefore requires antibiotics and a urology consult.'], 
      ['Severe right-sided hydronephrosis. No right ureteric jet appreciated.'], 
      ['Severe right-sided hydronephrosis.']
    ]  
  }, 	
	
	// Block 4
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal, renal cyst', 'pleural effusion'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '55 year old male comes in with right flank pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block5/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block5/2.mp4', ''], 
    questionInfo: [
      {
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['Signs of a simple cyst include:','BREAK','• Well-marginated anechoic lesion with thin, regular walls','BREAK','• Generally completely hypoechoic homogenous contents','BREAK','• Generally perfectly circular shape','BREAK','• No vascularity.','BREAK',' Having a cyst can sometimes make it difficult to ascertain hydronephrosis. It is key to remember that hydronephrosis starts in the collecting system within the center of the kidney and expands outwards, whereas a cyst will generally be in the periphery of the kidney.'], 
      ['Simple cyst on both left and right kidneys. No hydronephrosis appreciated. Pleural effusion seen above the right diaphragm.'], 
      ['No hydronephrosis appreciated. Right pleural effusion.']
    ]  
  }, 	

		// Block 5
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: 'Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '35 year old male comes in with left flank pain. You perform a POCUS:', 
    usviews:  ['', 'https://ultrasoundbox.s3.amazonaws.com/renal/block6/2.mp4', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['This is moderate-severe hydronephrosis of the kidney. It can be considered severe because it has started to erode the cortex of the normal renal architecture.'], 
      ['Moderate-Severe hydronephrosis of the left kidney.'], 
      ['Moderate-Severe hydronephrosis']
    ]  
  }, 	

		// Block 6
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '76 year old male comes in with suprapubic pain, abdominal distension and decreased urination. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block7/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block7/2.mp4', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
     ['This is severe hydronephrosis of the kidneys bilaterally. It can be considered severe because it has obliterated the normal renal architecture. It likely occurred due to undiagnosed benign prostatic hyperplasia resulting in urinary retention, which is common in elderly males.'], 
    ['Severe hydronephrosis of both kidneys bilaterally with loss of normal renal architecture.'], 
      ['Severe hydronephrosis bilaterally.']
    ]  
  }, 	

		// Block 7
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '81 year old male comes in with suprapubic pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block8/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block8/2.mp4', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['Here we see moderate hydronephrosis of both kidneys. Mild hydronephrosis will have renal pelvic dilatation alone. It is considered moderate when the central and peripheral minor calyces are also dilated. It is severe when the parenchyma is thinned.', {text: 'Here', hyperlink: 'https://www.pocus101.com/renal-ultrasound-made-easy-step-by-step-guide/'}, 'is a nice resource which has a pocket card for grading hydronephrosis'], 
      ['Moderate hydronephrosis bilaterally.'], 
      ['Moderate hydronephrosis bilaterally.']
    ]  
  }, 	
	
	// Block 8
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '88 year old male comes in with suprapubic pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block9/1.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block9/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block9/3.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
	], 
    interpretation: [
      ['Color flow can help differentiate if the hypoechoic spaces are normal renal vessels and not obstructed urine. This hydronephrosis is likely secondary to urinary obstruction as evidence by the large bladder with sediment in it.'], 
      ['Moderate hydronephrosis of both kidneys bilaterally. Distended bladder.'], 
     ['Moderate hydronephrosis of both kidneys bilaterally.']
    ]  
  }, 
	
	// Block 9
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'anatomy', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: 'Please correctly identify the labeled letters in the right flank view: ', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block10/1.jpg', '', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Which of the following structures represents the letter A?', 
        options: 'Gerotas fascia, Cortex, Liver, Renal pelvis, Ureter', 
        answer: 4, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Which of the following structures represents the letter B?', 
        options: 'Medulla, Gerotas fascia, Cortex, Liver, Renal pelvis, Ureter', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Which of the following structures represents the letter C?', 
        options: 'Medulla, Gerotas fascia, Cortex, Liver, Renal pelvis, Ureter', 
        answer: 3, 
        explain: ''
      },
		{
        qtype: 'reading', 
        question: 'Which of the following structures represents the letter D?', 
        options: 'Medulla, Gerotas fascia, Cortex, Liver, Renal pelvis, Ureter', 
        answer: 2, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Which of the following structures represents the letter E?', 
        options: 'Medulla, Gerotas fascia, Cortex, Liver, Renal pelvis, Ureter', 
        answer: 6, 
        explain: ''
      }, 
     {
        qtype: 'general', 
        question: ' What is the position of the left versus the right kidney?', 
        options: 'The right kidney is caudal to the left kidney, The left kidney is larger than the right kidney, Both kidneys are at the same approximate level in the frontal plane', 
        answer: 1, 
        explain: '1: The left kidney is more cranial than the right kidney anatomically. They will not be on the same level in the frontal plane. In addition, the left kidney has a smaller sonographic window making it harder to visualize.'
      }, 
      {
        qtype: 'general', 
        question: 'Why is it easier to image the right kidney versus the left kidney?: ', 
        options: ' The right kidney has a better acoustic window as it is next to the liver, Interference of air from the stomach and intestine while imaging the left kidney, Superior position of the left kidney more often putting it posterior to a rib, All of the above', 
        answer: 4, 
        explain: '4: The right kidney has a more superior acoustic window due to its position adjacent to the liver. Additionally, the left kidney is in close proximity to many structures that can contain air, which can obscure findings. Lastly, the left kidney lies more superiorly and posteriorly, making it more difficult to obtain a good window view of the left kidney'
      }, 
	]
  },
	
// Block 10
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '35 year old male with a history of nephrolithiasis comes in with a chief complaint of left flank pain. She has never required intervention for her kidney stones. You perform a POCUS:', 
    usviews:  ['', 'https://ultrasoundbox.s3.amazonaws.com/renal/block11/2.png', 'https://ultrasoundbox.s3.amazonaws.com/renal/block11/3.gif'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
     {
        qtype: 'general', 
        question: ' The patients pain is well controlled, and there are no signs of infection on the urinalysis. What should you do next in the management of this patient?', 
        options: 'Give Toradol 15 IV, Obtain a CT scan, Place a foley catheter, Discharge the patient', 
        answer: 4, 
        explain: '4. Young healthy patients, with mild hydronephrosis and adequately controlled flank can be discharged with urology follow up. Management would change if the patient had persistent symptoms, a solitary kidney, or an infected stone.'
      }, 
      {
        qtype: 'general', 
        question: 'How would your management change if the patient told you she has a congenital defect of the right kidney and it is nonfunctional? ', 
        options: ' Would not change management, Would consult urology for emergent ureteral stent placement', 
        answer: 2, 
        explain: '2. Patients with a solitary kidney or transplanted kidney should have a low threshold for urology consultation in the context of nephrolithiasis, as continued obstruction of the one functional kidney will result in kidney failure. This is contrasted with a patient with two functional kidneys, in which the non-obstructed kidney can easily compensate for the obstructed kidney.'
      }, 
	], 
    interpretation: [
      ['Renal US shows mild hydronephrosis in the left kidney, non distended bladder'], 
      ['Mild hydronephrosis was found in the left kidney.'], 
      ['Mild hydronephrosis was found in the left kidney.']
    ]  
  }, 


// Block 11
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis', 'UVJ stone', 'twinkle artifact'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '35 year old male comes in with flank pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block12/1.gif', '', 'https://ultrasoundbox.s3.amazonaws.com/renal/block12/3.png'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
     {
        qtype: 'general', 
        question: 'What is the significance of the finding on the bladder scan?', 
        options: 'ureteral stenosis, Uterovesical junction stone, Malignancy', 
        answer: 2, 
        explain: '2: Twinkle artifact represents a ureteral calculus. It is believed to be an artifact due the calculus having a highly irregular surface, causing turbulent flow of urine all around it.'
      }, 
      {
        qtype: 'general', 
        question: 'You obtain a CT scan and find a kidney stone measuring 4mm in diameter. Upon reassessment the patients pain has resolved. What is the appropriate next step in the management of this patient? ', 
        options: 'Discharge the patient, Consult urology, Admit the patient', 
        answer: 1, 
        explain: '1: The stone was found to be 4mm in diameter, which at that size will likely pass on its own. There is little utility for urology consultation in the emergency room. The patient should be given follow up with urology so they can be followed in the outpatient setting.'
      }, 
	], 
    interpretation: [
     ['This is a positive ultrasound, with evidence of a ureteral calculus found adjacent to the bladder, likely at the ureterovesical junction. The severe hydronephrosis seen in the kidney is likely secondary to an obstructed renal calculus. When color flow is put on a stone at the ureterovesical junction, the so called “twinkle artifact”, is seen, as shown here in the still image.'], 
      ['There is severe hydronephrosis in the right kidney. There is also a twinkle artifact indicating a calculus in the right UVJ.'], 
      ['There is severe hydronephrosis in the right kidney likely secondary to a visualized right UVJ calculus. ']
    ]  
  }, 
	
	// Block 12
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty1', 
    questionTags: [ 'renal', 'hydronephrosis'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '31 year old male comes in with a chief complaint of flank pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block13/1.gif', '', ''], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
     {
        qtype: 'general', 
        question: 'What artifact is appreciated  on this POCUS?', 
        options: 'Reverberation artifact, Side lobe artifact, Mirroring artifact, Shadowing artifact', 
        answer: 4, 
        explain: '4. Shadowing artifacts occur when a highly reflective surface, such as a UPJ stone as seen in this case, bounces all of the sound waves back, resulting in no sound waves making it past the stone. The object will be hyperechoic while everything posterior to it will be anechoic, which is the shadowing artifact of the object.'
      }, 
      {
        qtype: 'general', 
        question: 'What symptoms would you expect if you found nephrolithiasis within the kidney?', 
        options: 'Flank pain, Urinary frequency, Abdominal pain, No symptoms', 
        answer: 4, 
        explain: '4. Calculi within the kidney produce no symptoms. However, once the stone has migrated to the ureter it can cause distension, resulting in pain and other symptoms.'
      }, 
	], 
    interpretation: [
      ['This is a positive renal exam with pelvic distension indicating mild hydronephrosis in the right kidney, as well as a ureteropelvic junction stone with posterior shadowing.'], 
     [ 'There is a renal calculus at the ureteropelvic junction with posterior shadowing and mild right-sided hydronephrosis.'], 
    [  'Right uteropelvic junction calculi with mild hydronephrosis of the right kidney ']
    ]  
  }, 
	
	// Block 13
	  {
    categoryId: 2, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'renal', 'cyst'], 
    authorInfo: {
      name: ' Leland Perice,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/PericeLeland', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '55 year old male comes in with a chief complaint of lower back pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block14/1.gif', '', 'https://ultrasoundbox.s3.amazonaws.com/renal/block14/3.gif'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Does this patient have nephrolithiasis?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
     {
        qtype: 'general', 
        question: 'What are the criteria that a cyst must have to be considered benign?', 
        options: ' Posterior enhancement beyond the cyst, Well-defined interface between the cyst and adjacent renal parenchyma, Smooth borders, No internal echoes or solid elements, All of the above', 
        answer: 5, 
        explain: '5: All of the listed answers are characteristics of a benign cyst.'
      }, 
	], 
    interpretation: [
      ['No hydronephrosis is present in the right kidney. There is a simple cyst on the right kidney, which can be confused for hydronephrosis. It is distinguished from hydronephrosis in this case as it is not near the renal pelvis and insertion of the ureter, which is where hydronephrosis starts. A simple right renal cyst has smooth borders, no internal echoes, and is well defined.','BREAK','A round structure is also seen in the gallbladder with clean shadowing, making more likely a stone, although it is possibly also a polyp.','BREAK','A kidney stone is clearly seen posterior to the bladder at the left ureterovesical junction (UVJ). This is extremely painful, and is likely the cause of back pain in this patient.'],
      ['There is a visible left UVJ calculus. There is no hydronephrosis in the right kidney. There is a simple renal cyst in the right kidney. There is a shadowing structure within the gallbladder possibly consistent with a gallstone. There is no hydronephrosis in the left kidney.'], 
      ['Left UVJ calculus. No hydronephrosis was found bilaterally. Simple cyst on the right kidney. Possible gallstone within the gallbladder.']
    ]  
  },


	
	// Block 14
  {
    categoryId: 2, 
    difficultyLevel: 'difficulty2', 
    questionTags: [ 'renal', 'twinkle sign','nephrolithiasis'], 
    authorInfo: {
      name: ' Jordan Brown,  MD', 
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    }, 
    editorInfo: {
      name: 'Nhu-Nguyen Le,  MD', 
      institution: 'Riverside Community Hospital/University of California, Riverside', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/nhunguyenle', 
      email: 'nhunguyenle1@gmail.com', 
      phoneNumber: '', 
    }, 
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '39 year old male with no past medical history presents with one day of left lower quadrant pain. You perform a POCUS:', 
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block14+live/1.mp4','https://ultrasoundbox.s3.amazonaws.com/renal/block14+live/2.mp4', 'https://ultrasoundbox.s3.amazonaws.com/renal/block14+live/3.mp4'], 
    questionInfo: [
		{
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the right kidney?', 
        options: 'Yes,  No', 
        answer: 2, 
        explain: ''
      }, 
      {
        qtype: 'reading', 
        question: 'Do you see any hydronephrosis in the Left kidney?', 
        options: 'Yes,  No', 
        answer: 1, 
        explain: ''
      }, 
		{
        qtype: 'reading', 
        question: 'Is there evidence of nephrolithiasis? If so, where is the most likely location of the calculus?', 
        options: 'No nephrolithiasis is present, Utero vesical junction, Mid ureter, Proximal ureter, Intra-renal', 
        answer: 2, 
        explain: ''
      }
	], 
    interpretation: [
      ['There are direct and indirect findings of renal stones, as described',{text: 'here', hyperlink: 'https://www.pocus101.com/renal-ultrasound-made-easy-step-by-step-guide/'},'. Although subtle, ureteral stones with posterior shadowing can also be directly visualized at the left ureterovesicular junction (UVJ). An illustration of the stone being seen on normal b-mode imaging in this case can be seen',{text: 'here', hyperlink: 'https://ultrasoundbox.s3.amazonaws.com/renal/block14+live/1.png'},'.','BREAK','There is color flow seen in one spot localized to one spot at the UVJ, which is called the "Twinkle artifact". This is also called a "color comet tail" is the finding that gave away the kidney stone in the distal ureter. Twinkle artifact can be seen with or without comet tails. Although the stone is not moving it generates this artifact due to its rough, highly reflective surface. Sound ping-pongs around, which widens the spectrum, and the ultrasound detects that as frequency shifts - hence the color doppler signal.','BREAK','In this case, a CT was then performed confirming a 5mm kidney stone in the left UVJ that was consistent with the POCUS findings. Hydronephrosis can suggest the presence of renal stones, as described in this',{text: 'review', hyperlink: 'https://pubmed.ncbi.nlm.nih.gov/29427476/'},'. It is 70% sensitive and 75% specific. However, this increases to 94% specific in moderate and severe hydronephrosis. In one of the reviewed studies, in 28 patients with kidney stone without hydronephrosis, zero were admitted in the following 30 days for any urologic complications.','BREAK','References','BREAK','1.https://www.webcir.org/revistavirtual/articulos/septiembre11/chile/ch_ingles.pdf','BREAK','2. https://pubmed.ncbi.nlm.nih.gov/29427476/'],
      ['Twinkle sign in the left UVJ representing a ureteral calculus in that area. Moderate hydronephrosis of the left kidney'], 
      ['Calculus in the left UVJ with associated moderate hydronephrosis of the left kidney.']
    ]  
  },

  	// Block 15
    {
      categoryId: 2, 
      difficultyLevel: 'difficulty2', 
      questionTags: [ 'renal', 'abscess'], 
      authorInfo: {
        name: ' Shirley Wu,  MD', 
        institution: 'Brown, Providence, Rhode Island', 
        linkedinUrl: '', 
        facebookUrl: '', 
        instagramUrl: '', 
        twitterUrl: '', 
        email: '', 
        phoneNumber: '', 
      }, 
      editorInfo: {
        name: ' Leland Perice,  MD', 
        institution: 'Brown, Providence, Rhode Island', 
        linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
        facebookUrl: '', 
        instagramUrl: '', 
        twitterUrl: 'https://twitter.com/PericeLeland', 
        email: 'lelandperice@gmail.com', 
        phoneNumber: '', 
      }, 
      lineEditorInfo: {
        name: '', 
        institution: '', 
        linkedinUrl: '', 
        facebookUrl: '', 
        instagramUrl: '', 
        twitterUrl: '', 
        email: '', 
        phoneNumber: '', 
      },
      props: '36 year old female presented with flank pain, fevers and urinary symptoms. You diagnose pyelonephritis and perform a POCUS:', 
      usviews:  ['https://ultrasoundbox.s3.amazonaws.com/renal/block15/1.mp4','', ''], 
      questionInfo: [
      {
          qtype: 'reading', 
          question: 'Do you see any hydronephrosis in the right kidney?', 
          options: 'Yes,  No', 
          answer: 2, 
          explain: ''
        }, 
        {
          qtype: 'reading', 
          question: 'What urinary tract complications, if any, do you see on these images?', 
          options: 'Renal abscess, Nephrolithiasis, Pyonephrosis, Ureteral rupture', 
          answer: 1, 
          explain: ''
        }
    ], 
      interpretation: [
        ['This finding was concerning for an abscess, and a subsequent CT of the abdomen confirms this finding.  She was admitted for IV antibiotics and a urology consult in the context of these results.','BREAK','Abscesses appear as thick-walled cavities with hypoechoic fluid which may include a fluid debris level. Not all patients with pyelonephritis require advanced imaging and performing a CT on all of these patients would result in necessary costs and radiation. In fact, most urinary tract infections (UTI) are isolated to the bladder and do not need imaging. Complicated UTIs, however, warrant advanced imaging to rule complications such as pyelonephritis or a renal abscess. These complications can have a poor prognosis if not promptly diagnosed and treated.','BREAK','Ultrasound is ideal in this scenario because it is more cost efficient, does not use radiation, and can be immediately done at the bedside. It is more feasible to perform renal ultrasounds more often on cases of pyelonephritis to assess for complications such as a renal abscess or nephrolithiasis, rather than obtaining a CT scan on all these patients.'],
        ['Right kidney with small roundish anechoic structure in cortex with thick, irregular border and internal debri.'], 
        ['Renal abscess.']
      ]  
    },

      	// Block 16
        {
          categoryId: 2, 
          difficultyLevel: 'difficulty2', 
          questionTags: [ 'renal', 'abscess'], 
          authorInfo: {
            name: ' Erika Constantine,  MD', 
            institution: 'Brown, Providence, Rhode Island', 
            linkedinUrl: '', 
            facebookUrl: '', 
            instagramUrl: '', 
            twitterUrl: '', 
            email: '', 
            phoneNumber: '', 
          }, 
          editorInfo: {
            name: ' Leland Perice,  MD', 
            institution: 'Brown, Providence, Rhode Island', 
            linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/', 
            facebookUrl: '', 
            instagramUrl: '', 
            twitterUrl: 'https://twitter.com/PericeLeland', 
            email: 'lelandperice@gmail.com', 
            phoneNumber: '', 
          }, 
          lineEditorInfo: {
            name: '', 
            institution: '', 
            linkedinUrl: '', 
            facebookUrl: '', 
            instagramUrl: '', 
            twitterUrl: '', 
            email: '', 
            phoneNumber: '', 
          },
          props: '6 year old patient presents with abdominal pain worst in the left lower quadrant.  The umbilicus appears red with crusting:', 
          usviews:  ['','', 'https://ultrasoundbox.s3.amazonaws.com/renal/block16/Long.mp4'], 
          questionInfo: [
          {
              qtype: 'reading', 
              question: 'What artifact do abscesses most commonly display?', 
              options: 'Posterior acoustic enhancement, shadowing, reverberation artifact, mirror artifact', 
              answer: 1, 
              explain: ''
            }, 
            {
              qtype: 'reading', 
              question: 'What is the most likely etiology of this patients presentation?', 
              options: 'Appendicitis, Complicated diverticulitis, Urachal abscess, Meckels diverticulum', 
              answer: 3, 
              explain: ''
            }
        ], 
          interpretation: [
            ['This is a urachal abscess. The patient has a urachal cyst which had been infected multiple times previously. The urachus is a tube which connects the bladder to the umbilicus, and is usually obliterated during normal embryonic development. A urachal cyst is the remaining aspect of the urachus which had been incompletely obliterated. A completely patent urachus can result in urine leaking out of the umbilicus. An illiustration of this can be seen ',{text: 'here', hyperlink: 'https://ultrasoundbox.s3.amazonaws.com/renal/block16/Pic1.png'},'','BREAK','This patient was admitted to the surgical service and improved with iv antibiotics, and was eventually discharged without further intervention.','BREAK','Some pearls of ultrasounding abscesses in general include:','BREAK','1. The abscess itself is sometimes poorly visualized as the contents can be isoechoic with the surrounding soft tissue. In these cases they can be identified by looking for the posterior acoustic enhancement behind the isoechoic collection in the area that is symptomatic. Compressing to assess for ultrasonic fluctuance can also help.','BREAK','2. In general, before incising and draining an abscess, it is prudent to ultrasound it first to confirm it is indeed an abscess. Beware of abscess mimics including pseudoaneurysms, aneurysms, cysts, lymph nodes (especially when inflamed). Assessing for flow using color can help differentiate a pseudoaneurysm or aneurysm . The contents of these mimics will also not swirl with compression. ','BREAK','References:','BREAK','https://www.researchgate.net/figure/Types-of-urachal-anomalies-a-patent-urachus-b-urachal-sinus-c-urachal_fig4_230588357]','BREAK','https://journals.lww.com/md-journal/fulltext/2020/01310/an_infected_urachal_cyst_presenting_as_acute.38.aspx'],
            ['Complex collection posterior to the bladder with surrounding inflammation.'], 
            ['Abscess attached to the bladder likely representing a urachal abscess.']
          ]  
        },
]
module.exports = renalBlockList