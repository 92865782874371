const biliaryBlockList = [
  // Block 1
	  {
    categoryId: 4,
    difficultyLevel: 'difficulty1',
    questionTags: ['ruq'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '25 year old male presents with a chief complaint of epigastric pain. You perform a POCUS:',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block1/1.gif','https://ultrasoundbox.s3.amazonaws.com/biliary/block1/2.gif',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there pericholecystic fluid?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
	],
    interpretation: [
      ['Here we visualize the gallbladder along with the portal triad in cross-section forming the so-called exclamation point sign (the point being the portal triad). It is overall a normal ultrasound with no pathologic findings.','BREAK','Diagnosing Cholecystitis: Signs of cholecystitis include gallbladder wall thickening, pericholecystic fluid, hydrops gallbladder, stone-in-neck, or sonographic murphy’s sign. The gallbladder wall is considered thickened if it is >3mm. POCUS has a sensitivity of 86% for acute cholecystitis in the hands of an emergency physician.'],
      ['RUQ views were obtained in the sagittal and transverse planes. No wall thickening was found. The gallbladder is normal sized. No gallbladder wall edema. No pericholecystic fluid. Negative sonographic Murphy’s sign. No gallstones visualized. Anterior gallbladder wall measured 2.9mm.'],
      ['Normal gallbladder. No sign of cholecystitis.']
    ]  
  },
  
	//block 2
	
		  {
    categoryId: 4,
    difficultyLevel: 'difficulty1',
    questionTags: ['ruq', 'aute cholecystitis'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '45 year old female presents with a chief complaint of right upper quadrant pain. You perform a physical exam and find a positive murphys sign. You perform a POCUS:',
    patientGender: 'female',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block2/1.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block2/2.png',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there pericholecystic fluid?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there acute cholecystitis?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
	],
    interpretation: [
      ['It easy to miss the stone in the neck towards the end of the clip. This is called a stone in neck, or SIN sign. This alone has enough specificity to raise concern for the diagnosis of acute cholecystitis. This finding along with a positive murphy’s sign and a borderline thickened wall (3.0mm or greater) raises concern for acute cholecystitis.'],
      ['RUQ views were obtained in the sagittal and transverse planes. No pericholecystic fluid. Positive sonographic Murphy’s sign. Gallstones visualized in the neck of the gallbladder. Anterior gallbladder wall measured 3.0mm.'],
      ['Acute Cholecystitis.']
    ]  
  },
	
	//block 3
	
		  {
    categoryId: 4,
    difficultyLevel: 'difficulty1',
    questionTags: ['ruq', 'acute cholecystitis'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '65 year old female presents with a chief complaint of epigastric pain. There is a positive murphys sign on physical exam. You perform a POCUS:',
    patientGender: 'female',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block3/1.png','https://ultrasoundbox.s3.amazonaws.com/biliary/block3/2.mp4',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there pericholecystic fluid?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
	],
    interpretation: [
      ['The wall is thick at 6.3 mm, well above the normal upper limit of 3 mm. There are also numerous gallstones in the gallbladder along with a positive murphys sign, raising concern for acute cholecystitis.'],
      ['RUQ views were obtained in the sagittal and transverse planes. Wall thickening was found. No pericholecystic fluid. Gallstones visualized throughout the gallbladder. Anterior gallbladder wall measured 6.3 mm.'],
      ['Acute Cholecystitis.']
    ]  
  },
	
	//block 4
	
		  {
    categoryId: 4,
    difficultyLevel: 'difficulty2',
    questionTags: ['ruq', 'acalculous cholecystitis'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '92y year old female with a history of rheumatoid arthritis, diabetes mellitus, and hypertension, presents with altered mental status, abdominal pain, and a fever. You perform a POCUS:',
    patientGender: 'female',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block4/1.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block4/2.png',''],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
	],
    interpretation: [
      ['The wall is thickened here (>3 mm) and there is a large amount of sludge within the gallbladder. There is also slight pericholecystic fluid seen posterior to the gallbladder towards the end of the clip. This is a possible case of acalculous cholecystitis. It is possible to get acalculous cholecystitis in elderly patients with multiple medical co-morbidities such as the one presented in this case. This is important to recognize as this was likely the source of sepsis in this patient.'],
      ['RUQ views were obtained in the sagittal and transverse planes. Wall thickening was found. Small amount of pericholecystic fluid appreciated. Layering sludge within the gallbladder. Anterior gallbladder wall measured 5.2 mm.'],
      ['Multiple signs of gallbladder inflammation without gallstones raising concern for acalculous cholecystitis']
    ]  
  },
	
	//block 5
	
		  {
    categoryId: 4,
    difficultyLevel: 'difficulty1',
    questionTags: ['ruq', 'normal'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '29 year old male presents with a chief complaint of epigastric pain. You perform a POCUS:',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block5/1.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block5/2.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block5/3.png'],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there pericholecystic fluid?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there acute cholecystitis?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
	],
    interpretation: [
      ['The wall is within normal limits here (<3 mm). There are no signs of inflammation, there are no gallstones. This is a negative gallbladder scan.'],
      ['RUQ views were obtained in the sagittal and transverse planes. No wall thickening was found. No pericholecystic fluid. No gallstones were visualized throughout the gallbladder. Anterior gallbladder wall measured 1.9 mm.'],
      ['Normal Gallbladder.']
    ]  
  },
	
	//block 6
	
		  {
    categoryId: 4,
    difficultyLevel: 'difficulty1',
    questionTags: ['ruq', 'acute cholecystitis'],
    authorInfo: {
      name: ' Leland Perice, MD',
      institution: 'Brown University, Providence, Rhode Island',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital / University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    props: '29 year old male presents with a chief complaint of epigastric pain. You perform a POCUS:',
    patientGender: 'male',
    usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block6/1.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block6/2.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block6/3.png'],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Are there gallstones?',
        options: 'Yes, No',
        answer: 1,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there pericholecystic fluid?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there galbladder wall thickening?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there acute cholecystitis?',
        options: 'Yes, No',
        answer: 2,
        explain: ''
      },
	],
    interpretation: [
      ['The wall is within normal limits here (<3 mm). While there is one large gallstone, there are no other signs of gallbladder inflammation. This is cholelithiasis.'],
      ['RUQ views were obtained in the sagittal and transverse planes. No wall thickening was found. No pericholecystic fluid. Large gallstone was visualized within the gallbladder. Anterior gallbladder wall measured 2.3mm.'],
      ['Cholelithiasis.']
    ]  
  },

  	//block 7
	
    {
      categoryId: 4,
      difficultyLevel: 'difficulty1',
      questionTags: ['ruq','acute cholecystitis','pediatric'],
      authorInfo: {
        name: ' Jordan Brown, MD',
        institution: 'Brown University, Providence, Rhode Island',
        linkedinUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        email: '',
        phoneNumber: '',
      },
      editorInfo: {
        name: 'Leland Perice, MD',
        institution: 'Brown University, Providence, Rhode Island',
        linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: 'https://twitter.com/PericeLeland',
        email: 'lelandperice@gmail.com',
        phoneNumber: '',
      },
      lineEditorInfo: {
        name: '', 
        institution: '', 
        linkedinUrl: '', 
        facebookUrl: '', 
        instagramUrl: '', 
        twitterUrl: '', 
        email: '', 
        phoneNumber: '', 
      },
      props: '12 year old male presents with diffuse abdominal pain and fevers. He has a strong family history of gallstones, prompting you to perform a POCUS:',
      patientGender: 'male',
      usviews:  ['https://ultrasoundbox.s3.amazonaws.com/biliary/block7/1.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block7/2.mp4','https://ultrasoundbox.s3.amazonaws.com/biliary/block7/3.png'],
      questionInfo: [
        {
          qtype: 'reading',
          question: 'Are there gallstones?',
          options: 'Yes, No',
          answer: 1,
          explain: ''
        },
        {
          qtype: 'reading',
          question: 'Is there pericholecystic fluid?',
          options: 'Yes, No',
          answer: 1,
          explain: ''
        },
        {
          qtype: 'reading',
          question: 'Is there galbladder wall thickening?',
          options: 'Yes, No',
          answer: 1,
          explain: ''
        },
        {
          qtype: 'reading',
          question: 'What is the likely diagnosis?',
          options: 'Negative gallbladder, Cholelithiasis, Cholecystitis',
          answer: 3,
          explain: ''
        },
    ],
      interpretation: [
        ['Here the etiology of this pediatric patients abdominal pain was found to be acute cholecystitis.',{text: 'Here', hyperlink: 'https://ultrasoundbox.s3.amazonaws.com/biliary/block7/4.png'},'is an illustration pointing out the thickened gallbladder wall and pericholecystic fluid. Several studies have shown that the incidence of pediatric cholecystitis has increased over the past few decades. Adolescent females have the highest incidence of this group, and the fatty Western diet is believed to be a contributor. Magnetic resonance cholangiopancreatography was then done showing filling defect in the proximal common bile duct. They then underwent cholecystectomy which confirmed acute cholecystitis.','BREAK','Differentiating sludge versus pseudosludge (which is created by side lobe artifact): Pseudosludge typically has a convex curved margin, may be more hyperechoic and fade in intensity moving from a reflective adjacent structure such as bowel gas. Side lobe artifacts in general can be reduced by repositioning the patient, changing the transducer angle, or reducing the gain.','BREAK','Measuring gallbladder wall thickness: Edema of gallbladder wall (more hypoechoic) should be included in wall thickness. As the gallbladder becomes enlarged and edematous, the thickened walls may exude fluid --> pericholecystic fluid. So think of this as a spectrum.','BREAK','References:','BREAK',{text: 'Murphy PB, Vogt KN, Winick-Ng J, McClure JA, Welk B, Jones SA. The increasing incidence of gallbladder disease in children: A 20 year perspective. Journal of Pediatric Surgery. 2016;51(5):748-752.', hyperlink: 'https://www.jpedsurg.org/article/S0022-3468(16)00080-4/fulltext'},'BREAK',{text: 'Baad M, Lu ZF, Reiser I, Paushter D. Clinical significance of us artifacts. RadioGraphics. 2017;37(5):1408-1423.', hyperlink: 'https://pubs.rsna.org/doi/full/10.1148/rg.2017160175'}],
        ['Gallbladder full of sludge, thickened wall, surrounding pericholecystic fluid, several small stones in the fundus with clean shadowing, gallbladder neck with multiple folds.'],
        ['Acute Cholecystitis.']
      ]  
    },
	]
module.exports = biliaryBlockList