import React, { useEffect } from 'react'
import './HomeScreen.css'
import Nav from '../Nav'
import Banner from '../Banner'
import Row from '../Row'
import { auth, firebaseAnalytics } from '../firebase'
import SubmitYoursAd from '../SubmitYoursAd'
import { Link, useParams } from 'react-router-dom'

import brainstorming from '../svgs/brainstorming.gif'

function HomeScreen() {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homescreen">
      { auth.currentUser ? (
        ""
      ) : (
        <Banner />
      )}
      <div className="SFAbanner">
        <div>
          <p><span className="tagAlert">NEW</span>Advanced Module - Systolic Function Assessment</p>
          <Link to="/sfa"><button className="ub_solidPurple" >Try now</button></Link>
        </div>
      </div>
      <Row />
      <SubmitYoursAd />
      {/* <img className='logo' src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-horz.png" alt="logo" />
      <h1>Under Construction</h1>
      <p>We are constantly improving ourselves. We will be back on October 1st. If you have any questions, please contact us to ultrasoundbox.official@gmail.com</p>
      <br />
      <img src={brainstorming} alt="We are under construction" /> */}
    </div>
  )
}

export default HomeScreen
