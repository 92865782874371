import React, { useEffect } from 'react'
import Iframe from 'react-iframe'

import './WmaPrePostSurvey.css'

function WmaPostSurvey() {

  useEffect(() => {
    // Always starts from top
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="wmaPostSurvey">
      <Iframe src="https://docs.google.com/forms/d/e/1FAIpQLSceJF9XioXKeZUAc8gi89xj2Rwf1No5gEfrwVkj19D3hCg8PA/viewform?embedded=true" width="640" height="1170" frameBorder="0" marginheight="0" marginwidth="0">Loading…</Iframe>
    </div>
  )
}

export default WmaPostSurvey