import React from 'react'
import { useNavigate } from 'react-router-dom'
import './NoAccessScreen.css'

function NoAccessScreen() {
  const navigate = useNavigate()
  return (
    <div className='noAccessScreen'>
      <h1>No Access</h1>
      <p>You do not have access to this page.
        <br />Please register or login.</p>
      <br />
      <button className='ub_solidPurple' onClick={() => navigate('/login')}>Login / Register</button>
    </div>
  )
}

export default NoAccessScreen