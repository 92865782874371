import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './PelvicView.css'
import './TorsoView.css'

import pelvicBlockList from '../seed/pelvicBlockSeed'

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Dialog, DialogContent } from '@mui/material';

function PelvicView() {
  
  const pelvicBlockListRef = useRef(pelvicBlockList)
  // const { state } = useLocation();
  const location = useLocation();
  const locPath = location.pathname;
  const locNumCut = locPath.split('-')
  const locNum = locNumCut[locNumCut.length - 1] //current block number

  const currentBlockViews = pelvicBlockListRef.current[locNum * 1 - 1].usviews


  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showOneAlter, setShowOneAlter] = useState(false);
  const [showTwoAlter, setShowTwoAlter] = useState(false);
  const [showThreeAlter, setShowThreeAlter] = useState(false);
  const [showFourAlter, setShowFourAlter] = useState(false);

  const [showAlterView, setShowAlterView] = useState(false);
  const [zoomView, setZoomView] = useState(false);
  const openZoomView = (e) => {
    setZoomView(!zoomView)
  }

  const handleOne = (e) => {
    setShowOne(!showOne);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleOneAlter = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(!showOneAlter);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleTwo = (e) => {
    setShowOne(false);
    setShowTwo(!showTwo);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleTwoAlter = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(!showTwoAlter);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleThree = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(!showThree);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleThreeAlter = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(!showThreeAlter);
    setShowFourAlter(false);
  }
  const handleFour = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(!showFour);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(false);
  }
  const handleFourAlter = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(false);
    setShowFour(false);
    setShowOneAlter(false);
    setShowTwoAlter(false);
    setShowThreeAlter(false);
    setShowFourAlter(!showFourAlter);
  }
  

  return (
    <div className="pelvicView torsoViewComponent">
      { zoomView ?
        <Dialog
          open={zoomView}
          onClose={openZoomView}
          fullWidth="true"
          maxWidth="xl"
          className='zoomView__popupWrapper'
        >
          <DialogContent className="zoomView__popupContainer">
            <div className="zoomView__close smallText" onClick={openZoomView}>Close</div>
            {showOne ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showTwo ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[1][0])[0] ? Object.values(currentBlockViews[1][0])[0] : Object.values(currentBlockViews[1][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[1][0])[0] ? Object.values(currentBlockViews[1][0])[0] : Object.values(currentBlockViews[1][1])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showThree ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][0])[0] ? Object.values(currentBlockViews[2][0])[0] : Object.values(currentBlockViews[2][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[2][0])[0] ? Object.values(currentBlockViews[2][0])[0] : Object.values(currentBlockViews[2][1])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showFour ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[3][0])[0] ? Object.values(currentBlockViews[3][0])[0] : Object.values(currentBlockViews[3][1])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[3][0])[0] ? Object.values(currentBlockViews[3][0])[0] : Object.values(currentBlockViews[3][1])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showOneAlter ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][1])[0] ? Object.values(currentBlockViews[0][1])[0] : Object.values(currentBlockViews[0][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[0][1])[0] ? Object.values(currentBlockViews[0][1])[0] : Object.values(currentBlockViews[0][0])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showTwoAlter ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[1][1])[0] ? Object.values(currentBlockViews[1][1])[0] : Object.values(currentBlockViews[1][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[1][1])[0] ? Object.values(currentBlockViews[1][1])[0] : Object.values(currentBlockViews[1][0])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showThreeAlter ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][1])[0] ? Object.values(currentBlockViews[2][1])[0] : Object.values(currentBlockViews[2][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[2][1])[0] ? Object.values(currentBlockViews[2][1])[0] : Object.values(currentBlockViews[2][0])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}

            {showFourAlter ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${Object.values(currentBlockViews[3][1])[0] ? Object.values(currentBlockViews[3][1])[0] : Object.values(currentBlockViews[3][0])[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${Object.values(currentBlockViews[3][1])[0] ? Object.values(currentBlockViews[3][1])[0] : Object.values(currentBlockViews[3][0])[0]}`} type="video/mp4" />
                </video>
              </div>
            ) : ""}
          </DialogContent>
        </Dialog>

        : ""}

      <div className="torsoView__torsoWrapper">
        <div className="torsoView__torsoImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/TorsoFemale.jpg" alt="" />
        </div>
        <div className="torsoView__marks">
          {
            Object.values(currentBlockViews[0][0])[0] || Object.values(currentBlockViews[0][1])[0] ? ( 
              <div onClick={() => handleOne()} className={showOne || showOneAlter ? "torsoView__mark torsoView__markOnePelvic select" : "torsoView__mark torsoView__markOnePelvic"}>
              </div>
            ) : ("")
          }
          {
            Object.values(currentBlockViews[1][0])[0] || Object.values(currentBlockViews[1][1])[0] ? (
              <div onClick={() => handleTwo()} className={showTwo || showTwoAlter ? "torsoView__mark torsoView__markTwoPelvic select" : "torsoView__mark torsoView__markTwoPelvic"}>
              </div>
            ) :("")
          }
          
          {
            Object.values(currentBlockViews[2][0])[0] || Object.values(currentBlockViews[2][1])[0] ? (
              <div onClick={() => handleThree()} className={showThree || showThreeAlter ? "torsoView__mark torsoView__markThreePelvic select" : "torsoView__mark torsoView__markThreePelvic"}></div>
            ):(
              ""
            )
          }

          {
            Object.values(currentBlockViews[3][0])[0] || Object.values(currentBlockViews[3][1])[0] ? (
              <div onClick={() => handleFour()} className={showFour || showFourAlter ? "torsoView__mark torsoView__markFourPelvic select" : "torsoView__mark torsoView__markFourPelvic"}></div>
            ):(
              ""
            )
          }
          
          
          
          
          
        </div>
      </div>

      <div className="torsoView__usviewWrapper">
        {
          showOne || showTwo || showThree || showFour || showOneAlter || showTwoAlter ||  showThreeAlter || showFourAlter ?
          <div className="torsoView__usviewZoom"><ZoomInIcon onClick={openZoomView} /></div>
          :
          ""
        }

        {
          (showOne || showOneAlter) && (Object.values(currentBlockViews[0][0])[0]) && (Object.values(currentBlockViews[0][1])[0]) ? (
            <div className="torsoView__markAlt">
              <p onClick={() => handleOne()} className={showOne ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[0][0])[0]}
              </p>
              <p onClick={() => handleOneAlter()} className={showOneAlter ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[0][1])[0]}
              </p>
            </div>
          ) : ""
        }
        {
          (showTwo || showTwoAlter) && (Object.values(currentBlockViews[1][0])[0]) && (Object.values(currentBlockViews[1][1])[0]) ? (
            <div className="torsoView__markAlt">
              <p onClick={() => handleTwo()} className={showTwo ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[1][0])[0]}
              </p>
              <p onClick={() => handleTwoAlter()} className={showTwoAlter ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[1][1])[0]}
              </p>
            </div>
          ) : ""
        }
        {
          (showThree || showThreeAlter) && (Object.values(currentBlockViews[2][0])[0]) && (Object.values(currentBlockViews[2][1])[0]) ? (
            <div className="torsoView__markAlt">
              <p onClick={() => handleThree()} className={showThree ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[2][0])[0]}
              </p>
              <p onClick={() => handleThreeAlter()} className={showThreeAlter ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[2][1])[0]}
              </p>
            </div>
          ) : ""
        }
        {
          (showFour || showFourAlter) && (Object.values(currentBlockViews[3][0])[0]) && (Object.values(currentBlockViews[3][1])[0]) ? (
            <div className="torsoView__markAlt">
              <p onClick={() => handleFour()} className={showFour ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[3][0])[0]}
              </p>
              <p onClick={() => handleFourAlter()} className={showFourAlter ? "select smallText" : "smallText"}>{Object.keys(currentBlockViews[3][1])[0]}
              </p>
            </div>
          ) : ""
        }

        {showOne ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[0][0])[0] ? Object.values(currentBlockViews[0][0])[0] : Object.values(currentBlockViews[0][1])[0]}`} type="video/mp4" />
            </video>
          </div>
        ) : ""}

        {showTwo ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[1][0])[0] ? Object.values(currentBlockViews[1][0])[0] : Object.values(currentBlockViews[1][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[1][0])[0] ? Object.values(currentBlockViews[1][0])[0] : Object.values(currentBlockViews[1][1])[0]}`} type="video/mp4" />
            </video>
          </div>
        ) : ""}

        {showThree ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][0])[0] ? Object.values(currentBlockViews[2][0])[0] : Object.values(currentBlockViews[2][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[2][0])[0] ? Object.values(currentBlockViews[2][0])[0] : Object.values(currentBlockViews[2][1])[0]}`} type="video/mp4" />
            </video>
          </div>
        ) : ""}

        {showFour ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[3][0])[0] ? Object.values(currentBlockViews[3][0])[0] : Object.values(currentBlockViews[3][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[3][0])[0] ? Object.values(currentBlockViews[3][0])[0] : Object.values(currentBlockViews[3][1])[0]}`} type="video/mp4" />
            </video>
          </div>
        ) : ""}


        {showOneAlter ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[0][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[0][1])[0]}`} />
            </video>
          </div>
        ) : ""}

        {showTwoAlter ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[1][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[1][1])[0]}`} />
            </video>
          </div>
        ) : ""}

        {showThreeAlter ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[2][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[2][1])[0]}`} />
            </video>
          </div>
        ) : ""}

        {showFourAlter ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${Object.values(currentBlockViews[3][1])[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${Object.values(currentBlockViews[3][1])[0]}`} />
            </video>
          </div>
        ) : ""}

      </div>
    </div>
  )
}

export default PelvicView
