const aortaBlockList = [
  // Block 1
  {
    categoryId: 9,
    difficultyLevel: 'difficulty2',
    questionTags: ['aorta','Cardiac','aortic dissection','aortic root aneurysm'],
    authorInfo: {
      name: 'Carrie Walsh, MD', 
      institution: 'Massachusetts General Hospital, Boston, Massachusetts', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: 'https://twitter.com/carriedwalsh', 
      email: 'cdwalsh@bwh.harvard.edu', 
      phoneNumber: '', 
    },
    editorInfo: {
      name: 'Leland Perice, MD',
      institution: 'Maimonides Medical Center, Brooklyn, New York',
      linkedinUrl: 'https://www.linkedin.com/in/leland-perice-m-d-b6922032/',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/PericeLeland',
      email: 'lelandperice@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '55-year-old male, current smoker, with hypertension, but not compliant with medications, presents after waking from sleeping with acute onset upper back pain. Emergency medical services noted a blood pressure of 156/90 in the right arm and a blood pressure of 183/110 in the left arm. You perform a POCUS:',
    patientGender: 'male',
    usviews:  [
      [
        { 'Parasternal Long': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/1.mov'}, 
        { 'Freeze Image with measurement': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/2a.png'}
      ],
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/3.mov', 
      [
        { 'Subxiphoid view': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/4.mov'}, 
        { 'IVC': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/5.mov'}
      ],
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/6.mov', 
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/7.mov',
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block1/8.mov'
    ],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'Is there dilation of the aortic root?',
        options: 'No, Yes',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is an intimal flap visualized?',
        options: 'No, Yes',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there a pericardial effusion?',
        options: 'No, Yes',
        answer: 1,
        explain: ''
      },
    ],
    interpretation: [
      ['Findings that can indicate aortic dissection on POCUS include severe aortic root dilatation (when the aortic outflow track is > 4 cm), pericardial effusion, and visualization of an intimal aortic flap. Pericardial effusion can occur rapidly if the dissection involves the pericardium. POCUS can be an invaluable tool in assessing for pericardial effusion / tamponade, especially in cases of hypotension and syncope when aortic dissection into the pericardium is suspected.','BREAK','In this case, the aortic root is dilated, and an intimal flap is visualized in the longitudinal view as the probe is placed just caudal to the xiphoid bone and then again over the epigastric region. After the findings noted on POCUS, the patient was sent urgently to CT where a CTA chest and abdomen/pelvis demonstrated a dissection that involved both the ascending and descending aorta, with involvement of the carotids and extension caudally to the iliacs.','BREAK','Heart rate and blood pressure control is key in the management of aortic dissection until the patient can be taken to the OR. Recommended heart rate goal is < 60 bpm and SBP goal 100-120. An esmolol drip is the first agent of choice, used for its short half-life and easy titratability. Nicardipine is second line, with nitroprusside used as a third line agent.','BREAK','As a recent article noted in ',{text: 'ALiEM', hyperlink: 'https://www.aliem.com/blood-pressure-differences-patients-acute-aortic-dissections/'},', placing an A-line in the arm with higher blood pressure is key in the titration of antihypertensives because of pseudohypotension occurring in the limb with the dissected artery.','BREAK','References:','BREAK','ALiEM. 2022. Blood Pressure Differences in Patients with Acute Aortic Dissections. [online] Available at: <https://www.aliem.com/blood-pressure-differences-patients-acute-aortic-dissections/>'],
      ['Dilated aortic root. Intimal flap visualized in the abdominal aorta. No pericardial effusion seen.'],
      ['Abdominal Aortic dissection with a dilated aortic root raising concern for extension into the thoracic aorta.']
    ]
  },

  // Block 2
  {
    categoryId: 9,
    difficultyLevel: 'difficulty2',
    questionTags: ['aorta','Cardiac','aortic dissection','pericardial effusion','tamponade'],
    authorInfo: {
      name: 'Joann Hsu, MD', 
      institution: 'Maimonides Medical Center, Brooklyn, New York', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    additionalAuthorInfo: {
      name: 'Alex Khoury, MD',
      institution: 'Maimonides Medical Center,  Brooklyn,  New York', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    editorInfo: {
      name: 'Nhu-Nguyen Le, MD',
      institution: 'Riverside Community Hospital/University of California, Riverside',
      linkedinUrl: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: 'https://twitter.com/nhunguyenle',
      email: 'nhunguyenle1@gmail.com',
      phoneNumber: '',
    },
    lineEditorInfo: {
      name: '', 
      institution: '', 
      linkedinUrl: '', 
      facebookUrl: '', 
      instagramUrl: '', 
      twitterUrl: '', 
      email: '', 
      phoneNumber: '', 
    },
    props: '59 year old male with no known past medical history presents with acute onset chest pain. He was at home while watching a movie when this started. He is hemodynamically stable. You perform a POCUS:',
    patientGender: 'male',
    usviews:  [
      [
        { 'Parasternal Long': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block2/1.mp4'}, 
        { 'Parasternal Short': 'https://ultrasoundbox.s3.amazonaws.com/aorta/block2/2.mp4'}
      ],
      '', 
      [
        { 'Subxiphoid view': ''}, 
        { 'IVC': ''}
      ],
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block2/6.mp4', 
      'https://ultrasoundbox.s3.amazonaws.com/aorta/block2/7.mp4',
      ''
    ],
    questionInfo: [
      {
        qtype: 'reading',
        question: 'How is the systolic function of the left ventricle? ',
        options: 'Normal, Depressed',
        answer: 1,
        explain: ''
      },      {
        qtype: 'reading',
        question: 'Is there dilation of the aortic root?',
        options: 'No, Yes',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is an intimal flap visualized?',
        options: 'No, Yes',
        answer: 2,
        explain: ''
      },
      {
        qtype: 'reading',
        question: 'Is there a pericardial effusion?',
        options: 'No, Yes',
        answer: 2,
        explain: ''
      },
    ],
    interpretation: [
      ['There is a dissection flap consistent with an aortic dissection in both the thoracic and abdominal cavities. This is a surgical emergency, for which the patient was transferred for emergent operative management. Additionally, there is a moderate pericardial effusion with signs of impending tamponade. Make sure you check for these secondary signs of thoracic aortic dissection when you perform an ECHO:','BREAK','Pericardial effusion','BREAK','Widened thoracic aorta in parasternal long axis view','BREAK','Aortic root > 4cm','BREAK','Rule of thirds (RVOT, Aortic root, Left Atrium should have a 1:1:1 ratio)','BREAK','Aortic regurgitation','BREAK','You can also look at the suprasternal notch view for a dissection flap in the aortic arch', {text: 'as described here', hyperlink: 'https://123sonography.com/ebook/suprasternal-window'},'BREAK',{text: 'Here', hyperlink: 'https://www.pocus101.com/aorta-ultrasound-made-easy-step-by-step-guide/'}, ' is a helpful resource on performing thoracic and abdominal aorta US:','BREAK', {text: 'More on POCUS for dissection', hyperlink: 'http://www.emdocs.net/pocus-for-aortic-dissection-in-the-clinical-presentation-of-ischemic-stroke/'},],
      ['Parasternal long view of the heart: There is a moderate sized pericardial effusion with diastolic collapse of the right ventricle at the top of the screen raising concern for tamponade. The aortic root is grossly dilated (which is a disruption of the rule of thirds) with a visible intimal flap.','BREAK','Parasternal short axis view of the heart: There is a sliver of the pericardial effusion at the top of the screen. The right ventricle is collapsing, visible on top of the left ventricle.','BREAK','Abdominal aorta with a visible dissection flap.','BREAK','Longitudinal view of the abdominal aorta with the dissection flap again visualized.'],
      ['There is a moderate pericardial effusion with right ventricular diastolic collapse concerning for tamponade physiology. There is a dissection flap consistent with both a thoracic and abdominal aortic dissection.']
    ]
  },



]
module.exports = aortaBlockList