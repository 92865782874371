import { Accordion, AccordionDetails, AccordionSummary, Divider, ListItem, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './ContactScreen.css'
import { ExpandMoreOutlined } from '@mui/icons-material';

import ContactUs from '../ContactUs'

function Contact() {
  const navigate = useNavigate();
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <div className='contactScreen'>

      <div className="contactScreen__contact">
        <ContactUs />
      </div>
    </div>
  )
}

export default Contact
