import React, { useRef, useState, useEffect } from 'react'
import './GeneralBlockScreen.css'
import thoracicBlockList from '../seed/thoracicBlockSeed'
import { useNavigate, useLocation, Link } from 'react-router-dom'

import Button from '@mui/material/Button';
import ThoracicView from '../ultrasoundviews/ThoracicView'
import BannerTestAno from '../BannerTestAno'

import { Dialog, Fab } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AuthorPopup from '../AuthorPopup'
import EditorPopup from '../EditorPopup'
import LineEditorPopup from '../LineEditorPopup';
import CloseIcon from '@mui/icons-material/Close';
import { addDoc, collection } from 'firebase/firestore';
import db, { auth } from '../firebase';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import useTimer, {formatTime} from '../Timer';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';




function ThoracicBlockScreen(props) {
  const [showFloatView, setShowFloatView] = useState(false);
  const [showAboutAuthor, setShowAboutAuthor] = useState(false);
  const [showAboutAdditionalAuthor, setShowAboutAdditionalAuthor] = useState(false);
  const [showAboutEditor, setShowAboutEditor] = useState(false);
  const [showAboutLineEditor, setShowAboutLineEditor] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [score, setScore] = useState(0);


  const user = useSelector(selectUser)

  // For question destribute
  const thoracicBlockListRef = useRef(thoracicBlockList);
  // For take data collection
  const userId = useRef(null);
  const userEmail = useRef(null);
  const thoracicBlockId = useRef(null);
  const takeScore = useRef(null);
  const wrongQs = useRef(null);

  const { state } = useLocation();
  const navigate = useNavigate();


  // Find current block number in path
  const location = useLocation();
  const locPath = location.pathname;
  const locNumCut = locPath.split('-')
  const locNum = locNumCut[locNumCut.length - 1] //current block number

  const questionCollection = thoracicBlockListRef.current[locNum - 1 * 1].questionInfo
  const readingQuestions = thoracicBlockListRef.current[locNum - 1 * 1].questionInfo.filter((q) => q.qtype === 'reading')
  const generalQuestions = thoracicBlockListRef.current[locNum - 1 * 1].questionInfo.filter((q) => q.qtype === 'general')
  const interpretationArr = thoracicBlockListRef.current[locNum - 1 * 1].interpretation

  const interpretationAnswerArr = thoracicBlockListRef.current[locNum - 1 * 1].interpretation ? thoracicBlockListRef.current[locNum - 1 * 1].interpretation[0] : "";
  const interpretationInterpretationArr = thoracicBlockListRef.current[locNum - 1 * 1].interpretation ? thoracicBlockListRef.current[locNum - 1 * 1].interpretation[1] : "";
  const interpretationImpressionArr = thoracicBlockListRef.current[locNum - 1 * 1].interpretation ? thoracicBlockListRef.current[locNum - 1 * 1].interpretation[2] : "";

  const { timer, setTimer, isActive, isPaused, handleStart, handlePause, handleReset } = useTimer(0)
  
  // Start timer as user land on the page
  useEffect(() => {
    handleStart()
    // Always starts from top
    window.scrollTo(0, 0)
  }, []);
  


  // Click outside of author info box to close
  const ref = useRef()
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if(showAboutAuthor && ref.current && !ref.current.contains(e.target)){
        setShowAboutAuthor(false);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showAboutAuthor])


  // Pick options on each question
  let countChosen = document.querySelectorAll('input[type="radio"]:checked')
  const handleChanges = (e) => {
    // Collect number of chosen option and compare to the number of questions
    // When all questions has selected options, the submit button gets activated
    const questionLength = questionCollection.length;
    let countChosen = document.querySelectorAll('input[type="radio"]:checked')
    if(questionLength === countChosen.length){
      setIsSubmitActive(true)
    }
  }

  // 
  // 
  // Submit the answers
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResult(true);
    // Button deactivate after one submit
    setIsSubmitActive(false)
    // Scroll to the top after submit
    window.scrollTo({top:0, left:0, behavior:"smooth"})


    // Stop the test timer
    handlePause()

    
    // Selected options not changable after submit
    const allInputs = document.getElementsByTagName('input')
    for (let node of allInputs) { node.disabled = true; }

    // Collect all answers
    let countChosen = document.querySelectorAll('input[type="radio"]:checked')
    // Collect right and wrong
    const wrong = [];
    const correct = [];

    const allQs = document.querySelectorAll('#blockScreen_Q')
    for( let i = 0; i < questionCollection.length; i++ ){
      if(questionCollection[i].answer === Number(countChosen[i].value)){
        correct.push(Number(countChosen[i].value))
        // Style correct on the question
        allQs[i].className += ' correct'

      } else {
        wrong.push(i)
        // Style wrong on the question
        allQs[i].className += ' wrong'
        let optionsWithWrong = allQs[i].querySelectorAll('.blockScreen__optionWrapper')
        let eachOptions = optionsWithWrong[0].getElementsByTagName('div')
        eachOptions[`${questionCollection[i].answer - 1}`].className += 'correctAnswer'
      }
    }


    // Calculate the score
    const score = (correct.length / questionCollection.length).toFixed(2) * 100
    setScore(score);

    // Timestamp
    let currentTimestamp = Date.now()
    let submitTime = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(currentTimestamp)

    // Collect Take data
    const docRef = addDoc(collection(db, "takes"), {
      userId : `${user ? user.uid : "anonymusUserId"}`,
      userEmail: `${user ? user.email : "anonymusUserEmail"}`,
      thoracicBlockId: locNum,
      takeScore : score,
      wrongQs: wrong,
      takeTime: formatTime(timer),
      submitTime: submitTime,
    })
  }



  // 
  // 
  // Go to next block
  const toNextBlock = () => {
    window.scrollTo(0, 0)
    navigate(`/thoracic/block-${Number(locNum) + 1}`, { state: `${Number(locNum)}`})
    window.location.reload(true);
  }


  return (
    <div className="blockScreen thoracicBlockScreen">


      {
        showFloatView ? (
          <div className="blockScreen__floatViewWrapper">
            <div className="blockScreen__floatViewBackdrop" onClick={() => setShowFloatView(!showFloatView)}></div>
            <div className="floatView">
              <ThoracicView />
            </div>
            <div className="blockScreen__floatViewCloseWrapper">
              <div className="blockScreen__floatViewClose smallText" onClick={() => setShowFloatView(!showFloatView)}>Close</div>
            </div>
          </div>
        ) : ('')
      }

      {/* { auth.currentUser ? (
        <div className="blockScreen__blockTop">
        </div>
      ) : (
        <BannerTestAno />
      )} */}
      

      { showResult ? (
        <div className="blockScreen__score">
          <p>Your score is <span>{score}</span>% {(Number(locNum) + 1) > thoracicBlockList.length ? "" : <span className="ub_textWhite" onClick={() => toNextBlock()}>Take next block</span>}</p>
        </div>
      ) : (
        ""
      ) }

      
      {/* Question starts */}
      <div className="blockScreen__prop">
        {/* Breadcrumbs */}
        <p className="blockScreen__propBreadCrumb smallText"><span className="ub_textPurple" onClick={() => navigate('/')}>Thoracic</span> / Block {(locNum - 1 *1) + 1}</p>

        {/* Prop */}
        <p className="blockScreen__propQuestion">{thoracicBlockListRef.current[locNum - 1 * 1].props}</p>
        {/* Author */}
        {thoracicBlockListRef.current[locNum - 1 * 1].authorInfo.name ? (
          <p onClick={() => setShowAboutAuthor(!showAboutAuthor)} className="blockScreen__creditAuthor smallText">Author <span>{thoracicBlockListRef.current[locNum - 1 * 1].authorInfo.name}</span></p>
        ) : (
          <p onClick={() => setShowAboutAuthor(!showAboutAuthor)} className="blockScreen__creditAuthor smallText">Author <span>Leland Perice, MD</span></p>
        )}
        {/* Additional author */}
        {thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo && thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo.name ? (
          <p onClick={() => setShowAboutAdditionalAuthor(!showAboutAdditionalAuthor)} className="blockScreen__creditAuthor smallText">Author <span>{thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo.name}</span></p>
        ) : (
          null
        )}

        {/* Editor */}
        {thoracicBlockListRef.current[locNum - 1 * 1].editorInfo.name ? (
          <p onClick={() => setShowAboutEditor(!showAboutEditor)} className="blockScreen__creditAuthor smallText">Editor <span>{thoracicBlockListRef.current[locNum - 1 * 1].editorInfo.name}</span></p>
        ) : (
          null
        )}

        {/* Line Editor */}
        {thoracicBlockListRef.current[locNum - 1 * 1].lineEditorInfo.name ? (
          <p onClick={() => setShowAboutLineEditor(!showAboutLineEditor)} className="blockScreen__creditAuthor smallText">Line Editor <span>{thoracicBlockListRef.current[locNum - 1 * 1].lineEditorInfo.name}</span></p>
        ) : (
          null
        )}
      </div>

      
      {/* Author bubble */}
      {showAboutAuthor ? (
        <Dialog open={showAboutAuthor} onClose={() => setShowAboutAuthor(false)}>
          <AuthorPopup authorInfo={thoracicBlockListRef.current[locNum - 1 * 1].authorInfo} />
          <CloseIcon onClick={() => setShowAboutAuthor(false)} className="close__mobilePaddingTop" style={{zIndex: "999991", marginTop: "0.5em", position: "absolute", top: "0", right: "10"}} />
        </Dialog>
        
      ) : (
        ""
      )}
      {/* Additional author bubble */}
      {thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo && thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo.name && showAboutAdditionalAuthor ? (
        <Dialog open={showAboutAdditionalAuthor} onClose={() => setShowAboutAdditionalAuthor(false)}>
          <AuthorPopup authorInfo={thoracicBlockListRef.current[locNum - 1 * 1].additionalAuthorInfo} />
          <CloseIcon onClick={() => setShowAboutAdditionalAuthor(false)} className="close__mobilePaddingTop" style={{zIndex: "999991", marginTop: "0.5em", position: "absolute", top: "0", right: "10"}} />
        </Dialog>
        
      ) : (
        ""
      )}
      {showAboutEditor ? (
        <Dialog open={showAboutEditor} onClose={() => setShowAboutEditor(false)}>
          <EditorPopup editorInfo={thoracicBlockListRef.current[locNum - 1 * 1].editorInfo} />
          <CloseIcon onClick={() => setShowAboutEditor(false)} className="close__mobilePaddingTop" style={{zIndex: "999991", marginTop: "0.5em", position: "absolute", top: "0", right: "10"}} />
        </Dialog>
        
      ) : (
        ""
      )}
      {showAboutLineEditor ? (
        <Dialog open={showAboutLineEditor} onClose={() => setShowAboutLineEditor(false)}>
          <LineEditorPopup editorInfo={thoracicBlockListRef.current[locNum - 1 * 1].editorInfo} />
          <CloseIcon onClick={() => setShowAboutLineEditor(false)} className="close__mobilePaddingTop" style={{zIndex: "999991", marginTop: "0.5em", position: "absolute", top: "0", right: "10"}} />
        </Dialog>
        
      ) : (
        ""
      )}
      


      <ThoracicView />

      <form className="blockScreen__questionList" onSubmit={handleSubmit}>
        
          {/* Reading Qs */}
          { readingQuestions && readingQuestions.map((q, index) => {
            return (
              <div key={index + 1} id="blockScreen_Q" className="blockScreen__readingQ blockScreen_Q">
                <p>{index + 1}. {q.question}</p>
                { showResult ? (
                  <p className="hidden blockScreen__answer smallText">Answer: {q.answer}</p>
                ) : ("")}
                
                <div className="blockScreen__optionWrapper">
                  {q.options.split(', ').map((op, idx) => {
                    return (
                      <div key={idx + 1}>
                        <input type="radio" id={`${index + 1}-${idx + 1}`} name={index + 1} value={idx + 1} onClick={handleChanges} />
                        <label htmlFor={`${index + 1}-${idx + 1}`}>{op}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}

          { showResult && interpretationArr ? (
            <div className="blockScreen__readingExplain smallText">
              <div className="blockScreen__readingExplainWrapper">
                <div className="blockScreen__readingExplainItem">
                  <span>Answer</span>
                  {interpretationAnswerArr.map(arr => {
                    if(arr.text !== undefined){
                      return <a href={arr.hyperlink} target="_blank">{arr.text}</a>
                    } else if (arr === 'BREAK'){
                      return <p style={{display: "block"}}></p>
                    } else {
                      return <span>{arr}</span>
                    }
                  })}
                </div>
                <div className="blockScreen__readingExplainItem">
                  <span>Interpretation</span>
                  {interpretationInterpretationArr.map(arr => 
                    <p>{arr}</p>
                  )}
                </div>
                <div className="blockScreen__readingExplainItem">
                  <span>Impression</span>
                  {interpretationImpressionArr.map(arr => 
                    <p>{arr}</p>
                  )}
                </div>
              </div>
              
              <div className="blockScreen__readingExplainImg">
                <img src="https://ultrasoundbox.s3.amazonaws.com/img/ultrasoundbox-vert.png" alt="" />
              </div>
            </div>
          ) : (
            ""
          ) }

          {/* General Qs */}
          { generalQuestions && generalQuestions.map((q, index) => {
            return (
              <div key={index + 1 + readingQuestions.length} id="blockScreen_Q" className="blockScreen__generalQ blockScreen_Q">
                <p>{index + 1 + readingQuestions.length}. {q.question}</p>
                { showResult ? (
                  <p className="hidden blockScreen__answer smallText">The answer is {q.answer}</p>
                ) : ("")}

                <div className="blockScreen__optionWrapper">
                  {q.options.split(', ').map((op, idx) => {
                    return (
                      <div key={idx + 1}>
                        <input type="radio" id={`${index + 1 + readingQuestions.length}-${idx + 1}`} name={index + 1 + readingQuestions.length} value={idx + 1} onClick={handleChanges} />
                        <label htmlFor={`${index + 1 + readingQuestions.length}-${idx + 1}`}>{idx + 1}. {op}</label>
                      </div>
                    )
                  })}
                </div>

                { showResult ? (
                  <div className="blockScreen__generalQExplainWrapper">
                    <span>Explanation</span>
                    <p className="blockScreen__generalQExplain">{q.explain}</p>
                  </div>
                ): (
                  ""
                )}
              </div>
            )
          })}

          <div className="blockScreen__submitWrapper">
            { isSubmitActive ? 
              <button className="blockScreen__submit ub_solidPurple" type="submit" onClick={handleSubmit}>Check Answer</button>
            : 
              <button disabled className="blockScreen__submit ub_solidPurple" type="submit" onClick={handleSubmit}>Check Answer</button>
            }
            <a onClick={() => navigate('/')} className="blockScreen__goHome ub_textPurple" variant='text' href="/">Back to case selection</a>
          </div>
      </form>


      {/* Float buttons */}
      <div className="blockScreen__floatWrapper">
        {/* <Fab className="blockScreen__float blockScreen__floatTimer" area-label="" color="#efeeef" style={{color:"#999"}}>
          {formatTime(timer)}
        </Fab> */}
        <Fab onClick={() => setShowFloatView(!showFloatView)} className="blockScreen__float blockScreen__floatView" area-label="">
          View
        </Fab>
        {(Number(locNum) + 1) > thoracicBlockList.length ? 
          <Fab disabled className="blockScreen__float blockScreen__floatNext" area-label="">
            Next
          </Fab>
          : 
          <Fab onClick={() => toNextBlock()} className="blockScreen__float blockScreen__floatNext" area-label="">
            Next
          </Fab>
        }
        
      </div>


      {/* Bottom buttons on mobile */}
      <div className="blockScreen__bottomNavMobile">
        <div className="blockScreen__bottomMobileProgressWrapper">
          <div className="blockScreen__bottomMobileProgress" style={{width: `${(countChosen.length / questionCollection.length ) * 100}%`}}></div>
        </div>
        <div className="blockScreen__bottomMobileButtonWrapper smallText">
          <p className="blockScreen__bottomMobileButton" onClick={() => navigate('/')}><ArrowBackOutlinedIcon /><br />Back to<br />cases</p>
          {/* <hr /> */}
          <p className="blockScreen__bottomMobileButton" onClick={() => setShowFloatView(!showFloatView)}><VisibilityOutlinedIcon /><br />Quick<br />view</p>
          {/* <hr /> */}
          <p className={`blockScreen__bottomMobileButton ${isSubmitActive ? "" : "disabled"}`} onClick={handleSubmit}><RuleOutlinedIcon /><br />Check<br />answer</p>
          {/* <hr /> */}
            <p className={`blockScreen__bottomMobileButton ${(Number(locNum) + 1) > thoracicBlockList.length ? "disabled" : ""}`} onClick={() => toNextBlock()}><ArrowForwardOutlinedIcon /><br />Next<br />block</p>
          
        </div>
      </div>
      
      {/* { showFloatView ? (
        <div className="floatView">
          <ThoracicView />
        </div>
      ) : ("")} */}

      




      {/* Need Help? */}
      <div className="blockScreen__testHelp">
        {/* <div className="blockScreen__testHelpImg">
          <img src={searchIllustration} alt="" />
        </div> */}
        <div className="blockScreen__testHelpText">
          <h2>Need help?</h2>
          <p>Your learning experience is a top priority for us.<br />If you experience any issue or have a question, please let us know!</p>
          <button onClick={() => navigate('/contact')} className="ub_textPurple" variant='outlined'>Contact us</button>
        </div>
      </div>
    
    </div>
  )
}

export default ThoracicBlockScreen