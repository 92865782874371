import React, { useEffect, useRef, useState } from "react";
import VidProgressBar from "./VidProgressBar";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import susDB from "./SusmitDB";
import "./susmit.css";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";

function Susmit() {
  const vidSyncStandard = useRef();
  const vidSync1 = useRef();
  const vidSync2 = useRef();
  const progressBarRef = useRef();

  const [isVidSyncStandardPlay, setIsVidSyncStandardPlay] = useState(false);
  const [vidSyncBar, setVidSyncBar] = useState(0);

  const vidControlButton = document.querySelector(".videoControlBttn");

  // const susmitTestSeed = [
  //   {
  //     qId: 1,
  //     vignette:
  //       "An unidentified 42-year-old man is found in asystolic arrest and ROSC is obtained in the field by EMS. He is intubated, sedated, and started on targeted temperature modulation upon arrival at the hospital. EEG is connected and findings over the next 72 hours are shown below (four selected epochs are shown, they are not directly contiguous but represent the evolution of this patient’s EEG).",
  //     images: [],
  //     videos: [],
  //     qSets: [
  //       {
  //         qNum: 1,
  //         question: "Is this a normal or abnormal EEG?",
  //         choices: ["Normal", "Abnormal"],
  //         answers: ["Abnormal"],
  //       },
  //       {
  //         qNum: 2,
  //         question: "What is the montage?",
  //         choices: [
  //           "Longitudinal Bipolar",
  //           "Transverse Bipolar",
  //           "Circumferential Bipolar",
  //           "Average Reference",
  //           "Cz Reference",
  //           "Ispilateral Ear Reference",
  //           "Laplacian Reference",
  //         ],
  //         answers: ["Longitudinal Bipolar"],
  //       },
  //       {
  //         qNum: 3,
  //         question: "What is the background voltage?",
  //         choices: [
  //           "Suppressed (<10 microvolts)",
  //           "Low (>10 and <20 microvolts)",
  //           "Normal (20-150 microvolts)",
  //           "High (>150 microvolts)",
  //         ],
  //         answers: ["Suppressed (&lt;10 microvolts)"],
  //       },
  //       {
  //         qNum: 4,
  //         question: "Are the frequency and voltage symmetric?",
  //         choices: ["Symmetric", "Asymmetric"],
  //         answers: ["Symmetric"],
  //       },
  //       {
  //         qNum: 5,
  //         question: "How would you describe the background continuity?",
  //         choices: [
  //           "Continuous",
  //           "Nearly Continuous (&lt;10% suppressed)",
  //           "Discontinuous (10-49% suppressed)",
  //           "Burst-suppression (&gt;50% suppressed)",
  //           "Diffuse Suppression",
  //         ],
  //         answers: ["Burst-suppression (&gt;50% suppressed)"],
  //       },
  //       {
  //         qNum: 6,
  //         question: "Black oval – are these discharges epileptiform?",
  //         choices: ["Yes", "No"],
  //         answers: ["Yes"],
  //       },
  //       {
  //         qNum: 7,
  //         question:
  //           "Refer to the feature highlighted by the black oval – Select the features present here",
  //         choices: [
  //           "di- or tri-phasic waves with sharp or spiky morphology",
  //           "different wave duration than the ongoing background activity: either shorter or longer",
  //           "symmetry of the waveform",
  //           "the transient is followed by an associated slow after-wave",
  //           "Disruption of the background by the labeled feature",
  //         ],
  //         answers: [
  //           "di- or tri-phasic waves with sharp or spiky morphology",
  //           "different wave duration than the ongoing background activity: either shorter or longer",
  //           "symmetry of the waveform",
  //           "Disruption of the background by the labeled feature",
  //         ],
  //       },
  //     ],
  //     explanation: [
  //       {
  //         "Myoclonic status epilepticus":
  //           "This vignette demonstrates a patient with axial myoclonus correlated with EEG showing high‐amplitude polyspike bursts (“burst‐suppression with identical bursts”) occurring within 24h of anoxic brain injury. This is myoclonic status epilepticus (MSE), indicating diffuse and catastrophic brain injury and a poor prognosis. 1,2 In contrast, patients with Lance‐Adams syndrome are characterized by action myoclonus upon awakening from coma, with preserved cortical background activity on EEG, with or without narrow spike wave discharges. 1,3 Based on one large cohort, approximately half of these patients with pattern 2 ultimately regained consciousness from post-anoxic coma, though recovery was delayed by up to four weeks. 1,4",
  //       },
  //       {
  //         "Burst Suppression":
  //           "Burst suppression can be caused by anesthetic or sedative overdose, “cortical undercutting” (beyond the scope of this lesson), and, as with this case, anoxic injury. The length of anoxic period and the length of subsequent EEG flatness are correlated. The occurrence of epileptiform discharges also correlates with worse prognosis, which is why it is important to take into account both the nter-burst interval and the intra-burst content.",
  //       },
  //     ],
  //   },
  // ];
  function toggleVidPlay() {
    if (isVidSyncStandardPlay) {
      vidSyncStandard.current.pause();
      vidControlButton.textContent = "Resume videos";
      vidSync1.current.pause();
      vidSync2.current.pause();
      setIsVidSyncStandardPlay(false);
    } else {
      vidSyncStandard.current.play();
      vidControlButton.textContent = "Pause videos";
      vidSync1.current.play();
      vidSync2.current.play();
      setIsVidSyncStandardPlay(true);
    }
  }

  function currentSyncTime() {
    setVidSyncBar(
      (vidSyncStandard.current.currentTime / vidSyncStandard.current.duration) *
        100
    );
    console.log(
      "vidSyncStandardBar",
      vidSyncStandard.current.currentTime,
      vidSyncStandard.current.duration
    );
  }
  const handleProgressClick = (event) => {
    // get the full width of the progress bar
    const fullWidth = progressBarRef.current.offsetWidth;

    // calculate the x position of the click relative to the progress bar element
    const clickX =
      event.clientX - progressBarRef.current.getBoundingClientRect().left;

    // calculate the new time for the video
    const newTime = (clickX / fullWidth) * vidSyncStandard.current.duration;

    // update all videos to the new time
    vidSyncStandard.current.currentTime = newTime;
    vidSync1.current.currentTime = newTime;
    vidSync2.current.currentTime = newTime;
  };

  // useEffect(() => {
  //   const videoElement = vidSync1.current;

  //   const handleTimeUpdate = () => {
  //     currentSyncTime();
  //   };

  //   videoElement.addEventListener("timeupdate", handleTimeUpdate);

  //   // Return a cleanup function that gets called when the component is unmounted
  //   return () => {
  //     videoElement.removeEventListener("timeupdate", handleTimeUpdate);
  //   };
  // }, []);

  // Display alphabet instead of number on the answer options
  const optionDisplayConvert = {
    a: 0,
    b: 1,
    c: 2,
    d: 3,
    e: 4,
    f: 5,
    g: 6,
    h: 7,
    i: 8,
    j: 9,
    k: 10,
    l: 11,
    m: 12,
    n: 13,
    o: 14,
    p: 15,
    q: 16,
    r: 17,
    s: 18,
    t: 19,
    u: 20,
    v: 21,
    w: 22,
    x: 23,
    y: 24,
    z: 25,
  };

  // Question option selection collect
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleCheckboxChange = (event, questionNum) => {
    const input = event.target;

    setSelectedAnswers((prevAnswers) => {
      // Create a new object because state should be immutable
      let updatedAnswers = { ...prevAnswers };

      // If input type is a checkbox
      if (input.type === "checkbox") {
        // If this question hasn't been answered yet, add an empty Set
        if (!updatedAnswers[questionNum]) {
          updatedAnswers[questionNum] = new Set();
        }

        // If checkbox was already selected, remove it from the selected items; otherwise, add it
        input.checked
          ? updatedAnswers[questionNum].add(input.value)
          : updatedAnswers[questionNum].delete(input.value);

        // If input type is a radio button
      } else if (input.type === "radio") {
        // The value of radio button selection is directly assigned to the question number.
        updatedAnswers[questionNum] = input.checked ? input.value : "";
      }

      return updatedAnswers;
    });
  };

  console.log("selectedAnswers", selectedAnswers);
  // Check the answers
  const [howManyCorrect, setHowManyCorrect] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [isExplanationCollapsed, setIsExplanationCollapsed] = useState(false);

  const checkAnswers = () => {
    let newCorrectCount = 0;
    let newTotalSelected = 0;

    Object.keys(selectedAnswers).forEach((questionNum) => {
      const selectedAnswer = selectedAnswers[questionNum];
      const correctAnswers = susDB[0].questionList[questionNum - 1].answers;

      if (Array.isArray(selectedAnswer)) {
        // For multiple-choice questions (checkboxes)
        selectedAnswer.forEach((answer) => {
          if (correctAnswers.includes(parseInt(answer))) {
            newCorrectCount++;
          }
        });
        newTotalSelected += selectedAnswer.length;
      } else {
        // For single-choice questions (radio buttons)
        if (correctAnswers.includes(parseInt(selectedAnswer))) {
          newCorrectCount++;
        }
        newTotalSelected++;
      }
    });

    // Object.keys(selectedAnswers).forEach((key) => {
    //   Array.from(selectedAnswers[key]).forEach((answer) => {
    //     for (let i = 0; i < susDB[0].questionList.length; i++) {
    //       if (susDB[0].questionList[i].answers.includes(answer)) {
    //         newCorrectCount++;
    //       }
    //     }
    //   });
    //   newTotalSelected += selectedAnswers[key].size;
    // });

    // Disable all radio buttons
    let radios = document.querySelectorAll("input[type=radio]");
    radios.forEach((radio) => {
      radio.disabled = true;
    });

    // Disable all checkboxes
    let checkboxes = document.querySelectorAll("input[type=checkbox]");
    checkboxes.forEach((checkbox) => {
      checkbox.disabled = true;
    });

    window.scrollTo(0, 0);
    setHowManyCorrect(newCorrectCount);
    setTotalSelected(newTotalSelected);
    setAnswerSubmitted(true);
    setShowExplanationModal(true);
  };

  return (
    <div className="susmitTestPage">
      {/* Score */}
      {showExplanationModal ? (
        <div className="scoreWrapper">
          <p>{howManyCorrect} are correct</p>
        </div>
      ) : null}

      {/* questions */}
      <div className="questionSectionWrapper">
        <h2 className="questionVignette">
          {susDB[0].questionSetId}. {susDB[0].vignette}
        </h2>

        {/* Video needs more touch */}
        {Object.keys(susDB[0].video).length > 0 ? (
          <div className="">
            <div className="videoWrapper">
              <div className="sus-videoSize hiddenVid">
                <video
                  playsInline
                  muted
                  loop
                  controlsList="nodownload"
                  className="vidSyncStandard"
                  ref={vidSyncStandard}
                >
                  <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" />
                </video>
              </div>

              <div className="sus-videoSize">
                <video
                  // controls
                  playsInline
                  muted
                  loop
                  controlsList="nodownload"
                  className="vidOne"
                  // onTimeUpdate={updateTimeline}
                  ref={vidSync1}
                  // onClick={() => toggleVidPlay(vidSync)}
                >
                  <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" />
                </video>
              </div>

              <div className="sus-videoSize">
                <video
                  // controls
                  playsInline
                  muted
                  loop
                  controlsList="nodownload"
                  className="vidTwo"
                  ref={vidSync2}
                >
                  <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4" />
                </video>
              </div>
            </div>
            <VidProgressBar
              done={vidSyncBar}
              progressBarRef={progressBarRef}
              onProgressBarClick={handleProgressClick}
            />

            <div className="videoControl">
              <div
                className={`videoControlBttn ${
                  isVidSyncStandardPlay
                    ? "btn-color-playing "
                    : "btn-color-paused"
                }`}
                onClick={toggleVidPlay}
              >
                Play videos
              </div>
            </div>
          </div>
        ) : null}

        {/* Figures */}
        {Object.keys(susDB[0].figures).length > 0 ? (
          <div className="">
            <Carousel
              showArrows={true}
              emulateTouch={true}
              showIndicators={false}
            >
              {susDB[0].figures.map((figure, idx) => (
                <div key={`figure ${idx}`}>
                  <img src={figure.url} />
                  {/* <p className="">{figure.caption}</p> */}
                </div>
              ))}
            </Carousel>
          </div>
        ) : null}

        <p>Check answer(s) per question</p>
        <div className="questionContentWrapper">
          {susDB[0].questionList.map((q, index) => {
            let questionNum = q.questionId;
            return (
              <div className="eachQuestion" key={`qNum-${index}`}>
                <div>
                  <p className="eachQuestionTitle">
                    {index + 1}. {q.question}
                  </p>
                  {showExplanationModal ? (
                    <p className="eachQuesitonAnswer">
                      Answer(s):{" "}
                      {q.answers.map((answer, index) => (
                        <span>
                          {answer}
                          {q.answers.length > index + 1 ? ", " : ""}
                        </span>
                      ))}
                    </p>
                  ) : null}

                  {Object.keys(q.answers).length > 1
                    ? Object.keys(q.choices).map((cKey, idxAlph) => {
                        const alphabetIndex = String.fromCharCode(97 + idxAlph);
                        const isCorrectAnswer = q.answers.includes(
                          parseInt(cKey)
                        );
                        return (
                          <div
                            className="questionChoice"
                            key={`multipleAnswer-${cKey}`}
                          >
                            {showExplanationModal ? (
                              <label
                                className={`choiceContainer${
                                  isCorrectAnswer
                                    ? " marker-correctAnswer"
                                    : " marker-wrongAnswer"
                                }`}
                              >
                                {alphabetIndex}. {q.choices[cKey]}
                                <input
                                  type="checkbox"
                                  id={cKey}
                                  name={q.question}
                                  value={cKey}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, questionNum)
                                  }
                                />
                                <span className="customCheckmark"></span>
                              </label>
                            ) : (
                              <label className="choiceContainer">
                                {alphabetIndex}. {q.choices[cKey]}
                                <input
                                  type="checkbox"
                                  id={cKey}
                                  name={q.question}
                                  value={cKey}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, questionNum)
                                  }
                                />
                                <span className="customCheckmark"></span>
                              </label>
                            )}
                          </div>
                        );
                      })
                    : Object.keys(q.choices).map((cKey, idxAlph) => {
                        const alphabetIndex = String.fromCharCode(97 + idxAlph);
                        const isCorrectAnswer = q.answers.includes(
                          parseInt(cKey)
                        );
                        return (
                          <div
                            className="questionChoice"
                            key={`singleAnswer-${cKey}`}
                          >
                            {showExplanationModal ? (
                              <label
                                className={`choiceContainer${
                                  isCorrectAnswer
                                    ? " marker-correctAnswer"
                                    : " marker-wrongAnswer"
                                }`}
                              >
                                {alphabetIndex}. {q.choices[cKey]}
                                <input
                                  type="radio"
                                  id={cKey}
                                  name={q.question}
                                  value={cKey}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, questionNum)
                                  }
                                />
                                <span className="customCheckmark"></span>
                              </label>
                            ) : (
                              <label className="choiceContainer">
                                {alphabetIndex}. {q.choices[cKey]}
                                <input
                                  type="radio"
                                  id={cKey}
                                  name={q.question}
                                  value={cKey}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, questionNum)
                                  }
                                />
                                <span className="customCheckmark"></span>
                              </label>
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <div
            disabled={answerSubmitted ? true : false}
            className={`${
              answerSubmitted ? "videoControlBttnDisabled" : ""
            } videoControlBttn checkAnswerButtn`}
            onClick={checkAnswers}
          >
            <p>Check answers</p>
          </div>
          {/* <button>Check answers</button> */}
          {/* <p>
            Number of selected answers: {totalSelected}
            <br />
            Number of correctly selected answers: {howManyCorrect}
          </p> */}
        </div>

        {/* <div>
          <p>Selected checkboxes: </p>
          {Object.keys(selectedAnswers).length > 0
            ? Object.keys(selectedAnswers).map((key) => {
                // If the value at this key is a Set (i.e., from checkboxes)
                if (selectedAnswers[key] instanceof Set) {
                  return (
                    <div>
                      <p>""""""""{key}</p>
                      {Array.from(selectedAnswers[key]).map((answer) => (
                        <p>{answer}</p>
                      ))}
                    </div>
                  );
                  // If the value at this key is a string (i.e., from radio buttons)
                } else if (typeof selectedAnswers[key] === "string") {
                  return (
                    <div>
                      <p>""""""""{key}</p>
                      <p>{selectedAnswers[key]}</p>
                    </div>
                  );
                }
              })
            : null}
        </div> */}

        {/* Explanation popup */}
        {showExplanationModal ? (
          <div className="explanationBackground">
            <div className="explanationModal">
              <div className="explanationModalContentWrapper">
                {/* Headline */}
                <div
                  className={
                    isExplanationCollapsed
                      ? "explanationModalTitleCollapsed"
                      : "explanationModalTitleOpened"
                  }
                >
                  <h2>Concepts</h2>
                  <p
                    className="explanationModalCloseBtn"
                    onClick={() =>
                      setIsExplanationCollapsed(!isExplanationCollapsed)
                    }
                  >
                    {isExplanationCollapsed ? "Show" : "Hide"}
                  </p>
                </div>
                {/* Content */}
                {isExplanationCollapsed ? null : (
                  <div className="explanationContent">
                    {susDB[0].concepts.map((exp) => {
                      return (
                        <div className="explanationSectionWrapper">
                          <p className="explanationSectionTitle">{exp.title}</p>
                          {exp.content.map((paragraph) =>
                            Array.isArray(paragraph) ? (
                              <div>
                                <ol>
                                  {paragraph.map((item) => (
                                    <li>{item}</li>
                                  ))}
                                </ol>
                              </div>
                            ) : (
                              <p>{paragraph}</p>
                            )
                          )}
                        </div>
                      );
                    })}
                    {susDB[0].conceptImgs.length > 0
                      ? susDB[0].conceptImgs.map((img) => (
                          <div className="explanationImg">
                            {/* <p>{img.caption}</p> */}
                            <img src={img.url} />
                          </div>
                        ))
                      : null}
                    {susDB[0].references.length > 0 ? (
                      <div className="explanationSectionWrapper">
                        <p className="explanationSectionTitle">References</p>
                        <ol>
                          {susDB[0].references.map((ref, idx) => (
                            <li className="smallText">{ref}</li>
                          ))}
                        </ol>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Susmit;
