import React, { useEffect, useState } from "react";
import db from "../firebase";

import { CSVLink } from "react-csv";

import "./FemTestData.css";

function FemTestData() {
  const [isLoading, setIsLoading] = useState(true);
  const [allFemUserTestData, setAllFemUserTestData] = useState([]);
  const [maxDataLength, setMaxDataLength] = useState();
  const [dataToExportCSV, setDataToExportCSV] = useState([]);

  useEffect(() => {
    const fetchSurveyData = async (e) => {
      // Collect all user info
      let longest = 0;
      const allFemUsers = [];
      await db
        .collection("femusers")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (femuserDoc) => {
            if (femuserDoc.data().pastQuestionSet !== null) {
              allFemUsers.push({
                username: femuserDoc.data().username,
                // preSurvey: femuserDoc.data().preSurveyDone,
                pastQuestionSet: femuserDoc.data().pastQuestionSet,
                currentQ: femuserDoc.data().currentQ,
                totalScore: femuserDoc.data().totalScore,
              });
            }
            if (
              femuserDoc.data().pastQuestionSet !== null &&
              femuserDoc.data().pastQuestionSet.length > longest
            ) {
              longest = femuserDoc.data().pastQuestionSet.length;
            }
          });

          // local state update
          setAllFemUserTestData(allFemUsers);
          setMaxDataLength(longest);
          setIsLoading(false);
          setDataToExportCSV();
        });
    };
    fetchSurveyData();
  });

  return (
    <div className="femTestDataWrapper">
      <h1>FEM test data</h1>
      {/* {isLoading ? 
      null:
      (
        <CSVLink
          data={dataToExportCSV}
          filename={"sfaAdmin-test.csv"}
          target="_blank"
        >
          Download Excel
        </CSVLink>
      )} */}
      <div className="femTestDataTableWrapper">
        <div className="smallText breakWord">
          <div className="columnFreeze">
            <p>User name</p>
          </div>
          <div>
            <p>Total number of questions done</p>
          </div>
          <div>
            <p>Total score</p>
          </div>
          {isLoading
            ? null
            : Array(maxDataLength)
                .fill(null)
                .map((_, index) => (
                  <>
                    <div>
                      <p>{index + 1}/ LUQ</p>
                    </div>
                    <div>
                      <p>{index + 1}/ RUQ</p>
                    </div>
                    <div>
                      <p>{index + 1}/ Pelvic</p>
                    </div>
                  </>
                ))}
        </div>
        {isLoading ? (
          <div className="loadingWrapper">
            <p>Loading...</p>
          </div>
        ) : (
          allFemUserTestData.map((data, index) => {
            return (
              <div className="breakAll">
                <div className="columnFreeze">
                  <p>{data.username}</p>
                </div>
                <div style={{ backgroundColor: "rgba(238, 238, 238, 0.5)" }}>
                  <p>{Number(data.currentQ) - 1}</p>
                </div>
                <div>
                  <p>{data.totalScore}</p>
                </div>
                {data.pastQuestionSet.map((question, index) => {
                  return (
                    <>
                      <div
                        style={
                          index % 2 === 0
                            ? { backgroundColor: "rgba(238, 238, 238, 0.5)" }
                            : null
                        }
                      >
                        <p>
                          {question.resultSet.luq.isCorrect === true
                            ? "Correct"
                            : "Wrong"}
                        </p>
                      </div>
                      <div
                        style={
                          index % 2 === 0
                            ? { backgroundColor: "rgba(238, 238, 238, 0.5)" }
                            : null
                        }
                      >
                        <p>
                          {question.resultSet.ruq.isCorrect === true
                            ? "Correct"
                            : "Wrong"}
                        </p>
                      </div>
                      <div
                        style={
                          index % 2 === 0
                            ? { backgroundColor: "rgba(238, 238, 238, 0.5)" }
                            : null
                        }
                      >
                        <p>
                          {question.resultSet.pelvic.isCorrect === true
                            ? "Correct"
                            : "Wrong"}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default FemTestData;
