import React, { useState } from 'react'

function SfaImpressionDB(prop) {

  const [ impressionContent, setImpressionContent ] = useState(prop.impression)
  return (
    <div>
      { impressionContent === 1 ? 
      <div>
        <p>
        This is a heart with severely reduced systolic function of the left ventricle. </p>
      </div>
      :null}
      { impressionContent === 2 ? 
      <div>
        <p>This is a heart with a moderately reduced systolic function of the left ventricle.</p>
      </div>
      :null}
      { impressionContent === 3 ? 
      <div>
        <p>This is a heart with a normal left ventricular systolic function of the left ventricle.</p>
      </div>
      :null}
      { impressionContent === 4 ? 
      <div>
        <p>This is a heart with increased systolic function of the left ventricle, otherwise known as hyperdynamic ejection fraction. </p>
      </div>
      :null}
    </div>
  )
}

export default SfaImpressionDB