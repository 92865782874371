import React, { useState } from "react";
import {
  doc,
  addDoc,
  collection,
  updateDoc,
  setDoc,
  QuerySnapshot,
} from "firebase/firestore";
import db from "../firebase";

import "./surveyStyle.css";

const FemPostSurvey = (prop) => {
  const [helpful, setHelpful] = useState("");
  const [comfortable, setComfortable] = useState("");
  const [likelyPerform, setLikelyPerform] = useState("");
  const [likelyRecommend, setLikelyRecommend] = useState("");
  const [otherFeedback, setOtherFeedback] = useState("");

  const [isAllAnswered, setIsAllAnswered] = useState(false);

  let currentTimestamp = Date.now();
  let date = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(currentTimestamp);

  const handleSubmit = (event) => {
    event.preventDefault();
    // update master local status
    prop.updatePostSurveyProgress(date);
    // update DB
    const allFemUsers = [];
    db.collection("femusers")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (femuserDoc) => {
          allFemUsers.push(femuserDoc.data());
        });
        const matchedUser = allFemUsers.find(
          (user) => user.username === prop.accountInfo.username
        );
        if (matchedUser) {
          // update firebase db
          const updateFB = async () => {
            await updateDoc(doc(db, "femusers", matchedUser.username), {
              // lastLogin: date,
              postSurveyDone: {
                helpful,
                comfortable,
                likelyPerform,
                likelyRecommend,
                otherFeedback,
                date,
              },
              // loginHistory: [...matchedUser.loginHistory, date]
            });
          };
          updateFB();
        } else {
          window.alert("error");
        }
      });
  };
  // Implement form submission here, for example send data to a server
  console.log({
    helpful,
    comfortable,
    likelyPerform,
    likelyRecommend,
    otherFeedback,
    date,
  });

  return (
    <div>
      <h1>Post-Test Questionnaire</h1>
      <form>
        <div className="surveySection">
          <legend>
            On a scale from 1 = not at all helpful to 10 = extremely helpful,
            how helpful was this module for improving your skill level of
            interpreting FAST exams on point of care ultrasound (POCUS).
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="helpful"
              name="helpful"
              value={helpful}
              className="dropdownSelect"
              onChange={(e) => setHelpful(e.target.value)}
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            On a scale from 1 = not at all comfortable to 10 = extremely
            comfortable how comfortable are you performing a FAST to determine
            whether or not there is intraperitoneal free fluid after completing
            this module:
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="comfortable"
              name="comfortable"
              value={comfortable}
              className="dropdownSelect"
              onChange={(e) => setComfortable(e.target.value)}
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            On a scale from 1 = very likely to 10 = very unlikely, how likely
            are you to perform POCUS to assess for intraperitoneal free fluid
            after completing this module:
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="likelyPerform"
              name="likelyPerform"
              value={likelyPerform}
              className="dropdownSelect"
              onChange={(e) => setLikelyPerform(e.target.value)}
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            On a scale from 1 = very likely to 10 = very unlikely, how likely
            are you to recommend this learning module to colleagues as an
            effective training tool for learning how to interpret FAST exams.
          </legend>
          <div className="dropdownSelectWrapper">
            <select
              id="likelyRecommend"
              name="likelyRecommend"
              value={likelyRecommend}
              className="dropdownSelect"
              onChange={(e) => setLikelyRecommend(e.target.value)}
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="surveySection">
          <legend>
            Please let us know any other feedback, concerns, comments about this
            module
          </legend>

          {/* <label className="smallText" htmlFor="email">
            Email<span className="markRequired">Required</span>
          </label> */}
          <br />
          <textarea
            name="otherFeedback"
            rows="5"
            onChange={(e) => setOtherFeedback(e.target.value)}
          ></textarea>
          {/* <input
            type="email"
            id="otherFeedback"
            name="otherFeedback"
            value={otherFeedback}
            onChange={(e) => setOtherFeedback(e.target.value)}
            required
          /> */}
        </div>

        <div className="buttonWrapper">
          {helpful !== "" && comfortable !== "" && likelyPerform !== "" ? (
            <button className="ub_solidPurple" onClick={handleSubmit}>
              Submit
            </button>
          ) : (
            <button className="ub_solidPurple" disabled onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FemPostSurvey;
