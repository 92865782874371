import React from 'react'
import Iframe from 'react-iframe'

import './WmaPrePostSurvey.css'

function WmaPreSurvey() {
  return (
    <div className="wmaPreSurvey">
      <Iframe src="https://docs.google.com/forms/d/e/1FAIpQLScewIwS6xxpSyVBhy1MZwBPHRiGz9bwfOBUTkUOd5HzR1_GvQ/viewform?embedded=true" width="640" height="1891" frameborder="0" marginheight="0" marginwidth="0">Loading…</Iframe>
      
    </div>
  )
}

export default WmaPreSurvey