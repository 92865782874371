import React from "react";

import "./VidProgressBar.css";

function VidProgressBar({ done, progressBarRef, onProgressBarClick }) {
  return (
    <div
      className="vidProgressBar"
      ref={progressBarRef}
      onClick={onProgressBarClick}
    >
      <div className="vidProgressBarDone" style={{ width: `${done}%` }}></div>
    </div>
  );
}

export default VidProgressBar;
