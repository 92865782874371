import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import FadeIn from 'react-fade-in';


import { useNavigate } from 'react-router-dom'
import './LandingScreen.css'


function LandingScreen() {
  const navigate = useNavigate()

  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  
  return (
    <div className='landingScreen'>
      <FadeIn>
      <div className="landingScreen__hero">
        <FadeIn>
          <h1 className="titleFont">The Future<br />of Ultrasound<br />Education</h1>
          <div className="landingScreen__heroCta">
            <button className='ub_solidWhite' onClick={() => navigate('/')}>Get Started</button>
          </div>
        </FadeIn>
      </div>
      </FadeIn>
      

      <FadeIn visible>
      <div className="landingScreen__section landingScreen__section-about">
        <div className="landingScreen__section-aboutItemWrapper">
          <div className="landingScreen__section-aboutImage">
            <img src="https://ultrasoundbox.s3.amazonaws.com/img/testOnDevices.png" alt="" />
          </div>
          <div className="landingScreen__section-aboutItem">
            <h2>A powerful tool that allows the user to critically interpret ultrasound images on their own time.</h2>
            <p><br />Ultrasound box was designed to allow learners the ability ro practice interpreting and making decisions based off of ultrasound images on their own time.</p>
          </div>
        </div>
      </div>
      </FadeIn>


      

      <div className="landingScreen__section landingScreen__sectionBullet">
        <div className="landingScreen__sectionBulletPoint">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/img1.png" alt="" />
          <h2>For everyone</h2>
          <p>Our content is free to access and use as you see fit.</p>
        </div>
        <div className="landingScreen__sectionBulletPoint">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/img2.png" alt="" />
          <h2>Mobile friendly</h2>
          <p>Use the application seamlessly on the go.</p>
        </div>
        <div className="landingScreen__sectionBulletPoint">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/img3.png" alt="" />
          <h2>Take part</h2>
          <p>Contribute your own case and get publishing credit!</p>
        </div>
      </div>

      <div className="landingScreen__section landingScreen__section-joinus">
        <div className="landingScreen__section-joinusImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/graphUpColor.png" alt="" />
        </div>
        <div className="landingScreen__section-joinusWrapper">
          <h2>Track your scores</h2>
          <p>Create a free account to get access to features such as recording your test history and data analysis of your strong and weak points.</p>
          <button className="ub_solidWhite" onClick={() => navigate('/login')}>Sign up for free</button>
        </div>
        
      </div>
      
    </div>
  )
}

export default LandingScreen
