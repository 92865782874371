import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import './BiliaryFemaleView.css'
import './TorsoView.css'

import biliaryBlockList from '../seed/biliaryBlockSeed'

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Dialog, DialogContent } from '@mui/material';

function BiliaryView() {
  
  const biliaryBlockListRef = useRef(biliaryBlockList)
  // const { state } = useLocation();
  const location = useLocation();
  const locPath = location.pathname;
  const locNumCut = locPath.split('-')
  const locNum = locNumCut[locNumCut.length - 1] //current block number

  const currentBlockViews = biliaryBlockListRef.current[locNum * 1 - 1].usviews


  const [showOne, setShowOne] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);

  const [zoomView, setZoomView] = useState(false);
  const openZoomView = (e) => {
    setZoomView(!zoomView)
  }



  const handleOne = (e) => {
    setShowOne(!showOne);
    setShowTwo(false);
    setShowThree(false);
  }
  const handleTwo = (e) => {
    setShowOne(false);
    setShowTwo(!showTwo);
    setShowThree(false);
  }
  const handleThree = (e) => {
    setShowOne(false);
    setShowTwo(false);
    setShowThree(!showThree);
  }

  return (
    <div className="biliaryView torsoViewComponent">
      { zoomView ?
        <Dialog
          open={zoomView}
          onClose={openZoomView}
          fullWidth="true"
          maxWidth="xl"
          className='zoomView__popupWrapper'
        >
          <DialogContent className="zoomView__popupContainer">
            <div className="zoomView__close smallText" onClick={openZoomView}>Close</div>
            {showOne ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[0]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[0]}`} />
                </video>
              </div>
            ) : ""}

            {showTwo ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[1]}`} />
                </video>
              </div>
            ) : ""}

            {showThree ? (
              <div className="zoomView__popup" style={{backgroundImage : `url(${currentBlockViews[2]})`}}>
                <video playsInline autoPlay muted loop>
                  <source src={`${currentBlockViews[2]}`} />
                </video>
              </div>
            ) : ""}
          </DialogContent>
        </Dialog>

        : ""}


      <div className="torsoView__torsoWrapper">
        <div className="torsoView__torsoImg">
          <img src="https://ultrasoundbox.s3.amazonaws.com/img/TorsoFemaleRight.jpg" alt="" />
        </div>
        <div className="torsoView__marks">
          { currentBlockViews[0] ? (
              <div onClick={() => handleOne()} className={showOne ? "torsoView__mark torsoView__markOneBiliary select" : "torsoView__mark torsoView__markOneBiliary"}>
              </div>
            ) : ("")
          }
          { currentBlockViews[1] ? (
            <div onClick={() => handleTwo()} className={showTwo ? "torsoView__mark torsoView__markTwoBiliary select" : "torsoView__mark torsoView__markTwoBiliary"}>
            </div>
          ) : ("")}
          {
            currentBlockViews[2] || currentBlockViews[7] ? (
              <div onClick={() => handleThree()} className={showThree ? "torsoView__mark torsoView__markThreeBiliary select" : "torsoView__mark torsoView__markThreeBiliary"}>
              </div>
            ) : ("")
          }
          
          
        </div>
      </div>

      <div className="torsoView__usviewWrapper">
        {
          showOne || showTwo ||showThree ?
          <div className="torsoView__usviewZoom"><ZoomInIcon onClick={openZoomView} /></div>
          :
          ""
        }

        {showOne ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[0]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[0]}`} />
            </video>
          </div>
        ) : ""}

        {showTwo ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[1]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[1]}`} />
            </video>
          </div>
        ) : ""}

        {showThree ? (
          <div className="torsoView__usview" style={{backgroundImage : `url(${currentBlockViews[2]})`}}>
            <video playsInline autoPlay muted loop>
              <source src={`${currentBlockViews[2]}`} />
            </video>
          </div>
        ) : ""}
      </div>
    </div>
  )
}

export default BiliaryView
