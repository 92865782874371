import React, { useEffect, useRef, useState } from 'react'
import db from '../firebase';
import { doc, updateDoc } from "firebase/firestore"; 
import './WmaLandingScreen.css'
import wmaDB from './wmaSeed'
import wmaQuestionSets from './wmaQuestionSets'
import WmaPreSurvey from './WmaPreSurvey';
import WmaPostSurvey from './WmaPostSurvey';
import WmaQuestions from './WmaQuestions';
import WmaImageComponent from './WmaImageComponent';

import useTimer, {formatTime} from '../Timer';

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined';
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';


function WmaLandingScreen() {
  // if the input format isn't email
  const [ showEmailErrorAlert, setShowEmailErrorAlert ] = useState(false);
  const openEmailErrorAlert = () => {
    setShowEmailErrorAlert(true)
  }
  const closeEmailErrorAlert = () => {
    setShowEmailErrorAlert(false)
    wmaEmailRef.current.value = ''
  }

  // Alert saying users cannot go backwards
  const [ showFirstQuestionAlert, setShowFirstQuestionAlert ] = useState(false);
  const openFirstQalert = () => {
    setShowFirstQuestionAlert(true)
  }
  const closeFirstQalert = () => {
    setShowFirstQuestionAlert(false);
  }

  // Timer
  const { timer, setTimer, isActive, isPaused, handleStart, handlePause, handleReset } = useTimer(0)
  

  const [ allStudyUserList, setAllStudyUserList ] = useState();
  const [ currentStudyUserId, setCurrentStudyUserId ] = useState();
  const [ currentStudyUser, setCurrentStudyUser ] = useState();
  const [ pastUserAnswerCollection, setPastUserAnswerCollection ] = useState()

  const [ currentUserStep, setCurrentUserStep] = useState();
  const [ userRemainedQuestions, setUserRemainedQuestions] = useState();
  const [ currentQuestionUserChoices, setCurrentQuestionUserChoices] = useState()
  const [ isImgZoomed, setIsImgZoomed ] = useState(false);  

  const [ isImgLoading, setIsImgLoading ] = useState(false);
  const [ isUserAnswerCorrect, setIsUserAnswerCorrect ] = useState();

  const [ stepOne, setStepOne ] = useState(true);
  const [ stepTwo, setStepTwo ] = useState(false);
  const [ stepThree, setStepThree ] = useState(false);
  const [ stepFour, setStepFour ] = useState(false);

  const [ nextButtonActive, setNextButtonActive] = useState(false);  
  const [ showAnswer, setShowAnswer ] = useState(false)
  const [ showAnswerToggleOn, setShowAnswerToggleOn ] = useState(false)
  const [ isCompleted, setIsCompleted] = useState(false);  
  const wmaEmailRef = useRef(null)
  const wmaDataRef = useRef(wmaDB)
  const currentDisplayImgRef = useRef(userRemainedQuestions && userRemainedQuestions.length >= 1 ? userRemainedQuestions[0].usView : null)
  const currentDisplayAnswerImgRef = useRef(userRemainedQuestions && userRemainedQuestions.length >= 1 ? userRemainedQuestions[0].answerUsView : null)


  // Question choice sets
  const [ parasternalShort, setParasternalShort] = useState(wmaQuestionSets[0].choices)
  const [ parasternalLong, setParasternalLong] = useState(wmaQuestionSets[1].choices)
  const [ apical4, setApical4] = useState(wmaQuestionSets[2].choices)

  useEffect(() => {
    // Always starts from top
    window.scrollTo(0, 0)

    db.collection('wma')
    .get().then(querySnapshot => {
      const allStudyUsers = [];
      querySnapshot.forEach(async wmaDoc => {
        allStudyUsers.push({[wmaDoc.id]: wmaDoc.data()})
      });
      setAllStudyUserList(allStudyUsers)
    })
  }, [])


  const emailSubmitInputRef = useRef(null)
  const userIdInputRef = useRef(null)

  const wmaScrollToEmailInput = () => {
    emailSubmitInputRef.current.scrollIntoView({ behavior: "smooth"})
  }
  const screenTopRef = useRef(null)
  const wmaScrollToTop= () => {
    screenTopRef.current.scrollIntoView({ behavior: "smooth"})
  }
  
  // User toggle if user already started
  const [ firstVisit, setFirstVisit ] = useState(true)
  const toggleFirstVisit = () => {
    setFirstVisit(!firstVisit)
  }

  // Landing screen box steps to next
  const nextStepLanding = () => {
    wmaScrollToTop()
    if(stepOne && !stepTwo && !stepThree && !stepFour){
      setStepTwo(true);
    } else if (stepOne && stepTwo && !stepThree && !stepFour){
      setStepThree(true);
    } else if (stepOne && stepTwo && stepThree && !stepFour) {
      setStepFour(true);
    }
  }

  const wmaUserIdSubmit = (e) =>{
    e.preventDefault();
    // Scroll to the top after submit
    // window.scrollTo(0, 0)
    // window.scrollTo({top:0, left:0, behavior:"smooth"})
    wmaScrollToTop()

    // Timer start
    handleStart()

    // If it is an email format
    if (wmaEmailRef.current.value.includes('@' && '.')){
      // Find the current user data
      for(let i = 0; i < allStudyUserList.length; i++){
        // if the email is registered
        if(Object.values(allStudyUserList[i])[0].email === wmaEmailRef.current.value.toLowerCase()){
          setCurrentStudyUser(allStudyUserList[i])
          setCurrentUserStep(Object.values(allStudyUserList[i])[0].progress)
          setPastUserAnswerCollection(Object.values(allStudyUserList[i])[0].answers)

          // FOR NOW 
          // randomize the seed questions
          const randomized = []
          const funcRandom = () => {
            const tempRemainedQsSeed = wmaDataRef.current.map(a => a)
            for(let i = 0; i < wmaDataRef.current.length; i++){
              const randomNum = Math.floor(Math.random() * (tempRemainedQsSeed.length))
              randomized.push(tempRemainedQsSeed[randomNum])
              tempRemainedQsSeed.splice(randomNum, 1)
            }
            setUserRemainedQuestions(randomized)
            // setCurrentDisplayImg(randomized[0].usView);
            currentDisplayImgRef.current = randomized[0].usView
            currentDisplayAnswerImgRef.current = randomized[0].answerUsView
          }
          // HOW IT SHOULD BE LATER
          // const userUniqueRemainedQuestionDB = Object.values(allStudyUserList[i])[0].remainedQs

          // randomize the seed questions
          // const randomized = []
          // const funcRandom = () => {
          //   const tempRemainedQsSeed = userUniqueRemainedQuestionDB.map(a => a)
          //   for(let i = 0; i < userUniqueRemainedQuestionDB.length; i++){
          //     const randomNum = Math.floor(Math.random() * (tempRemainedQsSeed.length))
          //     randomized.push(tempRemainedQsSeed[randomNum])
          //     tempRemainedQsSeed.splice(randomNum, 1)
          //   }
          //   setUserRemainedQuestions(randomized)
          // }
          funcRandom()
        }
        // if the email is new 
        else {
          console.log('none')
        }
      }
    // If it is not an email format
    } else {
      console.log('not an email')
      openEmailErrorAlert()
    }
  }

  const toggleImgZoom = (e) => {
    setIsImgZoomed(!isImgZoomed)
  }

  // Toggle info image
  const [ showInfoImg, setShowInfoImg ] = useState(false)
  const toggleInfoImg = (e) => {
    setShowInfoImg(!showInfoImg)
  }
 
  
  const wmaClickHandle = () => {
    let collectAnswers = document.querySelectorAll('input[type="checkbox"]:checked')
    const userSelectedAnswer = []

    // collect answers user chose
    for (let i = 0; i < collectAnswers.length; i++){
      userSelectedAnswer.push(Number(collectAnswers[i].value))
    }

    // Count checked answer to activate next button
    if(userSelectedAnswer.length >= 1){
      setNextButtonActive(true)
    } else {
      setNextButtonActive(false)
    }

    // find if chosen answers are correct
    for(let j = 0; j < userSelectedAnswer.length; j++){
      
    }
    console.log('collectAnswers', userSelectedAnswer)
    setCurrentQuestionUserChoices(userSelectedAnswer)
  }



  const [ isCurrentQuestionCorrect, setIsCurrentQuestionCorrect] = useState(null)
  
  const allOptionDOM = document.querySelectorAll('.wmaQuestions__option')
  const allOptionDOMDiv = []
  for(let p = 0; p < allOptionDOM.length; p++){
    allOptionDOMDiv.push(allOptionDOM[p].getElementsByTagName('div'))
  }
  


  const allCheckboxes = document.querySelectorAll('.wmaQuestions__option');
  const wmaShowAnswer = async(e) => {
    e.preventDefault();

    // Scroll to the top after submit
    wmaScrollToTop();

    // Stop the timer
    handlePause()

    // Selected options not changable after submit
    const allInputs = document.getElementsByTagName('input')
    for (let node of allInputs) { node.disabled = true; }

    setShowAnswer(true)
    setShowAnswerToggleOn(true)
    // Show Answer
    // Currect answers
    let currentQuestionCorrectAnswer = userRemainedQuestions[0].answer
    // User selected
    
    
    
    for (let j = 1; j < allCheckboxes.length + 1; j++){
      console.log('currentQuestionCorrectAnswer.includes(j) && userSelectedAnswer.includes(j)', currentQuestionCorrectAnswer.includes(j) && currentQuestionUserChoices.includes(j), currentQuestionCorrectAnswer, currentQuestionUserChoices, j)
      if( currentQuestionCorrectAnswer.includes(j) && currentQuestionUserChoices.includes(j)) {
        allCheckboxes[j - 1].classList += ' wmaCorrect'
        setIsCurrentQuestionCorrect(true)
        // Wrong & to show actual answers or user selected answer style
      } else if (currentQuestionCorrectAnswer.includes(j) || currentQuestionUserChoices.includes(j)){
        allCheckboxes[j - 1].classList += ' wmaWrong'
        setIsCurrentQuestionCorrect(false)
        // To show actual answer
        if(currentQuestionCorrectAnswer.includes(j)){
          allCheckboxes[j - 1].classList += ' wmaAnswerMark'
        } else if (currentQuestionUserChoices.includes(j)){
          allCheckboxes[j - 1].classList += ' wmaWrongMark'
        }
      }
    }
    

    // Update the past
    setPastUserAnswerCollection([...pastUserAnswerCollection, {"questionId": userRemainedQuestions[0].questionId, "correctAnswer": userRemainedQuestions[0].answer, "userSelectedAnswer": currentQuestionUserChoices, "timeTaken": formatTime(timer)}])

    // Remove the current Q from the remained Q list
    let updatedRemainedQuestions = []
    function updateRemainedQs () {
      if(userRemainedQuestions.length >= 2) {
        for(let i = 1; i < userRemainedQuestions.length; i++) {
          console.log('updating this q', userRemainedQuestions[i])
          updatedRemainedQuestions.push(userRemainedQuestions[i])
        }
      } else {
        updatedRemainedQuestions = []
      }
      
      setUserRemainedQuestions( updatedRemainedQuestions)
      // Update the images
      // const contentToUpdate = document.getElementById('wmaViewContent')
      // setCurrentDisplayImg(updatedRemainedQuestions.length >= 1 ? updatedRemainedQuestions[0].usView : null)
    }
    updateRemainedQs()
    currentDisplayImgRef.current = updatedRemainedQuestions.length >= 1 ? updatedRemainedQuestions[0].usView : null
    
    // Update DB
    const firebaseWmaDataRef = doc(db, "wma", Object.keys(currentStudyUser)[0])
    await updateDoc(firebaseWmaDataRef, {
      "progress": currentUserStep + 1,
      "remainedQs": updatedRemainedQuestions,
      "answers": [...pastUserAnswerCollection, {"questionId": userRemainedQuestions[0].questionId, "correctAnswer": userRemainedQuestions[0].answer, "userSelectedAnswer": currentQuestionUserChoices, "timeTaken": formatTime(timer)}]
    })
    .then(currentUserStep === 1 ? alert('Moving to the next question. You cannot come back to this page.') : null)
    .catch((err) => {console.err('Error moving to next WMA step: ', err)})

  }
  console.log('timer', formatTime(timer))


  const wmaNext = async(e) => {
    e.preventDefault();
    setShowAnswer(false)

    // Start new timer
    handleReset()
    handleStart()

    // release inputs to be able to selected
    const allInputs = document.getElementsByTagName('input')
    for (let node of allInputs) { node.disabled = false; }

    // Button deactive after click Next
    setNextButtonActive(false);
    // Scroll to the top after submit
    wmaScrollToTop()
    
    // Add Steps
    setCurrentUserStep(currentUserStep + 1);

    // During question steps, collect user answers & remove marks
    if( 1 <= currentUserStep <= (userRemainedQuestions.length - 1)){
      // Remove all user checks
      let collectAnswers = document.querySelectorAll('input[type="checkbox"]:checked')
      for (let i = 0; i < collectAnswers.length; i++){
        collectAnswers[i].checked = false;
      }
      // Remove all answer marks
      for (let y = 0; y < allCheckboxes.length; y++){
        allCheckboxes[y].checked = false;
        allCheckboxes[y].classList = 'wmaQuestions__option'
      }
    }
    
  }


  const wmaStart = async(e) => {
    // window.scrollTo(0, 0)
    wmaScrollToTop()
    setCurrentUserStep(1)
    const firebaseWmaDataRef = doc(db, "wma", Object.keys(currentStudyUser)[0])
    await updateDoc(firebaseWmaDataRef, {
      "progress": 1,
    })
    .catch((err) => {console.err('Error moving to next WMA step: ', err)})
  }

  const wmaComplete = () => {
    // window.scrollTo(0, 0)
    wmaScrollToTop()
    setCurrentUserStep(currentUserStep + 1)
    setIsCompleted(true)

    // Mark DB Progress done
    const firebaseWmaDataRef = doc(db, "wma", Object.keys(currentStudyUser)[0])
    updateDoc(firebaseWmaDataRef, {
      "progress": 'Complete',
      "remainedQs": userRemainedQuestions
    })
    .catch((err) => {console.err('Error moving to next WMA step: ', err)})
  }

  
  // console.log("currentUserStep", currentUserStep)
  // console.log('wmaDB[currentUserStep]', wmaDB[currentUserStep])

  return (
    <div className="wmaLandingScreen" ref={screenTopRef}>

      {/* Email Error Alert Popup */}
      <Dialog
        // fullScreen={fullScreen}
        open={showEmailErrorAlert}
        onClose={closeEmailErrorAlert}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Use a different email address"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Unfortunately, we cannot recognize your User ID as an email address. Please use a different email address to continue the process.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={closeEmailErrorAlert} autoFocus className="ub_solidPurple">Re-enter email</button><br /><br />
        </DialogActions>
      </Dialog>

      {/* First question cannot come back alert */}
      <Dialog
        // fullScreen={fullScreen}
        open={showFirstQuestionAlert}
        onClose={closeFirstQalert}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"You will not be able to come back"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            For a precise study result, you will not be able to pass a question nor come back to this question later. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={closeEmailErrorAlert} autoFocus className="ub_solidPurple">Re-enter email</button><br /><br />
        </DialogActions>
      </Dialog>


      { isImgZoomed ?
        <Dialog
          open={ isImgZoomed }
          onClose={ toggleImgZoom}
          className="wmaQuestions__zoomPopup"
        >
          <DialogContent>
            <p className="wmaQuestions__zoomClose" onClick={toggleImgZoom}>Close</p>
            {
              showAnswer && showAnswerToggleOn ? 
              <div className="wmaQuestions__zoomedUsView" id="imgUpdate" style={{backgroundImage : `url("${currentDisplayAnswerImgRef.current}")`}}>
                <video key={currentDisplayAnswerImgRef.current} playsInline autoPlay muted loop>
                  <source id="imgUpdate" src={`${currentDisplayAnswerImgRef.current}`} />
                </video>
              </div>
              :
              <div className="wmaQuestions__zoomedUsView" id="imgUpdate" style={{backgroundImage : `url("${currentDisplayImgRef.current}")`}}>
                <video key={currentDisplayImgRef.current} playsInline autoPlay muted loop>
                  <source id="imgUpdate" src={`${currentDisplayImgRef.current}`} />
                </video>
              </div>
            }
          </DialogContent>
        </Dialog>
      : null}

      {/* {!currentStudyUser ?
        <div className="wmaLanding__revisitToggle">
          <p className="smallText">Do you have a user ID already?</p>
          <div className="wmaLanding__revisitToggleBg" style={{justifyContent: firstVisit ? "flex-start" : "flex-end"}} onClick={toggleFirstVisit}>
            <div className="wmaLanding__revisitToggleButton" style={{backgroundColor: firstVisit ? "#999" : "#1976d2"}}></div>
          </div>
        </div>
      : null} */}
      {!currentStudyUser && !firstVisit ?
        <div className="wmaLanding__intro">
          <div className="wmaLanding__processWrapper">
            <div className="wmaLanding__input">
              <h1>Welcome back</h1><br />
              <form>
                <input type="text" placeholder="User ID" name="wma-email" ref={wmaEmailRef} />
                <button type="submit" className="ub_solidPurple" onClick={wmaUserIdSubmit}>Resume</button>
              </form>
              <p className="wmaLanding__input-help smallText"><br />If you have any questions, feel free to <a className="ub_textPurple" href="https://ultrasoundbox.com/contact">contact us</a> at ultrasoundbox.official@gmail.com
              <br /><br /><br />
              </p>
              <div className="wmaLanding__revisitToggle">
                <p className="ub_textPurple" onClick={toggleFirstVisit}>Register and join study</p>
              </div>
            </div>
            
          </div>
        </div>
      : null }
      {!currentStudyUser && firstVisit ?
        <div className="wmaLanding__intro">
          <div className="wmaLanding__processWrapper">
            <div className="wmaLanding__processLeftPanel">
              <p className={`${stepOne && !stepTwo ? "wmaLanding__processCurrent" : ""} ${stepOne && stepTwo ? "wmaLanding__processPast": ""}`}><LooksOneIcon /> Introduction</p>
              <p className={`${stepTwo && !stepThree ? "wmaLanding__processCurrent" : ""}${stepTwo && stepThree ? "wmaLanding__processPast" : ""}`}>{ stepTwo ? <LooksTwoIcon /> : <LooksTwoOutlinedIcon /> } Consent</p>
              <p className={`${stepThree && !stepFour ? "wmaLanding__processCurrent" : ""}${stepThree && stepFour ? "wmaLanding__processPast" : ""}`}>{ stepThree ? <Looks3Icon /> : <Looks3OutlinedIcon />} Account</p>
              <p className={`${stepFour ? "wmaLanding__processCurrent": ""}`}>{ stepFour ? <Looks4Icon /> : <Looks4OutlinedIcon />} Pre-Survey</p>

              <div className="wmaLanding__revisitToggle">
                <p className="ub_textPurple" onClick={toggleFirstVisit}>Have a user ID already?</p>
              </div>

            
            </div>
            
            { stepOne && !stepTwo && !stepThree && !stepFour ? 
            <div className="wmaLanding__processRightPanel">
              <div className="wmaLanding__input">
                <h1>Regional Wall Motion Abnormality Module</h1>
                <p className="smallText">Please read to the bottom to move to the next step</p>
              </div>
              <br />
              <div className="wmaLanding__intro-video">
                <iframe width="640" height="360" src="https://www.youtube.com/embed/TWviIgG2V60" title="Point-of-Care Echo: Regional Wall Motion Abnormalities" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <p>Regional wall motion abnormalities (RWMAs) can predict cardiac events in ED patients with chest pain and are an independent variable of acute coronary atherothrombosis (ACA) (4). Detection of RWMAs can change management in the ED and their presence or absence can be helpful in guiding management of cardiac patients.
                <br /><br />
                We hope our module, consisting of both normal and pathologic ECHO images in the parasternal short view, will give you the ability to recognize RWMAs. We are going analyze all of the data collected during the completion of this module in an anonymous fashion to study the effectives of this module. There is a pre and a post survey on the first and last question.<br />
                </p>
                <button className="ub_solidPurple" onClick={nextStepLanding}>Next</button>
              </div>
              
            </div>
            : null}


            { stepOne && stepTwo && !stepThree && !stepFour ? 
            <div className="wmaLanding__processRightPanel">
              <div className="wmaLanding__input">
                <h1>Consent Form</h1>
                <p>Please read to the bottom to move to the next step</p>
              </div>
              <br />
              <div className="wmaLanding__intro-video">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                <br /><br />
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                </p>
              </div>
              <br /><br />
              <button className="ub_solidPurple" onClick={nextStepLanding}>Next</button>
            </div>
            : null}


            { stepOne && stepTwo && stepThree && !stepFour ? 
            <div className="wmaLanding__processRightPanel">
              <div className="wmaLanding__input">
                <h1>Let's start</h1>
                <form>
                  <input type="text" placeholder="User ID" name="wma-email" ref={wmaEmailRef} />
                  <button type="submit" className="ub_solidPurple" onClick={wmaUserIdSubmit}>Start</button>
                </form>
              </div>
              <p className="wmaLanding__input-help smallText"><br />If you have any questions, feel free to <a className="ub_textPurple" href="https://ultrasoundbox.com/contact">contact us</a> at ultrasoundbox.official@gmail.com
              </p>
            </div>
            : null}

            { stepOne && stepTwo && stepThree && stepFour ? 
            <div className="wmaLanding__processRightPanel">
              <div className="wmaLanding__input">
                <h1>Pre-survey</h1>
              </div>
              <WmaPreSurvey />
              <br /><br />
              <button className="ub_solidPurple" onClick={nextStepLanding}>Next</button>
            </div>
            : null}
            
          </div>

        </div>
      :
      null }
      
      {currentStudyUser && (currentUserStep === 0) ? 
        <WmaPreSurvey />
      :
      null
      }

      {currentStudyUser && (currentUserStep === wmaDB.length + 1) ? 
        <WmaPostSurvey />
      :
      null
      }

      {
        isCompleted ?
          <div className="wmaLanding__complete">
            <div className="wmaLanding__complete-contentWrapper">
              <h1>Thank you!</h1><br />
              <p>If you have any questions, feel free to <a className="ub_textPurple" href="https://ultrasoundbox.com/contact">contact us</a> at ultrasoundbox.official@gmail.com</p>
            </div>
          </div>
        :
        null
      }

      {/* Question progress bar */}

      {
        showInfoImg && wmaDB[currentUserStep - 1].questionModel === 'parasternal short' ? 
        <div className="wmaInfoImg__wrapper" onClick={toggleInfoImg}>
          <div className="wmaInfoImg__content">
            <h2>Segmentation of the left ventricle</h2>
            <p>Parasternal short-axis view (PSAX)</p>
            <div className="wmaInfoImg__content-close smallText">
              <CloseIcon /> Close
            </div>
            <img src="https://ultrasoundbox.s3.amazonaws.com/wma+module/Reference+Pics/parasternalLong.png" />
          </div>
        </div>
        : null
      }
      {
        showInfoImg && wmaDB[currentUserStep - 1].questionModel === 'parasternal long' ? 
        <div className="wmaInfoImg__wrapper" onClick={toggleInfoImg}>
          <div className="wmaInfoImg__content">
            <h2>Segmentation of the left ventricle</h2>
            <p>Parasternal long-axis view (PLAX)</p>
            <div className="wmaInfoImg__content-close smallText">
              <CloseIcon /> Close
            </div>
            <img src="https://ultrasoundbox.s3.amazonaws.com/wma+module/Reference+Pics/parasternalShort.png" />
          </div>
        </div>
        : null
      }
      {
        showInfoImg && wmaDB[currentUserStep - 1].questionModel === 'apical 4' ? 
        <div className="wmaInfoImg__wrapper" onClick={toggleInfoImg}>
          <div className="wmaInfoImg__content">
            <h2>Segmentation of the left ventricle</h2>
            <p>Apical 4 - chamber view (A4C)</p>
            <div className="wmaInfoImg__content-close smallText">
              <CloseIcon /> Close
            </div>
            <img src="https://ultrasoundbox.s3.amazonaws.com/wma+module/Reference+Pics/apical4.png" />
          </div>
        </div>
        : null
      }
      {currentStudyUser && (wmaDB.length >= currentUserStep && currentUserStep >= 1) ?
        <div className="wmaLanding__progress">
          <div className="wmaLanding__progressBg">
            
            <div className="wmaLanding__prograssBarWrapper">
              <span style={{fontSize:"0.7em", paddingRight: "10px", color: "#999"}}>Progress: </span>
              <div className="wmaLanding__progressBar" style={{background: "#ededed", color:"#666", width:"auto", padding: "0 10px"}}>Pre-survey</div>
            { wmaDB.map((question, index) => {
              return (
                <div key={index} className="wmaLanding__progressBar" style={{
                  // width:`calc(100% / ${wmaDB.length})`, 
                  height: "24px", background: (index + 1) < currentUserStep ? '#ededed' : '#fff', color: (index + 1) <= currentUserStep ? "#666" : "#666", boxShadow: (index + 1) === currentUserStep ? "0 0 0 2px #1976d2 inset" : "0 0 0 1px #ededed inset" }}>
                  {index + 1}
                </div>
              )
            })}
              <div className="wmaLanding__progressBar" style={{background:'#fff', boxShadow:"0 0 0 1px #ededed inset", width:"auto", color:"#666", padding: "0 10px"}}>Complete</div>
            </div>
          </div>
        </div>
      :
      null
      }


      {/* Question content section */}
      {currentStudyUser && (wmaDB.length >= currentUserStep && currentUserStep >= 1) ?
        <div className="wmaQuestions__content">


          {/* Ultrasound view */}
          <div className="wmaQuestions__view">
            {
              showAnswer && showAnswerToggleOn ? 
              <div className="wmaQuestions__usview" id="imgUpdate" style={{backgroundImage : `url("${currentDisplayAnswerImgRef.current}")`}}>
                <video key={currentDisplayAnswerImgRef.current} playsInline autoPlay muted loop>
                  <source id="imgUpdate" src={`${currentDisplayAnswerImgRef.current}`} />
                </video>
              </div>
              :
              <div className="wmaQuestions__usview" id="imgUpdate" style={{backgroundImage : `url("${currentDisplayImgRef.current}")`}}>
                <video key={currentDisplayImgRef.current} playsInline autoPlay muted loop>
                  <source id="imgUpdate" src={`${currentDisplayImgRef.current}`} />
                </video>
              </div>
            }
            {/* Small torso img on the left bottom */}
            <img className="wmaQuestions__torso" src="https://ultrasoundbox.s3.amazonaws.com/wma/WMA_torso.png" alt="" />
            <ZoomInIcon onClick={toggleImgZoom} className="wmaQuestions__zoomIcon" />
          </div>
          {/* { isImgLoading ? null : <WmaImageComponent currentImg={currentDisplayImgRef.current} />} */}
          

          {/* Question section - right side */}
          <div className="wmaQuestions__questionWrapper">
            <p className="wmaQuestions__question">
              <span className="biggerText fontPurple" style={{fontWeight:"bold"}}>{currentUserStep}</span> / 70
              { showAnswer ? 
              <span className="wmaQuestions__correctWrongBadge" style={{backgroundColor: isCurrentQuestionCorrect ? "#709e3f" : "#cb4949"}}>
                { isCurrentQuestionCorrect ? "Correct" : "Incorrect"}
              </span>
              : null}
              <br />
              Where do you see Wall Motion Abnormality in this Ultrasound view?
              <br />
              <span onClick={toggleInfoImg} className="ub_textPurple">Show reference</span>
            </p>
            <form>
              {
                wmaDB[currentUserStep - 1].questionModel === 'parasternal short' ? 
                <div className="wmaQuestions__optionWrapper">
                  { parasternalShort.map( choice => {
                    return (
                      <div className="wmaQuestions__option" key={choice}>
                        <input type="checkbox" id="" name="" value="1" onClick={wmaClickHandle} />
                        <label className="" htmlFor="1">{choice}</label>
                      </div>
                    )
                  })}
                </div>
                : null
              }
              {
                wmaDB[currentUserStep - 1].questionModel === 'parasternal long' ? 
                <div className="wmaQuestions__optionWrapper">
                  { parasternalLong.map( choice => {
                    return (
                      <div className="wmaQuestions__option" key={choice}>
                        <input type="checkbox" id="" name="" value="1" onClick={wmaClickHandle} />
                        <label className="" htmlFor="1">{choice}</label>
                      </div>
                    )
                  })}
                </div>
                : null
              }
              {
                wmaDB[currentUserStep - 1].questionModel === 'apical 4' ? 
                <div className="wmaQuestions__optionWrapper">
                  { apical4.map( choice => {
                    return (
                      <div className="wmaQuestions__option" key={choice}>
                        <input type="checkbox" id="" name="" value="1" onClick={wmaClickHandle} />
                        <label className="" htmlFor="1">{choice}</label>
                      </div>
                    )
                  })}
                </div>
                : null
              }
            </form>
            { showAnswer ? 
              <div className="wmaQuestions__answerViewToggleWrapper" onClick={() => setShowAnswerToggleOn(!showAnswerToggleOn)}>
                <span className="smallText">View answer </span>
                <div className="wmaQuestions__answerViewToggleBg" style={{justifyContent: showAnswerToggleOn ? "flex-end" : "flex-start"}}>
                  <div className="wmaQuestions__answerViewToggle" style={{backgroundColor: showAnswerToggleOn ? "#1976d2" : "#999"}}></div>
                </div>
              </div>
              :null
              }
          </div>

        </div>
      :
      null
      }

      {currentStudyUser && (wmaDB.length >= currentUserStep && currentUserStep >= 1) ?
        <div className="wmaLanding__nextButton">
          <button 
          key={showAnswer.current}
          // Once figure out Submit button == Next button
          // disabled={ nextButtonActive ? false : true }
          type="next" className="ub_solidPurple" onClick={ showAnswer ? wmaNext : wmaShowAnswer}>{ showAnswer ? "Next" : "Check answer"}</button>
        </div>
      : null }

      {currentStudyUser && (currentUserStep === 0) ?
        <div className="wmaLanding__nextButton">
          <button 
          // Once figure out Submit button == Next button
          // disabled={ nextButtonActive ? false : true } 
          type="next" className="ub_solidPurple" onClick={wmaStart}>Start Questions</button>
        </div>
      : null }
      {currentStudyUser && (wmaDB.length + 1 === currentUserStep ) ?
        <div className="wmaLanding__nextButton">
          <button 
          // Once figure out Submit button == Next button
          // disabled={ nextButtonActive ? false : true } 
          type="next" className="ub_solidPurple" onClick={wmaComplete}>Complete</button>
        </div>
      : null }
      
    </div>
  )
}

export default WmaLandingScreen