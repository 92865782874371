import { Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectUser } from '../features/userSlice'
import db, { auth } from '../firebase'
import { doc, updateDoc } from "firebase/firestore"; 
import Axios from 'axios'


import './ProfileScreen.css'

import DataSummary from '../DataSummary'

function ProfileScreen() {

  // Open the page from the top
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);
  
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const titleRef = useRef(null);
  const institutionRef = useRef(null);

  const [accountId, setAccountId] = useState([])
  const [accounts, setAccounts] = useState([])
  const [isUpdating, setIsUpdating] = useState(false)

  const [ oldPhoto, setOldPhoto ] = useState();
  const [ oldFirstName, setOldFirstName ] = useState();
  const [ oldLastName, setOldLastName ] = useState();
  const [ oldTitle, setOldTitle ] = useState();
  const [ oldInstitution, setOldInstitution ] = useState();

  
  
  const userLogOut = () => {
    // navigate('/')
    auth.signOut()
    navigate('/')
    // window.location.reload()
  }
  
  useEffect(() => {
    db.collection('users')
    .where("email", "==", auth.currentUser.email)
    .get().then(querySnapshot => {
      const accounts = {};
      querySnapshot.forEach(async userDoc => {
        accounts[userDoc.id] = userDoc.data()
      });
      setAccounts(Object.values(accounts)[0])
      setAccountId(Object.keys(accounts)[0])
      setOldPhoto(Object.values(accounts)[0].photo ? Object.values(accounts)[0].photo : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png')
      setOldFirstName(Object.values(accounts)[0].firstName)
      setOldLastName(Object.values(accounts)[0].lastName)
      setOldTitle(Object.values(accounts)[0].title)
      setOldInstitution(Object.values(accounts)[0].institution)
    })
  }, [])

  const [updatePhoto, setUpdatePhoto] = useState('')
  const updateProfilePhoto = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0])
    formData.append("upload_preset", "s8qubgyt")

    Axios.post("https://api.cloudinary.com/v1_1/ultrasoundbox/profiles/upload", formData)
    .then((response) => setUpdatePhoto(response.data.url));
  }

  const updateProfile = async() => {
    const firebaseUserRef = doc(db, "users", accountId)
    await updateDoc(firebaseUserRef, {
      "firstName": firstNameRef.current.value ? firstNameRef.current.value : oldFirstName,
      "lastName": lastNameRef.current.value ? lastNameRef.current.value : oldLastName,
      "title": titleRef.current.value ? titleRef.current.value : oldTitle,
      "institution": institutionRef.current.value ? institutionRef.current.value : oldInstitution
    })
    .then(alert('Your profile is successfully updated!'))
    .catch((err) => {console.error("Error updating profile: ", err)})
    window.location.reload()
  }


  console.log(accounts)

  return (
    <div className="profileScreen">


      <div className="profileScreen__body"> 
      {isUpdating ? (
        // Updating profile
        <div className="profileScreen__info">
          
          <div className="profileScreen__details">
            <h2>Update your profile<br /><br /></h2>
            <div className="profileScreen__photoWrapper">
              <div className="profileScreen__photo" style={{backgroundImage: `url("${updatePhoto ? updatePhoto : oldPhoto}")`}}></div>
            </div>
            <input type="file" resource_type="all" name="attachment" onChange={(e) => updateProfilePhoto(e)} />

            <div className="profileScreen__profile">
              <div className="profileScreen__profileUpdateInput">
                <span className='smallText'>First name</span>
                <input ref={firstNameRef} placeholder={oldFirstName} type="text" />
              </div>
              <div className="profileScreen__profileUpdateInput">
                <span className='smallText'>Last name</span>
                <input ref={lastNameRef} placeholder={oldLastName} type="text" />
              </div>
              <div className="profileScreen__profileUpdateInput">
                <span className='smallText'>Title</span>
                <input ref={titleRef} placeholder={oldTitle ? oldTitle : 'Title'} type="text" />
              </div>
              <div className="profileScreen__profileUpdateInput">
                <span className='smallText'>Institution</span>
                <input ref={institutionRef} placeholder={oldInstitution ? oldInstitution : 'Institution'} type="text" />
              </div>
              
            </div>
            <div className="">
              <button type="submit" className="ub_solidPurple"  onClick={updateProfile}>Update</button>
              <br /><br />
              <button onClick={() => setIsUpdating(false)} className="ub_textPurple">Cancel</button>
            </div>
          </div>
        </div>
      ) : (

        // Showing profile
        <div className="profileScreen__info">
          <div className="profileScreen__details">
            <h1>Profile</h1>
            <br />
            <div className="profileScreen__photoWrapper">
              <div className="profileScreen__photo" style={{backgroundImage: `url("${oldPhoto}")`}}></div>
            </div>
            <div className="profileScreen__profile">
              <p><span className='smallText'>Email</span>{accounts.email ? accounts.email : ""}</p>
              <p><span className='smallText'>Full Name</span>{accounts.firstName} {accounts.lastName}</p>
              <p><span className='smallText'>Title</span>{accounts.title ? accounts.title : "N/A"}</p>
              <p><span className='smallText'>Institution</span>{accounts.institution}</p>
            </div>
            <div className="">
              {/* <button onClick={() => setIsUpdating(true)} className="ub_textPurple">Update profile</button>
              <br /><br /> */} 
              {accounts.isAdmin ? <button onClick={() => navigate('/admin')} className="ub_solidPurple__secondary">Admin portal</button> : ""}
              <button className="ub_solidPurple" onClick={() => setIsUpdating(true)}>Update profile</button>
              <br /><br />
              <button onClick={userLogOut} className="profileScreen__signOut ub_textPurple">Log Out</button>
              
            </div>
          </div>
        </div>
      )}
      </div>

      {/* <div className="profileScreen__data">
        <DataSummary />
      </div> */}
    </div>
  )
}

export default ProfileScreen
