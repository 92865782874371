import { Button } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import './CardioBlockList.css'
import thoracicBlockList from './seed/thoracicBlockSeed'

import db, { auth } from './firebase'



function ThoracicBlockList(props) {
  let navigate = useNavigate();


  const thoracicBlockListRef = useRef(thoracicBlockList)

  let countTimes = 0;
  const countHowManyTimes = (num) => {
    countTimes = 0;
    props.data.allThoracicTakes.map((take) => {
      if (Number(take.thoracicBlockId) === num) countTimes++
    })
    return countTimes
  }

  let highestScore = 0;
  const findHighestScore = (num) => {
    highestScore = 0;
    props.data.allThoracicTakes.map((take) => {
      if (Number(take.thoracicBlockId) === num && highestScore < take.takeScore) highestScore = take.takeScore
    })
    return highestScore;
  }



  return (
    <div className="blockList">
      { thoracicBlockListRef.current.map((b, index) => {
        
        let currentDifficulty = {};
        if (b.difficultyLevel && b.difficultyLevel === "difficulty1"){
          currentDifficulty = props.data.difficulty.difficulty1
        } else if(b.difficultyLevel && b.difficultyLevel === "difficulty2") {
          currentDifficulty = props.data.difficulty.difficulty2
        } else if(b.difficultyLevel && b.difficultyLevel === "difficulty3") {
          currentDifficulty = props.data.difficulty.difficulty3
        } else {
          currentDifficulty = {
            difficultyId: "Unknown",
            for: "Unknown",
            level: "Unknown"
          }
        }

        return (
          <div key={index} className="blockList__block" onClick={() => {navigate(`/thoracic/block-${index + 1}`, { state: `${index}`})}}>
            
            <div className="blockList__blockInfoWrap">
              <span className="blockList__difficulty smallText">
                { currentDifficulty.difficultyId === '1' ? (
                  <div className="qEasyDot qLevelDot"></div>
                ) : ("")}
                { currentDifficulty.difficultyId === '2' ? (
                  <div className="qIntermediateDot qLevelDot"></div>
                ) : ("")}
                { currentDifficulty.difficultyId === '3' ? (
                  <div className="qAdvancedDot qLevelDot"></div>
                ) : ("")}
                {currentDifficulty.level}
              </span>
              <span className="blockList__blockCategory smallText">Thoracic</span>
              <h2 className="blockList__blockNumber">{index < 9 ? '0' : ''}{index + 1}</h2>
              { auth.currentUser !== null && countHowManyTimes(index + 1) === 0 ? (
                <div className="blockList__takeData neverTaken smallText">
                  <span>No record yet</span>
                </div>
              ) : ("")}
              { auth.currentUser !== null && countHowManyTimes(index + 1) > 0 ? (
                <div className="blockList__takeData smallText">
                  {/* <span>Taken {countHowManyTimes(index + 1)} times</span> */}
                  <span>Best {findHighestScore(index + 1)}%</span>
                </div>
              ) : ("")}
            </div>

            <div className="blockList__tags">
              { b.questionTags ? (
                b.questionTags.map((tag) => {
                  return(
                    <p key={tag}>{tag}</p>
                  )
                })
              ) : ("")}
            </div>
            
            <div className='blockList__blockButton'>
              <button className="ub_textPurple">Take</button>
            </div>
            
          </div>
        )
      })}
    
    </div>
  )
}

export default ThoracicBlockList
